
import {Vue } from 'vue-property-decorator';
import ApplicantViewTemplate from '@/components/templates/ApplicantTemplate/ApplicantViewTemplate.vue';
import * as Config from '@/config';
import auth from '@/auth';
import moment from 'moment';
export interface DataType {
  // Props
  isAction: number;
  loading: boolean;
  isRegistering: boolean;
  resData: any;
  resHisData: any;
  listGates: any;
  resCardData: any;
  resExtData: any;
  errorMessageLogs: string;
  isDisabledCancelApplication: boolean;

}
export default Vue.extend({
  name: 'ApplicantPage',
  components: {
    ApplicantViewTemplate,
  },
  data: (): DataType => ({
    // Props
    loading: false,
    isRegistering: false, // default: false -> reading data
    isAction: 0,
    resData: {},
    resExtData: {},
    resHisData: [],
    listGates: [],
    resCardData: {},
    errorMessageLogs: '',
    isDisabledCancelApplication: false,
  }),
  watch: {
    loading(value) {
      this.$emit('loading', value);
      if (value) {
        this.$emit('textCircle', this.isRegistering ? Config.TEXT_PROGRESS_REGISTERING : Config.TEXT_PROGRESS_READING);
      } else if (this.isRegistering) {
        this.isRegistering = false;
      }
    },
  },
  mounted() {
    this.$emit('activeMenu', 'applicant');
    const dataQuery = this.$route.query;
    if (dataQuery.request_id !== undefined) {
      this.loading = true;
      this.getListGate().then(() => {
        this.getListData(dataQuery.request_id.toString());
      });
    }
  },
  created() {
    //
  },
  methods: {
    openExtensions(args: any) {
      this.resExtData = {};
      const apiLink = Config.ENTRY_REQUESTS_API + '/' + this.$route.query.request_id + '/extensions';
      this.$http.get(apiLink)
        .then((response: any) => {
          console.log(response.body.extensions);
          if (response.body.extensions.length > 0) {
            const result = response.body.extensions.slice(-1);
            this.resExtData = result[0];
            if (result[0].status === 'waiting') {
              this.isDisabledCancelApplication = true;
            }
          }
        })
        .then( null, (err: any) => {
          console.log(err);
        }); // catch
    },
    actionApproval(args: any) {
      this.loading = true;
      const dataQuery = this.$route.query;
      let data = {};
      data = {comment: args.data};
      console.log(args);
      if (args.action === 'extensions') {
        const m = moment(this.resData.end);
        switch (args.workDate) {
          case '入室終了日時+2時間' : m.add(2, 'hours'); break;
          case '入室終了日時+4時間' : m.add(4, 'hours'); break;
          case '入室終了日時+6時間' : m.add(6, 'hours'); break;
        }
        data = {
          end: auth.formatISOString(m),
          comment: args.description,
        };
      }
      this.$http.post(Config.ENTRY_REQUESTS_API + '/' + dataQuery.request_id + '/' + args.action, data)
      .then((response: any) => {
        if (args.action === 'cancellations') {
          alert(Config.TEXT_CANCELLATION_COMPLETED);
        } else {
          if (args.isResend) {
            alert(Config.TEXT_RESEND_EMAIL_COMPLETED);
          } else {
            alert(Config.TEXT_SUBMISSION_COMPLETED);
          }
        }
        this.loading = false;
        if (args.action === 'submissions') {
          this.$router.push({ path: Config.APPLICANT});
        } else {
          this.getListData(dataQuery.request_id.toString());
        }
      })
      .then( null, (err: any) => {
        if (args.action === 'cancellations') {
          alert(Config.TEXT_CANCELLATION_FAILED);
        } else {
          if (args.isResend) {
            alert(Config.TEXT_RESEND_EMAIL_FAILED);
          } else {
            if (err.status === 400) {
              alert(Config.TEXT_SUBMISSION_PROBLEM);
            } else if (err.status === 409) {
              alert(Config.TEXT_SUBMISSION_STATE_PROBLEM);
            } else {
              alert(Config.TEXT_SUBMISSION_FAILED);
            }
          }
        }
        this.loading = false;
      }); // catch
    },
    getExtensions(val: any) {
      return new Promise((resolve, reject) => {
        const apiLink = Config.ENTRY_REQUESTS_API + '/' + val + '/extensions';
        this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.extensions.length > 0) {
            const result = response.body.extensions.slice(-1);
            this.resExtData = result[0];
            if (result[0].status === 'waiting') {
              this.isDisabledCancelApplication = true;
            }
          }
          resolve(true);
        })
        .then( null, (err: any) => {
          console.log(err);
          resolve(true);
        }); // catch
      });
    },
    getListData(ID: any): any {
      this.loading = true;
      this.resData = {};
      const apiLink = Config.ENTRY_REQUESTS_API + '/' + ID;
      this.$http.get(apiLink)
        .then(async (response: any) => {
          this.resData = response.body.request;
          this.resData.begin = (moment(response.body.request.begin).add(15, 'minutes'));
          console.log(auth.converDateOnlyTime(this.resData.begin));
          if (this.resData.office_code !== undefined &&
              this.resData.office_code !== null &&
              this.resData.office_code !== '') {
            await this.getOfficeDetail(this.resData.office_code);
          }
          if (this.resData.area_code !== undefined &&
              this.resData.area_code !== null &&
              this.resData.area_code !== '') {
            await this.getAreasDetail(this.resData.area_code);
          }
          this.resData.listGate = '';
          const gates = this.listGates.filter((o1: { code: any; }) =>
          this.resData.gates.some((o2: { code: any; }) => o1.code === o2.code));
          if (gates.length > 0) {
            this.resData.listGates = gates.map((item: { name: any; }) => item.name).join(', ');
          }
          if (this.resData.requester_uid !== undefined &&
              this.resData.requester_uid !== null &&
              this.resData.requester_uid !== '') {
            await this.getListCard(this.resData.requester_uid);
          }
          this.resData.organizations =
          this.resData.requester_organizations.map((item: { label: any; }) => item.label).join(', ');
          await this.getExtensions(ID);
          this.getListHistoryData(ID);
          this.loading = false;
        })
        .then( null, (err: any) => {
          this.loading = false;
          this.errorMessageLogs = Config.TEXT_ENTRY_REQUESTS_LIST_FAILED;
        }); // catch
    },
    getAreasDetail(ID: any): any {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.AREAS_API + '?area_code=' + ID)
          .then((response: any) => {
            if (response.body.areas.length > 0) {
              const data = response.body.areas[0];
              this.resData.area_name = data.name;
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getOfficeDetail(ID: any): any {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.OFFICES_LIST_API + '?office_code=' + ID)
          .then((response: any) => {
            if (response.body.offices.length > 0) {
              const data = response.body.offices[0];
              this.resData.office_name = data.name;
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getListGate(): any {
      return new Promise((resolve, reject) => {
        this.resData.gates = [];
        this.$http.get(Config.GATES_API + '?is_status=false')
          .then((response: any) => {
            if (response.body.gates.length > 0) {
              response.body.gates.forEach(async (element: any, key: any) => {
                this.listGates.push({
                  code : element.code,
                  name: element.name,
                });
              });
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getGates(ID: any): any {
      return new Promise((resolve, reject) => {
        this.resData.gates = [];
        this.$http.get(Config.GATES_API + '?area_code=' + ID + '&is_status=false')
          .then((response: any) => {
            if (response.body.gates.length > 0) {
              response.body.gates.forEach(async (element: any, key: any) => {
                this.resData.gates.push(element.name);
              });
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getListCard(ID: any): any {
      return new Promise((resolve, reject) => {
        const apiLink = Config.CARD_LIST_API + '/' + ID;
        this.$http.get(apiLink)
          .then((response: any) => {
            this.resCardData = response.body.cards;
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getListHistoryData(ID: any): any {
      this.resHisData = [];
      // this.resHisData = [];
      const apiLink = Config.ENTRY_REQUESTS_API + '/' + ID + '/histories';
      this.$http.get(apiLink)
        .then((response: any) => {
          this.resHisData = response.body.histories;
          if (response.body.histories.length > 0) {
            response.body.histories.forEach(async (element: any, key: any) => {
                element.organizations = element.organizations.map((item: { label: any; }) => item.label).join(', ');
            });
          }
        })
        .then( null, (err: any) => {
          //
        }); // catch
    },
    getUserItemForHistory(userId: any, key: any) {
      return new Promise((resolve, reject) => {
        const apiLink = Config.USERS_LIST_API + '/' + userId;
        this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.user !== null) {
            const res = response.body.user;
            this.resHisData[key].user_name = res.name;
            if (res.organizations.length > 0) {
              this.resHisData[key].organizations =
                  res.organizations.map((item: { label: any; }) => item.label).join(', ');
            }
            resolve(true);
          }
        })
        .then(null, (err: any) => {
          this.resData[key].user_name = '';
          this.resData[key].organizations = '';
          resolve(false);
        });
      });
    },
    getUserItem(userId: any) {
      return new Promise((resolve, reject) => {
      const apiLink = Config.USERS_LIST_API + '?user_id=' + userId;
      this.$http.get(apiLink)
      .then((response: any) => {
        if (response.body.users.length > 0) {
          const res = response.body.users[0];
          this.resData.user_name = res.name;
          if (res.organizations.length > 0) {
            this.resData.organizations = res.organizations.map((item: { label: any; }) => item.label).join(', ');
          }
          resolve(true);
        }
      })
      .then(null, (err: any) => {
        this.resData.user_name = '';
        this.resData.organizations = '';
        resolve(false);
        });
      });
    },
  },
});
