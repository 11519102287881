import _Vue from 'vue';
import router from './router';
import store from './store';
// @ts-ignore
import jwtDecode from 'jwt-decode';

import * as Config from './config';
import main from './main';
import axios from 'axios';
import moment from 'moment-timezone';
import { sha256 } from 'js-sha256';

// Auth Plugin
// (see https://vuejs.org/v2/guide/plugins.html for more info on Vue.js plugins)
// Handles login and token authentication using OAuth2.
interface Response {
  status: any;
  body: {
    message: any;
  };
  url: any;
}

export default {

  /**
   * Install the Auth class.
   *
   * Creates a Vue-resource http interceptor to handle automatically adding auth headers
   * and refreshing tokens. Then attaches this object to the global Vue (as Vue.auth).
   *
   * @param {Object} Vue The global Vue.
   * @param {Object} options Any options we want to have in our plugin.
   * @return {void}
   */
  install(vue: any, options: any): void {
    vue.http.interceptors.push((request: any, next: any) => {
      const token = sessionStorage.getItem('accessToken');
      const hasAuthHeader = request.headers.has('Authorization');
      if (token && !hasAuthHeader) {
        this.setAuthHeader(request);
      }
      // set x-api-key for request headers
      const hasApiKey = request.headers.has('x-api-key');
      if (!hasApiKey && sessionStorage.getItem('ownerId') &&
          sessionStorage.getItem('ownerId') !== '') {
        request.headers.set('x-api-key',
                            sha256(Config.API_KEY_PREFIX +
                              sessionStorage.getItem('ownerId') +
                            Config.API_KEY_SUFFIX));
      }
      next((response: any) => {
        if (this.isInvalidToken(response)) {
          window.alert = () => {
            //
          };
          window.location.href = location.origin + Config.LOGIN + '?kanriId=' +
          sessionStorage.getItem('ownerId') + '&userId=' + sessionStorage.getItem('user_login_id') + '&clear=true';
        }
      });
    });
    vue.prototype.$auth = vue.auth = this;
  },
  /**
   * Logout
   *
   * Clear all data in our Vuex store (which resets logged-in status) and redirect back
   * to login form.
   *
   * @return {void}
   */
  async logout(isResetPass: boolean = false): Promise<void> {
    const ownerId: any = sessionStorage.getItem('ownerId');
    const userId: any = sessionStorage.getItem(isResetPass ? 'userId' : 'userProfileId');
    localStorage.setItem('ownerId', ownerId);
    localStorage.setItem('userId', userId);
    sessionStorage.clear();
    await this.waitProcess(100);
    sessionStorage.setItem('ownerId', localStorage.ownerId.toString());
    sessionStorage.setItem('userId', localStorage.userId.toString());
    await this.waitProcess(100);
    store.commit('CLEAR_ALL_DATA');
    localStorage.clear();
    router.push({ path: Config.LOGIN, query: { kanriId: sessionStorage.getItem('ownerId'),
                                               userId: sessionStorage.getItem('userId') } });
    window.location.reload();
  },
  /**
   * Set the Authorization header on a Vue-resource Request.
   *
   * @param {Request} request The Vue-Resource Request instance to set the header on.
   * @return {void}
   */
  setAuthHeader(request: any): void {
    // このLineがないとAPIを叩くことができない。
    request.headers.set('Authorization', 'Basic Og==');
    request.headers.set('x-id-token', sessionStorage.getItem('accessToken'));
    if (sessionStorage.getItem('ownerId') && sessionStorage.getItem('ownerId') !== '') {
      request.headers.set('x-api-key',
                          sha256(Config.API_KEY_PREFIX +
                            sessionStorage.getItem('ownerId') +
                          Config.API_KEY_SUFFIX));
    }
  },

  /**
   * Retry the original request.
   *
   * Let's retry the user's original target request that had recieved a invalid token response
   * (which we fixed with a token refresh).
   *
   * @param {Request} request The Vue-resource Request instance to use to repeat an http call.
   * @return {Promise}
   */
  _retry(request: any) {
    this.setAuthHeader(request);
    console.log('request', request);
    // return main.$http(request).then((data: any) => {
    //   return data;
    // }).then(null, (err) => {
    //     console.log('err', err);
    //     return err;
    //   });
    // return this.http(request).then((data) => {
    //   return data;
    // });
    return main.$http(request)
      .then((response) => {
        console.log(response);
        return response;
      })
      .then(null, (err) => {
        console.log('err', err);
        return err;
      });
  },

  /**
   * Refresh the access token
   *
   * Make an ajax call to the OAuth2 server to refresh the access token (using our refresh token).
   *
   * @private
   * @param {Request} request Vue-resource Request instance, the original request that we'll retry.
   * @return {Promise}
   */

  refreshToken(request: any) {
    return new Promise((resolve, reject) => {
      main.$http.post(Config.REFRESH_TOKEN_API, { refresh_token: sessionStorage.getItem('refreshToken')})
        .then((response: any) => {
          this._storeToken(response.body.id_token, response.body.refresh_token);
          resolve(true);
        })
        .then(null, (err: any) => {
          this.logout();
          resolve(false);
        });
    });
  },

  /**
   * Store tokens
   *
   * Update the Vuex store with the access/refresh tokens received from the response from
   * the Oauth2 server.
   *
   * @private
   * @param {Response} response Vue-resource Response instance from an OAuth2 server.
   *      that contains our tokens.
   * @return {void}
   */
  _storeToken(idToken: any, refreshToken: any) {
    return new Promise((resolve, reject) => {
      // const auth = store.state.auth;
      // const user = store.state.user;
      // auth.isLoginChallenge = false;
      // auth.isLoggedIn = true;
      const tk = jwtDecode(idToken);
      // auth.accessToken = idToken;
      // auth.refreshToken = refreshToken;
      const authTime = this.formatISOString(tk.auth_time);
      sessionStorage.setItem('userAuthTime', authTime);
      sessionStorage.setItem('accessToken', idToken);
      sessionStorage.setItem('refreshToken', refreshToken);
      sessionStorage.setItem('isLoggedIn', 'true');
      // store.commit('UPDATE_AUTH', auth);
      // store.commit('UPDATE_USER', user);
      resolve(true);
    });
  },
  _getProfile() {
    return new Promise((resolve, reject) => {
      main.$http.get(Config.USERS_LIST_API + '/' +
        encodeURIComponent(sessionStorage.getItem('user_login_id') as string))
        .then(async (response: any) => {
          sessionStorage.setItem('domainCode', response.body.user.domain_code);
          sessionStorage.setItem('priviledge', response.body.user.priviledge);
          sessionStorage.setItem('user_name', response.body.user.name);
          sessionStorage.setItem('domain_name', response.body.user.domain_name);
          sessionStorage.setItem('type', response.body.user.type);
          sessionStorage.setItem('userProfileId', response.body.user.user_id);
          resolve(true);
        })
        .then(null, (err: any) => {
          console.log(err);
          sessionStorage.setItem('domain_name', '');
          resolve(err.status);
        });
    });
  },
  getOfficeCode() {
    return new Promise((resolve, reject) => {
      const ID = sessionStorage.hasOwnProperty('userProfileId') ?
        sessionStorage.getItem('userProfileId') as string : '';
      if (ID === '' || sessionStorage.getItem('priviledge') !== '2') {
        resolve(false);
      } else {
        main.$http.get(Config.APPROVER_API + '?user_id=' + encodeURIComponent(ID))
          .then((response: any) => {
            if (response.body.approvers.length > 0) {
              sessionStorage.setItem('office_code', response.body.approvers[0].office_code);
              resolve(true);
            } else {
              sessionStorage.setItem('office_code', '');
              resolve(false);
            }
          }).then(null, (err: any) => {
            console.log(err);
            sessionStorage.setItem('office_code', '');
            resolve(false);
          });
      }
    });
  },
  getOfficeName(ID: any) {
    return new Promise((resolve, reject) => {
      const userID = sessionStorage.hasOwnProperty('userProfileId') ?
        sessionStorage.getItem('userProfileId') as string : '';
      if (userID === '' || sessionStorage.getItem('priviledge') !== '2') {
        resolve(false);
      } else {
      const apiLink = Config.OFFICES_LIST_API + '?office_code=' + ID;
      main.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.offices.length > 0) {
            sessionStorage.setItem('office_name', response.body.offices[0].name);
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .then(null, (err: any) => {
          resolve(false);
          //
        });
      }
    });
  },
  /**
   * Check if the Vue-resource Response is an invalid token response.
   *
   * @private
   * @param {Response} response The Vue-resource Response instance received from an http call.
   * @return {boolean}
   */
    isInvalidToken(response: Response) {
    const status = response.status;
    const error = response.body.message;
    const ENDPOINT = process.env.VUE_APP_API_ENDPOINT + '/login';
    if (status === 401) {
      if (response.url === process.env.VUE_APP_API_ENDPOINT + '/login' ||
        response.url === process.env.VUE_APP_API_ENDPOINT + '/reset-code' ||
        response.url === process.env.VUE_APP_API_ENDPOINT + '/change-password') {
        alert(Config.ERROR_LOGIN_INFO_MISMATCH);
        return true;
      } else {
        alert(Config.TEXT_CONFIRM_EX_TOKEN);
        return true;
      }
    } else {
      return false;
    }
  },
  saveCurrentAuthenticatedUser(idToken: any, refreshToken: any) {
    return new Promise((resolve, reject) => {
      return this._storeToken(idToken, refreshToken)
        .then(() => this._getProfile())
        .then((value: any) => {
          if (value === true) {
            sessionStorage.setItem('office_code', '');
            sessionStorage.setItem('office_name', '');
            this.getOfficeCode().then(() => this.getOfficeName(sessionStorage.getItem('office_code')))
            .then(() => {
              const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
              if (isMobile) {
                sessionStorage.setItem('isMobile', 'true');
              } else {
                sessionStorage.setItem('isMobile', '');
              }
              if (sessionStorage.getItem('priviledge') === '3') {
                router.push({ path: Config.APPLICANT });
              } else if (sessionStorage.getItem('priviledge') === '2') {
                router.push({ path: Config.APPROVAL_STATUS });
              } else if (sessionStorage.getItem('priviledge') === '1') {
                router.push({ path: Config.LOG });
              } else if (sessionStorage.getItem('priviledge') === '0') {
                router.push({ path: Config.OFFICE });
              } else {
                router.push({ path: Config.DASHBOARD });
              }
              resolve(true);
            });
          } else {
            router.push({ path: Config.LOGIN, query: { status: 'false' } });
            window.location.reload();
            resolve(true);
          }
        });
    });
  },
  errorAuthenticatedUser() {
    localStorage.clear();
    sessionStorage.clear();
    store.commit('CLEAR_ALL_DATA');
    router.push({ path: Config.LOGIN, query: { status: 'false' } });
  },
  async clearAllData() {
    const ownerId: any = sessionStorage.getItem('ownerId');
    localStorage.setItem('ownerId', ownerId);
    sessionStorage.clear();
    await this.waitProcess(100);
    sessionStorage.setItem('ownerId', localStorage.ownerId);
    await this.waitProcess(100);
    localStorage.clear();
    store.commit('CLEAR_ALL_DATA');
  },
  getDefaultStringVal(val: string): string {
    if (val === undefined || val === null) {
      return '';
    } else {
      return val;
    }
  },
  getLoadingMessage(loadingType: number): string {
    let msg = Config.TEXT_PROGRESS_READING;
    if (loadingType === 1) {
      msg = Config.TEXT_PROGRESS_REGISTERING;
    } else if (loadingType === 2) {
      msg = Config.TEXT_PROGRESS_REGISTERING;
    } else if (loadingType === 3) {
      msg = Config.TEXT_PROGRESS_DELETING;
    } else {
      msg = Config.TEXT_PROGRESS_READING;
    }
    return msg;
  },
  convertStatus(val: any) {
    let text = '';
    switch (val) {
      case 'saved': text = '保存済'; break;
      case 'submitted': text = '提出済'; break;
      case 'approved': text = '承認済'; break;
      case 'returned': text = '差戻'; break;
      case 'rescinded': text = '承認取消'; break;
      case 'canceled': text = '申請取消'; break;
    }
    return text;
  },
  convertPriviledge(val: any) {
    let text = '';
    switch (val) {
      case 0: text = '最上位管理者'; break;
      case 1: text = '上位管理者'; break;
      case 2: text = '入室管理者'; break;
      case 3: text = '一般ユーザー'; break;
    }
    return text;
  },
  convertContent(val: any) {
    let text = '';
    switch (val) {
      case 'submit': text = '入室申請提出'; break;
      case 'approve': text = '入室申請承認'; break;
      case 'return': text = '入室申請差戻'; break;
      case 'rescind': text = '入室申請承認取消'; break;
      case 'cancel': text = '入室申請取消'; break;
      case 'extend': text = '入室期間延長（延長申請承認）'; break;
      case 'ext.req': text = '延長の申請'; break;
      case 'ext.deny': text = '延長否認'; break;
    }
    return text;
  },
  convertUserType(val: any) {
    let text = '';
    switch (val) {
      case 0: text = 'ログイン'; break;
      case 1: text = '検索'; break;
      case 2: text = '追加'; break;
      case 3: text = '編集'; break;
      case 4: text = '削除'; break;
    }
    return text;
  },
  converDateShort(t: any) {
    if (t !== undefined && t !== '') {
      return moment(t).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm');
    } else {
      return '';
    }
  },
  converDatePicker(t: any) {
    return t !== '' ? moment(t).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm') : '';
  },
  converDateOnlyTime(t: any) {
    return t !== '' ? moment(t).tz('Asia/Tokyo').format('HH:mm') : '';
  },
  countTime(t: any) {
    return t !== '' ? moment(t).tz('Asia/Tokyo').utc().format('HH') : '';
  },
  converDateOnlyDate(t: any) {
    return t !== '' ? moment(t).tz('Asia/Tokyo').format('YYYY-MM-DD') : '';
  },
  formatTimestamp(t: any) {
    return t !== '' ? moment(t).tz('Asia/Tokyo').unix() : '';
  },
  formatISOString(t: any) {
    return t !== '' ? moment.tz(t, 'Asia/Tokyo').utc().format() : '';
  },
  downloadCSV(exportData: any, filename: any) {
    const saveAs = require('file-saver');
    let csv = this.convertArrayOfObjectsToCSV({
      data: exportData,
    });
    if (filename === Config.TEXT_ADMISSION_APPLICANTS) {
      csv = csv.replace('COL_SEX', '未使用');
      csv = csv.replace('COL_AREA', '未使用');
      csv = csv.replace('COL_AFFILIATION', '未使用');
    }
    filename = filename;
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
    const blob = new Blob([bom, csv], { type: 'text/csv;charset=utf-8;' });
    // ダウンロード実行
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const nav = (window.navigator as any);
    if (isSafari) {
      saveAs(blob, filename);
    } else {
      if (nav.msSaveOrOpenBlob !== undefined) {
        // IEの場合
        nav.msSaveOrOpenBlob(blob, filename);
      } else {
        // IE以外(Chrome, Firefox)
        // const downloadLink = $('<a></a>');
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
        downloadLink.setAttribute('download', filename);
        downloadLink.setAttribute('target', '_blank');
        // $('body').append(downloadLink);
        downloadLink.click();
        downloadLink.remove();
      }
    }
  },
  convertArrayOfObjectsToCSV(args: any) {
    let result: any;
    let ctr: number;
    let keys: any[];
    let columnDelimiter: string | undefined;
    let lineDelimiter: any;
    let data: any;
    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }
    columnDelimiter = args.columnDelimiter || ',';
    lineDelimiter = args.lineDelimiter || '\n';
    keys = Object.keys(data[0]);
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    data.forEach((item: { [x: string]: any; }) => {
      ctr = 0;
      keys.forEach((key: any) => {
        if (ctr > 0) {
          result += columnDelimiter;
        }
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });
    return result;
  },
  convertVoltage(val: any, type: any, isMinDate: any) {
    let voltage = '';
    if (type === Config.CONST_GATE_TYPE_ESUMLOCK ||
      type === Config.CONST_GATE_TYPE_HANDLELOCK ||
      type === Config.CONST_GATE_TYPE_ESUM || type === Config.CONST_GATE_TYPE_HANDLE) {
      if (isMinDate === true) {
        voltage = '-';
      } else if (val < 2.76) {
        voltage = '5％';
      } else if (val >= 2.76 && val < 2.8) {
        voltage = '20％';
      } else if (val >= 2.8 && val < 2.94) {
        voltage = '50％';
      } else if (val >= 2.94 && val < 3) {
        voltage = '80％';
      } else if (val >= 3) {
        voltage = '100％';
      }
    } else {
      voltage = '-';
    }
    return voltage;
  },
  convertEquipmentDate(val: any, type: any) {
    let updateDate = '';
    if (type === Config.CONST_GATE_TYPE_ESUMLOCK ||
      type === Config.CONST_GATE_TYPE_HANDLELOCK ||
      type === Config.CONST_GATE_TYPE_ESUM || type === Config.CONST_GATE_TYPE_HANDLE) {
      updateDate = this.converDateShort(val);
    } else {
      updateDate = '-';
    }
    return updateDate;
  },
  checkComboboxValue(item: any) {
    if (item !== undefined && item !== null && item.value !== undefined && item.value !== null && item.value !== '') {
      return true;
    } else {
      return false;
    }
  },
  minusTime(val: any) {
    let text = '';
    switch (val) {
      case '06:00': text = '05:45'; break;
      case '18:00': text = '17:45'; break;
      case '00:00': text = '23:45'; break;
    }
    return text;
  },
  waitProcess(val: any) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, val);
    });
  },
};

