
import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import Applicant from '@/components/organisms/WorkApplication/Applicant.vue';
import Searchbox, {SearchTargetItem} from '@/components/molecules/Searchbox/Searchbox.vue';
import * as Config from '../../../config';
interface TypeSelect {
  text: string;
  value: string;
}
export interface DataType {
  selectedApplicantIds: string[];
  configData: any;
}
export default Vue.extend({
  name: 'ApplicantViewTemplate',
  components: {
    Applicant,
    Searchbox,
    Breadcrumbs,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isAction: {
      type: Number,
      default: 0,
    },
    errorMessageLogs: {
      default: '',
    },
    successMessageLogs: {
      default: '',
    },
    isDisabledCancelApplication: {
      type: Boolean,
      default: false,
    },
    resData: {
      type: Object,
      default: () => ({}),
    },
    resCardData: {
      type: Object,
      default: () => ({}),
    },
    resHisData: {
      default: () => [],
    },
    resExtData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: (): DataType => ({
    selectedApplicantIds: [],
    configData: Config,
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: Config.TEXT_APPLICATION_DETAILS, disabled: true },
        ];
      },
    },
  },
  methods: {
    //
    goBack() {
      this.$router.push({ path: Config.APPLICANT});
    },
  },
});
