
import { Vue } from 'vue-property-decorator';
import UserManagementTemplate from '@/components/templates/UserManagementTemplate/UserManagementTemplate.vue';
import * as Config from '@/config';
import auth from '@/auth';
import { label } from '@aws-amplify/ui';
export interface DataType {
  // Props
  isEdit: boolean;
  loading: boolean;
  loadingCSV: boolean;
  loadingModify: boolean;
  resData: any;
  keyword: string;
  loadingType: number; // 0: reading (default), 1: adding, 2: editing, 3: deleting
  userTotalCount: number;
  perPage: number;
  total: number;
  page: number;
  organizations: any;
  userInfo: any;
  errorMessageProcess: any;
  isResetData: boolean;
  loadMore: boolean;

  showModalAddEditUser: boolean;
  showModalBulkUser: boolean;

  progress: number;
  successItemCSV: number;
  importDataCSV: any;
  exeCountCSV: number;
  percent: number;
  totalDataRowCSV: number;
  success: boolean;
  message: string;
  countExport: number;
  rowinErrDialog: number;
  pageExport: number;
  countDelUser: number;
  exportData: any;
  deleteUserDialog: boolean;
  changeUserInfoDialog: boolean;
  loadingSearch: boolean;
  isDeleteFinish: boolean;
  listDataExport: any;
  errorList: any;
  successList: any;
}
interface User {
  user_id: string;
  name: string;
  furigana?: string;
  domain_code: string;
  email?: string;
  memo?: string;
  idi01?: string;
  idm01?: string;
  idi02?: string;
  idm02?: string;
  idi03?: string;
  idm03?: string;
  type: number;
  organizations?: any;
}

export default Vue.extend({
  name: 'UserManagementPage',
  components: {
    UserManagementTemplate,
  },
  data: (): DataType => ({
    // Props
    loadingCSV: false,
    loading: false,
    loadingModify: false,
    isEdit: false,
    resData: [],
    errorMessageProcess: [],
    keyword: '',
    loadingType: 0, // default: 0 -> reading data
    userTotalCount: 0,
    rowinErrDialog: 0,
    perPage: Config.PER_PAGE,
    page: 1,
    pageExport: 1,
    organizations: [],
    userInfo: {
      userId: '',
      // password: '',
      userName: '',
      furigana: '',
      email: '',
      userType: '',
      organizations: [],
      supplement: '',
    },
    progress: 0,
    total: 0,
    isResetData: false,
    showModalAddEditUser: false,
    showModalBulkUser: false,
    successItemCSV: 0,
    importDataCSV: [],
    exeCountCSV: 0,
    percent: 0,
    totalDataRowCSV: 0,
    success: false,
    loadMore: false,
    message: '',
    countExport: 0,
    exportData: [],
    deleteUserDialog: false,
    changeUserInfoDialog: false,
    loadingSearch: false,
    isDeleteFinish: false,
    countDelUser: 0,
    listDataExport: [],
    errorList: [],
    successList: [],
  }),
  mounted() {
    this.$emit('activeMenu', 'user');
  },
  created() {
    this.getUserList();
    this.getOrganizations();
  },
  watch: {
    countExport(newVal, oldVal) {
      if (newVal >= this.pageExport) {
        if (this.exportData.length === 0) {
          this.exportData.push({
              ['"' + Config.TEXT_USER_ID + '"']: '""',
              ['"' + Config.TEXT_USER_NAME + '"']: '""',
              ['"' + Config.TEXT_FURIGANA + '"']: '""',
              ['"' + Config.TEXT_SEX + '"']: '""',
              ['"' + Config.TEXT_AFFILIATION + '"']: '""',
              ['"' + Config.TEXT_COL_AREA + '"']: '""',
              ['"' + Config.TEXT_COL_EMAIL_ADDRESS + '"']: '""',
              ['"' + Config.TEXT_REMARKS + '"']: '""',
              ['"' + Config.TEXT_CARD_I1 + '"']: '""',
              ['"' + Config.TEXT_CARD_M1 + '"']: '""',
              ['"' + Config.TEXT_CARD_I2 + '"']: '""',
              ['"' + Config.TEXT_CARD_M2 + '"']: '""',
              ['"' + Config.TEXT_CARD_I3 + '"']: '""',
              ['"' + Config.TEXT_CARD_M3 + '"']: '""',
              ['"' + Config.TEXT_SEARCH_TAG_1 + '"']: '""',
          });
        }
        auth.downloadCSV(this.exportData, Config.TEXT_ADMISSION_APPLICANTS);
        this.loadingCSV = false;
      } else if (newVal > 0) {
        this.getExportData(newVal + 1);
      }
    },
    loading(value) {
      this.$emit('loading', value);
      if (value) {
        this.$emit('textCircle', auth.getLoadingMessage(this.loadingType));
      } else if (this.loadingType !== 0) {
        this.loadingType = 0; // reset to default value
      }
    },
    loadingCSV(value) {
      this.$emit('loading', value);
    },
    loadingModify(value) {
      this.$emit('loading', value);
      if (value) {
        this.$emit('textCircle', auth.getLoadingMessage(this.loadingType));
      } else if (this.loadingType !== 0) {
        this.loadingType = 0; // reset to default value
      }
    },
  },
  methods: {
    //
    getCurrentItems(item: any) {
      this.listDataExport = item;
    },
    changeColumn(value: any): any {
      this.loading = true;
      setTimeout(() => {this.loading = false; }, 300);
    },
    exportCSV(value: any): any {
      this.exportData = [];
      this.pageExport = 0;
      this.loadingCSV = true;
      const TEXT_SEX = 'COL_SEX';
      const TEXT_COL_AREA = 'COL_AREA';
      const TEXT_SEARCH_TAG_1 = Config.TEXT_LABEL_SEARCH_TAG;
      if (this.listDataExport.length > 0) {
        this.listDataExport.forEach(async (element: any, key: any) => {
          this.exportData.push({
            ['"' + Config.TEXT_USER_ID + '"']: '"' + element.user_id + '"',
            ['"' + Config.TEXT_USER_NAME + '"']: '"' + element.name + '"',
            ['"' + Config.TEXT_FURIGANA + '"']: '"' + element.furigana + '"',
            ['"' + TEXT_SEX + '"']: '""',
            ['"' + Config.TEXT_AFFILIATION + '"']: '"' + element.domain_code + '"',
            ['"' + TEXT_COL_AREA + '"']: '""',
            ['"' + Config.TEXT_COL_EMAIL_ADDRESS + '"']: '"' + element.email + '"',
            ['"' + Config.TEXT_REMARKS + '"']: '"' + element.memo + '"',
            ['"' + Config.TEXT_CARD_I1 + '"']: '""',
            ['"' + Config.TEXT_CARD_M1 + '"']: '""',
            ['"' + Config.TEXT_CARD_I2 + '"']: '""',
            ['"' + Config.TEXT_CARD_M2 + '"']: '""',
            ['"' + Config.TEXT_CARD_I3 + '"']: '""',
            ['"' + Config.TEXT_CARD_M3 + '"']: '""',
            ['"' + TEXT_SEARCH_TAG_1 + '"']: '"' +
            element.organizations + '"',
          });
        });
      } else {
        this.exportData.push({
          ['"' + Config.TEXT_USER_ID + '"']: '""',
          ['"' + Config.TEXT_USER_NAME + '"']: '""',
          ['"' + Config.TEXT_FURIGANA + '"']: '""',
          ['"' + TEXT_SEX + '"']: '""',
          ['"' + Config.TEXT_AFFILIATION + '"']: '""',
          ['"' + TEXT_COL_AREA + '"']: '""',
          ['"' + Config.TEXT_COL_EMAIL_ADDRESS + '"']: '""',
          ['"' + Config.TEXT_REMARKS + '"']: '""',
          ['"' + Config.TEXT_CARD_I1 + '"']: '""',
          ['"' + Config.TEXT_CARD_M1 + '"']: '""',
          ['"' + Config.TEXT_CARD_I2 + '"']: '""',
          ['"' + Config.TEXT_CARD_M2 + '"']: '""',
          ['"' + Config.TEXT_CARD_I3 + '"']: '""',
          ['"' + Config.TEXT_CARD_M3 + '"']: '""',
          ['"' + TEXT_SEARCH_TAG_1 + '"']: '""',
        });
      }
      auth.downloadCSV(this.exportData, Config.TEXT_ADMISSION_APPLICANTS);
      this.loadingCSV = false;
    },
    searchUser(args: any): any {
      this.listDataExport = [];
      this.loadingSearch = true;
      this.keyword = '';
      this.resData = [];
      this.page = 1;
      this.total = 0;
      args.target.forEach((element: any, key: any) => {
        if (element.value !== undefined && element.value !== null && element.value !== '') {
          if (element.name === 'user_id' && element.value.length > Config.USER_ID_MAX_LEN) {
            this.keyword += element.name + '=' + element.value.slice(0, Config.USER_ID_MAX_LEN) + '&';
          } else if (element.name === 'name' && element.value.length > Config.USER_NAME_MAX_LEN) {
            this.keyword += element.name + '=' + element.value.slice(0, Config.USER_NAME_MAX_LEN) + '&';
          } else if (element.name === 'email' && element.value.length > Config.EMAIL_MAX_LEN) {
            this.keyword += element.name + '=' + element.value.slice(0, Config.EMAIL_MAX_LEN) + '&';
          } else if (element.name === 'organizations' && element.value.length > 0) {
            let value = '';
            for (const ogr of element.value) {
              value += ogr.value + ',';
            }
            value = value.slice(0, -1);
            this.keyword += element.name + '=' + value + '&';
          } else {
            this.keyword += element.name + '=' + element.value + '&';
          }
          // this.keyword += element.name + '=' + element.value + '&';
        }
      });
      this.getUserList();
    },
    changePerPageUser(value: any): any {
      this.perPage = value;
      sessionStorage.setItem('User', value);
      this.getUserList();
    },
    changePageUser(value: any): any {
      this.page = value;
      this.loadingSearch = true;
      this.getUserList();
    },
    showUserChangeConfirm() {
      this.changeUserInfoDialog = true;
    },
    closeUserChangeConfirm() {
      this.changeUserInfoDialog = false;
    },
    showConfirmDeleteUserDialog() {
      this.deleteUserDialog = true;
    },
    closeDeleteUserDialog() {
      this.deleteUserDialog = false;
    },
    closeFinish(args: any) {
      this.deleteUserDialog = false;
      this.errorMessageProcess = [];
      this.isDeleteFinish = false;
      this.resData = [];
      this.total = 0;
      this.loadingSearch = true;
      this.getUserList();
    },
    addUser() {
      this.isEdit = false;
      this.userInfo = {
        userId: '',
        // password: string,
        userName: '',
        priviledge: '',
        furigana: '',
        email: '',
        userType: '',
        organizations: [],
        supplement: '',
      };
      this.isResetData = false;
      this.showModalAddEditUser = true;
    },
    async deleteUser(data: any) {
      if (data.length > 100) {
        this.loading = false;
        this.deleteUserDialog = false;
        alert(Config.TEXT_ERROR_FOR_ENTRY_APPLICANT_DEL_MAX);
      } else {
        this.isDeleteFinish = false;
        this.loadingType = 3;
        this.loading = true;
        this.loadMore = false;
        this.rowinErrDialog = 0;
        this.errorMessageProcess = [];
        this.countDelUser = 0;
        const successList = [];
        const errorList = [];
        let alertMessage = '';
        this.deleteUserDialog = false;
        for (const item of data) {
          const result = await this.delUser(item);
          if (result) {
            successList.push(item);
          } else {
            errorList.push(item);
          }
        }
        if (successList.length > 0) {
          alertMessage = Config.TEXT_DELETE_COMPLETED;
          if (errorList.length > 0) {
            successList.forEach((success: any) => {
              alertMessage += '\n' + success;
            });
            alertMessage += '\n';
          }
        }
        if (errorList.length > 0) {
          alertMessage += Config.TEXT_ERROR_FOR_ENTRY_APPLICANT_DEL;
          errorList.forEach((error: any) => {
            alertMessage += '\n' + error;
          });
        }
        alert(alertMessage);
        this.resData = [];
        this.getUserList();
        this.isDeleteFinish = true;
      }

    },
    editUser(value: string) {
      this.loadingModify = true;
      this.isEdit = true;
      const apiLink = Config.USERS_LIST_API + '?user_id=' + encodeURIComponent(value);
      this.isResetData = false;
      this.showModalAddEditUser = true;
      // this.loadingSearch = true;
      // this.loading = true;
      this.$http.get(apiLink)
        .then((response: any) => {
          this.loadingModify = false;
          if (!this.isEmpty(response.body.users) && response.body.users.length > 0) {
            this.userInfo = {
              userId: response.body.users[0].user_id,
              userName: response.body.users[0].name !== null ? response.body.users[0].name : '' ,
              priviledge: response.body.users[0].priviledge,
              furigana: response.body.users[0].furigana !== null ? response.body.users[0].furigana : '',
              email: response.body.users[0].email !== null ? response.body.users[0].email : '',
              userType: response.body.users[0].type,
              organizations: [], // response.body.users[0].organizations,
              supplement: response.body.users[0].memo !== null ? response.body.users[0].memo : '',
            };
            if (!this.isEmpty(response.body.users[0].organizations) &&
                response.body.users[0].organizations.length > 0) {
              for (const org of response.body.users[0].organizations) {
                this.userInfo.organizations.push({value: org.label, text: org.label});
              }
            } else {
              this.userInfo.organizations = [];
            }
            // this.loadingSearch = false;
            // this.loading = false;
          } else {
            this.userInfo = {
              userId: '',
              userName: '',
              priviledge: '',
              furigana: '',
              email: '',
              userType: '',
              organizations: [],
              supplement: '',
            };
            this.userTotalCount = 0;
            // this.loadingSearch = false;
            // this.loading = false;
          }
        })
        .then(null, (err: any) => {
          this.loadingModify = false;
          this.showModalAddEditUser = false;
          // this.loadingSearch = false;
          // this.loading = false;
          alert(Config.TEXT_ERROR_FOR_ENTRY_APPLICANT);
        });
    },
    closeAddEditUserDialog() {
      this.isEdit = false;
      this.showModalAddEditUser = false;
      this.userInfo = {
        userId: '',
        // password: string,
        userName: '',
        furigana: '',
        email: '',
        userType: '',
        organizations: [],
        supplement: '',
      };
      this.isResetData = true;
    },
    bulkRegistration() {
      this.showModalBulkUser = true;
    },
    closeBulkUserDialog() {
      this.showModalBulkUser = false;
    },
    async clickSaveUser(data: any) {
      await this.postUser(data);
    },
    getOrganizations() {
      this.organizations = [];
      const apiLink = Config.ORGANIZATIONS_API + '?domain_code=' + sessionStorage.getItem('domainCode');
      this.$http.get(apiLink)
      .then((response: any) => {
        if (!this.isEmpty(response.body.organizations) && response.body.organizations.length > 0) {
          for (const org of response.body.organizations) {
            this.organizations.push({value: org.label, text: org.label});
          }
        }
      })
      .then(null, (err: any) => {
        console.log(err);
      });
    },
    refreshUserList(): any {
      return new Promise((resolve) => {
        this.loadMore = false;
        let apiLink = Config.USERS_LIST_API + '?type=1&limit=' + this.perPage +
          '&page=' + this.page;
        if (this.keyword !== '') {
          apiLink += '&' + this.keyword.replace(/&\s*$/, '');
        }
        if (sessionStorage.getItem('priviledge') === '2') {
          apiLink += '&priviledge=3';
        }
        this.$http.get(apiLink)
        .then((response: any) => {
          if (!this.isEmpty(response.body.users) && response.body.users.length > 0) {
            this.total = response.body.total;
            this.page = Math.ceil(response.body.head / this.perPage);
            for (const element of response.body.users) {
              element.organizations = element.organizations.map((item: { label: any; }) => item.label).join(', ');
            }
            this.resData.push(...response.body.users);
          }
          if (response.body.total > response.body.tail) {
            this.loadMore = true;
          }
          this.loadingSearch = false;
          resolve(true);
        })
        .then( null, (err: any) => {
          this.loadingSearch = false;
          resolve(false);
        }); // catch
      });
    },
    getUserList() {
      this.loading = true;
      this.loadMore = false;
      let apiLink = Config.USERS_LIST_API + '?type=1&limit=' + this.perPage +
        '&page=' + this.page ;
      if (this.keyword !== '') {
        apiLink += '&' + this.keyword.replace(/&\s*$/, '');
      }
      if (sessionStorage.getItem('priviledge') === '2') {
        apiLink += '&priviledge=3';
      }
      this.$http.get(apiLink)
      .then((response: any) => {
        if (!this.isEmpty(response.body.users) && response.body.users.length > 0) {
          // this.resData = response.body.users;
          this.total = response.body.total;
          this.page = Math.ceil(response.body.head / this.perPage);
          response.body.users.forEach(async (element: any, key: any) => {
            element.organizations = element.organizations.map((item: { label: any; }) => item.label).join(', ');
          });
          this.resData.push(...response.body.users);
        }
        if (response.body.total > response.body.tail) {
          this.loadMore = true;
        }
        this.loadingSearch = false;
        this.loading = false;
      })
      .then( null, (err: any) => {
        this.loadingSearch = false;
        this.loading = false;
        alert(Config.TEXT_ERROR_FOR_ENTRY_APPLICANT);
      }); // catch
    },
    getExportData(page: any) {
      let apiLink = Config.USERS_LIST_API + '?type=1&limit=1000&page=' + page ;
      if (this.keyword !== '') {
        apiLink += '&' + this.keyword.replace(/&\s*$/, '');
      }
      if (sessionStorage.getItem('priviledge') === '2') {
        apiLink += '&priviledge=3';
      }
      this.$http.get(apiLink)
      .then((response: any) => {
        if (!this.isEmpty(response.body.users) && response.body.users.length > 0) {
          const resData = response.body.users;
          this.pageExport = Math.ceil(response.body.total / 1000);
          resData.forEach(async (element: any, key: any) => {
            this.exportData.push({
              ['"' + Config.TEXT_USER_ID + '"']: '"' + element.user_id + '"',
              ['"' + Config.TEXT_USER_NAME + '"']: '"' + element.name + '"',
              ['"' + Config.TEXT_FURIGANA + '"']: '"' + element.furigana + '"',
              ['"' + Config.TEXT_SEX + '"']: '""',
              ['"' + Config.TEXT_AFFILIATION + '"']: '"' + element.domain_code + '"',
              ['"' + Config.TEXT_COL_AREA + '"']: '""',
              ['"' + Config.TEXT_COL_EMAIL_ADDRESS + '"']: '"' + element.email + '"',
              ['"' + Config.TEXT_REMARKS + '"']: '"' + element.memo + '"',
              ['"' + Config.TEXT_CARD_I1 + '"']: '""',
              ['"' + Config.TEXT_CARD_M1 + '"']: '""',
              ['"' + Config.TEXT_CARD_I2 + '"']: '""',
              ['"' + Config.TEXT_CARD_M2 + '"']: '""',
              ['"' + Config.TEXT_CARD_I3 + '"']: '""',
              ['"' + Config.TEXT_CARD_M3 + '"']: '""',
              ['"' + Config.TEXT_SEARCH_TAG_1 + '"']: '"' +
              element.organizations.map((item: { label: any; }) => item.label).join(',') + '"',
            });
          });
        }
        this.countExport++;
      })
      .then( null, (err: any) => {
        this.countExport++;
      }); // catch
    },
    postUser(data: any) {
      return new Promise((resolve, reject) => {
        this.loadingType = 1;
        this.loadingModify = true;
        const user = {
          user_id: data.userInfo.userId,
          type: 1,
          priviledge: 3,
          domain_code: sessionStorage.getItem('domainCode'),
          name: data.userInfo.userName,
          organizations: data.userInfo.organizations.map((item: any) => ({ label: item.value })),
          furigana: data.userInfo.furigana,
          email: data.userInfo.email,
          memo: data.userInfo.supplement,
        };
        // console.log('data', data);
        // console.log('user', user);
        this.$http.post(Config.USERS_LIST_API, JSON.stringify(user))
        .then((response: any) => {
          if (response.body.message === 'Success') {
            this.isResetData = true;
            this.closeAddEditUserDialog();
            this.resData = [];
            this.page = 1;
            this.total = 0;
            this.loadingSearch = true;
            this.refreshUserList()
            .then(() => {
              alert(Config.TEXT_REGISTRATION_COMPLETED);
              this.loadingSearch = false;
              this.loadingModify = false;
            });
            resolve(true);
          } else {
            alert(Config.ERROR_HAS_OCCURRED);
            this.loadingModify = false;
            resolve(false);
          }
        })
        .then(null, (error: any) => {
          console.log('error', error);
          this.closeAddEditUserDialog();
          alert(Config.TEXT_ERROR_FOR_ENTRY_APPLICANT_NEW);
          this.loadingModify = false;
          resolve(false);
        });
      });
    },
    clickUpdateUser(data: any) {
      this.updateUser(data);
    },
    updateUser(data: any) {
      const encodedUserId = encodeURIComponent(data.userInfo.userId);
      // Modify user information
      const user = {
        priviledge: data.userInfo.priviledge,
        // type: data.userInfo.userType,
        name: data.userInfo.userName,
        organizations: data.userInfo.organizations.map((item: any) => ({ label: item.value })),
        furigana: data.userInfo.furigana,
        email: data.userInfo.email,
        memo: data.userInfo.supplement,
      };
      // console.log(user);
      this.loadingType = 2;
      this.loadingModify = true;
      this.$http.patch(Config.USERS_LIST_API + '/' + encodedUserId, JSON.stringify(user))
      .then((response: any) => {
        this.closeUserChangeConfirm();
        this.closeAddEditUserDialog();
        this.resData = [];
        this.page = 1;
        this.total = 0;
        this.loadingSearch = true;
        this.refreshUserList()
        .then(() => {
          alert(Config.TEXT_UPDATE_COMPLETED);
          this.loadingSearch = false;
          this.loadingModify = false;
        })
        .then(null, (err: any) => {
          this.loadingSearch = false;
          this.loadingModify = false;
        });
      })
      .then(null, (error) => {
        this.closeUserChangeConfirm();
        this.closeAddEditUserDialog();
        alert(Config.TEXT_ERROR_FOR_ENTRY_APPLICANT_UPDATE);
        this.loadingModify = false;
      });
    },
    clickImportCSV(data: any) {
      this.importCSV(data);
    },
    async importCSV(data: any) {
      const vm = this;
      const files = data.files;
      this.loading = true;
      if (files !== undefined && files !== null && files.length !== 0) {
        this.progress = 0;
        this.exeCountCSV = 0;
        this.successItemCSV = 0;
        const reader = new FileReader();
        reader.onload = (): void => {
          const read =  reader.result as string;
          const lineArr = read.split('\n');
          const itemArr = [];
          for (let i = 0; i < lineArr.length; i++) {
            itemArr[i] = lineArr[i].split('","');
          }
          if (itemArr.length > 1) {
            const users = [];
            for (let k = 1; k < itemArr.length; k++) {
              if (itemArr[k].length === 15 && itemArr[k][0].length > 0) {
                const item = itemArr[k];
                let user: User;
                // Domain
                let domainCode = sessionStorage.getItem('domainCode');
                if (sessionStorage.getItem('priviledge') === '0') {
                  domainCode = auth.getDefaultStringVal(item[4]).replace('"', '');
                }
                user = {
                  user_id: item[0].replace('"', ''),
                  name: item[1].replace('"', ''),
                  domain_code: domainCode === null ? '' : domainCode,
                  type: 1,
                };
                // Furigana
                const furigana = item[2].replace('"', '');
                if (furigana === '') {
                  //
                } else if (furigana === Config.TEXT_DEL) {
                  user.furigana = '';
                } else {
                  user.furigana = furigana;
                }
                // Email
                const email = auth.getDefaultStringVal(item[6]).replace('"', '');
                if (email === '') {
                  //
                } else if (email === Config.TEXT_DEL) {
                  user.email = '';
                } else {
                  user.email = email;
                }
                // Remarks
                const memo = auth.getDefaultStringVal(item[7]).replace('"', '');
                if (memo === '') {
                  //
                } else if (memo === Config.TEXT_DEL) {
                  user.memo = '';
                } else {
                  user.memo = memo;
                }
                // idi01
                const idi01 = auth.getDefaultStringVal(item[8]).replace('"', '');
                if (idi01 === '') {
                  //
                } else if (idi01 === Config.TEXT_DEL) {
                  user.idi01 = '';
                } else {
                  user.idi01 = idi01;
                }
                // idm01
                const idm01 = auth.getDefaultStringVal(item[9]).replace('"', '');
                if (idm01 === '') {
                  //
                } else if (idm01 === Config.TEXT_DEL) {
                  user.idm01 = '';
                } else {
                  user.idm01 = idm01;
                }
                // idi02
                const idi02 = auth.getDefaultStringVal(item[10]).replace('"', '');
                if (idi02 === '') {
                  //
                } else if (idi02 === Config.TEXT_DEL) {
                  user.idi02 = '';
                } else {
                  user.idi02 = idi02;
                }
                // idm02
                const idm02 = auth.getDefaultStringVal(item[11]).replace('"', '');
                if (idm02 === '') {
                  //
                } else if (idm02 === Config.TEXT_DEL) {
                  user.idm02 = '';
                } else {
                  user.idm02 = idm02;
                }
                // idi03
                const idi03 = auth.getDefaultStringVal(item[12]).replace('"', '');
                if (idi03 === '') {
                  //
                } else if (idi03 === Config.TEXT_DEL) {
                  user.idi03 = '';
                } else {
                  user.idi03 = idi03;
                }
                // idm03
                const idm03 = auth.getDefaultStringVal(item[13]).replace('"', '');
                if (idm03 === '') {
                  //
                } else if (idm03 === Config.TEXT_DEL) {
                  user.idm03 = '';
                } else {
                  user.idm03 = idm03;
                }
                // organizations
                const organizations = auth.getDefaultStringVal(item[14]).replace('"', '');
                if (organizations === '') {
                  //
                } else if (organizations === Config.TEXT_DEL) {
                  user.organizations = [];
                } else {
                  user.organizations = this.getOrigination(auth.getDefaultStringVal(item[14]));
                }
                // console.log(user);
                users.push(user);
              }
            }
            if (users.length > Config.MAXIMUM_ITEM_IN_CSV) {
              this.loading = false;
              alert(Config.ERROR_MAXIMUM_ITEM_CSV_FILE);
            } else if (users.length < 1) {
              this.loading = false;
              alert(Config.ERROR_MIN_ITEM_CSV_FILE);
            } else {
              const validUsers = users;
              this.$http.post(Config.BULKS_USERS_API, JSON.stringify({ users: validUsers }))
              .then((response: any) => {
                if (response.body.message === 'Success') {
                  this.loading = false;
                  alert(Config.TEXT_BULK_REGISTRAION_ACCEPTED);
                  this.resData = [];
                  this.page = 1;
                  this.total = 0;
                  this.showModalBulkUser = false;
                  this.getUserList();
                } else {
                  this.loading = false;
                  alert(Config.TEXT_ERROR_BULK_REGISTRAION_ACCEPTED);
                }
              })
              .then(null, (err: any) => {
                this.loading = false;
                alert(Config.TEXT_ERROR_BULK_REGISTRAION_ACCEPTED);
              });
            }
          } else {
            vm.success = true;
            this.loading = false;
            alert(Config.ERROR_BAD_FILE);
          }
        },
        reader.readAsText(files, 'Shift_JIS');
      } else {
        this.loading = false;
        alert(Config.ERROR_MUST_SELECT_CSV_FILE);
        this.success = true;
      }
    },
    delUser(ID: any) {
      // this.deleteUserDialog = false;
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.USERS_LIST_API + '/' + ID)
        .then((response: any) => {
          this.countDelUser++;
          resolve(true);
        })
        .then(null, (error: any) => {
          resolve(false);
        });
      });
    },
    startLoading() {
      if (this.loading === false) {
        this.loading = true;
      }
    },
    endLoading() {
      if (this.loading === true) {
        this.loading = false;
      }
    },
    getOrigination(data: any) {
      data = data.replaceAll('"', '');
      data = data.replace('\n', '');
      data = data.replace('\r', '');
      const arr: any[] = [];
      if (data !== undefined) {
        const arrData = data.split(',');
        const organizations = arrData.filter((element: any) => {
          if (Object.keys(element).length !== 0) {
            return true;
          }
          return false;
        });
        if (organizations[0] !== undefined && organizations[0] !== null && organizations[0] !== '') {
          arr.push({label: organizations[0]});
        }
        if (organizations[1] !== undefined && organizations[1] !== null && organizations[1] !== '') {
          arr.push({label: organizations[1]});
        }
        if (organizations[2] !== undefined && organizations[2] !== null && organizations[2] !== '') {
          arr.push({label: organizations[2]});
        }
      }
      return arr;
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
  },
});
