var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("user-management-template", {
    attrs: {
      loading: _vm.loading,
      resData: _vm.resData,
      delUser: _vm.countDelUser,
      isEdit: _vm.isEdit,
      isResetData: _vm.isResetData,
      loadMore: _vm.loadMore,
      showModalAddEditUser: _vm.showModalAddEditUser,
      showModalBulkUser: _vm.showModalBulkUser,
      organizations: _vm.organizations,
      userData: _vm.userInfo,
      loadingSearch: _vm.loadingSearch,
      total: _vm.total,
      page: _vm.page,
      perPage: _vm.perPage,
      deleteUserDialog: _vm.deleteUserDialog,
      errorMessageProcess: _vm.errorMessageProcess,
      isDeleteFinish: _vm.isDeleteFinish,
      changeUserInfoDialog: _vm.changeUserInfoDialog,
    },
    on: {
      "change-column": function ($event) {
        return _vm.changeColumn($event)
      },
      "search-user": function ($event) {
        return _vm.searchUser($event)
      },
      "change-per-page": function ($event) {
        return _vm.changePerPageUser($event)
      },
      "close-finish": function ($event) {
        return _vm.closeFinish($event)
      },
      "change-page": function ($event) {
        return _vm.changePageUser($event)
      },
      addUser: _vm.addUser,
      editUser: function ($event) {
        return _vm.editUser($event)
      },
      "delete-user": function ($event) {
        return _vm.deleteUser($event)
      },
      clickUpdateUser: function ($event) {
        return _vm.clickUpdateUser($event)
      },
      clickSaveUser: function ($event) {
        return _vm.clickSaveUser($event)
      },
      bulkRegistration: _vm.bulkRegistration,
      clickImportCSV: function ($event) {
        return _vm.clickImportCSV($event)
      },
      exportCSV: function ($event) {
        return _vm.exportCSV($event)
      },
      closeAddEditUserDialog: _vm.closeAddEditUserDialog,
      closeBulkUserDialog: _vm.closeBulkUserDialog,
      showConfirmDeleteUserDialog: function ($event) {
        return _vm.showConfirmDeleteUserDialog()
      },
      closeDeleteUserDialog: function ($event) {
        return _vm.closeDeleteUserDialog()
      },
      showUserChangeConfirm: function ($event) {
        return _vm.showUserChangeConfirm()
      },
      closeUserChangeConfirm: function ($event) {
        return _vm.closeUserChangeConfirm()
      },
      "get-current-items": function ($event) {
        return _vm.getCurrentItems($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }