
import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import EquipmentStatusList from '@/components/organisms/EquipmentStatusList/EquipmentStatusList.vue';
import Searchbox, {SearchTargetItem} from '@/components/molecules/Searchbox/Searchbox.vue';
import * as Config from '../../../config';
export interface DataType {
  selectedApplicantIds: string[];
  tab: number;
  searchTargetItems: SearchTargetItem[];
  configData: any;
  isCheck: boolean;
}
export default Vue.extend({
  name: 'EquipmentStatusTemplate',
  components: {
    EquipmentStatusList,
    Searchbox,
    Breadcrumbs,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingSearch: {
      type: Boolean,
      default: false,
    },
    isAction: {
      type: Number,
      default: 0,
    },
    resData: {
      default: () => [],
    },
    errorMessageLogs: {
      default: '',
    },
    total: {
      type: Number,
      default: 0,
    },
    dataArea: {
      default: () => [],
    },
    dataOffice: {
      default: () => [],
    },
  },
  data: (): DataType => ({
    isCheck: sessionStorage.getItem('priviledge')  === '1' ? true : false,
    selectedApplicantIds: [],
    tab: 0,
    searchTargetItems: sessionStorage.getItem('priviledge')  === '1' ? [
      { label: Config.TEXT_COL_MANAGEMENT_OFFICE, name: 'office_list', value: '' },
      { label: Config.TEXT_AREA, name: 'area_list', value: ''},
    ] : [
      sessionStorage.getItem('priviledge')  === '0' ?
      { label: Config.TEXT_COL_MANAGEMENT_OFFICE, name: 'office_list', value: ''} :
      { label: Config.TEXT_COL_MANAGEMENT_OFFICE, name: 'office_code',
        value: sessionStorage.getItem('office_name'), isDisabled: true},
      { label: Config.TEXT_AREA, name: 'area_list', value: ''},
    ],
    configData: Config,
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: Config.TEXT_EQUIPMENT_STATUS_CONDITION, disabled: true },
        ];
      },
    },
  },
  methods: {
    changeOffice(args: any): any {
      if (args === null || args.value === null || args.value === '') {
        this.searchTargetItems[1].value = '';
      }
      this.$emit('change-office', args);
    },
  },
});
