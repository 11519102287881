
  import Vue, { PropType } from 'vue';
  import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
  import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
  import InputForm from '@/components/molecules/InputForm/InputForm.vue';
  // import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
  import { DataTableHeader } from 'vuetify';
  import * as Config from '@/config';

  export interface DataType {
    /*
    displayOptionItems: DisplayOptionItem[];
    selectedDisplayOption: string[];
    showOption: boolean;
    isVaildOnly: boolean;
    */
    dataTableHeight: number;
    rulesValidate: any;
    configData: any;
  }
  interface Office {
    officeId: string;
    officeName: string;
    domainCode: string;
    supplement: string;
    areaList: Area[];
  }

  interface SelectItem { text: string; value: any; }

  interface Area {
    areaId: string;
    areaName: string;
    memo: string;
  }

  export default Vue.extend({
    name: 'OfficeSettingsForm',
    components: {
      // DisplayOption,
      InputForm,
      CancelButton,
      SuccessButton,
    },
    props: {
      showModalOfficeSettings: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      isResetData: {
        default: false,
      },
      isCheck: {
        type: Boolean,
        default: false,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      officeInfo: {
        type: Object as PropType<Office>,
        default: {
          officeId: '',
          officeName: '',
          domainCode: '',
          supplement: '',
          areaList: [],
        },
      },
      areaList: {
        type: Array as PropType<Area[]>,
        default: () => [],
      },
      domainItems: {
        type: Array as PropType<SelectItem[]>,
        default: () => [],
      },
      confirmOfficeDialog: {
        type: Boolean,
        default: false,
      },
      // confirmOfficeTitle: {
      //   type: String,
      //   default: '',
      // },
      confirmOfficeText: {
        type: String,
        default: '',
      },
      selectedIds: {
        default: () => [],
      },
    },
    mounted() {
      //
    },
    watch: {
      isResetData(value) {
        if (value) {
          if (this.$refs.OfficeSettingsForm) {
            (this.$refs.OfficeSettingsForm as Vue & { resetValidation: () => boolean }).resetValidation();
          }
        }
      },
      // areaList(areas) {
      //   if (areas.length === 0) {
      //     this.dataTableHeight = 97;
      //   } else {
      //     this.dataTableHeight = 190;
      //   }
      // },
    },
    computed: {
      headers: {
        get(): DataTableHeader[] {
          const headers: DataTableHeader[] = [
            {
              text: this.configData.TEXT_AREA_CODE,
              align: 'start',
              sortable: true,
              value: 'areaId',
            },
            {
              text: this.configData.TEXT_AREA_NAME,
              align: 'start',
              sortable: true,
              value: 'areaName',
            },
            {
              text: this.configData.TEXT_REMARKS,
              align: 'start',
              sortable: true,
              value: 'remarks',
            },
          ];
          return headers;
        },
        set(): void {
          return;
        },
      },
      isDisabled: {
        get(): boolean {
          let isValid = true;
          isValid = !!this.officeInfo.officeId;
          if (isValid) {
            const pattern = /^[a-z\d\-_\s]{4,20}$/i;
            isValid = isValid && pattern.test(this.officeInfo.officeId);
          }
          isValid = isValid && !!this.officeInfo.officeName;
          isValid = isValid && (this.officeInfo.officeName.length <= Config.OFFICE_NAME_MAX_LEN);
          isValid = isValid && !!this.officeInfo.domainCode;
          isValid = isValid && (this.officeInfo.supplement.length <= Config.MEMO_MAX_LEN);
          if (isValid) {
            const pattern = /^[0-9a-zA-Z]{4,20}$/i;
            if (!!this.officeInfo.areaList && this.officeInfo.areaList.length > 0) {
              isValid = isValid && this.officeInfo.areaList.every((a: any) => pattern.test(a.areaId));
            }
          }

          return !isValid;
        },
        set(): void {
          return;
        },
      },
    },
    data: (): DataType => ({
      /*
      selectedDisplayOption: [
        'areaId',
        'areaName',
        'remarks',
      ],
      displayOptionItems: [
        { label: '施設ID', value: 'areaId', isDisabled: true },
        { label: '施設名', value: 'areaName' },
        { label: '備考', value: 'remarks' },
      ],
      */
      dataTableHeight: 97, // No data
      rulesValidate: {
        requiredOfficeId: (loading: boolean, officeId: string) => {
          return loading || !!officeId || Config.ERROR_ADMINISTRATIVE_FACILITY_ID_IS_REQUIRED;
        },
        validateOfficeId: (officeId: string) => {
          const pattern = /^[a-z\d\-_\s]{4,20}$/i;
          return pattern.test(officeId) || Config.ERROR_OFFICE_LENGTH;
        },
        requiredOfficeName: (loading: boolean, officeName: string) => {
          return loading || !!officeName || Config.ERROR_MANAGEMENT_FACILITY_NAME_IS_REQUIRED;
        },
        validateOfficeName: (officeName: string) => {
          return officeName.length <= Config.OFFICE_NAME_MAX_LEN || Config.ERROR_OFFICE_NAME_LENGTH;
        },
        requiredAffiliation: (loading: boolean, domainCode: string) => {
          return loading || !!domainCode || Config.ERROR_AFFILIATION_IS_REQUIRED;
        },
        validateAreaCode: (areaList: any) => {
          const pattern = /^[0-9a-zA-Z]{4,20}$/i;
          if (!!areaList && areaList.length > 0) {
            return areaList.every((a: any) => pattern.test(a.areaId)) || Config.ERROR_AREA_LENGTH;
          } else {
            return true;
          }
        },
        validateOfficeSupplement: (Supplement: string) => {
          return Supplement.length <= Config.MEMO_MAX_LEN || Config.ERROR_MEMO_LENGTH;
        },
      },
      configData: Config,
    }),
    methods: {
      closeOfficeSettingsDialog() {
        this.$emit('closeOfficeSettingsDialog');
      },
      showConfirmOfficeDialog() {
        const result = (this.$refs.OfficeSettingsForm as Vue & { validate: () => boolean }).validate();
        if (result) {
          this.$emit('showConfirmOfficeDialog', this.officeInfo.officeId);
        }
      },
      closeConfirmOfficeDialog() {
        this.$emit('closeConfirmOfficeDialog');
      },
      saveOfficeSettings() {
        const result = (this.$refs.OfficeSettingsForm as Vue & { validate: () => boolean }).validate();
        if (result) {
          this.$emit('saveOfficeSettings', {
            office: this.officeInfo,
          });
        }
      },
      updateOfficeSettings() {
        const result = (this.$refs.OfficeSettingsForm as Vue & { validate: () => boolean }).validate();
        if (result) {
          this.$emit('updateOfficeSettings', {
              office: this.officeInfo,
            },
          );
        }
      },
      customSort(items: any, index: any, isDescending: any) {
        if (!index || !index.length || !isDescending || !isDescending.length) {
        return items;
        }
        items.sort((a: any, b: any) => {
          if (!isDescending[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
        });
        return items;
      },
    },
  });
