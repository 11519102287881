var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.priviledge === "3"
    ? _c(
        "v-navigation-drawer",
        {
          staticClass: "allig-nav",
          attrs: { app: "", clipped: "" },
          model: {
            value: _vm.innerShow,
            callback: function ($$v) {
              _vm.innerShow = $$v
            },
            expression: "innerShow",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _c(
                "v-list-item-group",
                {
                  attrs: { "active-class": "lighten-2" },
                  model: {
                    value: _vm.selectedItem,
                    callback: function ($$v) {
                      _vm.selectedItem = $$v
                    },
                    expression: "selectedItem",
                  },
                },
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "applicant")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.configData.TEXT_APPLICANT_STATUS)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm.priviledge === "2"
    ? _c(
        "v-navigation-drawer",
        {
          staticClass: "allig-nav",
          attrs: { app: "", clipped: "" },
          model: {
            value: _vm.innerShow,
            callback: function ($$v) {
              _vm.innerShow = $$v
            },
            expression: "innerShow",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _c(
                "v-list-item-group",
                {
                  attrs: { "active-class": "lighten-2" },
                  model: {
                    value: _vm.selectedItem,
                    callback: function ($$v) {
                      _vm.selectedItem = $$v
                    },
                    expression: "selectedItem",
                  },
                },
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "approval-status")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          _vm._s(_vm.configData.TEXT_APPROVAL_STATUS_LIST)
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "entrance-history")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.configData.TEXT_ENTRANCE_HISTORY)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "equipment-status")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          _vm._s(_vm.configData.TEXT_EQUIPMENT_STATUS_CONDITION)
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "user")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.configData.TEXT_USER_MANAGEMENT)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "approver-settings")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.configData.TEXT_APPROVER_SETTING)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm.priviledge === "1"
    ? _c(
        "v-navigation-drawer",
        {
          staticClass: "allig-nav",
          attrs: { app: "", clipped: "" },
          model: {
            value: _vm.innerShow,
            callback: function ($$v) {
              _vm.innerShow = $$v
            },
            expression: "innerShow",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _c(
                "v-list-item-group",
                {
                  attrs: { "active-class": "lighten-2" },
                  model: {
                    value: _vm.selectedItem,
                    callback: function ($$v) {
                      _vm.selectedItem = $$v
                    },
                    expression: "selectedItem",
                  },
                },
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "log")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.configData.TEXT_OPERATION_LOG)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "entrance-history")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.configData.TEXT_ENTRANCE_HISTORY)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "equipment-status")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          _vm._s(_vm.configData.TEXT_EQUIPMENT_STATUS_CONDITION)
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "approval-status")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          _vm._s(_vm.configData.TEXT_APPROVAL_STATUS_LIST)
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "user")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.configData.TEXT_USER_MANAGEMENT)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "approver-settings")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.configData.TEXT_APPROVER_SETTING)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "email-settings")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.configData.TEXT_EMAIL_SETTINGS)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "office")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          _vm._s(_vm.configData.TEXT_COL_MANAGEMENT_OFFICE)
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm.priviledge === "0"
    ? _c(
        "v-navigation-drawer",
        {
          staticClass: "allig-nav",
          attrs: { app: "", clipped: "" },
          model: {
            value: _vm.innerShow,
            callback: function ($$v) {
              _vm.innerShow = $$v
            },
            expression: "innerShow",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _c(
                "v-list-item-group",
                {
                  attrs: { "active-class": "lighten-2" },
                  model: {
                    value: _vm.selectedItem,
                    callback: function ($$v) {
                      _vm.selectedItem = $$v
                    },
                    expression: "selectedItem",
                  },
                },
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "office")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          _vm._s(_vm.configData.TEXT_COL_MANAGEMENT_OFFICE)
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "email-settings")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.configData.TEXT_EMAIL_SETTINGS)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "approver-settings")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.configData.TEXT_APPROVER_SETTING)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "user")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.configData.TEXT_USER_MANAGEMENT)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "equipment-status")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          _vm._s(_vm.configData.TEXT_EQUIPMENT_STATUS_CONDITION)
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "entrance-history")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.configData.TEXT_ENTRANCE_HISTORY)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "log")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.configData.TEXT_OPERATION_LOG)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("menuClick", "approval-status")
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          _vm._s(_vm.configData.TEXT_APPROVAL_STATUS_LIST)
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }