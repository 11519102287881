
  import { Vue } from 'vue-property-decorator';
  import ManualTemplate from '@/components/templates/ManualTemplate/ManualTemplate.vue';
  import * as Config from '@/config';

  export interface DataType {
    // Props
    loading: boolean;
    resData: any;
    configData: any;
  }

  export default Vue.extend({
    name: 'ManualPage',
    components: {
        ManualTemplate,
    },
    data: (): DataType => ({
      // Props
      loading: false,
      resData: [],
      configData: Config,
    }),
    mounted() {
      this.$emit('activeMenu', 'manual');
    },
    created() {
      const priviledge = sessionStorage.getItem('priviledge');
      switch (priviledge) {
        case '0':
          this.resData = {
              admin: Config.TEXT_MANUAL_FOR_ADMIN,
              operation: Config.TEXT_MANUAL_FOR_OPERATION_MGR,
              applicants: Config.TEXT_MANUAL_FOR_APPLICANTS,
              room_entry_applicants: Config.TEXT_MANUAL_FOR_ROOM_ENTRY_APPLICANTS,
          };
          break;
        case '1':
          this.resData = {
              operation: Config.TEXT_MANUAL_FOR_OPERATION_MGR,
              applicants: Config.TEXT_MANUAL_FOR_APPLICANTS,
              room_entry_applicants: Config.TEXT_MANUAL_FOR_ROOM_ENTRY_APPLICANTS,
          };
          break;
        case '2':
          this.resData = {
              applicants: Config.TEXT_MANUAL_FOR_APPLICANTS,
              room_entry_applicants: Config.TEXT_MANUAL_FOR_ROOM_ENTRY_APPLICANTS,
          };
          break;
        case '3':
          this.resData = {
              room_entry_applicants: Config.TEXT_MANUAL_FOR_ROOM_ENTRY_APPLICANTS,
          };
          break;
        default:
          break;
      }
    },
    methods: {
      //
    },
  });
