
import { Vue } from 'vue-property-decorator';
import OfficeListTemplate from '@/components/templates/OfficeListTemplate/OfficeListTemplate.vue';
import * as Config from '@/config';
import auth from '@/auth';

interface Area {
  areaId: string;
  areaName: string;
  memo: string;
}
interface OfficeInfo {
  officeId: string;
  officeName: string;
  domainCode: any;
  supplement: string;
  updated: string;
  areaList: Area[];
}

export interface DataType {
  // Props
  loading: boolean;
  isRegistering: boolean;
  loadingCSV: boolean;
  loadingModify: boolean;
  loadingType: number; // 0: reading (default), 1: adding, 2: editing, 3: deleting
  isEdit: boolean;
  dataOfficeName: any;
  dataOfficeCode: any;
  officeKeyword: string;
  officeList: any;
  officeTotalCount: number;
  curOfficeList: any;
  exportData: any;
  perPage: number;
  keyword: string;
  showModalOfficeSettings: boolean;
  areaKeyword: string;
  areaList: any;
  oriOfficeAreaList: any;
  officeInfo: OfficeInfo;
  domainItems: any;
  isResetData: boolean;
  confirmOfficeDialog: boolean;
  confirmOfficeText: string;
  deleteOfficeDialog: boolean;
  loadingSearch: boolean;
  office_name: string;
  isReload: number;
}

export default Vue.extend({
  name: 'OfficeListPage',
  components: {
    OfficeListTemplate,
  },
  data: (): DataType => ({
    // Props
    loading: false,
    isRegistering: false, // default: false -> reading data
    loadingCSV: false,
    loadingModify: false,
    loadingType: 0, // default: 0 -> reading data
    isEdit: false,
    dataOfficeName: [],
    dataOfficeCode: [],
    officeKeyword: '',
    officeList: [],
    areaList: [],
    officeTotalCount: 0,
    showModalOfficeSettings: false,
    areaKeyword: '',
    oriOfficeAreaList: [],
    officeInfo: {
      officeId: '',
      officeName: '',
      domainCode: '',
      supplement: '',
      updated: '',
      areaList: [],
    },
    domainItems: [],
    isResetData: false,
    curOfficeList: [],
    exportData: [],
    keyword: '',
    perPage: Config.PER_PAGE,
    confirmOfficeDialog: false,
    confirmOfficeText: '',
    deleteOfficeDialog: false,
    loadingSearch: false,
    office_name: '',
    isReload: 0,
  }),
  watch: {
    loading(value) {
      this.$emit('loading', value);
      if (value) {
        this.$emit('textCircle', auth.getLoadingMessage(this.loadingType));
      } else if (this.loadingType !== 0) {
        this.loadingType = 0; // reset to default value
      }
    },
    loadingCSV(value) {
      this.$emit('loading', value);
    },
    loadingModify(value) {
      this.$emit('loading', value);
      if (value) {
        this.$emit('textCircle', auth.getLoadingMessage(this.loadingType));
      } else if (this.loadingType !== 0) {
        this.loadingType = 0; // reset to default value
      }
    },
  },
  mounted() {
    this.$emit('activeMenu', 'office');
  },
  created() {
    //
    this.getOfficeList(true);
    // get area list to use for creating/updating office
    this.initAreaList();
  },
  methods: {
    //
    exportCSV(value: any): any {
      this.exportData = [];
      this.loadingCSV = true;
      this.getExportData();
    },
    changeColumn(value: any): any {
      this.loading = true;
      setTimeout(() => {this.loading = false; }, 300);
    },
    searchOffice(args: any): any {
      this.loadingSearch = true;
      this.officeKeyword = '';
      this.officeTotalCount = 0;
      this.office_name = '';
      args.target.forEach((element: any, key: any) => {
        if (element.value !== undefined && element.value !== null && element.value !== '') {
          // this.officeKeyword += element.name + '=' + element.value + '&';
          if (element.name === 'office_name' && element.value.text !== null && element.value.text !== '') {
            // this.officeKeyword += element.name + '=' + element.value.slice(0, Config.OFFICE_NAME_MAX_LEN) + '&';
            this.office_name = element.value.text;
            this.officeKeyword += element.name + '=' + element.value.text + '&';
          } else if (element.name === 'office_code_list' && element.value.value !== null &&
                    element.value.value !== '') {
            this.officeKeyword += 'office_code' + '=' + element.value.value + '&';
          } else {
            this.officeKeyword += element.name + '=' + element.value + '&';
          }
        }
      });
      // console.log('officeKeyword: ' + this.officeKeyword);
      this.getOfficeList();
    },
    getExportData(): any {
      let displayOptions = sessionStorage.getItem('displayOptionOffice');
      console.log('displayOptionOffice: ' + displayOptions);
      if (displayOptions === undefined || displayOptions === null) {
        displayOptions = JSON.stringify(['officeId', 'officeName', 'supplement', 'updated']);
      }
      const columns = JSON.parse(displayOptions);
      if (!this.isEmpty(this.curOfficeList) && this.curOfficeList.length > 0) {
        for (const item of this.curOfficeList) {
          this.exportData.push(this.getExportDataItem(item, columns));
        }
        auth.downloadCSV(this.exportData, Config.TEXT_MANAGEMENT_FACILITY_LIST);
        this.loadingCSV = false;
      } else {
        if (this.exportData.length === 0) {
          this.exportData.push(this.getExportNoData(columns));
        }
        auth.downloadCSV(this.exportData, Config.TEXT_MANAGEMENT_FACILITY_LIST);
        this.loadingCSV = false;
      }
    },
    getExportDataItem(item: any, columns: any): any {
      let exportItem: any = {};
      if (columns.includes('officeName') && columns.includes('supplement') && columns.includes('updated')) {
        exportItem = {
          [Config.TEXT_MANAGEMENT_FACILITY_CODE]: item.officeId,
          [Config.TEXT_MANAGEMENT_FACILITY_NAME]: auth.getDefaultStringVal(item.officeName),
          [Config.TEXT_REMARKS]: auth.getDefaultStringVal(item.supplement),
          [Config.TEXT_UPDATE_DATE]: auth.converDateShort(item.updated),
        };
      } else if (columns.includes('supplement') && columns.includes('updated')) {
        exportItem = {
          [Config.TEXT_MANAGEMENT_FACILITY_CODE]: item.officeId,
          [Config.TEXT_REMARKS]: auth.getDefaultStringVal(item.supplement),
          [Config.TEXT_UPDATE_DATE]: auth.converDateShort(item.updated),
        };
      } else if (columns.includes('officeName')  && columns.includes('updated')) {
        exportItem = {
          [Config.TEXT_MANAGEMENT_FACILITY_CODE]: item.officeId,
          [Config.TEXT_MANAGEMENT_FACILITY_NAME]: auth.getDefaultStringVal(item.officeName),
          [Config.TEXT_UPDATE_DATE]: auth.converDateShort(item.updated),
        };
      } else if (columns.includes('officeName') && columns.includes('supplement')) {
        exportItem = {
          [Config.TEXT_MANAGEMENT_FACILITY_CODE]: item.officeId,
          [Config.TEXT_MANAGEMENT_FACILITY_NAME]: auth.getDefaultStringVal(item.officeName),
          [Config.TEXT_REMARKS]: auth.getDefaultStringVal(item.supplement),
        };
      } else if (columns.includes('officeName')) {
        exportItem = {
          [Config.TEXT_MANAGEMENT_FACILITY_CODE]: item.officeId,
          [Config.TEXT_MANAGEMENT_FACILITY_NAME]: auth.getDefaultStringVal(item.officeName),
        };
      } else if (columns.includes('supplement')) {
        exportItem = {
          [Config.TEXT_MANAGEMENT_FACILITY_CODE]: item.officeId,
          [Config.TEXT_REMARKS]: auth.getDefaultStringVal(item.supplement),
        };
      } else if (columns.includes('updated')) {
        exportItem = {
          [Config.TEXT_MANAGEMENT_FACILITY_CODE]: item.officeId,
          [Config.TEXT_UPDATE_DATE]: auth.converDateShort(item.updated),
        };
      } else {
        exportItem = {
          [Config.TEXT_MANAGEMENT_FACILITY_CODE]: item.officeId,
        };
      }
      return exportItem;
    },
    getExportNoData(columns: any): any {
      let exportItem: any = {};
      if (columns.includes('officeName') && columns.includes('supplement') && columns.includes('updated')) {
        exportItem = {
          [Config.TEXT_MANAGEMENT_FACILITY_CODE]: '',
          [Config.TEXT_MANAGEMENT_FACILITY_NAME]: '',
          [Config.TEXT_REMARKS]: '',
          [Config.TEXT_UPDATE_DATE]: '',
        };
      } else if (columns.includes('supplement') && columns.includes('updated')) {
        exportItem = {
          [Config.TEXT_MANAGEMENT_FACILITY_CODE]: '',
          [Config.TEXT_REMARKS]: '',
          [Config.TEXT_UPDATE_DATE]: '',
        };
      } else if (columns.includes('officeName')  && columns.includes('updated')) {
        exportItem = {
          [Config.TEXT_MANAGEMENT_FACILITY_CODE]: '',
          [Config.TEXT_MANAGEMENT_FACILITY_NAME]: '',
          [Config.TEXT_UPDATE_DATE]: '',
        };
      } else if (columns.includes('officeName') && columns.includes('supplement')) {
        exportItem = {
          [Config.TEXT_MANAGEMENT_FACILITY_CODE]: '',
          [Config.TEXT_MANAGEMENT_FACILITY_NAME]: '',
          [Config.TEXT_REMARKS]: '',
        };
      } else if (columns.includes('officeName')) {
        exportItem = {
          [Config.TEXT_MANAGEMENT_FACILITY_CODE]: '',
          [Config.TEXT_MANAGEMENT_FACILITY_NAME]: '',
        };
      } else if (columns.includes('supplement')) {
        exportItem = {
          [Config.TEXT_MANAGEMENT_FACILITY_CODE]: '',
          [Config.TEXT_REMARKS]: '',
        };
      } else if (columns.includes('updated')) {
        exportItem = {
          [Config.TEXT_MANAGEMENT_FACILITY_CODE]: '',
          [Config.TEXT_UPDATE_DATE]: '',
        };
      } else {
        exportItem = {
          [Config.TEXT_MANAGEMENT_FACILITY_CODE]: '',
        };
      }
      return exportItem;
    },
    showConfirmOfficeDialog(officeCode: any) {
      if (this.isEdit) {
        this.confirmOfficeText = Config.TEXT_REQUEST_UPDATE_MANAGEMENT_FACILITY;
        this.showModalOfficeSettings = false;
        this.confirmOfficeDialog = true;
      } else {
        const apiLink = Config.OFFICES_LIST_API + '?office_code=' + officeCode;
        // this.loading = true;
        this.$http.get(apiLink)
        .then((response: any) => {
          // this.loading = false;
          if (!this.isEmpty(response.body.offices) && response.body.offices.length > 0) {
            this.showModalOfficeSettings = false;
            this.isResetData = true;
            alert(Config.TEXT_REGISTER_DUPLICATE_FACILITY_ID);
            this.isResetData = false;
          } else {
            this.confirmOfficeText = Config.TEXT_REQUEST_ADD_MANAGEMENT_FACILITY;
            this.showModalOfficeSettings = false;
            this.confirmOfficeDialog = true;
          }
        })
        .then(null, (err: any) => {
          // this.loading = false;
          console.log(err);
        });
      }
    },
    closeConfirmOfficeDialog() {
      this.confirmOfficeDialog = false;
      this.confirmOfficeText = '';
      this.showModalOfficeSettings = true;
    },
    addOfficeArea(officeCode: any, data: any) {
      const apiLink = Config.OFFICE_AREAS_API + '/' + officeCode;
      return new Promise((resolve, reject) => {
        const area = {
          area_code: data.areaId,
          memo: data.remarks,
        };
        this.$http.put(apiLink, JSON.stringify(area))
          .then((response: any) => {
            if (response.body.message === 'Success') {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .then(null, (error: any) => {
            let errorName = '';
            switch (error.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 401:
                errorName = Config.ERROR_401;
                break;
              case 403:
                errorName = Config.ERROR_403;
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            resolve(false);
          });
      });
    },
    deleteOfficeArea(officeCode: any, areaCode: any) {
      const apiLink = Config.OFFICE_AREAS_API + '/' + officeCode + '?area_code=' + areaCode;
      return new Promise((resolve) => {
        this.$http.delete(apiLink)
          .then((res: any) => {
            if (res.body.message === 'Success') {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 401:
                errorName = Config.ERROR_401;
                break;
              case 403:
                errorName = Config.ERROR_403;
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = areaCode + '>><span>' + errorName + '</span>';
            // this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }); // catch
      });
    },
    getOfficeList(isInit: boolean = false): any {
      let apiLink = Config.OFFICES_LIST_API;
      if (this.officeKeyword !== '') {
        apiLink = apiLink + '?' + this.officeKeyword.replace(/&\s*$/, '');
      }
      this.officeList = [];
      this.startLoading();
      this.$http.get(apiLink)
        .then((response: any) => {
          if (!this.isEmpty(response.body.offices) && response.body.offices.length > 0) {
            const officeList = response.body.offices.map(
              (v: { code: string; name: string; domain_code: string; memo: string; updated: string; }) =>
              ({officeId: v.code, officeName: v.name, domainCode: v.domain_code,
                supplement: v.memo, updated: v.updated}));
            this.officeTotalCount = officeList.length;
            officeList.forEach(async (element: any, key: any) => {
              if (this.office_name !== '') {
                if (element.officeName === this.office_name) {
                  this.officeList.push(element);
                }
              } else {
                this.officeList.push(element);
              }
            });
            if (isInit) {
              this.dataOfficeCode = [];
              this.dataOfficeName = [];
              response.body.offices.forEach(async (element: any, key: any) => {
                this.dataOfficeCode.push({
                  text: element.code,
                  value: element.code,
                });
                this.dataOfficeName.push({
                  text: element.name,
                  value: element.code,
                });
              });
            }
            this.endLoading();
            // console.log('officeTotalCount = ', this.officeTotalCount);
          } else {
            this.officeList = [];
            this.officeTotalCount = 0;
            this.endLoading();
          }
          this.loadingSearch = false;
        })
        .then(null, (err: any) => {
          this.officeTotalCount = 0;
          this.endLoading();
          this.loadingSearch = false;
          alert(Config.TEXT_ERROR_FOR_MANAGEMENT_FACILITY);
        });
    },
    reloadOfficeList(): any {
      return new Promise((resolve) => {
        let apiLink = Config.OFFICES_LIST_API;
        this.loadingSearch = true;
        if (this.officeKeyword !== '') {
          apiLink = apiLink + '?' + this.officeKeyword.replace(/&\s*$/, '');
        }
        this.officeList = [];
        this.officeTotalCount = 0;
        this.$http.get(apiLink)
        .then((response: any) => {
          if (!this.isEmpty(response.body.offices) && response.body.offices.length > 0) {
            this.officeList = response.body.offices.map(
              (v: { code: string; name: string; domain_code: string; memo: string; updated: string; }) =>
              ({officeId: v.code, officeName: v.name, domainCode: v.domain_code,
                supplement: v.memo, updated: v.updated}));
            this.officeTotalCount = this.officeList.length;
          } else {
            this.officeList = [];
            this.officeTotalCount = 0;
          }
          this.loadingSearch = false;
          resolve(true);
        })
        .then(null, (err: any) => {
          console.log(err);
          this.loadingSearch = false;
          alert(Config.TEXT_ERROR_FOR_MANAGEMENT_FACILITY);
          resolve(false);
        });
      });
    },
    saveOffice(officeCode: string, office: any) {
      return new Promise((resolve) => {
        this.confirmOfficeDialog = false;
        this.confirmOfficeText = '';
        const apiLink = Config.OFFICES_LIST_API + '/' + officeCode;
        this.isResetData = false;
        this.$http.patch(apiLink, JSON.stringify(office))
        .then((response: any) => {
          if (response.body.message === 'Success') {
            this.officeInfo = {
              officeId: '',
              officeName: '',
              domainCode: '',
              supplement: '',
              updated: '',
              areaList: [],
            };
            this.isResetData = true;
            resolve(true);
          } else {
            alert(Config.TEXT_ERROR_FOR_UPDATE_MANAGEMENT_FACILITY);
            resolve(false);
          }
        })
        .then(null, (err: any) => {
          alert(Config.TEXT_ERROR_FOR_UPDATE_MANAGEMENT_FACILITY);
          resolve(false);
        });
      });
    },
    createNewOffice() {
      this.isResetData = true;
      this.isEdit = false;
      this.officeInfo = {
        officeId: '',
        officeName: '',
        domainCode: '',
        supplement: '',
        updated: '',
        areaList: [],
      };
      this.getDomainList();
      this.oriOfficeAreaList = [];
      this.showModalOfficeSettings = true;
    },
    clickEditOffice(value: any) {
      this.isEdit = true;
      this.isResetData = true;
      this.showModalOfficeSettings = true;
      // this.areaList = [];
      this.domainItems = [];
      this.oriOfficeAreaList = [];
      const apiLink = Config.OFFICES_LIST_API + '?office_code=' + value;
      this.loadingModify = true;
      this.$http.get(apiLink)
        .then((response: any) => {
          if (!this.isEmpty(response.body.offices) && response.body.offices.length > 0) {
            this.officeInfo = {
              officeId: response.body.offices[0].code,
              officeName: response.body.offices[0].name,
              domainCode: {text: response.body.offices[0].domain_code, value: response.body.offices[0].domain_code},
              supplement: response.body.offices[0].memo,
              updated: response.body.offices[0].updated,
              areaList: [],
            };
            this.getOfficeAreaList(this.officeInfo)
            .then(() => {
              if (sessionStorage.getItem('priviledge')  === '0') {
                this.getDomainList()
                .then(() => {
                  this.loadingModify = false;
                });
              } else {
                this.domainItems.push({text: response.body.offices[0].domain_name,
                  value: response.body.offices[0].domain_code});
                this.loadingModify = false;
              }
            });
          } else {
            this.officeInfo = {
              officeId: '',
              officeName: '',
              domainCode: '',
              supplement: '',
              updated: '',
              areaList: [],
            };
            this.loadingModify = false;
            this.isResetData = false;
          }
        })
        .then(null, (err: any) => {
          this.isResetData = false;
          // this.officeInfo = {
          //     officeId: '',
          //     officeName: '',
          //     supplement: '',
          //     updated: '',
          //   };
          this.loadingModify = false;
          alert(Config.TEXT_ERROR_FOR_MANAGEMENT_FACILITY);
        });
    },
    saveOfficeSettings(args: any) {
      this.confirmOfficeDialog = false;
      this.confirmOfficeText = '';
      const office = args.office;
      const newOffice = {
        domain_code: office.domainCode,
        office_code: office.officeId,
        office_name: office.officeName,
        office_memo: office.supplement,
      };
      this.isRegistering = true;
      this.loadingType = 1;
      this.loadingModify = true;
      this.isResetData = false;
      this.$http.post(Config.OFFICES_LIST_API, JSON.stringify(newOffice))
        .then((response: any) => {
          if (response.body.message === 'Success') {
            // add area(s) to office
            for (const area of args.office.areaList) {
              // PUT /office-areas/{office_code}
              this.addOfficeArea(args.office.officeId, area);
            }
            this.officeInfo = {
              officeId: '',
              officeName: '',
              domainCode: '',
              supplement: '',
              updated: '',
              areaList: [],
            };
            this.isResetData = true;
            this.officeTotalCount = 0;
            this.reloadOfficeList()
            .then(() => {
              this.loadingModify = false;
              alert(Config.TEXT_REGISTRATION_COMPLETED);
            });
          } else {
            this.loadingModify = false;
            alert(Config.TEXT_ERROR_FOR_REGISTRATION_MANAGEMENT_FACILITY);
          }
        })
        .then(null, (err: any) => {
          switch (err.status) {
            case 409:
              this.showModalOfficeSettings = false;
              this.isResetData = true;
              alert(Config.TEXT_REGISTER_DUPLICATE_FACILITY_ID);
              this.isResetData = false;
              break;
            default:
              alert(Config.TEXT_ERROR_FOR_REGISTRATION_MANAGEMENT_FACILITY);
              break;
          }
          this.loadingModify = false;
        });
    },
    updateOfficeSettings(args: any) {
      const office = { office_name: args.office.officeName, office_memo: args.office.supplement };
      // PATCH /offices/{office_code}
      this.loadingType = 2;
      this.loadingModify = true;
      this.saveOffice(args.office.officeId, office)
      .then((response) => {
        if (response === true) {
          for (const area of args.office.areaList) {
            const idx = this.oriOfficeAreaList.findIndex((a: Area) => a.areaId === area.areaId);
            if (idx < 0) {
              // ADD -> PUT /office-areas/{office_code}
              this.addOfficeArea(args.office.officeId, area);
            }
          }
          for (const area of this.oriOfficeAreaList) {
            const idx = args.office.areaList.findIndex((a: Area) => a.areaId === area.areaId);
            if (idx < 0) {
              // DELETE -> DELETE /office-areas/{office_code}{?area_code}
              this.deleteOfficeArea(args.office.officeId, area.areaId);
            }
          }
          this.oriOfficeAreaList = [];
          this.officeTotalCount = 0;
          this.reloadOfficeList()
          .then(() => {
            alert(Config.TEXT_UPDATE_COMPLETED);
            this.loadingSearch = false;
            this.loadingModify = false;
          })
          .then(null, (err: any) => {
            this.loadingSearch = false;
            this.loadingModify = false;
          });
        } else {
          this.oriOfficeAreaList = [];
          this.loadingModify = false;
        }
      })
      .then(null, (err: any) => {
        console.log(err);
        this.oriOfficeAreaList = [];
        this.loadingModify = false;
      });
    },
    closeOfficeSettingsDialog() {
      this.showModalOfficeSettings = false;
      this.confirmOfficeDialog = false;
      this.confirmOfficeText = '';
      this.officeInfo = {
        officeId: '',
        officeName: '',
        domainCode: '',
        supplement: '',
        updated: '',
        areaList: [],
      };
      this.oriOfficeAreaList = [];
      this.isResetData = false;
    },
    showConfirmDeleteDialog() {
      this.deleteOfficeDialog = true;
    },
    closeDeleteOfficeDialog() {
      this.deleteOfficeDialog = false;
    },
    async deleteSelectedOffices(selectedOfficeIds: string[]) {
      this.deleteOfficeDialog = false;
      this.loadingType = 3;
      this.loadingModify = true;
      console.log('Selected offices: ' + JSON.stringify(selectedOfficeIds));
      const successList = [];
      const errorList = [];
      let alertMessage = '';
      for (const selectedOfficeId of selectedOfficeIds) {
        // delete office
        const result = await this.deleteOffice(selectedOfficeId);
        if (result) {
          successList.push(selectedOfficeId);
        } else {
          errorList.push(selectedOfficeId);
        }
      }
      if (successList.length > 0) {
        alertMessage = Config.TEXT_DELETE_COMPLETED;
        successList.forEach((success) => {
          alertMessage += '\n' + success;
        });
        alertMessage += '\n';
      }
      if (errorList.length > 0) {
        alertMessage += Config.ERROR_409_OFFICE;
        errorList.forEach((error) => {
          alertMessage += '\n' + error;
        });
      }
      alert(alertMessage);
      this.officeTotalCount = 0;
      this.isReload++;
      this.reloadOfficeList()
        .then(() => {
          this.loadingModify = false;
        });
    },
    deleteOffice(officeId: string) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.OFFICES_LIST_API + '/' + encodeURIComponent(officeId))
          .then((response: any) => {
            if (response.body.message === 'Success') {
              resolve(true);
            } else {
              // this.officeDeleteFail.push(officeId);
              resolve(false);
            }
          })
          .then(null, (err: any) => {
            console.log('err: ', err);
            resolve(false);
          });
      });
    },
    getOfficeAreaList(office: any) {
      return new Promise((resolve, reject) => {
        const apiLink = Config.OFFICE_AREAS_API + '/' + office.officeId;
        this.$http.get(apiLink)
        .then((response: any) => {
          if (!this.isEmpty(response.body.areas) && response.body.areas.length > 0) {
            office.areaList = response.body.areas.map(
              (v: { area_code: string; area_name: string; memo: string }) =>
              ({areaId: v.area_code, areaName: '', remarks: v.memo}));
            if (office.areaList !== null && office.areaList.length > 0 &&
                this.areaList !== null && this.areaList.length > 0) {
              for (const area of office.areaList) {
                const val = this.areaList.find((a: any) => a.areaId === area.areaId);
                if (val) {
                  area.areaName = val.areaName;
                }
              }
            }
            this.oriOfficeAreaList = office.areaList.map((area: any) => ({...area}));
          } else {
            office.areaList = [];
          }
          resolve(true);
        })
        .then(null, (err: any) => {
          alert(Config.TEXT_ERROR_FOR_LIST_OF_MANAGEMENT_FACILITY);
        });
        resolve(false);
      });
    },
    initAreaList() {
      return new Promise((resolve, reject) => {
        this.areaList = [];
        this.$http.get(Config.AREAS_API)
        .then(async (res: any) => {
          if (!this.isEmpty(res.body.areas) && res.body.areas.length > 0) {
            // add remaining area to list
            for (const area of res.body.areas) {
              this.areaList.push({areaId: area.code, areaName: area.name, remarks: area.memo});
            }
          } else {
            // no area
          }
          resolve(true);
        })
        .then(null, (err: any) => {
          alert(Config.TEXT_ERROR_FOR_LIST_OF_FACILITIES);
        });
        resolve(false);
      });
    },
    getDomainList() {
      return new Promise((resolve, reject) => {
        this.domainItems = [];
        this.$http.get(Config.DOMAINS_API)
        .then((res: any) => {
          if (!this.isEmpty(res.body.domains) && res.body.domains.length > 0) {
            for (const domain of res.body.domains) {
              this.domainItems.push({text: domain.name, value: domain.code});
            }
          } else {
            // no domain
          }
          resolve(true);
        })
        .then(null, (err: any) => {
          alert(Config.TEXT_ERROR_FOR_AFFILIATION_LIST);
          resolve(false);
        });
      });
    },
    currentItems(data: any) {
      this.curOfficeList = data;
    },
    startLoading() {
      if (this.loading === false) {
        this.loading = true;
      }
    },
    endLoading() {
      if (this.loading === true) {
        this.loading = false;
      }
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
  },
});
