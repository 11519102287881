var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("entrance-history-template", {
    attrs: {
      loading: _vm.loading,
      resData: _vm.resData,
      isAction: _vm.isAction,
      dataOffice: _vm.dataOffice,
      dataArea: _vm.dataArea,
      gateCode: _vm.gateCode,
      loadMore: _vm.loadMore,
      loadingSearch: _vm.loadingSearch,
      total: _vm.total,
      page: _vm.page,
      perPage: _vm.perPage,
      office_name: _vm.office_name,
    },
    on: {
      search: function ($event) {
        return _vm.search($event)
      },
      export: function ($event) {
        return _vm.exportCSV($event)
      },
      "change-column": function ($event) {
        return _vm.changeColumn($event)
      },
      "change-per-page": function ($event) {
        return _vm.changePerPage($event)
      },
      "change-page": function ($event) {
        return _vm.changePage($event)
      },
      "change-office": function ($event) {
        return _vm.changeOffice($event)
      },
      "change-area": function ($event) {
        return _vm.changeArea($event)
      },
      "get-current-items": function ($event) {
        return _vm.getCurrentItems($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }