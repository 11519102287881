var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.page > 0
    ? _c(
        "div",
        { staticClass: "allig-select-per-page" },
        [
          _c(
            "v-row",
            {
              staticClass:
                "text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption",
              attrs: { "no-gutters": "", justify: "end" },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-end allig-per-page-select" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mr-4 text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption",
                    },
                    [_vm._v(" 表示件数: ")]
                  ),
                  _c("v-select", {
                    staticClass:
                      "text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption pt-0",
                    attrs: {
                      color: "gray",
                      "item-color": "gray",
                      "hide-details": "",
                      light: "",
                      items: _vm.items,
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.innerValue,
                      callback: function ($$v) {
                        _vm.innerValue = $$v
                      },
                      expression: "innerValue",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }