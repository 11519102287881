
import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import ApproverSettingsList from '@/components/organisms/ApproverSettingsList/ApproverSettingsList.vue';
import Searchbox, { SearchTargetItem} from '@/components/molecules/Searchbox/Searchbox.vue';
import * as Config from '../../../config';
interface TypeSelect {
  text: string;
  value: string;
}
export interface DataType {
  selectedApprovers: string[];
  tab: number;
  searchTargetItems: SearchTargetItem[];
  configData: any;
}
export default Vue.extend({
  name: 'ApproverSettingsTemplate',
  components: {
    ApproverSettingsList,
    Searchbox,
    Breadcrumbs,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingSearch: {
      type: Boolean,
      default: false,
    },
    isShowAddDialog: {
      type: Boolean,
      default: false,
    },
    isDeleteFinish: {
      type: Boolean,
      default: false,
    },
    isAction: {
      type: Number,
      default: 0,
    },
    resData: {
      default: () => [],
    },
    approverTotalCount: {
      type: Number,
      default: 0,
    },
    organizations: {
      default: () => [],
    },
    dataOffice: {
      default: () => [],
    },
    userInfo: {
      default: () => [],
    },
    isResetData: {
      default: false,
    },
    actionItems: {
      default: () => [],
    },
    errorUser_id: {
      default: '',
    },
    errorMessageProcess: {
      default: [],
    },
    office_name: {
      default: '',
    },
    deleteApproverSetting: {
      type: Boolean,
      default: false,
    },
  },
  data: (): DataType => ({
    selectedApprovers: [],
    tab: 0,
    searchTargetItems: [
        { label: Config.TEXT_USER_ID, name: 'user_id', value: '', maxLen: Config.USER_ID_MAX_LEN},
        { label: Config.TEXT_USER_NAME, name: 'name', value: '', maxLen: Config.USER_NAME_MAX_LEN},
        sessionStorage.getItem('priviledge')  === '2' ?
        { label: Config.TEXT_COL_MANAGEMENT_OFFICE, name: 'office_name',
        value: sessionStorage.getItem('office_name'), isDisabled: true}
        : { label: Config.TEXT_COL_MANAGEMENT_OFFICE, name: 'office_list', value: ''},
        { label: Config.TEXT_COL_EMAIL_ADDRESS, name: 'email', value: '', maxLen: Config.EMAIL_MAX_LEN},
    ],
    configData: Config,
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: Config.TEXT_APPROVER_SETTING },
        ];
      },
    },
  },
  watch: {
    loading(val) {
      if (val === false) {
        this.selectedApprovers = [];
      }
    },
  },
  methods: {
    selectApprover(args: any) {
      this.selectedApprovers = args;
    },
  },
  mounted() {
    console.log('res data', this.resData);
  },
});
