
import {Vue } from 'vue-property-decorator';
import ApproverSettingsTemplate from '@/components/templates/ApproverSettingsTemplate/ApproverSettingsTemplate.vue';
import * as Config from '@/config';
import auth from '@/auth';
import { config } from 'chai';
export interface DataType {
  // Props
  page: number;
  perPage: number;
  isAction: number;
  loadingCSV: boolean;
  loading: boolean;
  isRegistering: string;
  resData: any;
  approverTotalCount: number;
  userInfo: any;
  keyword: string;
  statementId: string;
  errorUser_id: string;
  office_name: string;
  organizations: any;
  dataOffice: any;
  exportData: any;
  deleteApproverSetting: boolean;
  isResetData: boolean;
  loadingSearch: boolean;
  isShowAddDialog: boolean;
  name: string;
  email: string;
  rowinErrDialog: number;
  errorMessageProcess: any;
  isDeleteFinish: boolean;
  countDelApprover: number;
  listDataExport: any;
  user_id: string;
  userIdsSuccess: any;
  userIdsFailded: any;
}
export default Vue.extend({
  name: 'ApproverSettingsListPage',
  components: {
    ApproverSettingsTemplate,
  },
  data: (): DataType => ({
    // Props
    loading: false,
    isRegistering: 'loading', // default: false -> reading data
    loadingCSV: false,
    isAction: 0,
    page: 1,
    resData: [],
    approverTotalCount: 0,
    keyword: '',
    perPage: 100,
    office_name: '',
    statementId: '',
    userInfo: {},
    organizations: [],
    dataOffice: [],
    exportData: [],
    deleteApproverSetting: false,
    isResetData: false,
    loadingSearch: false,
    isShowAddDialog: false,
    name: '',
    email: '',
    errorUser_id: '',
    rowinErrDialog: 0,
    errorMessageProcess: [],
    listDataExport: [],
    isDeleteFinish: false,
    countDelApprover: 0,
    user_id: '',
    userIdsSuccess: [],
    userIdsFailded: [],
  }),
  mounted() {
    sessionStorage.removeItem('displayApproveSetting');
    this.$emit('activeMenu', 'approver-settings');
    this.loading = true;
    if (sessionStorage.getItem('priviledge')  === '0') {
      this.getDataOfficeAll()
      .then(() => this.getListData())
      .then( null, (err: any) => {
        this.loading = false;
        console.log(err);
      });
    } else {
      this.getDataOffice(sessionStorage.getItem('domainCode'))
      .then(() => this.getListData())
      .then( null, (err: any) => {
        this.loading = false;
        console.log(err);
      });
    }
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
      if (value) {
        let text = '';
        if (this.isRegistering === 'add') {
          text = Config.TEXT_PROGRESS_REGISTERING;
        } else if(this.isRegistering === 'del') {
          text = Config.TEXT_PROGRESS_DELETING;
        } else {
          text = Config.TEXT_PROGRESS_READING;
        }
        this.$emit('textCircle', text);
      } else if (this.isRegistering) {
        this.isRegistering = 'loading';
      }
    },
    loadingCSV(value) {
      this.$emit('loading', value);
    },
  },
  created() {
    //
  },
  methods: {
    getCurrentItems(item: any) {
      this.listDataExport = item;
    },
    changeColumn(value: any): any {
      this.loading = true;
      setTimeout(() => {this.loading = false; }, 300);
    },
    exportCSV(value: any): any {
      this.exportData = [];
      this.loadingCSV = true;
      this.getExportData();
    },
    showAddDialog() {
      this.isResetData = false;
      if (sessionStorage.getItem('priviledge')  === '2') {
        this.userInfo = {
          office_code: {
            text: sessionStorage.getItem('office_name'),
            value: sessionStorage.getItem('office_code'),
          },
        };
      }
      this.isShowAddDialog = true;
    },
    closeAddDialog() {
      this.userInfo = {};
      this.isResetData = true;
      this.isShowAddDialog = false;
      this.resData = [];
      this.approverTotalCount = 0;
      this.getListData();
    },
    showDeleteApproverSettingDialog() {
      this.deleteApproverSetting = true;
    },
    closeDeleteDialog() {
      this.userInfo = {};
      this.deleteApproverSetting = false;
    },
    closeFinish() {
      this.deleteApproverSetting = false;
      this.errorMessageProcess = [];
      this.isDeleteFinish = false;
      this.resData = [];
      this.approverTotalCount = 0;
      this.getListData();
    },
    search(args: any) {
      this.listDataExport = [];
      sessionStorage.removeItem('displayApproveSetting');
      this.loadingSearch = true;
      this.keyword = '';
      this.page = 1;
      this.name = '';
      this.user_id = '';
      this.email = '';
      this.resData = [];
      this.approverTotalCount = 0;
      args.target.forEach((element: any, key: any) => {
        if (element.value !== undefined && element.value !== null && element.value !== '') {
          if (element.name === 'name' || element.name === 'email' || element.name === 'user_id')  {
            if (element.name === 'name') {
              this.name = element.value.slice(0, Config.USER_NAME_MAX_LEN);
            } else if (element.name === 'email') {
              this.email = element.value.slice(0, Config.EMAIL_MAX_LEN);
            } else if (element.name === 'user_id') {
              this.user_id = element.value.slice(0, Config.USER_ID_MAX_LEN);
            }
          } else {
            if (element.name === 'office_list') {
              this.keyword += 'office_code=' + element.value.value + '&';
            } else {
              this.keyword += element.name + '=' + element.value + '&';
            }
          }
        }
      });
      this.getListData();
    },
    async getExportData(): Promise<any> {
      if (this.listDataExport.length > 0) {
        for (const item of this.listDataExport) {
          let templateExport: any = {};
          if (sessionStorage.hasOwnProperty('displayApproveSetting')) {
            let displayOption = sessionStorage.getItem('displayApproveSetting') as string;
            displayOption = displayOption.replace(/'/g, '"');
            displayOption = JSON.parse(displayOption);
            if (displayOption.includes('user_id')) {
              const useID = Config.TEXT_USER_ID;
              templateExport[useID] = auth.getDefaultStringVal(item.user_id);
            }
            if (displayOption.includes('user_name')) {
              const userName = Config.TEXT_USER_NAME;
              templateExport[userName] = auth.getDefaultStringVal(item.user_name);
            }
            if (displayOption.includes('office_name')) {
              const officeName = Config.TEXT_COL_MANAGEMENT_OFFICE;
              templateExport[officeName] = auth.getDefaultStringVal(item.office_name);
            }
            if (displayOption.includes('email')) {
              const email = Config.TEXT_COL_EMAIL_ADDRESS;
              templateExport[email] = auth.getDefaultStringVal(item.email);
            }
            if (displayOption.includes('memo')) {
              const memo = Config.TEXT_REMARKS;
              templateExport[memo] = auth.getDefaultStringVal(item.mmo);
            }
            if (displayOption.includes('updated')) {
              const updated = Config.TEXT_UPDATE_DATE;
              templateExport[updated] = auth.converDateShort(item.updated);
            }
          } else {
            templateExport = {
              [Config.TEXT_USER_ID]: auth.getDefaultStringVal(item.user_id),
              [Config.TEXT_USER_NAME]: auth.getDefaultStringVal(item.user_name),
              [Config.TEXT_COL_MANAGEMENT_OFFICE]: auth.getDefaultStringVal(item.office_name),
              [Config.TEXT_COL_EMAIL_ADDRESS]: auth.getDefaultStringVal(item.email),
              [Config.TEXT_REMARKS]: auth.getDefaultStringVal(item.memo),
              [Config.TEXT_UPDATE_DATE]: auth.converDateShort(item.updated),
            };
          }
          await this.exportData.push(templateExport);
        }
        auth.downloadCSV(this.exportData, Config.TEXT_APPROVER_SETTING);
        this.loadingCSV = false;
      } else {
        let templateExport: any = {};
        if (sessionStorage.hasOwnProperty('displayApproveSetting')) {
          let displayOption = sessionStorage.getItem('displayApproveSetting') as string;
          displayOption = displayOption.replace(/'/g, '"');
          displayOption = JSON.parse(displayOption);
          if (displayOption.includes('user_id')) {
            const useID = Config.TEXT_USER_ID;
            templateExport[useID] = '';
          }
          if (displayOption.includes('user_name')) {
            const userName = Config.TEXT_USER_NAME;
            templateExport[userName] = '';
          }
          if (displayOption.includes('office_name')) {
            const officeName = Config.TEXT_COL_MANAGEMENT_OFFICE;
            templateExport[officeName] = '';
          }
          if (displayOption.includes('email')) {
            const email = Config.TEXT_COL_EMAIL_ADDRESS;
            templateExport[email] = '';
          }
          if (displayOption.includes('memo')) {
            const memo = Config.TEXT_REMARKS;
            templateExport[memo] = '';
          }
          if (displayOption.includes('updated')) {
            const updated = Config.TEXT_UPDATE_DATE;
            templateExport[updated] = '';
          }
        } else {
          templateExport = {
            [Config.TEXT_USER_ID]: '',
            [Config.TEXT_USER_NAME]: '',
            [Config.TEXT_COL_MANAGEMENT_OFFICE]: '',
            [Config.TEXT_COL_EMAIL_ADDRESS]: '',
            [Config.TEXT_REMARKS]: '',
            [Config.TEXT_UPDATE_DATE]: '',
          };
        }
        await this.exportData.push(templateExport);
        auth.downloadCSV(this.exportData, Config.TEXT_APPROVER_SETTING);
        this.loadingCSV = false;
      }
    },
    getOrganizations() {
      this.organizations = [];
      // this.organizations = [];
      const apiLink = Config.ORGANIZATIONS_API + '?domain_code=' + sessionStorage.getItem('domainCode');
      this.$http.get(apiLink)
        .then((response: any) => {
          if (!this.isEmpty(response.body.organizations) && response.body.organizations.length > 0) {
            for (const org of response.body.organizations) {
              this.organizations.push(org.label);
            }
          } else {
            this.organizations = [];
          }
        })
        .then(null, (err: any) => {
          //
        });
    },
    getListData(): any {
      this.loading = true;
      this.office_name = '';
      let apiLink = Config.APPROVER_API;
      if (this.keyword !== '') {
        apiLink = apiLink + '?' + this.keyword.replace(/&\s*$/, '');
      }
      this.loading = true;
      this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.approvers.length > 0) {
            // this.resData = response.body.approvers;
            const resData = response.body.approvers;
            // this.getOffice(sessionStorage.getItem('office_code'));
            resData.forEach(async (element: any, key: any) => {
              let tempData = null;
              const result = this.dataOffice.find((a: any) => a.value === element.office_code);
              if (result) {
                element.office_name = result.text;
              }
              if (this.user_id === '' && this.name === '' && this.email === '') {
                tempData = (element);
              } else {
                if (this.user_id !== '') {
                  if (element.user_id.toUpperCase().startsWith(this.user_id.toUpperCase())) {
                    if (tempData === null) {
                      tempData = element;
                    }
                  } else {
                    tempData = '';
                  }
                }
                if (this.name !== '' && tempData !== '') {
                  if (element.user_name.toUpperCase().startsWith(this.name.toUpperCase())) {
                    tempData = element;
                  } else {
                    tempData = '';
                  }
                }
                if (this.email !== '' && tempData !== '') {
                  const email = tempData !== null ? tempData.email : element.email;
                  if (email.toUpperCase().startsWith(this.email.toUpperCase())) {
                    tempData = element;
                  } else {
                    tempData = '';
                  }
                }
              }
              if (tempData !== null && tempData !== '') {
                this.resData.push(tempData);
              }
            });
            this.approverTotalCount = this.resData.length;
          }
          this.loading = false;
          this.loadingSearch = false;
        })
        .then( null, (err: any) => {
          this.approverTotalCount = 0;
          this.loading = false;
          this.loadingSearch = false;
          alert(Config.TEXT_ERROR_FOR_APPROVER_LIST);
        }); // catch
    },
    getDataOffice(domainCode: any) {
      this.dataOffice = [];
      return new Promise((resolve, reject) => {
        const apiLink = Config.OFFICES_LIST_API + '?domain_code=' + domainCode;
        this.loading = true;
        this.$http.get(apiLink)
          .then((response: any) => {
            if (response.body.offices.length > 0) {
              response.body.offices.forEach((element: any, key: any) => {
                this.dataOffice.push({text: element.name, value: element.code});
              });
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .then(null, (err: any) => {
            resolve(false);
            //
          });
      });
    },
    getDataOfficeAll() {
      this.dataOffice = [];
      return new Promise((resolve, reject) => {
        const apiLink = Config.OFFICES_LIST_API;
        this.loading = true;
        this.$http.get(apiLink)
          .then((response: any) => {
            if (response.body.offices.length > 0) {
              response.body.offices.forEach((element: any, key: any) => {
                this.dataOffice.push({text: element.name, value: element.code});
              });
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .then(null, (err: any) => {
            resolve(false);
            //
          });
      });
    },
    addApprover(): any {
      this.isRegistering = 'add';
      const officeCode = this.userInfo.office_code.value;
      const apiLink = Config.APPROVER_API + '/' + officeCode;
      const data = {
        user_id: this.userInfo.user_id,
        memo: this.userInfo.memo,
      };
      this.loading = true;
      this.$http.put(apiLink, JSON.stringify(data))
      .then((response: any) => {
        this.loading = false;
        this.isShowAddDialog = false;
        if (response.body.message === 'Success') {
          this.resData = [];
          this.approverTotalCount = 0;
          this.getListData();
          alert(Config.TEXT_UPDATE_COMPLETED);
        } else {
          alert(Config.TEXT_ERROR_FOR_APPLICANT_APPROVER_NEW);
        }
      })
      .then( null, (err: any) => {
        this.loading = false;
        this.isShowAddDialog = false;
        alert(Config.TEXT_ERROR_FOR_APPLICANT_APPROVER_NEW);
      });
    },
    async deleteApprover(args: any) {
      this.isDeleteFinish = false;
      this.loading = true;
      this.rowinErrDialog = 0;
      this.errorMessageProcess = [];
      this.countDelApprover = 0;
      this.deleteApproverSetting = false;
      this.userIdsSuccess = [];
      this.userIdsFailded = [];
      this.isRegistering = 'del';
      let result;
      for (const element of args) {
        result = await this.deleteItem(element.office_code, element.user_id);
        if (result) {
          this.userIdsSuccess.push(element.user_id);
        } else {
          this.userIdsFailded.push(element.user_id);
        }
      }
      this.loading = false;
      let error = '';
      if (this.userIdsSuccess.length > 0 && this.userIdsFailded.length > 0) {
        error += '解除完了しました。\n';
        this.userIdsSuccess.forEach((element: any) => {
          error += element + '\n';
        });
        error += '申請承認者の解除に失敗しました。\n';
        this.userIdsFailded.forEach((element: any) => {
          error += element + '\n';
        });

      } else if (this.userIdsSuccess.length === 0 && this.userIdsFailded.length > 0) {
        error += '申請承認者の解除に失敗しました。\n';
        this.userIdsFailded.forEach((element: any) => {
          error += element + '\n';
        });
      } else {
        error = Config.TEXT_REMOVED_ITEMS;
      }
      alert(error);
      this.closeFinish();
    },
    deleteItem(officeCode: any, userId: string) {
      const apiLink = Config.APPROVER_API + '/' + encodeURIComponent(officeCode) +
                      '?user_id=' + encodeURIComponent(userId);
      return new Promise((resolve) => {
        this.$http.delete(apiLink)
        .then((response: any) => {
          this.countDelApprover++;
          resolve(true);
        })
        .then( null, (err: any) => {
          // const rowstatus = userId + '>>' + Config.TEXT_ERROR_FOR_APPLICANT_APPROVER_DEL + '';
          // this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
          resolve(false);
        }); // catch
      });
    },
    enterUserId(value: any) {
      this.errorUser_id = '';
      this.isResetData = true;
      this.getOrganizations();
      this.getUser(value);
      this.isResetData = false;
    },
    getUser(value: string) {
      const apiLink = Config.USERS_LIST_API + '/' + encodeURIComponent(value);
      this.loading = true;
      this.userInfo.name = '';
      this.userInfo.priviledge = '';
      this.userInfo.furigana = '';
      this.userInfo.email = '';
      this.userInfo.userType = '';
      this.userInfo.organizations = [];
      this.userInfo.memo = '';
      if (sessionStorage.getItem('priviledge')  === '2') {
        this.userInfo.office_code = {
          text: sessionStorage.getItem('office_name'),
          value: sessionStorage.getItem('office_code'),
        };
      } else {
        this.userInfo.office_code = {text: '', value: ''};
      }
      this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.user !== null) {
            this.userInfo = {
              user_id: response.body.user.user_id,
              name: response.body.user.name,
              priviledge: response.body.user.priviledge,
              furigana: response.body.user.furigana,
              email: response.body.user.email,
              userType: response.body.user.type,
              organizations: [], // response.body.user.organizations,
              memo: response.body.user.memo,
              office_code: {text: sessionStorage.getItem('office_name'), value: sessionStorage.getItem('office_code')},
            };
            this.getDataOffice(response.body.user.domain_code);
            if (!this.isEmpty(response.body.user.organizations) &&
              response.body.user.organizations.length > 0) {
              for (const org of response.body.user.organizations) {
                this.userInfo.organizations.push(org.label);
              }
            } else {
              this.userInfo.organizations = [];
            }
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .then(null, (err: any) => {
          this.errorUser_id = Config.TEXT_ERROR_USER_ID;
          this.loading = false;
        });
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
    },
    },
  },
);
