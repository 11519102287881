var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "allig-camcel-button" },
    [
      _c(
        "v-btn",
        {
          staticClass: "text-bold btn-secondary",
          attrs: {
            color: "green darken-1",
            outlined: "",
            disabled: _vm.disabled,
          },
          on: {
            click: function ($event) {
              return _vm.$emit("click")
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.text) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }