var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1000", persistent: "" },
          model: {
            value: _vm.showModalBulkUser,
            callback: function ($$v) {
              _vm.showModalBulkUser = $$v
            },
            expression: "showModalBulkUser",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "modal-add-edit-user" },
            [
              _c(
                "v-card-title",
                { staticClass: "modal-title", attrs: { color: "green" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.configData.TEXT_BULK_REG_ENTRY_APPLICANT) +
                      " "
                  ),
                ]
              ),
              _c("v-divider"),
              _c(
                "v-card",
                { staticClass: "pa-5 pt-5 ma-5" },
                [
                  _c(
                    "v-row",
                    [
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c(
                            "v-col",
                            { staticClass: "text-csv", attrs: { cols: "3" } },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.configData.TEXT_SELECT_FILES)
                                ),
                                _c("span", { staticClass: "required" }, [
                                  _vm._v(_vm._s(_vm.configData.TEXT_MUST)),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-form",
                            { ref: "userAddBulk" },
                            [
                              _c("v-text-field", {
                                staticClass: "input-add add-file-name",
                                attrs: {
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                  rules: [_vm.rulesValidate.requiredCSVFile],
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openFile()
                                  },
                                },
                                model: {
                                  value: _vm.fileName,
                                  callback: function ($$v) {
                                    _vm.fileName = $$v
                                  },
                                  expression: "fileName",
                                },
                              }),
                              _vm.fileName
                                ? _c("p", { staticClass: "txt-error" }, [
                                    _vm._v(_vm._s(_vm.checkMessage)),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "1" } }, [
                        _c(
                          "label",
                          {
                            staticClass: "btn-upload-second",
                            attrs: { for: "file_csv" },
                          },
                          [
                            _vm._v(" ... "),
                            _c("input", {
                              ref: "files",
                              staticClass: "custom-file-input",
                              staticStyle: { display: "none" },
                              attrs: {
                                type: "file",
                                id: "file_csv",
                                accept: ".csv",
                              },
                              on: {
                                change: _vm.previewFiles,
                                click: function ($event) {
                                  $event.target.value = null
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "mb-2 mt-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.closeBulkUserDialog()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.configData.TEXT_CANCEL) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.clickImportCSV()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.configData.TEXT_IMPORT) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }