
import Vue, { PropType } from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import WorkStationListDialog from '@/components/organisms/WorkStationListDialog/WorkStationListDialog.vue';
import DoorListDialog from '@/components/organisms/DoorListDialog/DoorListDialog.vue';
import ManagementStationListDialog from '@/components/organisms/ManagementStationListDialog/ManagementStationListDialog.vue';
import DatePicker from '@/components/molecules/DatePicker/DatePicker.vue';
import * as Config from '@/config';
import auth from '@/auth';
interface TypeSelect {
  text: string;
  value: string;
}

export interface DataType {
  rulesValidate: any;
  workStationDialog: boolean;
  doorDialog: boolean;
  managementStationDialog: boolean;
  configData: any;
  dataSpecialStart: any;
  dataSpecialEnd: any;
  specialStart: any;
  specialEnd: any;
  areaRows: number;
  startMaxDate: string;
  endMinDate: string;
  endTime: string;
  currentTime: string;
  comment: string;
  isValidGates: boolean;
}

export default Vue.extend({
  name: 'ApplicantAddForm',
  components: {
    InputForm,
    WorkStationListDialog,
    DoorListDialog,
    ManagementStationListDialog,
    DatePicker,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    isDataLoaded: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isAction: {
      type: Number,
      default: 0,
    },
    resDataAreaItem: {
      default: () => [],
    },
    resData: {
      default: () => [],
    },
    resDataAreas: {
      default: () => [],
    },
    resDataGates: {
      default: () => [],
    },
    resCardData: {
      default: () => [],
    },
    resDataOffice: {
      default: () => [],
    },
    dataItem: {
      default: () =>  ({
        begin: '',
        updater: sessionStorage.getItem('userId'),
        end: '',
        area_code: { value: '', text: '' },
        office_code: { value: '', text: '' },
        gates: [],
        description: '',
        timeBegin: '',
        timeEnd: '',
      }),
    },
    confirmDialog: {
      type: Boolean,
      default: false,
    },
    confirmDialogFinish: {
      type: Boolean,
      default: false,
    },
    // successMessageLogs: {
    //   type: String,
    //   default: '',
    // },
    // errorMessageLogs: {
    //   type: String,
    //   default: '',
    // },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  computed: {
    //
    isDisabledAdd: {
      get(): boolean {
        let isValid = true;
        isValid = isValid && !!this.dataItem.begin;
        isValid = isValid && !!this.dataItem.end;
        const currentDate = auth.converDateOnlyDate(new Date());
        if (this.dataItem.begin < currentDate) {
          isValid = false;
        }
        if (this.dataItem.begin === this.dataItem.end) {
          if (this.specialStart.value === '18:00' && this.specialEnd.value === '18:00') {
            isValid = false;
          }
        }
        if (!!this.dataItem.description) {
          isValid = isValid && (this.dataItem.description.length <= Config.WORK_CONTENT_MAX_LEN);
        }
        return !isValid;
      },
      set(): void {
        return;
      },
    },
    isDisabledSubEntry: {
      get(): boolean {
        let isValid = true;
        if (this.dataItem.office_code !== undefined && this.dataItem.office_code !== null &&
            this.dataItem.office_code.value !== null && this.dataItem.office_code.value !== '') {
          isValid = !!this.dataItem.office_code;
        } else {
          isValid = false;
        }
        if (!!this.dataItem.description) {
          isValid = isValid && (this.dataItem.description.length <= Config.WORK_CONTENT_MAX_LEN);
        } else {
          isValid = false;
        }
        isValid = isValid && !!this.dataItem.begin;
        isValid = isValid && !!this.dataItem.end;
        const currentDate = auth.converDateOnlyDate(new Date());
        if (this.dataItem.begin < currentDate) {
          isValid = false;
        }
        if (this.dataItem.begin === this.dataItem.end) {
          if (this.specialStart.value === '18:00' && this.specialEnd.value === '18:00') {
            isValid = false;
          }
        }
        if (this.dataItem.area_code !== undefined && this.dataItem.area_code !== null &&
            this.dataItem.area_code.value !== null && this.dataItem.area_code.value !== '') {
          isValid = isValid && !!this.dataItem.area_code;
        } else {
          isValid = false;
        }
        this.checkChangedGates();
        return !isValid;
      },
      set(): void {
        return;
      },
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    //
    'isDataLoaded'() {
      if (this.isDataLoaded === true) {
        console.log('AAA');
        this.specialStart = {
          text : '',
          value : (this.dataItem.timeBegin).toString(),
        };
        this.specialEnd = {
          text : '',
          value : (this.dataItem.timeEnd).toString(),
        };
      }
    },
    'dataItem.area_code'(val: any) {
      this.resetValidate();
    },
    'dataItem.description'(val: any) {
      this.resetValidate();
    },
    'dataItem.begin'(val: any) {
      this.resetValidate();
    },
    'dataItem.office_code'(val: any) {
      this.resetValidate();
    },
    'dataItem.end'(val) {
      this.resetValidate();
    },
    'specialEnd'(val) {
      this.resetValidate();
    },
    'specialStart'(val) {
      this.resetValidate();
    },
    'dataItem.gates'(val) {
      this.resetValidate();
    },
  },
  data: (): DataType => ({
    workStationDialog: false,
    doorDialog: false,
    managementStationDialog: false,
    specialStart: {text : '昼(6:00～)', value : '06:00'},
    specialEnd: {text : '昼(～18:00)', value : '18:00'},
    dataSpecialStart: [
      {text : '昼(6:00～)', value : '06:00'},
      {text : '夜(18:00～)', value : '18:00'},
      { text : '終日(0:00～)', value : '00:00'},
    ],
    dataSpecialEnd: [
      {text : '昼(～18:00)', value : '18:00'},
      {text : '夜(～翌6:00)', value : '06:00'},
      { text : '終日(～翌0:00)', value : '00:00'},
    ],
    configData: Config,
    rulesValidate: {
      //
      description: (val: string) => {
        if (val !== undefined && val !== null && val !== '') {
          return val.length <= Config.WORK_CONTENT_MAX_LEN || Config.ERROR_WORK_CONTENT_LENGTH;
        } else {
          return true;
        }
      },
    },
    areaRows: 4,
    startMaxDate: '',
    endMinDate: '',
    endTime: '',
    currentTime: auth.converDateShort(new Date()),
    comment: '',
    isValidGates: false,
  }),
  created() {
    this.areaRows = screen.width <= 415 ? 15 : 4;
  },
  methods: {
    //
    resetValidate() {
      this.$emit('reset-message');
    },
    isCheckCard(data: any) {
      if (data !== undefined && data !== null) {
        if (auth.getDefaultStringVal(data.masked_idi1) !== '' ||
            auth.getDefaultStringVal(data.masked_idi2) !== '' ||
            auth.getDefaultStringVal(data.masked_idi3) !== '') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    isCheckEmpty(val: any) {
      if (val !== '' && val !== undefined && val !== null) {
        return false;
      } else {
        return true;
      }
    },
    onResize() {
      this.areaRows = screen.width <= 415 ? 15 : 4;
    },
    getTimeStart(val: any) {
      this.dataItem.begin = val;
      this.endMinDate = this.dataItem.begin;
    },
    changeOffice() {
      this.$emit('change-office', this.dataItem.office_code);
      this.checkChangedGates();
    },
    changeArea() {
      this.$emit('change-area', this.dataItem.area_code);
      this.checkChangedGates();
    },
    saveEntry() {
      const result = (this.$refs.addForm as Vue & { validate: () => boolean }).validate();
      if (result) {
        let dataPost: any;
        dataPost = {};
        if (this.dataItem.office_code !== null && !this.isCheckEmpty(this.dataItem.office_code)  &&
          !this.isCheckEmpty(this.dataItem.office_code.value)) {
          dataPost.office_code = this.dataItem.office_code.value;
        }
        if (!this.isCheckEmpty(this.dataItem.description)) {
          dataPost.description = this.dataItem.description;
        }
        if (this.dataItem.begin !== '' && this.dataItem.begin !== 'Invalid date') {
          dataPost.begin = this.dataItem.begin;
        }
        if (this.dataItem.end !== '' && this.dataItem.end !== 'Invalid date') {
          if (this.specialEnd.value === '00:00' || this.specialEnd.value === '06:00') {
            const dateEnd = new Date(this.dataItem.end);
            dateEnd.setUTCDate(dateEnd.getUTCDate() + 1);
            dataPost.end = auth.converDateOnlyDate(dateEnd);
          } else {
            dataPost.end = this.dataItem.end;
          }
        }
        if (this.dataItem.area_code !== null && !this.isCheckEmpty(this.dataItem.area_code)
          && !this.isCheckEmpty(this.dataItem.area_code.value)) {
          dataPost.area_code = this.dataItem.area_code.value;
        }
        if (this.dataItem.gates !== null && this.dataItem.gates !== undefined && this.dataItem.gates.length > 0) {
          dataPost.gates = [];
          this.dataItem.gates.forEach(async (element: any, key: any) => dataPost.gates.push({ code: element.value }));
        }
        this.$emit('saveEntry',
          {data: dataPost, specialStart: this.specialStart.value, specialEnd: this.specialEnd.value},
        );
      }
    },
    updateEntry() {
      let dateEndStr = this.dataItem.end;
      if (this.specialEnd.value === '00:00' || this.specialEnd.value === '06:00') {
        const dateEnd = new Date(this.dataItem.end);
        dateEnd.setUTCDate(dateEnd.getUTCDate() + 1);
        dateEndStr = auth.converDateOnlyDate(dateEnd);
      }
      let dataPost: any;
      dataPost = {};
      if (this.dataItem.office_code !== null && !this.isCheckEmpty(this.dataItem.office_code)  &&
      !this.isCheckEmpty(this.dataItem.office_code.value)) {
        dataPost.office_code = this.dataItem.office_code.value;
      }
      if (!this.isCheckEmpty(this.dataItem.description)) {
          dataPost.description = this.dataItem.description;
        }
      if (this.dataItem.begin !== '' && this.dataItem.begin !== 'Invalid date') {
        dataPost.begin = this.dataItem.begin;
      }
      if (this.dataItem.end !== '' && this.dataItem.end !== 'Invalid date') {
        if (this.specialEnd.value === '00:00' || this.specialEnd.value === '06:00') {
          const dateEnd = new Date(this.dataItem.end);
          dateEnd.setUTCDate(dateEnd.getUTCDate() + 1);
          dataPost.end = auth.converDateOnlyDate(dateEnd);
        } else {
          dataPost.end = this.dataItem.end;
        }
      }
      if (this.dataItem.area_code !== null && !this.isCheckEmpty(this.dataItem.area_code)
      && !this.isCheckEmpty(this.dataItem.area_code.value)) {
          dataPost.area_code = this.dataItem.area_code.value;
        }
      if (this.dataItem.gates !== null && this.dataItem.gates !== undefined && this.dataItem.gates.length > 0) {
        dataPost.gates = [];
        this.dataItem.gates.forEach(async (element: any, key: any) => dataPost.gates.push({ code: element.value }));
      }
      this.$emit('updateEntry',
        {data: dataPost, specialStart: this.specialStart.value, specialEnd: this.specialEnd.value},
      );
    },
    subEntry() {
      const dataPost = {
        begin: '',
        // updater: sessionStorage.getItem('userId'),
        end: '',
        area_code: '',
        office_code: '',
        gates: [{}],
        description: '',
      };
      if (this.specialEnd.value === '00:00' || this.specialEnd.value === '06:00') {
        const dateEnd = new Date(this.dataItem.end);
        dateEnd.setUTCDate(dateEnd.getUTCDate() + 1);
        dataPost.end = auth.converDateOnlyDate(dateEnd);
      } else {
        dataPost.end = this.dataItem.end;
      }
      dataPost.office_code = this.dataItem.office_code.value;
      dataPost.area_code = this.dataItem.area_code.value;
      dataPost.begin = this.dataItem.begin;
      dataPost.description = this.dataItem.description;
      if (this.dataItem.gates !== null && this.dataItem.gates !== undefined && this.dataItem.gates.length > 0) {
        dataPost.gates = [];
        this.dataItem.gates.forEach(async (element: any, key: any) => dataPost.gates.push({ code: element.value }));
      }
      this.$emit('subEntry',
        {data: dataPost, specialStart: this.specialStart.value, specialEnd: this.specialEnd.value},
      );
    },
    reSubEntry() {
      let dateEndStr = this.dataItem.end;
      if (this.specialEnd.value === '00:00' || this.specialEnd.value === '06:00') {
        const dateEnd = new Date(this.dataItem.end);
        dateEnd.setUTCDate(dateEnd.getUTCDate() + 1);
        dateEndStr = auth.converDateOnlyDate(dateEnd);
      }
      let dataPost: any;
      dataPost = {};
      if (this.dataItem.office_code !== null && !this.isCheckEmpty(this.dataItem.office_code)  &&
      !this.isCheckEmpty(this.dataItem.office_code.value)) {
        dataPost.office_code = this.dataItem.office_code.value;
      }
      if (!this.isCheckEmpty(this.dataItem.description)) {
          dataPost.description = this.dataItem.description;
        }
      if (this.dataItem.begin !== '' && this.dataItem.begin !== 'Invalid date') {
        dataPost.begin = this.dataItem.begin;
      }
      if (this.dataItem.end !== '' && this.dataItem.end !== 'Invalid date') {
        if (this.specialEnd.value === '00:00' || this.specialEnd.value === '06:00') {
          const dateEnd = new Date(this.dataItem.end);
          dateEnd.setUTCDate(dateEnd.getUTCDate() + 1);
          dataPost.end = auth.converDateOnlyDate(dateEnd);
        } else {
          dataPost.end = this.dataItem.end;
        }
      }
      if (this.dataItem.area_code !== null && !this.isCheckEmpty(this.dataItem.area_code)
      && !this.isCheckEmpty(this.dataItem.area_code.value)) {
          dataPost.area_code = this.dataItem.area_code.value;
        }
      if (this.dataItem.gates !== null && this.dataItem.gates !== undefined && this.dataItem.gates.length > 0) {
        dataPost.gates = [];
        this.dataItem.gates.forEach(async (element: any, key: any) => dataPost.gates.push({ code: element.value }));
      }
      this.$emit('reSubEntry', {data: dataPost, description: this.dataItem.description,
      specialStart: this.specialStart.value, specialEnd: this.specialEnd.value});
    },
    getTimeEnd(val: any) {
      this.dataItem.end = val;
      this.startMaxDate = this.dataItem.end;
    },
    setGate(item: any) {
      this.doorDialog = false;
      this.dataItem.gates = [];
      if (item.length > 0) {
        this.dataItem.gates = item;
      }
    },
    setOffice(item: any) {
      this.managementStationDialog = false;
      this.dataItem.office_code = { value: '', text: '' };
      if (item.length > 0) {
        this.dataItem.office_code = item[0].code;
      }
    },
    setArea(item: any) {
      this.workStationDialog = false;
      this.dataItem.area_code = { value: '', text: '' };
      if (item.length > 0) {
        this.dataItem.area_code = item[0].area_code;
      }
    },
    closeDialog(val: any) {
      switch (val) {
        case 'workStationDialog': this.workStationDialog = false; break;
        case 'managementStationDialog': this.managementStationDialog = false; break;
        case 'doorDialog': this.doorDialog = false; break;
      }
    },
    clickShowDialog(val: any) {
      switch (val) {
        case 'workStationDialog': this.workStationDialog = true; break;
        case 'managementStationDialog': this.managementStationDialog = true; break;
        case 'doorDialog': this.doorDialog = true; break;
      }
      this.$emit('showDialog', {
        dialog: val,
        office_code: this.dataItem.office_code,
        area_code: this.dataItem.area_code,
      });
    },
    sendSubmissions() {
      if (this.isAction === 0) {
        this.$emit('sendSubmissions', this.comment);
      } else {
        this.$emit('sendSubmissionsEdit', this.comment);
      }
      this.comment = '';
    },
    closeConfirmDialog() {
      this.$emit('closeConfirmDialog');
      this.comment = '';
    },
    checkChangedGates() {
      if (this.dataItem.gates !== undefined && this.dataItem.gates !== null && this.dataItem.gates.length > 0) {
        this.isValidGates = true;
      } else {
        this.isValidGates = false;
      }
    },
    isObject(val: any) {
      return val instanceof Object;
    },
    convertContent(val: any) {
      return auth.convertContent(val);
    },
    converDateShort(val: any) {
      return auth.converDateShort(val);
    },
    convertStatus(val: any) {
      return auth.convertStatus(val);
    },
    goBack() {
      this.$router.push({ path: Config.APPLICANT});
    },
  },
});
