var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _c("v-container", [
        _c(
          "div",
          [
            _c("approver", {
              attrs: {
                loading: _vm.loading,
                resData: _vm.resData,
                resHisData: _vm.resHisData,
                resCardData: _vm.resCardData,
                resDataGates: _vm.resDataGates,
                request_id: _vm.request_id,
                isAction: _vm.isAction,
                closeDialogExtention: _vm.closeDialogExtention,
                resExtData: _vm.resExtData,
              },
              on: {
                "change-column": function ($event) {
                  return _vm.$emit("change-column", $event)
                },
                "action-approval": function ($event) {
                  return _vm.$emit("action-approval", $event)
                },
                "open-extensions": function ($event) {
                  return _vm.$emit("open-extensions", $event)
                },
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }