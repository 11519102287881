
import Vue, { PropType } from 'vue';
import Searchbox from '@/components/molecules/Searchbox/Searchbox.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';
import auth from '@/auth';

export interface TypeItem {
  title: string;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  showOption: boolean;
  isVaildOnly: boolean;
  Loading: boolean;
  applicantSelected: [];
  configData: any ;
  loadPage: any ;
}

export default Vue.extend({
  name: 'applicantListTable',
  components: {
    Searchbox,
    ShowPerPage,
    Pagination,
    SelectPerPage,
    DisplayOption,
    TextLink,
  },
  watch: {
    selectedDisplayOption(value) {
      sessionStorage.setItem('displayOptionApplicant', JSON.stringify(value));
    },
  },
  props: {
    resData: {
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    loadMore: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    isFinishProcessEnable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadPage = this.page;
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: Config.TEXT_COL_APPLICATION_NUMBER,
            align: 'start',
            sortable: true,
            value: 'request_id',
            width: '250',
          },
        ];
        if (this.selectedDisplayOption.includes('status')) {
          headers.push({
            text: Config.TEXT_COL_STATUS,
            align: 'start',
            sortable: true,
            value: 'status',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('begin')) {
          headers.push({
            text: Config.TEXT_COL_BEGIN_DATE,
            align: 'start',
            sortable: true,
            value: 'begin',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('end')) {
          headers.push({
            text: Config.TEXT_COL_END_DATE,
            align: 'start',
            sortable: true,
            value: 'end',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('office_name')) {
          headers.push({
            text: Config.TEXT_COL_MANAGEMENT_OFFICE,
            align: 'start',
            sortable: true,
            value: 'office_name',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('area_name')) {
          headers.push({
            text: Config.TEXT_COL_AREA,
            align: 'start',
            sortable: true,
            value: 'area_name',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('updater')) {
          headers.push({
            text:  Config.TEXT_USER_MANAGEMENT,
            align: 'start',
            sortable: true,
            value: 'requester_name',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('updated')) {
          headers.push({
            text:  Config.TEXT_UPDATE_DATE,
            align: 'start',
            sortable: true,
            value: 'updated',
            width: 'auto',
          });
        }
        return headers;
      },
      set(): void {
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', val);
        return;
      },
    },
  },
  data: (): DataType => ({
    configData: Config,
    selectedDisplayOption: [
      'request_id',
      'status',
      'begin',
      'end',
      'office_name',
      'area_name',
      'updater',
      'updated',
    ],
    displayOptionItems: [
      { label: Config.TEXT_COL_APPLICATION_NUMBER, value: 'request_id', isDisabled: true},
      { label: Config.TEXT_COL_STATUS, value: 'status' },
      { label: Config.TEXT_COL_BEGIN_DATE, value: 'begin' },
      { label: Config.TEXT_COL_END_DATE, value: 'end' },
      { label: Config.TEXT_COL_MANAGEMENT_OFFICE, value: 'office_name' },
      { label: Config.TEXT_COL_AREA, value: 'area_name' },
      { label: Config.TEXT_USER_MANAGEMENT, value: 'updater' },
      { label: Config.TEXT_UPDATE_DATE, value: 'updated' },
    ],
    applicantSelected: [],
    showOption: false,
    isVaildOnly: false,
    Loading: false,
    loadPage: 1,
  }),
  methods: {
    countPage() {
      this.innerPage =  this.innerPage + 1;
    },
    row_classes(item: { status: any}) {
      if (item.status === 'returned') {
        return 'bg1';
      } else if (item.status === 'canceled' || item.status === 'rescinded') {
        return 'bg2';
      }
    },
    clickDetail(val: any) {
      this.$router.push({ path: Config.APPLICANT + '/view', query: {request_id: val}});
    },
    converDateShort(val: any) {
      return auth.converDateShort(val);
    },
    convertStatus(val: any) {
      return auth.convertStatus(val);
    },
    CheckColumn(data: any) {
      this.$emit('change-column');
      sessionStorage.setItem('displayOptionApplicant', JSON.stringify(data));
      // this.Loading = false;
      // this.Loading = false;
    },
    currentItems(val: any) {
      this.$emit('get-current-items', val);
    },
  },
});
