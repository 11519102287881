
import Vue, { PropType } from 'vue';
import Searchbox,  {SearchTargetItem} from '@/components/molecules/Searchbox/Searchbox.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import { DataTableHeader } from 'vuetify';
import auth from '@/auth';
import * as Config from '@/config';
export interface TypeItem {
  title: string;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  showOption: boolean;
  isVaildOnly: boolean;
  typeSearch: any;
  applicantSelected: [];
  configData: any;
}

export default Vue.extend({
  name: 'EquipmentStatusList',
  components: {
    Searchbox,
    ShowPerPage,
    Pagination,
    SelectPerPage,
    DisplayOption,
  },
  watch: {
    selectedDisplayOption(value) {
      sessionStorage.setItem('displayOptionEquip', JSON.stringify(value));
    },
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    resData: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    isFinishProcessEnable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    //
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: this.configData.TEXT_GATE_CODE,
            align: 'start',
            sortable: true,
            value: 'code',
            width: 'auto',
          },
        ];
        if (this.selectedDisplayOption.includes('gate_name')) {
          headers.push({
            text: this.configData.TEXT_GATE_NAME,
            align: 'start',
            sortable: true,
            value: 'name',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('area_name')) {
          headers.push({
            text: this.configData.TEXT_AREA,
            align: 'start',
            sortable: true,
            value: 'area_name',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('gate_voltage')) {
          headers.push({
            text: this.configData.TEXT_BATTERY_LEVEL,
            align: 'start',
            sortable: true,
            value: 'voltage',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('scan_date')) {
          headers.push({
            text: this.configData.TEXT_GET_DATE,
            align: 'start',
            sortable: true,
            value: 'scanDate',
            width: 'auto',
          });
        }
        return headers;
      },
      set(): void {
        return;
      },
    },
  },
  data: (): DataType => ({
    selectedDisplayOption: [
      'gate_code',
      'gate_name',
      'area_name',
      'gate_voltage',
      'scan_date',
    ],
    displayOptionItems: [
      { label:  Config.TEXT_GATE_CODE, value: 'gate_code', isDisabled: true },
      { label:  Config.TEXT_GATE_NAME, value: 'gate_name' },
      { label:  Config.TEXT_AREA, value: 'area_name' },
      { label:  Config.TEXT_BATTERY_LEVEL, value: 'gate_voltage' },
      { label:  Config.TEXT_GET_DATE, value: 'scan_date' },
    ],
    applicantSelected: [],
    showOption: false,
    isVaildOnly: false,
    typeSearch: 0,
    configData: Config,
  }),
  methods: {
    currentItems(val: any) {
      this.$emit('get-current-items', val);
    },
    convertVoltage(val: any, type: any, isMinDate: any) {
      return auth.convertVoltage(val, type, isMinDate);
    },
    CheckColumn(data: any) {
      this.$emit('change-column');
      sessionStorage.setItem('displayOptionEquip', JSON.stringify(data));
    },
  },
});
