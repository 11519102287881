var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("card-template", {
    attrs: {
      loading: _vm.loading,
      errorMessageLogs: _vm.errorMessageLogs,
      resData: _vm.resData,
      actionItems: _vm.actionItems,
      isAction: _vm.isAction,
      userInfo: _vm.userInfo,
      upToDate: _vm.upToDate,
      maskedIdi01: _vm.maskedIdi01,
      maskedIdi02: _vm.maskedIdi02,
      maskedIdi03: _vm.maskedIdi03,
      showMessageDialog: _vm.showMessageDialog,
      messageDialogTitle: _vm.messageDialogTitle,
      messageDialogText: _vm.messageDialogText,
      maxNumOfCards: _vm.maxNumOfCards,
      confirmDialog: _vm.confirmDialog,
      confirmPwdDialog: _vm.confirmPwdDialog,
    },
    on: {
      updateUserInfo: _vm.updateUserInfo,
      changePassword: _vm.changePassword,
      "register-card": _vm.startCardRegistration,
      "deregister-card": _vm.deregisterCard,
      "close-message-dialog": _vm.closeMessageDialog,
      showConfirmDialog: function ($event) {
        return _vm.showConfirmDialog()
      },
      closeConfirmDialog: function ($event) {
        return _vm.closeConfirmDialog()
      },
      showConfirmPwdDialog: function ($event) {
        return _vm.showConfirmPwdDialog()
      },
      closeConfirmPwdDialog: function ($event) {
        return _vm.closeConfirmPwdDialog()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }