
import Vue, { PropType } from 'vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';

import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';
import auth from '@/auth';

export interface TypeItem {
  title: string;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  showOption: boolean;
  isVaildOnly: boolean;
  applicantSelected: [];
  configData: any;
}

export default Vue.extend({
  name: 'LogList',
  components: {
    DisplayOption,
    ShowPerPage,
  },
  watch: {
    selectedDisplayOption(value) {
      sessionStorage.setItem('displayOptionLog', JSON.stringify(value));
    },
  },
  props: {
    resData: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    logTotalCount: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    isFinishProcessEnable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    //
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: Config.TEXT_DATE_AND_TIME,
            value: 'timestamp',
            width: 'auto',
          },
        ];
        if (this.selectedDisplayOption.includes('type')) {
          headers.push({
            text: Config.TEXT_COL_OPERATION_DISTINCTION,
            value: 'type',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('user_id')) {
          headers.push({
            text: Config.TEXT_USER_ID,
            value: 'user_id',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('user_name')) {
          headers.push({
            text: Config.TEXT_USER_NAME,
            value: 'user_name',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('organizations')) {
          headers.push({
            text: Config.TEXT_COL_COMPANY_DEPARTMENT,
            value: 'organizations',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('message')) {
          headers.push({
            text: Config.TEXT_OPERATION_CONTENT,
            value: 'message',
            width: 'auto',
          });
        }
        return headers;
      },
      set(): void {
        return;
      },
    },
  },
  data: (): DataType => ({
    selectedDisplayOption: [
      'timestamp',
      'type',
      'user_id',
      'user_name',
      'organizations',
      'message',
    ],
    displayOptionItems: [
      { label: Config.TEXT_DATE_AND_TIME, value: 'timestamp', isDisabled: true },
      { label: Config.TEXT_COL_OPERATION_DISTINCTION, value: 'type' },
      { label: Config.TEXT_USER_ID, value: 'user_id' },
      { label: Config.TEXT_USER_NAME, value: 'user_name' },
      { label: Config.TEXT_COL_COMPANY_DEPARTMENT, value: 'organizations' },
      { label: Config.TEXT_OPERATION_CONTENT, value: 'message' },
    ],
    applicantSelected: [],
    showOption: false,
    isVaildOnly: false,
    configData: Config,
  }),
  methods: {
    currentItems(val: any) {
      this.$emit('get-current-items', val);
    },
    convertUserType(val: any) {
      return auth.convertUserType(val);
    },
    converDateShort(t: any) {
      return auth.converDateShort(t);
    },
    CheckColumn(data: any) {
      this.$emit('change-column');
      sessionStorage.setItem('displayOptionLog', JSON.stringify(data));
    },
  },
});
