var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "suika-approval-status-template" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _c("v-container", [
        _c("div", { staticClass: "container-user" }, [
          _vm.isAction === 0
            ? _c(
                "div",
                { staticClass: "round-action" },
                [
                  _c(
                    "v-row",
                    { staticClass: "btn-div", attrs: { justify: "start" } },
                    [
                      _c("v-col", [
                        _c(
                          "div",
                          { staticClass: "area-search-box" },
                          [
                            _c(
                              "v-row",
                              {
                                attrs: {
                                  "no-gutters": "",
                                  justify: "space-between",
                                },
                              },
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("searchbox", {
                                      staticClass: "input-search",
                                      attrs: {
                                        dataArea: _vm.dataArea,
                                        dataOffice: _vm.dataOffice,
                                        searchTargetItems:
                                          _vm.searchTargetItems,
                                        isCSV: true,
                                        approvalStatusPage: true,
                                      },
                                      on: {
                                        search: function ($event) {
                                          return _vm.$emit("search", $event)
                                        },
                                        export: function ($event) {
                                          return _vm.$emit("export", $event)
                                        },
                                        add: function ($event) {
                                          return _vm.$emit("addUser", $event)
                                        },
                                        "change-office": function ($event) {
                                          return _vm.changeOffice($event)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isAction === 0
            ? _c(
                "div",
                [
                  _vm.loadingSearch === false
                    ? _c("approval-status-list", {
                        attrs: {
                          loading: _vm.loading,
                          loadMore: _vm.loadMore,
                          openExtensions: _vm.openExtensions,
                          resData: _vm.resData,
                          approvalStatusTotalCount:
                            _vm.approvalStatusTotalCount,
                          perPage: _vm.perPage,
                          page: _vm.page,
                          total: _vm.total,
                          resExtData: _vm.resExtData,
                          confirmDialogFinish: _vm.confirmDialogFinish,
                        },
                        on: {
                          "change-column": function ($event) {
                            return _vm.$emit("change-column", $event)
                          },
                          "action-approval": function ($event) {
                            return _vm.$emit("action-approval", $event)
                          },
                          "change-per-page": function ($event) {
                            return _vm.$emit("change-per-page", $event)
                          },
                          "change-page": function ($event) {
                            return _vm.$emit("change-page", $event)
                          },
                          "open-extensions": function ($event) {
                            return _vm.$emit("open-extensions", $event)
                          },
                          "close-dialog": function ($event) {
                            return _vm.$emit("close-dialog", $event)
                          },
                          "get-current-items": function ($event) {
                            return _vm.$emit("get-current-items", $event)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm.isAction === 1 || _vm.isAction === 2
            ? _c(
                "div",
                [
                  _c("approval-status-form", {
                    attrs: {
                      loading: _vm.loading,
                      resData: _vm.resData,
                      isAction: _vm.isAction,
                      isShow: true,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }