
import Vue, { PropType } from 'vue';
import Searchbox,  {SearchTargetItem} from '@/components/molecules/Searchbox/Searchbox.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import { DataTableHeader } from 'vuetify';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import * as Config from '@/config';
import auth from '@/auth';
interface User {
  user_id: string;
  name: string;
  furigana: string;
  email: string;
  organizations: any;
  office_code: any;
  memo: string;
}
export interface TypeItem {
  title: string;
  userId: string;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  searchTargetItems: SearchTargetItem[];
  showOption: boolean;
  isVaildOnly: boolean;
  typeSearch: any;
  selected: [];
  rulesValidate: any;
  isEdit: boolean;
  isCheckUser: boolean;
  configData: any;
  isApprover: boolean;
  error: string;
}

export default Vue.extend({
  name: 'ApproverSettingsList',
  components: {
    Searchbox,
    ShowPerPage,
    Pagination,
    SelectPerPage,
    DisplayOption,
    InfoItem,
    InputForm,
    TextLink,
  },
  watch: {
    selectedDisplayOption(value) {
      sessionStorage.setItem('displayApproveSetting', JSON.stringify(value));
    },
    selected(val) {
      this.$emit('selected', val.map((item: { user_id: any; office_code: any }) =>
        ({
          user_id: item.user_id,
          office_code: item.office_code,
        }),
      ));
    },
    isResetData(value) {
      if (value) {
        if (this.$refs.ApproverForm) {
          (this.$refs.ApproverForm as Vue & { resetValidation: () => boolean }).resetValidation();
        }
      }
    },
    errorUser_id(value) {
      if (value) {
        this.error = value;
        (this.$refs.ApproverForm as Vue & { validate: () => boolean }).validate();
      }
    },
  },
  props: {
    resData: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    approverTotalCount: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isResetData: {
      default: false,
    },
    isDeleteFinish: {
      type: Boolean,
      default: false,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorUser_id: {
      type: String,
      default: '',
    },
    errorMessageProcess: {
      default: () => [],
    },
    isFinishProcessEnable: {
      type: Boolean,
      default: false,
    },
    organizations: {
      default: () => [],
    },
    dataOffice: {
      default: () => [],
    },
    userId: {
      type: String,
      default: null,
    },
    office_name: {
      type: String,
      default: null,
    },
    userInfo: {
      type: Object as PropType<User>,
      default: () => ({
        user_id: '',
        name: '',
        furigana: '',
        email: '',
        organizations: [],
        office_code: { text: '', value: '' },
        memo: '',
      }),
    },
    isShowAddDialog: {
      type: Boolean,
      default: false,
    },
    deleteApproverSetting: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    //
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: Config.TEXT_USER_ID,
            align: 'start',
            sortable: true,
            value: 'user_id',
            width: 'auto',
          },
        ];
        if (this.selectedDisplayOption.includes('user_name')) {
          headers.push({
            text: Config.TEXT_USER_NAME,
            align: 'start',
            sortable: true,
            value: 'user_name',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('office_name')) {
          headers.push({
            text: Config.TEXT_COL_MANAGEMENT_OFFICE,
            align: 'start',
            sortable: true,
            value: 'office_name',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('email')) {
          headers.push({
            text: Config.TEXT_COL_EMAIL_ADDRESS,
            align: 'start',
            sortable: true,
            value: 'email',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('memo')) {
          headers.push({
            text: Config.TEXT_REMARKS,
            align: 'start',
            sortable: true,
            value: 'memo',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('updated')) {
          headers.push({
            text: Config.TEXT_UPDATE_DATE,
            align: 'start',
            sortable: true,
            value: 'updated',
            width: 'auto',
          });
        }
        console.log(headers);
        return headers;
      },
      set(): void {
        return;
      },
    },
    isDisabled: {
      get(): boolean {
        let isValid = true;
        isValid = !!this.userInfo.user_id;
        if (isValid) {
          const pattern = /^[a-z\d\-_\s]{4,80}$/i;
          isValid = isValid && pattern.test(this.userInfo.user_id);
        }
        isValid = isValid && !!this.userInfo.name;
        isValid = isValid && (this.userInfo.name.length <= Config.USER_NAME_MAX_LEN);
        isValid = isValid && (this.userInfo.furigana.length <= Config.USER_FURIGANA_MAX_LEN);
        if (this.userInfo.email !== undefined && this.userInfo.email !== '' && this.userInfo.email !== null) {
          const emailPattern = this.userInfo.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/);
          if (emailPattern) {
            isValid = isValid && this.userInfo.email.length <= Config.EMAIL_MAX_LEN;
          } else {
            isValid = false;
          }
        }
        isValid = isValid && (this.userInfo.memo.length <= Config.MEMO_MAX_LEN);
        // check validate office_code
        if (isValid) {
          if (this.isApprover === true ||
             (auth.checkComboboxValue(this.userInfo.office_code))) {
            isValid = true;
          } else {
            isValid = false;
          }
        }
        return !isValid;
      },
      set(): void {
        return;
      },
    },
  },
  data: (): DataType => ({
    isEdit: false,
    error: '',
    rulesValidate: {
      requiredUserId: (userId: string) => !!userId || Config.ERROR_USER_ID_REQUIRED,
      validateUserId: (userId: string, isCheckUser: any, errorUser_id: any) => {
        if (isCheckUser === true && errorUser_id !== '') {
          return false;
        } else {
          const pattern = /^[a-z\d\-_\s]{4,80}$/i;
          return pattern.test(userId) || Config.ERROR_USER_ID_LENGTH;
        }
      },
      requiredName: (name: string) => !!name || Config.ERROR_USER_NAME_REQUIRED,
      validateName: (name: string) => {
        if (name !== undefined && name !== null && name !== '') {
          return name.length <= Config.USER_NAME_MAX_LEN || Config.ERROR_USER_NAME_LENGTH;
        } else {
          return true;
        }
      },
      validateFurigana: (furigana: string) => {
        if (furigana !== undefined && furigana !== null && furigana !== '') {
          return furigana.length <= Config.USER_FURIGANA_MAX_LEN || Config.ERROR_FURIGANA_LENGTH;
        } else {
          return true;
        }
      },
      validateEmail: (email: string) => {
        if (email !== undefined && email !== null && email !== '') {
          const pattern = email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/);
          if (pattern) {
            return email.length <= Config.EMAIL_MAX_LEN || Config.ERROR_EMAIL_LENGTH;
          } else {
            return Config.ERROR_EMAIL_VALIDATE;
          }
        } else {
          return true;
        }
      },
      // validateOrganizations: (organizations: string) => {
      //     if (organizations !== undefined && organizations !== null && organizations !== '') {
      //       return organizations.length <= 80 || Config.ERROR_ORGANIZATIONS_LENGTH;
      //     } else {
      //       return true;
      //   }
      // },
      validateRemarks: (remarks: string) => {
        if (remarks !== undefined && remarks !== null && remarks !== '') {
          return remarks.length <= Config.MEMO_MAX_LEN || Config.ERROR_REMARKS_LENGTH;
        } else {
          return true;
        }
      },
      validateOfficeCode: (check: boolean, item: any, isCheckUser: any) => {
        if (isCheckUser) {
          return true;
        } else {
          if (check === true || (auth.checkComboboxValue(item))) {
            return true;
          } else {
            return Config.ERROR_OFFICE_REQUIRED;
          }
        }
      },
      // validatePassword: (pass: string) => {
      //   const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$/;
      //   return pattern.test(pass) || 'パスワードは英大文字、英小文字、数字を組み合わせた8文字以上64文字以内で設定してください。';
      // },
    },
    selectedDisplayOption: [
      'user_id',
      'user_name',
      'office_name',
      'email',
      'memo',
      'updated',
    ],
    displayOptionItems: [
      { label: Config.TEXT_USER_ID, value: 'user_id', isDisabled: true },
      { label: Config.TEXT_USER_NAME, value: 'user_name' },
      { label: Config.TEXT_COL_MANAGEMENT_OFFICE, value: 'office_name' },
      { label: Config.TEXT_COL_EMAIL_ADDRESS, value: 'email' },
      { label: Config.TEXT_REMARKS, value: 'memo' },
      { label: Config.TEXT_UPDATE_DATE, value: 'updated' },
    ],
    searchTargetItems: [],
    selected: [],
    showOption: false,
    isVaildOnly: false,
    typeSearch: 0,
    configData: Config,
    isApprover: sessionStorage.getItem('priviledge')  === '2' ? true : false,
    isCheckUser: false,
  }),
  methods: {
    row_classes(item: { user_name: any}) {
      if (item.user_name === null) {
        return 'row-bg-gray';
      }
    },
    currentItems(val: any) {
      this.$emit('get-current-items', val);
    },
    closeAddDialog() {
      // (this.$refs.ApproverForm as Vue & { reset: () => boolean }).reset();
      this.$emit('closeAddDialog');
    },
    closeDeleteDialog() {
      this.$emit('closeDeleteDialog');
    },
    deleteApprover() {
      this.$emit('deleteApprover');
    },
    converDateShort(val: any) {
      return auth.converDateShort(val);
    },
    CheckColumn(data: any) {
      this.$emit('change-column');
      sessionStorage.setItem('displayApproveSetting', JSON.stringify(data));
    },
    checkUserId() {
      this.isCheckUser = true;
      this.error = '';
      setTimeout(() => {
        const result = (this.$refs.ApproverForm as Vue & { validate: () => boolean }).validate();
        if (result) {
          this.$emit('enter-userId', this.userInfo.user_id);
        }
      }, 100);
    },
    clickAddApprover() {
      const result = (this.$refs.ApproverForm as Vue & { validate: () => boolean }).validate();
      if (result) {
        this.$emit('add-approver');
      }
    },
  },
});
