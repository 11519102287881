
type InputType = 'text' | 'switch' | 'select' | 'multi-select' | 'another' | 'clearable';
type TextType = 'text' | 'password';
interface SelectItem { text: string; value: any; }

import Vue, { PropType } from 'vue';
import HelpIcon from '@/components/molecules/HelpIcon/HelpIcon.vue';

export default Vue.extend({
  name: 'FormInput',
  components: {
    HelpIcon,
  },
  props: {
    value: {
      type: [String, Boolean, Array, Number] as PropType<string | boolean | string[] | number>,
    },
    title: {
      type: String,
      default: '',
    },
    inputType: {
      type: String as PropType<InputType>,
      default: 'text',
    },
    textType: {
      type: String as PropType<TextType>,
      default: 'text',
    },
    selectItems: {
      type: Array as PropType<SelectItem[]>,
      default: () => [],
    },
    areaRows: {
      type: Number,
      default: 3,
    },
    helpText: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    headerCols: {
      type: Number,
      default: 2,
    },
  },
  data: () => ({
    showIconEye: false,
  }),
  computed: {
    innerValue: {
      get(): string | boolean | string[] | number {
        return this.value;
      },
      set(val: string | boolean | string[] | number): void {
        this.$emit('input', val);
        return;
      },
    },
  },
});
