var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _c("v-container", [
        _c(
          "div",
          { staticClass: "round-tab" },
          [
            [
              _c(
                "v-tabs",
                {
                  attrs: { transition: false },
                  on: {
                    change: function ($event) {
                      return _vm.$emit("changeTab", $event)
                    },
                  },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                _vm._l(_vm.actionItems, function (item) {
                  return _c("v-tab", { key: item.tabname }, [
                    _vm._v(" " + _vm._s(item.title) + " "),
                  ])
                }),
                1
              ),
            ],
            _c(
              "v-tabs-items",
              {
                attrs: { touchless: "" },
                model: {
                  value: _vm.tab,
                  callback: function ($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab",
                },
              },
              _vm._l(_vm.actionItems, function (item) {
                return _c(
                  "v-tab-item",
                  {
                    key: item.tabname,
                    attrs: { transition: false, "reverse-transition": false },
                  },
                  [
                    item.tabname === "tab1"
                      ? _c(
                          "v-card",
                          [
                            _vm.isAction === 0
                              ? _c("card-registration", {
                                  attrs: {
                                    loading: _vm.loading,
                                    resData: _vm.resData,
                                    errorMessage: _vm.errorMessageLogs,
                                    upToDate: _vm.upToDate,
                                    messageDialogTitle: _vm.messageDialogTitle,
                                    messageDialogText: _vm.messageDialogText,
                                    showMessageDialog: _vm.showMessageDialog,
                                    maskedIdi01: _vm.maskedIdi01,
                                    maskedIdi02: _vm.maskedIdi02,
                                    maskedIdi03: _vm.maskedIdi03,
                                    maxNumOfCards: _vm.maxNumOfCards,
                                  },
                                  on: {
                                    "register-card": _vm.registerCard,
                                    "deregister-card": _vm.deregisterCard,
                                    "close-message-dialog": function ($event) {
                                      return _vm.$emit("close-message-dialog")
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.tabname === "tab2"
                      ? _c(
                          "v-card",
                          [
                            _vm.isAction === 0
                              ? _c("change-password", {
                                  attrs: {
                                    loading: _vm.loading,
                                    resData: _vm.resData,
                                    confirmPwdDialog: _vm.confirmPwdDialog,
                                    showPwdMsgDialog: _vm.showPwdMsgDialog,
                                  },
                                  on: {
                                    changePassword: function ($event) {
                                      return _vm.$emit("changePassword", $event)
                                    },
                                    showConfirmPwdDialog: function ($event) {
                                      return _vm.$emit("showConfirmPwdDialog")
                                    },
                                    closeConfirmPwdDialog: function ($event) {
                                      return _vm.$emit("closeConfirmPwdDialog")
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.tabname === "tab3"
                      ? _c(
                          "v-card",
                          [
                            _vm.isAction === 0
                              ? _c("user-information", {
                                  attrs: {
                                    loading: _vm.loading,
                                    resData: _vm.resData,
                                    userInfo: _vm.userInfo,
                                    confirmDialog: _vm.confirmDialog,
                                  },
                                  on: {
                                    updateUserInfo: function ($event) {
                                      return _vm.$emit("updateUserInfo", $event)
                                    },
                                    showConfirmDialog: function ($event) {
                                      return _vm.$emit("showConfirmDialog")
                                    },
                                    closeConfirmDialog: function ($event) {
                                      return _vm.$emit("closeConfirmDialog")
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }