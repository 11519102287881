var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _c("v-container", [
        _c(
          "div",
          { staticClass: "container-user" },
          [
            _c(
              "div",
              { staticClass: "round-action" },
              [
                _c(
                  "v-row",
                  { staticClass: "btn-div", attrs: { justify: "start" } },
                  [
                    _c("v-col", [
                      _c(
                        "div",
                        { staticClass: "area-search-box" },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "space-between",
                              },
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("searchbox", {
                                    staticClass: "input-search",
                                    attrs: {
                                      searchTargetItems: _vm.searchTargetItems,
                                      organizations: _vm.organizations,
                                      approvalSettingsPage: true,
                                      isRegister: true,
                                      isRelease: true,
                                      isCSV: true,
                                      selected: _vm.selectedApprovers,
                                      dataOffice: _vm.dataOffice,
                                    },
                                    on: {
                                      search: function ($event) {
                                        return _vm.$emit("search-user", $event)
                                      },
                                      add: function ($event) {
                                        return _vm.$emit("showAddDialog")
                                      },
                                      export: function ($event) {
                                        return _vm.$emit("export")
                                      },
                                      delete: function ($event) {
                                        return _vm.$emit(
                                          "showDeleteApproverSettingDialog"
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.loadingSearch === false
              ? _c("approver-settings-list", {
                  attrs: {
                    isShowAddDialog: _vm.isShowAddDialog,
                    loading: _vm.loading,
                    isResetData: _vm.isResetData,
                    resData: _vm.resData,
                    approverTotalCount: _vm.approverTotalCount,
                    userInfo: _vm.userInfo,
                    office_name: _vm.office_name,
                    organizations: _vm.organizations,
                    errorUser_id: _vm.errorUser_id,
                    dataOffice: _vm.dataOffice,
                    deleteApproverSetting: _vm.deleteApproverSetting,
                    isDeleteFinish: _vm.isDeleteFinish,
                    errorMessageProcess: _vm.errorMessageProcess,
                  },
                  on: {
                    "enter-userId": function ($event) {
                      return _vm.$emit("enter-userId", $event)
                    },
                    closeAddDialog: function ($event) {
                      return _vm.$emit("closeAddDialog")
                    },
                    selected: function ($event) {
                      return _vm.selectApprover($event)
                    },
                    "add-approver": function ($event) {
                      return _vm.$emit("add-approver")
                    },
                    deleteApprover: function ($event) {
                      return _vm.$emit("delete-approver", _vm.selectedApprovers)
                    },
                    "change-column": function ($event) {
                      return _vm.$emit("change-column", $event)
                    },
                    closeDeleteDialog: function ($event) {
                      return _vm.$emit("closeDeleteDialog", $event)
                    },
                    "close-finish": function ($event) {
                      return _vm.$emit("close-finish", $event)
                    },
                    "get-current-items": function ($event) {
                      return _vm.$emit("get-current-items", $event)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }