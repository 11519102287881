
  import Vue, { PropType } from 'vue';
  import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
  import UserAddForm from '@/components/organisms/UserAddForm/UserAddForm.vue';
  import UserBulkAddForm from '@/components/organisms/UserBulkAddForm/UserBulkAddForm.vue';
  import UserManagementList from '@/components/organisms/UserManagementList/UserManagementList.vue';
  import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
  import * as Config from '../../../config';
  export interface DataType {
    searchTargetItems: SearchTargetItem[];
    selectedApplicantIds: string[];
    tab: number;
    configData: any;
    selectedIds: any;
  }
  export default Vue.extend({
    name: 'UserManagementTemplate',
    components: {
      UserAddForm,
      UserBulkAddForm,
      UserManagementList,
      Searchbox,
      Breadcrumbs,
    },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      isDeleteFinish: {
        type: Boolean,
        default: false,
      },
      loadingSearch: {
        type: Boolean,
        default: false,
      },
      isAdmin: {
        type: Boolean,
        default: false,
      },
      loadMore: {
        type: Boolean,
        default: false,
      },
      showModalAddEditUser: {
        type: Boolean,
        default: false,
      },
      showModalBulkUser: {
        type: Boolean,
        default: false,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      userTotalCount: {
        type: Number,
        default: 0,
      },
      page: {
        type: Number,
        default: 0,
      },
      total: {
        type: Number,
        default: 0,
      },
      perPage: {
        type: Number,
        default: 0,
      },
      // userTypeOptions: {
      //   type: Array,
      //   default: () => ['一般', '承認者', '運用管理者'],
      // },
      organizations: {
        default: () => [],
      },
      userData: {
        default: {
          userId: '',
          password: '',
          userName: '',
          furigana: '',
          email: '',
          userType: '',
          organizations: [],
          supplement: '',
        },
      },
      resData: {
        default: () => [],
      },
      isResetData: {
        default: false,
      },
      errorMessageProcess: {
        default: [],
      },
      deleteUserDialog: {
        type: Boolean,
        default: false,
      },
      changeUserInfoDialog: {
        type: Boolean,
        default: false,
      },
    },
    data: (): DataType => ({
      searchTargetItems: [
        { label: Config.TEXT_USER_ID, name: 'user_id', value: '', maxLen: Config.USER_ID_MAX_LEN},
        { label: Config.TEXT_USER_NAME, name: 'name', value: '', maxLen: Config.USER_NAME_MAX_LEN},
        { label: Config.TEXT_COL_COMPANY_DEPARTMENT, name: 'organizations', value: ''},
        { label: Config.TEXT_COL_EMAIL_ADDRESS, name: 'email', value: '', maxLen: Config.EMAIL_MAX_LEN},
      ],
      selectedApplicantIds: [],
      tab: 0,
      configData: Config,
      selectedIds: [],
    }),
    computed: {
      breadcrumbs: {
        get(): BreadcrumbItem[] {
          return [
            { text: Config.TEXT_USER_MANAGEMENT, disabled: true },
          ];
      },
    },
    },
    methods: {
      //
    },
  });
