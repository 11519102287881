
import { Vue } from 'vue-property-decorator';
import LayoutTemplate from '@/components/templates/LayoutTemplate/LayoutTemplate.vue';
import auth from '@/auth';
import { PERSONAL_SETTING } from '@/config';
import { MANUAL } from '@/config';
import * as Config from '@/config';
const position = 0;
export default Vue.extend({
  name: 'LayoutTemplatePage',
  components: {
    LayoutTemplate,
  },
  data() {
    return {
      pathName : '',
      isAdmin : false,
      selectedItemNav: '-1',
    };
  },
  created() {
    // this.getProfile();
  },
  mounted() {
    if (typeof sessionStorage.getItem('selectedItemNav') === 'undefined') {
      sessionStorage.setItem('selectedItemNav', '-1');
      this.selectedItemNav = '-1';
    } else {
      this.selectedItemNav = sessionStorage.getItem('selectedItemNav') as string;
    }
  },
  methods: {
    activeMenu(menuName: any) {
      if (menuName === 'manual' || menuName === 'personal') {
        this.selectedItemNav = '-1';
      } else {
        if (sessionStorage.getItem('priviledge') === '2') {
          switch (menuName) {
            case 'approval-status' : this.selectedItemNav = position.toString(); break;
            case 'entrance-history' : this.selectedItemNav = (position + 1 ).toString(); break;
            case 'equipment-status' : this.selectedItemNav = (position + 2 ).toString(); break;
            case 'user' : this.selectedItemNav = (position + 3 ).toString(); break;
            case 'approver-settings' : this.selectedItemNav = (position + 4 ).toString(); break;
          }
        } else if (sessionStorage.getItem('priviledge') === '1' ) {
          switch (menuName) {
            case 'log' : this.selectedItemNav = (position).toString(); break;
            case 'entrance-history' : this.selectedItemNav = (position + 1 ).toString(); break;
            case 'equipment-status' : this.selectedItemNav = (position + 2 ).toString(); break;
            case 'approval-status' : this.selectedItemNav = (position + 3 ).toString(); break;
            case 'user' : this.selectedItemNav = (position + 4 ).toString(); break;
            case 'approver-settings' : this.selectedItemNav = (position + 5 ).toString(); break;
            case 'email-settings' : this.selectedItemNav = (position + 6 ).toString(); break;
            case 'office' : this.selectedItemNav = (position + 7 ).toString(); break;
          }
        } else if ( sessionStorage.getItem('priviledge') === '0') {
          switch (menuName) {
            case 'office' : this.selectedItemNav = (position).toString(); break;
            case 'email-settings' : this.selectedItemNav = (position + 1 ).toString(); break;
            case 'approver-settings' : this.selectedItemNav = (position + 2 ).toString(); break;
            case 'user' : this.selectedItemNav = (position + 3 ).toString(); break;
            case 'equipment-status' : this.selectedItemNav = (position + 4 ).toString(); break;
            case 'entrance-history' : this.selectedItemNav = (position + 5 ).toString(); break;
            case 'log' : this.selectedItemNav = (position + 6 ).toString(); break;
            case 'approval-status' : this.selectedItemNav = (position + 7 ).toString(); break;
          }
        } else if ( sessionStorage.getItem('priviledge') === '3') {
            this.selectedItemNav = '0';
        }
      }
      this.pathName = menuName;
      console.log(this.selectedItemNav);
      console.log(menuName);
      sessionStorage.setItem('selectedItemNav', this.selectedItemNav.toString());
      sessionStorage.setItem('menuName', menuName);
    },
    menuClick(menuName: string) {
      this.pathName = menuName;
      const pathCurrent = `/${menuName}`;
      if (sessionStorage.getItem('priviledge') === '2') {
        switch (menuName) {
          case 'approval-status' : this.selectedItemNav = position.toString(); break;
          case 'entrance-history' : this.selectedItemNav = (position + 1 ).toString(); break;
          case 'equipment-status' : this.selectedItemNav = (position + 2 ).toString(); break;
          case 'user' : this.selectedItemNav = (position + 3 ).toString(); break;
          case 'approver-settings' : this.selectedItemNav = (position + 4 ).toString(); break;
        }
      } else if (sessionStorage.getItem('priviledge') === '1') {
          switch (menuName) {
            case 'log' : this.selectedItemNav = (position).toString(); break;
            case 'entrance-history' : this.selectedItemNav = (position + 1 ).toString(); break;
            case 'equipment-status' : this.selectedItemNav = (position + 2 ).toString(); break;
            case 'approval-status' : this.selectedItemNav = (position + 3 ).toString(); break;
            case 'user' : this.selectedItemNav = (position + 4 ).toString(); break;
            case 'approver-settings' : this.selectedItemNav = (position + 5 ).toString(); break;
            case 'email-settings' : this.selectedItemNav = (position + 6 ).toString(); break;
            case 'office' : this.selectedItemNav = (position + 7 ).toString(); break;
          }
      } else if (sessionStorage.getItem('priviledge') === '0') {
        switch (menuName) {
          case 'office' : this.selectedItemNav = (position).toString(); break;
          case 'email-settings' : this.selectedItemNav = (position + 1 ).toString(); break;
          case 'approver-settings' : this.selectedItemNav = (position + 2 ).toString(); break;
          case 'user' : this.selectedItemNav = (position + 3 ).toString(); break;
          case 'equipment-status' : this.selectedItemNav = (position + 4 ).toString(); break;
          case 'entrance-history' : this.selectedItemNav = (position + 5 ).toString(); break;
          case 'log' : this.selectedItemNav = (position + 6 ).toString(); break;
          case 'approval-status' : this.selectedItemNav = (position + 7 ).toString(); break;
        }
      } else if ( sessionStorage.getItem('priviledge') === '3') {
          this.selectedItemNav = '0';
      }
      sessionStorage.setItem('selectedItemNav', this.selectedItemNav);
      sessionStorage.setItem('menuName', menuName);
      if (this.$route.path !== pathCurrent) {
        this.$router.push({ path: pathCurrent});
      } else {
        this.$router.go(0);
      }
    },
    logout(): void {
      auth.logout();
    },
    personal(): void {
      this.$router.push({ path: PERSONAL_SETTING});
    },
    clickNotice(): void {
      //
    },
    manual() {
      const url = 'user?userId=' + sessionStorage.getItem('userProfileId') + '&goBack=manual';
      if (url !== this.$route.fullPath && this.$route.fullPath !== Config.MANUAL) {
        sessionStorage.setItem('goBack', this.$route.fullPath);
      }
      this.$router.push({ path: MANUAL });
    },
  },
});
