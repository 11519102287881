var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "1000", persistent: "" },
          model: {
            value: _vm.showModalOfficeSettings,
            callback: function ($$v) {
              _vm.showModalOfficeSettings = $$v
            },
            expression: "showModalOfficeSettings",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "modal-office-settings" },
            [
              _vm.isEdit
                ? _c(
                    "v-card-title",
                    { staticClass: "modal-title", attrs: { color: "green" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.configData
                              .TEXT_ADMINISTRATIVE_FACILITY_SETTINGS_EDIT
                          ) +
                          " "
                      ),
                    ]
                  )
                : _c(
                    "v-card-title",
                    { staticClass: "modal-title", attrs: { color: "green" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.configData.TEXT_ADMINISTRATIVE_FACILITY_SETTINGS
                          ) +
                          " "
                      ),
                    ]
                  ),
              _c("v-divider"),
              _c(
                "v-card",
                { staticClass: "pa-5 pt-0 mt-5 mr-5 ml-5" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "OfficeSettingsForm",
                      attrs: { "lazy-validation": "" },
                    },
                    [
                      !_vm.isCheck || !_vm.isEdit
                        ? _c("input-form", {
                            attrs: {
                              title:
                                _vm.configData.TEXT_MANAGEMENT_FACILITY_CODE +
                                `<span class='required'>` +
                                _vm.configData.TEXT_MUST +
                                `</span>`,
                              headerCols: 3,
                              inputType: "text",
                              disabled: !_vm.isCheck || _vm.isEdit,
                              rules: [
                                _vm.rulesValidate.requiredOfficeId(
                                  _vm.loading,
                                  _vm.officeInfo.officeId
                                ),
                                _vm.rulesValidate.validateOfficeId,
                              ],
                            },
                            model: {
                              value: _vm.officeInfo.officeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.officeInfo, "officeId", $$v)
                              },
                              expression: "officeInfo.officeId",
                            },
                          })
                        : !_vm.isCheck || _vm.isEdit
                        ? _c("input-form", {
                            attrs: {
                              title:
                                _vm.configData.TEXT_MANAGEMENT_FACILITY_CODE +
                                `<span class='required'>` +
                                _vm.configData.TEXT_MUST +
                                `</span>`,
                              headerCols: 3,
                              inputType: "text",
                              disabled: !_vm.isCheck || _vm.isEdit,
                            },
                            model: {
                              value: _vm.officeInfo.officeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.officeInfo, "officeId", $$v)
                              },
                              expression: "officeInfo.officeId",
                            },
                          })
                        : _vm._e(),
                      _c("input-form", {
                        attrs: {
                          title:
                            _vm.configData.TEXT_MANAGEMENT_FACILITY_NAME +
                            `<span class='required'>` +
                            _vm.configData.TEXT_MUST +
                            `</span>`,
                          headerCols: 3,
                          inputType: "text",
                          disabled: !_vm.isCheck,
                          rules: [
                            _vm.rulesValidate.requiredOfficeName(
                              _vm.loading,
                              _vm.officeInfo.officeName
                            ),
                            _vm.rulesValidate.validateOfficeName,
                          ],
                        },
                        model: {
                          value: _vm.officeInfo.officeName,
                          callback: function ($$v) {
                            _vm.$set(_vm.officeInfo, "officeName", $$v)
                          },
                          expression: "officeInfo.officeName",
                        },
                      }),
                      _c("input-form", {
                        attrs: {
                          title:
                            _vm.configData.TEXT_AFFILIATION +
                            `<span class='required'>` +
                            _vm.configData.TEXT_MUST +
                            `</span>`,
                          headerCols: 3,
                          inputType: "select",
                          selectItems: _vm.domainItems,
                          disabled: !_vm.isCheck || _vm.isEdit,
                          rules: [
                            _vm.rulesValidate.requiredAffiliation(
                              _vm.loading,
                              _vm.officeInfo.domainCode
                            ),
                          ],
                        },
                        model: {
                          value: _vm.officeInfo.domainCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.officeInfo, "domainCode", $$v)
                          },
                          expression: "officeInfo.domainCode",
                        },
                      }),
                      _c(
                        "v-row",
                        [
                          _vm.$vuetify.breakpoint.mdAndUp
                            ? _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-subheader", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.configData.TEXT_LIST_OF_FACILITIES
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            [
                              _c("v-combobox", {
                                staticClass: "list-department",
                                attrs: {
                                  items: _vm.areaList,
                                  "item-text": "areaName",
                                  multiple: "",
                                  "deletable-chips": "",
                                  chips: "",
                                  disabled:
                                    _vm.areaList.length === 0 || !_vm.isCheck,
                                  autocomplete: "off",
                                  color: "green",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                  "return-object": "",
                                  rules: [
                                    _vm.rulesValidate.validateAreaCode(
                                      _vm.officeInfo.areaList
                                    ),
                                  ],
                                },
                                model: {
                                  value: _vm.officeInfo.areaList,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.officeInfo, "areaList", $$v)
                                  },
                                  expression: "officeInfo.areaList",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("input-form", {
                        attrs: {
                          title: _vm.configData.TEXT_SUPPLEMENT,
                          headerCols: 3,
                          inputType: "text",
                          disabled: !_vm.isCheck,
                          rules: [_vm.rulesValidate.validateOfficeSupplement],
                        },
                        model: {
                          value: _vm.officeInfo.supplement,
                          callback: function ($$v) {
                            _vm.$set(_vm.officeInfo, "supplement", $$v)
                          },
                          expression: "officeInfo.supplement",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _vm.isCheck
                    ? _c(
                        "v-row",
                        {
                          staticClass: "mb-2 mt-2 mr-0",
                          attrs: { justify: "end" },
                        },
                        [
                          _vm.isEdit
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "mb-2 mt-2 mr-0",
                                  attrs: { justify: "end" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-secondary",
                                      attrs: { outlined: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.closeOfficeSettingsDialog()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.configData.TEXT_CANCEL) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-primary",
                                      attrs: {
                                        outlined: "",
                                        disabled: _vm.isDisabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showConfirmOfficeDialog()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.configData.TEXT_UPDATE) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "v-row",
                                {
                                  staticClass: "mb-2 mt-2 mr-0",
                                  attrs: { justify: "end" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-secondary",
                                      attrs: { outlined: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.closeOfficeSettingsDialog()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.configData.TEXT_CANCEL) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-primary",
                                      attrs: {
                                        outlined: "",
                                        disabled: _vm.isDisabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showConfirmOfficeDialog()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.configData.TEXT_CREATE) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        {
                          staticClass: "mb-2 mt-2 mr-0",
                          attrs: { justify: "center" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeOfficeSettingsDialog()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.configData.TEXT_CLOSE_UP) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.confirmOfficeDialog,
            callback: function ($$v) {
              _vm.confirmOfficeDialog = $$v
            },
            expression: "confirmOfficeDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "modal-title", attrs: { color: "black" } },
                [_vm._v(" " + _vm._s(_vm.configData.TEXT_CONFIRMATION) + " ")]
              ),
              _c(
                "v-card-text",
                { staticClass: "mt-5" },
                [
                  _c("div", [_c("p", [_vm._v(_vm._s(_vm.confirmOfficeText))])]),
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _c("v-spacer"),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.closeConfirmOfficeDialog()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.configData.TEXT_RETURN))]
                      ),
                      _vm.isEdit
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.updateOfficeSettings()
                                },
                              },
                            },
                            [_vm._v("OK")]
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.saveOfficeSettings()
                                },
                              },
                            },
                            [_vm._v("OK")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }