
  import { Vue } from 'vue-property-decorator';
  import EmailSettingsTemplate from '@/components/templates/EmailSettingsTemplate/EmailSettingsTemplate.vue';
  import * as Config from '@/config';

  interface EmailSettings {
    domain_code: string;
    domain_name?: any;
    submittion: string;
    cancellation: string;
    approval: string;
    return: string;
    rescission: string;
    extension_request: string;
    extension_approval: string;
    extension_denial: string;
  }

  export interface DataType {
    // Props
    loading: boolean;
    isRegistering: boolean;
    emailSettings: EmailSettings;
    domainItems: any;
  }
  export default Vue.extend({
    name: 'EmailSettingsPage',
    components: {
      EmailSettingsTemplate,
    },
    data: (): DataType => ({
      // Props
      loading: false,
      isRegistering: false, // default: false -> reading data
      emailSettings: {
        domain_code: '',
        domain_name: '',
        submittion: '',
        cancellation: '',
        approval: '',
        return: '',
        rescission: '',
        extension_request: '',
        extension_approval: '',
        extension_denial: '',
      },
      domainItems: [],
    }),
    watch: {
      loading(value) {
        this.$emit('loading', value);
        if (value) {
          this.$emit('textCircle', this.isRegistering ?
           Config.TEXT_PROGRESS_REGISTERING : Config.TEXT_PROGRESS_READING);
        } else if (this.isRegistering) {
          this.isRegistering = false;
        }
      },
    },
    mounted() {
      this.$emit('activeMenu', 'email-settings');
    },
    created() {
      if (sessionStorage.getItem('priviledge') === '0') {
        this.getDomainList();
      }
      const domainCode = sessionStorage.getItem('domainCode');
      this.getEmailSettings(domainCode);
    },
    methods: {
      async getEmailSettings(domainCode: any) {
        if (domainCode === undefined || domainCode === null || domainCode === '') {
          this.emailSettings = {
            domain_code: '',
            submittion: '',
            cancellation: '',
            approval: '',
            return: '',
            rescission: '',
            extension_request: '',
            extension_approval: '',
            extension_denial: '',
          };
        } else {
          // GET /configurations/messages
          this.loading = true;
          this.$http.get(Config.CONFIGURATIONS_API + '?domain_code=' + domainCode)
            .then((response: any) => {
              this.loading = false;
              if (!this.isEmpty(response.body.messages)) {
                // console.log('configuration: ' + response.body.configuration);
                // this.emailSettings.domain_code = response.body.messages.domain_code;
                this.emailSettings.domain_code = domainCode;
                this.emailSettings.domain_name = sessionStorage.getItem('domain_name') ;
                this.emailSettings.submittion = response.body.messages.submittion;
                this.emailSettings.cancellation = response.body.messages.cancellation;
                this.emailSettings.approval = response.body.messages.approval;
                this.emailSettings.return = response.body.messages.return;
                this.emailSettings.rescission = response.body.messages.rescission;
                this.emailSettings.extension_request = response.body.messages.extension_request;
                this.emailSettings.extension_approval = response.body.messages.extension_approval;
                this.emailSettings.extension_denial = response.body.messages.extension_denial;
              } else {
                alert(Config.TEXT_ERROR_FOR_EMAIL_SETTINGS);
              }
            })
            .then(null, (err: any) => {
              this.loading = false;
              this.emailSettings = {
                domain_code: domainCode,
                submittion: '',
                cancellation: '',
                approval: '',
                return: '',
                rescission: '',
                extension_request: '',
                extension_approval: '',
                extension_denial: '',
              };
              console.log('err: ', err);
              alert(Config.TEXT_ERROR_FOR_EMAIL_SETTINGS);
            });
        }
      },
      getDomainList() {
        this.domainItems = [];
        this.$http.get(Config.DOMAINS_API)
          .then((res: any) => {
            if (!this.isEmpty(res.body.domains) && res.body.domains.length > 0) {
              for (const domain of res.body.domains) {
                this.domainItems.push({text: domain.name, value: domain.code});
              }
            } else {
              // no domain
            }
          })
          .then(null, (err: any) => {
            alert(Config.TEXT_ERROR_FOR_AFFILIATION_LIST);
          });
      },
      async changeDomain(domain: any) {
        if (domain !== undefined && domain !== null && domain !== '') {
          await this.getEmailSettings(domain);
        }
      },
      updateSubmittionMessage(eventArgs: any) {
        const emailSettings: any = {
          domain_code: this.emailSettings.domain_code,
          messages: {
            submittion: this.emailSettings.submittion,
          },
        };
        this.updateEmailSettings(emailSettings);
      },
      updateCancelationMessage(eventArgs: any) {
        const emailSettings: any = {
          domain_code: this.emailSettings.domain_code,
          messages: {
            cancellation: this.emailSettings.cancellation,
          },
        };
        this.updateEmailSettings(emailSettings);
      },
      updateApprovalMessage(eventArgs: any) {
        const emailSettings: any = {
          domain_code: this.emailSettings.domain_code,
          messages: {
            approval: this.emailSettings.approval,
          },
        };
        this.updateEmailSettings(emailSettings);
      },
      updateReturnMessage(eventArgs: any) {
        const emailSettings: any = {
          domain_code: this.emailSettings.domain_code,
          messages: {
            return: this.emailSettings.return,
          },
        };
        this.updateEmailSettings(emailSettings);
      },
      updateRescissionsMessage(eventArgs: any) {
        const emailSettings: any = {
          domain_code: this.emailSettings.domain_code,
          messages: {
            rescission: this.emailSettings.rescission,
          },
        };
        this.updateEmailSettings(emailSettings);
      },
      updateExntensionRequestMessage(eventArgs: any) {
        const emailSettings: any = {
          domain_code: this.emailSettings.domain_code,
          messages: {
            extension_request: this.emailSettings.extension_request,
          },
        };
        this.updateEmailSettings(emailSettings);
      },
      updateExntensionApprovalMessage(eventArgs: any) {
        const emailSettings: any = {
          domain_code: this.emailSettings.domain_code,
          messages: {
            extension_approval: this.emailSettings.extension_approval,
          },
        };
        this.updateEmailSettings(emailSettings);
      },
      updateExntensionDenialMessage(eventArgs: any) {
        const emailSettings: any = {
          domain_code: this.emailSettings.domain_code,
          messages: {
            extension_denial: this.emailSettings.extension_denial,
          },
        };
        this.updateEmailSettings(emailSettings);
      },
      updateEmailSettings(emailSettings: any) {
        // PUT /configurations/messages
        this.isRegistering = true;
        this.loading = true;
        this.$http.put(Config.CONFIGURATIONS_API, emailSettings)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              this.loading = false;
              alert(Config.TEXT_UPDATE_COMPLETED);
            } else {
              this.loading = false;
              alert(Config.TEXT_ERROR_FOR_EMAIL_SETTINGS_UPDATE);
            }
          })
          .then(null, (err: any) => {
            alert(Config.TEXT_ERROR_FOR_EMAIL_SETTINGS_UPDATE);
            this.loading = false;
          });
      },
      isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            return false;
          }
        }
        return true;
      },
    },
  });
