var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-container", [
    _c(
      "div",
      [
        _c(
          "v-row",
          {
            staticClass: "mt-3 border-bottom-bold",
            attrs: {
              "no-gutters": "",
              justify: "space-between",
              "align-content": "center",
            },
          },
          [
            _c("show-per-page", {
              staticClass: "ml-8 mb-3",
              attrs: {
                "per-page": _vm.total,
                page: 1,
                "items-length": _vm.total,
              },
            }),
            _c("v-spacer"),
            _c(
              "div",
              {
                staticClass:
                  "text-caption display-option included-option text-sm-caption text-md-caption text-lg-caption text-xl-caption mr-8",
              },
              [
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple",
                        value: false,
                        expression: "false",
                      },
                    ],
                    staticClass: "allig-btn-text-link-gray allig-btn-text-link",
                    attrs: { block: "", type: "button", text: "" },
                    on: {
                      click: () => {
                        _vm.showOption = !_vm.showOption
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "display-option-title" }, [
                      _vm._v(
                        " " + _vm._s(_vm.configData.TEXT_DISPLAY_OPTIONS) + " "
                      ),
                    ]),
                    _c(
                      "span",
                      [
                        _c("v-icon", { attrs: { dense: "" } }, [
                          _vm._v("mdi-chevron-down"),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          { staticClass: "pt-0 pb-0 mt-0" },
          [
            _c(
              "v-container",
              { staticClass: "pt-0" },
              [
                _c("display-option", {
                  attrs: {
                    isShowOption: _vm.showOption,
                    "display-option-items": _vm.displayOptionItems,
                    disabled: _vm.loading,
                    isVaildOnly: _vm.isVaildOnly,
                  },
                  on: {
                    "show-only-isVaild": function ($event) {
                      return _vm.$emit("show-only-isVaild", $event)
                    },
                    input: function ($event) {
                      return _vm.CheckColumn($event)
                    },
                  },
                  model: {
                    value: _vm.selectedDisplayOption,
                    callback: function ($$v) {
                      _vm.selectedDisplayOption = $$v
                    },
                    expression: "selectedDisplayOption",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm.loading == false
          ? _c("v-data-table", {
              directives: [
                { name: "resizable-columns", rawName: "v-resizable-columns" },
              ],
              staticClass: "border-table scroll-table",
              attrs: {
                "must-sort": "",
                headers: _vm.headers,
                items: _vm.resData,
                "items-per-page": -1,
                loading: _vm.loading,
                "item-key": "test1",
                "no-data-text": _vm.configData.TEXT_NO_DATA,
                "loading-text": _vm.configData.TEXT_LOADING_DATA,
                "hide-default-footer": "",
                "sort-by": ["code"],
                "sort-desc": false,
              },
              on: { "current-items": _vm.currentItems },
              scopedSlots: _vm._u(
                [
                  {
                    key: `item.voltage`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "span",
                          { class: { "text-orange": item.voltage < 2.94 } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.convertVoltage(
                                  item.voltage,
                                  item.type,
                                  item.isMinDate
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.applicantSelected,
                callback: function ($$v) {
                  _vm.applicantSelected = $$v
                },
                expression: "applicantSelected",
              },
            })
          : _vm._e(),
        _c("v-row", {
          staticStyle: { height: "20px" },
          attrs: { "no-gutters": "" },
        }),
        _c("v-spacer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }