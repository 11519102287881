
import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
import LogList from '@/components/organisms/LogList/LogList.vue';
import * as Config from '../../../config';
export interface DataType {
  searchTargetItems: SearchTargetItem[];
}

export default Vue.extend({
  name: 'LogListTemplate',
  components: {
    LogList,
    Searchbox,
    Breadcrumbs,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingSearch: {
      type: Boolean,
      default: false,
    },
    resData: {
      default: () => [],
    },
    logTotalCount: {
      type: Number,
      default: 0,
    },
  },
  data: (): DataType => ({
    searchTargetItems: [
      { label: Config.TEXT_COL_OPERATION_DISTINCTION, name: 'type', value: ''},
      { label: Config.TEXT_USER_ID, name: 'user_id', value: '', maxLen: Config.USER_ID_MAX_LEN},
      { label: Config.TEXT_USER_NAME, name: 'user_name', value: '', maxLen: Config.USER_NAME_MAX_LEN},
      { label: Config.TEXT_SEARCH_PERIOD_START_DATE_AND_TIME, name: 'since', value: ''},
      { label: Config.TEXT_SEARCH_PERIOD_END_DATE_AND_TIME, name: 'until', value: ''},
    ],
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: Config.TEXT_OPERATION_LOG, disabled: true },
        ];
      },
    },
  },
  methods: {
    //
  },
});
