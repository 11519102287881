
import Vue, { PropType } from 'vue';
import Searchbox,  {SearchTargetItem} from '@/components/molecules/Searchbox/Searchbox.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';
import moment from 'moment-timezone';
import auth from '@/auth';
export interface TypeItem {
  title: string;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  showOption: boolean;
  isVaildOnly: boolean;
  confirmDialog: boolean;
  typeSearch: any;
  configData: any;
  applicantSelected: [];
  comment: string;
  actions: string;
  confirmDialogExtention: boolean;
  workDate: any;
  itemWorkDate: any;
  description: string;
  titleChange: any;
  displayPriviledge: any;
  endTime: string;
  currentTime: string;
  extPeriodTime: string;
  endDate: string;
}

export default Vue.extend({
  name: 'ApprovalList',
  components: {
    Searchbox,
    ShowPerPage,
    Pagination,
    SelectPerPage,
    DisplayOption,
  },
  watch: {
    closeDialogExtention(val) {
      if (val === true) {
        this.confirmDialogExtention = false;
        this.confirmDialog = false;
      }
    },
    selectedDisplayOption(value) {
      sessionStorage.setItem('displayOptionApprover', JSON.stringify(value));
    },
    resData() {
      if (this.resExtData.end) {
        this.endTime = auth.converDateShort(this.resExtData.end);
      } else {
        this.endTime = auth.converDateShort(this.resData.end);
      }
    },
    resExtData() {
      this.description = this.resExtData.comment;
      if (this.resExtData.end) {
        const a  = moment(this.resExtData.end);
        const b  = moment(this.endDate);
        const duration = moment.duration(a.diff(b));
        const hours = duration.asMilliseconds();
        const countTime = auth.countTime(hours);
        let text = '';
        if (countTime === '02') {
          text = '+2時間';
        } else if (countTime === '04') {
          text = '+4時間';
        } else if (countTime === '06') {
          text = '+6時間';
        }
        this.extPeriodTime = auth.converDateShort(this.endDate) + '  ' + text;
      } else {
        this.extPeriodTime = auth.converDateShort(this.endDate);
      }
    },
  },
  props: {
    resData: {
      type: Object,
      default: () => ({}),
    },
    resHisData: {
      default: () => [],
    },
    resDataGates: {
      default: () => [],
    },
    resExtData: {
      type: Object,
      default: () => ({}),
    },
    resCardData: {
      type: Object,
      default: () => ({}),
    },
    applicantTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    isAction: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 100,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    request_id: {
      type: String,
      default: '',
    },
    isFinishProcessEnable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    closeDialogExtention: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    //
    this.endTime = auth.converDateShort(this.resData.end);
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: Config.TEXT_UPDATE_TIME,
            align: 'start',
            value: 'timestamp',
          },
          {
            text: Config.TEXT_OPERATION_CONTENT,
            align: 'start',
            value: 'content',
          },
          {
            text: Config.TEXT_BEFORE_OPERATION,
            align: 'start',
            value: 'from',
          },
          {
            text: Config.TEXT_AFTER_OPERATION,
            align: 'start',
            value: 'to',
          },
          {
            text: Config.TEXT_USER_NAME,
            align: 'start',
            value: 'user_name',
          },
          {
            text: Config.TEXT_COL_COMPANY_DEPARTMENT_NAME,
            align: 'start',
            value: 'organizations',
          },
          {
            text: Config.TEXT_CONTENT,
            align: 'start',
            value: 'comment',
          },
        ];
        return headers;
      },
      set(): void {
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', val);
        return;
      },
    },
  },
  data: (): DataType => ({
    selectedDisplayOption: [],
    displayOptionItems: [],
    applicantSelected: [],
    showOption: false,
    isVaildOnly: false,
    typeSearch: 0,
    confirmDialog: false,
    comment: '',
    actions: '',
    configData: Config,
    confirmDialogExtention: false,
    itemWorkDate: ['入室終了日時+2時間', '入室終了日時+4時間', '入室終了日時+6時間'],
    description: '',
    workDate: '',
    titleChange: sessionStorage.getItem('priviledge'),
    displayPriviledge: sessionStorage.getItem('priviledge'),
    endTime: '',
    currentTime: auth.converDateShort(new Date()),
    extPeriodTime: '',
    endDate: '',
  }),
  methods: {
    isDate(val1: any , val2: any) {
      const reqEnd = auth.formatTimestamp(val1) ;
      const extEnd = auth.formatTimestamp(val2);
      return reqEnd < extEnd;
    },
    cancel() {
        this.$router.push({ path: Config.APPROVAL_STATUS});
    },
    clickApproval(extStatus: string) {
      this.$emit('action-approval', {
          action: 'extensions',
          request_id: this.resData.request_id,
          data: {
            extension_id: this.resExtData.id,
            status: extStatus,
            comment: this.comment,
          },
        });
      this.comment = '';
    },
    actionApproval() {
        this.$emit('action-approval', {action: this.actions, data: this.comment});
        this.comment = '';
    },
    convertContent(val: any) {
      return auth.convertContent(val);
    },
    converDateShort(val: any) {
      return auth.converDateShort(val);
    },
    convertStatus(val: any) {
      return auth.convertStatus(val);
    },
    showDialog(value: any) {
      this.comment = '';
      this.confirmDialogExtention = false;
      this.confirmDialog = true;
      this.actions = value;
    },
    showExtensions(value: any) {
      this.comment = '';
      this.endDate = value;
      this.$emit('open-extensions');
      this.confirmDialogExtention = true;
    },
    CheckColumn(data: any) {
      sessionStorage.setItem('displayOptionApprover', JSON.stringify(data));
    },
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
  },
});
