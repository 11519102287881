var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "info-item" },
    [
      _c(
        "v-row",
        [
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-col",
                { attrs: { cols: "3" } },
                [_c("v-subheader", [_vm._v(_vm._s(_vm.title))])],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "text-body-2", attrs: { "align-self": "center" } },
            [_vm._v(" " + _vm._s(_vm.text) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }