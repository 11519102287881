
import Vue, { PropType } from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import * as Config from '@/config';

interface TypeSelect {
  text: string;
  value: string;
}

export interface DataType {
  rulesValidate: any;
  workStationDialog: boolean;
  doorDialog: boolean;
  managementStationDialog: boolean;
  test: string;
  configData: any;
  showCurrentPassword: boolean;
  showNewPassword: boolean;
  showConfirmNewPassword: boolean;
}

export interface ChangePassword {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export default Vue.extend({
  name: 'ChangePassword',
  components: {
    InputForm,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    isAction: {
      type: Number,
      default: 0,
    },
    resData: {
      default: () => [],
    },
    changePassword: {
      type: Object,
      default: () => ({}),
    },
    successMessageLogs: {
      default: '',
    },
    confirmPwdDialog: {
      type: Boolean,
      default: false,
    },
    showPwdMsgDialog: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    loading(val: any) {
      if (val === false) {
        (this.$refs.changePasswordForm as Vue & { reset: () => boolean }).reset();
      }
    },
  },
  computed: {
    isDisabled: {
      get(): boolean {
        let isValid = true;
        const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*)\S{8,64}$/;
        const hiragana = /^(?=.*[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f])\S{8,64}$/;
        isValid = !!this.changePassword.currentPassword;
        if (isValid) {
          if (this.changePassword.currentPassword.match(hiragana)) {
            isValid = false;
          } else if (this.changePassword.currentPassword.match(/[\uff00-\uffff]/g)) {
            isValid = false;
          } else {
            isValid = isValid && pattern.test(this.changePassword.currentPassword);
          }
        }

        isValid = isValid && !!this.changePassword.newPassword;
        if (isValid) {
          if (this.changePassword.newPassword.match(hiragana)) {
            isValid = false;
          } else if (this.changePassword.newPassword.match(/[\uff00-\uffff]/g)) {
            isValid = false;
          } else {
            isValid = isValid && pattern.test(this.changePassword.newPassword);
          }
        }

        isValid = isValid && !!this.changePassword.confirmNewPassword;
        if (isValid) {
          if (this.changePassword.confirmNewPassword.match(hiragana)) {
            isValid = false;
          } else if (this.changePassword.confirmNewPassword.match(/[\uff00-\uffff]/g)) {
            isValid = false;
          } else {
            isValid = isValid && pattern.test(this.changePassword.confirmNewPassword);
          }
        }
        isValid = isValid && (this.changePassword.newPassword === this.changePassword.confirmNewPassword);

        return !isValid;
      },
      set(): void {
        return;
      },
    },
  },
  data: (): DataType => ({
    workStationDialog: false,
    doorDialog: false,
    managementStationDialog: false,
    test: '',
    rulesValidate: {
      requiredCurrentPassword: (currentPassword: string) => !!currentPassword || Config.ERROR_CURRENT_PASSWORD_REQUIRED,
      validateCurrentPassword: (currentPassword: string) => {
        if (currentPassword !== undefined && currentPassword !== null && currentPassword !== '') {
          const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*)\S{8,64}$/;
          const hiragana = /^(?=.*[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f])\S{8,64}$/;
          if (currentPassword.match(hiragana)) {
            return Config.ERROR_CONFIRM_PASSWORD_REG;
          } else if (currentPassword.match(/[\uff00-\uffff]/g)) {
            return Config.ERROR_CONFIRM_PASSWORD_REG;
          } else {
            return pattern.test(currentPassword) || Config.ERROR_CONFIRM_PASSWORD_REG;
          }
        } else {
          return true;
        }
      },
      requiredNewPassword: (newPassword: string) => !!newPassword || Config.ERROR_NEW_PASSWORD_REQUIRED,
      validateNewPassword: (newPassword: string) => {
        if (newPassword !== undefined && newPassword !== null && newPassword !== '') {
          const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*)\S{8,64}$/;
          const hiragana = /^(?=.*[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f])\S{8,64}$/;
          if (newPassword.match(hiragana)) {
            return Config.ERROR_CONFIRM_NEW_PASSWORD_REG;
          } else if (newPassword.match(/[\uff00-\uffff]/g)) {
            return Config.ERROR_CONFIRM_NEW_PASSWORD_REG;
          } else {
            return pattern.test(newPassword) || Config.ERROR_CONFIRM_NEW_PASSWORD_REG;
          }
        } else {
          return true;
        }
      },
      requiredConfirmNewPassword: (confirmNewPassword: string) =>
      !!confirmNewPassword || Config.ERROR_CONFIRM_NEW_PASSWORD_REQUIRED,
      validateConfirmNewPassword: (newPassword: any, confirmNewPassword: string) => {
        if (confirmNewPassword !== undefined && confirmNewPassword !== null && confirmNewPassword !== '') {
          const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*)\S{8,64}$/;
          const hiragana = /^(?=.*[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f])\S{8,64}$/;
          if (confirmNewPassword.match(hiragana)) {
            return Config.ERROR_CONFIRM_NEW_PASSWORD_FOR_CONFIRMATION_REG;
          } else if (confirmNewPassword.match(/[\uff00-\uffff]/g)) {
            return Config.ERROR_CONFIRM_NEW_PASSWORD_FOR_CONFIRMATION_REG;
          } else if (!pattern.test(confirmNewPassword)) {
            return Config.ERROR_CONFIRM_NEW_PASSWORD_FOR_CONFIRMATION_REG;
          } else {
            if (newPassword === confirmNewPassword) {
              return true;
            } else {
              return Config.ERROR_PASSWORD_NOT_MATCH;
            }
          }
        } else {
          return true;
        }
      },
    },
    configData: Config,
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmNewPassword: false,
  }),
  mounted() {
    //
  },
  methods: {
    closeConfirmPwdDialog() {
      this.$emit('closeConfirmPwdDialog');
    },
    showConfirmPwdDialog() {
      const result = (this.$refs.changePasswordForm as Vue & { validate: () => boolean }).validate();
      if (result) {
        this.$emit('showConfirmPwdDialog');
      }
    },
    ChangePassword() {
      const result = (this.$refs.changePasswordForm as Vue & { validate: () => boolean }).validate();
      if (result) {
        this.$emit('changePassword',
        {currentPassword: this.changePassword.currentPassword,
        newPassword: this.changePassword.newPassword,
        confirmNewPassword: this.changePassword.confirmNewPassword});
      }
    },
    matchingPassword() {
      if (this.changePassword.newPassword === this.changePassword.confirmNewPassword) {
        return true;
      } else {
        return Config.ERROR_PASSWORD_NOT_MATCH;
      }
    },
  },
});
