
import {Vue } from 'vue-property-decorator';
import ApplicantAddTemplate from '@/components/templates/ApplicantTemplate/ApplicantAddTemplate.vue';
import * as Config from '@/config';
import auth from '@/auth';
import moment from 'moment-timezone';
export interface DataType {
  // Props
  page: number;
  isAction: number;
  loading: boolean;
  isRegistering: boolean;
  isDataLoaded: boolean;
  confirmDialog: boolean;
  // confirmDialogFinish: boolean;
  resData: any;
  resDataAreas: any;
  resDataOffice: any;
  resDataGates: any;
  resCardData: any;
  dataItem: any;
  areaList: any;
  resDataAreaItem: any;
  resDataGateItem: any;
  isError: boolean;
}
export default Vue.extend({
  name: 'ApplicantAddPage',
  components: {
    ApplicantAddTemplate,
  },
  data: (): DataType => ({
    // Props
    loading: false,
    isRegistering: false, // default: false -> reading data
    isDataLoaded: false,
    confirmDialog: false,
    // confirmDialogFinish: false,
    page: 1,
    isAction: 0,
    resData: [],
    resDataGates: [],
    resDataAreas: [],
    resDataOffice: [],
    resCardData: [],
    isError: false,
    resDataAreaItem: [],
    resDataGateItem: [],
    dataItem: {
      begin: '',
      updater: sessionStorage.getItem('user_name'),
      requester_name: sessionStorage.getItem('user_name'),
      end: '',
      area_code: { text: '', value: ''},
      office_code: { text: '', value: ''},
    },
    areaList: [],
  }),
  watch: {
    loading(value) {
      this.$emit('loading', value);
      if (value) {
        this.$emit('textCircle', this.isRegistering ? Config.TEXT_PROGRESS_REGISTERING : Config.TEXT_PROGRESS_READING);
      } else if (this.isRegistering) {
        this.isRegistering = false;
      }
    },
  },
  mounted() {
    this.$emit('activeMenu', 'applicant');
    const dataQuery = this.$route.query;
    if (dataQuery.request_id !== undefined) {
      this.isAction = 1;
      this.getData(dataQuery.request_id);
    } else {
      this.loading = true;
      this.getOffice()
      .then((res: any) => {
        this.getListCard(sessionStorage.getItem('userProfileId'))
        .then(() => {
          this.loading = false;
        });
      });
    }
  },
  created() {
    //
    this.getAreas();
  },
  methods: {
    // Add
    resetMessage() {
      if (this.isAction === 1) {
        this.isError = false;
      }
    },
    async saveEntry(eventArgs: any) {
      this.isRegistering = true;
      this.loading = true;
      this.isError = false;
      this.dataItem.request_id = '';
      const data = eventArgs.data;
      if (data.begin) {
        const time =  auth.minusTime(eventArgs.specialStart);
        if (eventArgs.specialStart === '00:00') {
          const date = new Date(data.begin);
          date.setUTCDate(date.getUTCDate() - 1);
          data.begin = auth.converDateOnlyDate(date);
        }
        data.begin =  auth.formatISOString(data.begin + ' ' + time);
      }
      if (data.end) {
        data.end =  auth.formatISOString(data.end + ' ' + eventArgs.specialEnd);
      }
      await this.postData(data, 'saveEntry');
      this.dataItem = {
          begin: '',
          updater: sessionStorage.getItem('userId'),
          requester_name: sessionStorage.getItem('user_name'),
          end: '',
          area_code: { text: '', value: ''},
          office_code: { text: '', value: ''},
        },
      this.loading = false;
    },
    async subEntry(eventArgs: any) {
      this.loading = true;
      this.isError = false;
      this.dataItem.request_id = '';
      this.isRegistering = true;
      const data = eventArgs.data;
      const time =  auth.minusTime(eventArgs.specialStart);
      if (eventArgs.specialStart === '00:00') {
        const date = new Date(data.begin);
        date.setUTCDate(date.getUTCDate() - 1);
        data.begin = auth.converDateOnlyDate(date);
      }
      data.begin =  auth.formatISOString(data.begin + ' ' + time);
      data.end =  auth.formatISOString(data.end + ' ' + eventArgs.specialEnd);
      const result = await this.postData(data, 'subEntry');
      if (result) {
        this.confirmDialog = true;
      } else {
        this.dataItem = {
          request_id: this.dataItem.request_id,
          begin: '',
          updater: sessionStorage.getItem('userId'),
          requester_name: sessionStorage.getItem('user_name'),
          end: '',
          area_code: { text: '', value: ''},
          office_code: { text: '', value: ''},
        };
      }
      this.loading = false;
    },
    async sendSubmissions(comment: string) {
      this.loading = true;
      this.isError = false;
      this.confirmDialog = false;
      this.isRegistering = true;
      let  result = false;
      result = await this.submitEntry(comment);
      this.dataItem = {
        request_id: '',
        begin: '',
        updater: sessionStorage.getItem('userId'),
        requester_name: sessionStorage.getItem('user_name'),
        end: '',
        area_code: { text: '', value: ''},
        office_code: { text: '', value: ''},
      };
      this.loading = false;
      console.log('result', result);
      if (result) {
        this.$router.push({ path: Config.APPLICANT});
      }
    },
    async sendSubmissionsEdit(comment: string) {
      this.loading = true;
      this.confirmDialog = false;
      const result = await this.submitEntry(comment);
      this.loading = false;
      if (result) {
        this.$router.push({ path: Config.APPLICANT});
      }
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
      this.isError = false;
      if (this.isAction === 0) {
        this.$router.push({ path: Config.APPLICANT_EDIT, query: { request_id: this.dataItem.request_id }});
        window.location.reload();
      }
    },
    // Edit
    async updateEntry(eventArgs: any) {
      this.loading = true;
      this.isError = false;
      const data = eventArgs.data;
      const time =  auth.minusTime(eventArgs.specialStart);
      if (eventArgs.specialStart === '00:00') {
        const date = new Date(data.begin);
        date.setUTCDate(date.getUTCDate() - 1);
        data.begin = auth.converDateOnlyDate(date);
      }
      data.begin =  auth.formatISOString(data.begin + ' ' + time);
      data.end =  auth.formatISOString(data.end + ' ' + eventArgs.specialEnd);
      data.requester_name =  this.dataItem.requester_name;
      const result = await this.patchData(data, 'updateEntry');
      if (result) {
        // this.loading = false; -> this code will call in getData() function
        this.getData(this.dataItem.request_id);
      } else {
        this.loading = false;
      }
    },
    async reSubEntry(eventArgs: any) {
      this.loading = true;
      this.isRegistering = true;
      this.isError = false;
      const data = eventArgs.data;
      const time =  auth.minusTime(eventArgs.specialStart);
      if (eventArgs.specialStart === '00:00') {
        const date = new Date(data.begin);
        date.setUTCDate(date.getUTCDate() - 1);
        data.begin = auth.converDateOnlyDate(date);
      }
      data.begin =  auth.formatISOString(data.begin + ' ' + time);
      data.end =  auth.formatISOString(data.end + ' ' + eventArgs.specialEnd);
      data.requester_name =  this.dataItem.requester_name;
      const result = await this.patchData(data, 'reSubEntry');
      if (result) {
        this.confirmDialog = true;
      }
      this.loading = false;
    },
    async changeOffice(val: any) {
      this.loading = true;
      this.resDataAreas = [];
      this.dataItem.area_code = { text: '', value: ''};
      this.resDataGates = [];
      this.dataItem.gates = [];
      if (val !== '' && val !== undefined && val !== null) {
        await this.getOfficeAreas(val.value);
      }
      this.loading = false;
    },
    async changeArea(val: any) {
      this.loading = true;
      this.resDataGates = [];
      this.dataItem.gates = [];
      if (val !== '' && val !== undefined && val !== null) {
        await this.getGates(val.value);
      }
      this.loading = false;
    },
    showDialog(eventArgs: any) {
      // this.resDataAreas = [];
      // this.resDataOffice = [];
      switch (eventArgs.dialog) {
        case 'workStationDialog' : this.getOfficeAreas(eventArgs.office_code); break;
        case 'managementStationDialog' : this.getOffice(); break;
        case 'doorDialog' : this.getGates(eventArgs.area_code); break;
      }
    },
    getData(ID: any): any {
      this.loading = true;
      const apiLink = Config.ENTRY_REQUESTS_API + '/' + ID;
      this.$http.get(apiLink)
        .then((response: any) => {
          this.dataItem = response.body.request;
          let endDate = auth.converDateOnlyDate(response.body.request.end);
          const endTime = auth.converDateOnlyTime(response.body.request.end);
          if (endTime === '00:00' || endTime === '06:00') {
            const date = new Date(endDate);
            date.setUTCDate(date.getUTCDate() - 1);
            endDate = auth.converDateOnlyDate(date);
          }
          const dateBegin = moment(response.body.request.begin).add(15, 'minutes');
          this.dataItem = {
            request_id: response.body.request.request_id,
            requester_name: response.body.request.requester_name,
            requester_uid: response.body.request.requester_uid,
            status: response.body.request.status,
            description: response.body.request.description,
            area_code: null, // response.body.request.area_code,
            office_code: null, // response.body.request.office_code,
            begin: auth.converDateOnlyDate(dateBegin),
            end: endDate,
            timeBegin: auth.converDateOnlyTime(dateBegin),
            timeEnd: endTime,
            submitted: response.body.request.submitted,
            registerer: response.body.request.registerer,
            registered: response.body.request.registered,
            updater: response.body.request.updater,
            updated: response.body.request.updated,
            gates: [], // response.body.request.gates,
          };
          this.isDataLoaded = true;
          this.getOffice()
          .then((res: any) => {
            this.dataItem.office_code = this.resDataOffice.find(
              (a: any) => a.value === response.body.request.office_code);
          });
          if (response.body.request.office_code !== undefined &&
              response.body.request.office_code !== null &&
              response.body.request.office_code !== '') {
            this.getOfficeAreas(response.body.request.office_code)
            .then((res: any) => {
              this.dataItem.area_code = this.resDataAreas.find((a: any) => a.value === response.body.request.area_code);
            });
          } else {
            this.getListCard(sessionStorage.getItem('userProfileId'))
            .then(() => {
              this.loading = false;
            });
          }
          if (response.body.request.area_code !== undefined &&
              response.body.request.area_code !== null &&
              response.body.request.area_code !== '') {
            this.getGates(response.body.request.area_code)
            .then((res: any) => {
              response.body.request.gates.forEach(async (element: any, key: any) => {
                const gate = this.resDataGates.find((a: any) => a.value === element.code);
                if (gate) {
                  this.dataItem.gates.push(gate);
                }
              });
              this.getListCard(sessionStorage.getItem('userProfileId'))
              .then(() => {
                this.loading = false;
              });
            });
          } else {
            this.getListCard(sessionStorage.getItem('userProfileId'))
            .then(() => {
              this.loading = false;
            });
          }
        })
        .then( null, (err: any) => {
          this.getListCard(sessionStorage.getItem('userProfileId'))
          .then(() => {
            this.loading = false;
            this.isError = true;
            alert(Config.TEXT_ENTRY_REQUESTS_LIST_FAILED);
          });
        }); // catch
    },
    patchData(data: any, type: any): any {
      return new Promise((resolve, reject) => {
        this.$http.patch(Config.ENTRY_REQUESTS_API + '/' + this.dataItem.request_id, data)
          .then((response: any) => {
            // 8/22 変更 レスポンスから、request_idを削除
            // this.dataItem.request_id = response.body.request_id;
            this.isError = true;
            if (type === 'updateEntry') {
              alert(Config.TEXT_SAVE_COMPLETED);
              this.$router.push({ path: Config.APPLICANT});
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            this.isError = true;
            alert(Config.ERROR_MESSAGE_FOR_SAVE_FAILED);
            resolve(false);
          }); // catch
      });
    },
    postData(data: any, type: any): any {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.ENTRY_REQUESTS_API, data)
          .then((response: any) => {
            this.dataItem.request_id = response.body.request_id;
            this.isError = true;
            if (type === 'saveEntry') {
              alert(Config.TEXT_SAVE_COMPLETED);
              this.$router.push({ path: Config.APPLICANT});
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            this.isError = true;
            alert(Config.ERROR_MESSAGE_FOR_SAVE_FAILED);
            resolve(false);
          }); // catch
      });
    },
    submitEntry(description: any): any {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.ENTRY_REQUESTS_API + '/' + this.dataItem.request_id + '/submissions',
                        { comment: description})
          .then((response: any) => {
            this.isError = true;
            alert(Config.TEXT_SUBMISSION_COMPLETED);
            resolve(true);
          })
          .then( null, (err: any) => {
            this.isError = true;
            if (err.status === 400) {
              alert(Config.TEXT_SUBMISSION_PROBLEM);
            } else if (err.status === 409) {
              alert(Config.TEXT_SUBMISSION_STATE_PROBLEM);
            } else {
              alert(Config.TEXT_SUBMISSION_FAILED);
            }
            resolve(false);
          }); // catch
      });
    },
    getOfficeAreas(ID: any): any {
      return new Promise((resolve, reject) => {
        this.resDataAreas = [];
        this.$http.get(Config.OFFICE_AREAS_API + '/' + ID)
          .then((response: any) => {
            if (response.body.areas !== undefined && response.body.areas !== null &&
                response.body.areas !== '' && response.body.areas.length > 0) {
              response.body.areas.forEach(async (element: any, key: any) => {
                const area = this.areaList.find((a: any) => a.value === element.area_code);
                if (area) {
                  this.resDataAreas.push(area);
                }
              });
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getAreas(): any {
      return new Promise((resolve, reject) => {
        this.areaList = [];
        this.$http.get(Config.AREAS_API)
          .then((response: any) => {
            if (response.body.areas.length > 0) {
              response.body.areas.forEach(async (element: any, key: any) => {
                this.areaList.push({
                  text: element.name,
                  value: element.code,
                });
              });
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getOffice(): any {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.OFFICES_LIST_API)
          .then((response: any) => {
            if (response.body.offices.length > 0) {
              response.body.offices.forEach(async (element: any, key: any) => {
                this.resDataOffice.push(
                  {
                    text: element.name,
                    value: element.code,
                  },
                );
              });
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getGates(ID: any): any {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.GATES_API + '?area_code=' + ID + '&is_status=false')
          .then((response: any) => {
            if (response.body.gates.length > 0) {
              response.body.gates.forEach(async (element: any, key: any) => {
                this.resDataGates.push(
                  {
                    text: element.name,
                    value: element.code,
                  },
                );
              });
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getGatesItem(ID: any): any {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.GATES_API + '?area_code=' + ID + '&is_status=false')
          .then((response: any) => {
            if (response.body.gates.length > 0) {
              response.body.gates.forEach(async (element: any, key: any) => {
                this.resDataGateItem.push(
                  {
                    text: element.name,
                    value: element.code,
                  },
                );
              });
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getListCard(ID: any): any {
      return new Promise((resolve, reject) => {
        const apiLink = Config.CARD_LIST_API + '/' + ID;
        this.$http.get(apiLink)
          .then((response: any) => {
            this.resCardData = response.body.cards;
            resolve(true);
          })
          .then( null, (err: any) => {
            this.isError = true;
            alert(Config.ERROR_MESSAGE_FOR_CARD_LIST_FAILED);
            resolve(false);
          }); // catch
      });
    },
  },
});
