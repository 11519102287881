var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "pa-6 pr-10 mb-5" },
        [
          _vm.priviledge <= "0"
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "3", lg: "3" } },
                    [
                      _c("div", { staticClass: "v-subheader theme--light" }, [
                        _c("span", [_vm._v(_vm._s(_vm.resData.admin))]),
                      ]),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 align-self-center",
                      attrs: { cols: "12", sm: "9", md: "9", lg: "9" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple",
                              value: false,
                              expression: "false",
                            },
                          ],
                          staticClass: "allig-btn-text-link",
                          attrs: { block: "", type: "button", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.downLoad(
                                "/manual/システム管理者向けマニュアル.pdf",
                                "システム管理者向けマニュアル"
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.configData.TEXT_CLICK_TO_DOWNLOAD) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.priviledge <= "1"
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "3", lg: "3" } },
                    [
                      _c("div", { staticClass: "v-subheader theme--light" }, [
                        _c("span", [_vm._v(_vm._s(_vm.resData.operation))]),
                      ]),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 align-self-center",
                      attrs: { cols: "12", sm: "9", md: "9", lg: "9" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple",
                              value: false,
                              expression: "false",
                            },
                          ],
                          staticClass: "allig-btn-text-link",
                          attrs: { block: "", type: "button", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.downLoad(
                                "/manual/運用管理者向けマニュアル.pdf",
                                "運用管理者向けマニュアル"
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.configData.TEXT_CLICK_TO_DOWNLOAD) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.priviledge <= "2"
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "3", lg: "3" } },
                    [
                      _c("div", { staticClass: "v-subheader theme--light" }, [
                        _c("span", [_vm._v(_vm._s(_vm.resData.applicants))]),
                      ]),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 align-self-center",
                      attrs: { cols: "12", sm: "9", md: "9", lg: "9" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple",
                              value: false,
                              expression: "false",
                            },
                          ],
                          staticClass: "allig-btn-text-link",
                          attrs: { block: "", type: "button", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.downLoad(
                                "/manual/申請承認者向けマニュアル.pdf",
                                "申請承認者向けマニュアル"
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.configData.TEXT_CLICK_TO_DOWNLOAD) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.priviledge <= "3"
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "3", lg: "3" } },
                    [
                      _c("div", { staticClass: "v-subheader theme--light" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.resData.room_entry_applicants)),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 align-self-center",
                      attrs: { cols: "12", sm: "9", md: "9", lg: "9" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple",
                              value: false,
                              expression: "false",
                            },
                          ],
                          staticClass: "allig-btn-text-link",
                          attrs: { block: "", type: "button", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.downLoad(
                                "/manual/入室申請者向けマニュアル.pdf",
                                "入室申請者向けマニュアル"
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.configData.TEXT_CLICK_TO_DOWNLOAD) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }