
import Vue, { PropType } from 'vue';
import Searchbox,  {SearchTargetItem} from '@/components/molecules/Searchbox/Searchbox.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';
import auth from '@/auth';
export interface TypeItem {
  title: string;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  showOption: boolean;
  isVaildOnly: boolean;
  typeSearch: any;
  applicantSelected: [];
  configData: any;
  loadPage: number;
}

export default Vue.extend({
  name: 'EntranceHistoryList',
  components: {
    Searchbox,
    ShowPerPage,
    Pagination,
    SelectPerPage,
    DisplayOption,
  },
  watch: {
    selectedDisplayOption(value) {
      sessionStorage.setItem('displayOptionEntranceHis', JSON.stringify(value));
    },
  },
  props: {
    resData: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    applicantTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 100,
    },
    loadMore: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    isFinishProcessEnable: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    //
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: this.configData.TEXT_DATE_AND_TIME,
            align: 'start',
            sortable: true,
            value: 'timestamp',
            width: 'auto',
          },
        ];
        if (this.selectedDisplayOption.includes('message')) {
          headers.push({
            text:  this.configData.TEXT_MESSAGE,
            align: 'start',
            sortable: true,
            value: 'message',
            width: '400px',
          });
        }
        if (this.selectedDisplayOption.includes('area_name')) {
          headers.push({
            text:  this.configData.TEXT_AREA,
            align: 'start',
            sortable: true,
            value: 'area_name',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('gate_name')) {
          headers.push({
            text:  this.configData.TEXT_GATE,
            align: 'start',
            sortable: true,
            value: 'gate_name',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('user_id')) {
          headers.push({
            text: this.configData.TEXT_USER_ID,
            align: 'start',
            sortable: true,
            value: 'user_id',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('user_name')) {
          headers.push({
            text: this.configData.TEXT_USER_NAME,
            align: 'start',
            sortable: true,
            value: 'user_name',
            width: 'auto',
          });
        }
        console.log(headers);
        return headers;
      },
      set(): void {
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', val);
        return;
      },
    },
  },
  data: (): DataType => ({
    selectedDisplayOption: [
      'timestamp',
      'message',
      'area_name',
      'gate_name',
      'user_id',
      'user_name',
    ],
    displayOptionItems: [
      { label:  Config.TEXT_DATE_AND_TIME, value: 'timestamp', isDisabled: true },
      { label: Config.TEXT_MESSAGE, value: 'message' },
      { label: Config.TEXT_AREA, value: 'area_name' },
      { label: Config.TEXT_GATE, value: 'gate_name' },
      { label:  Config.TEXT_USER_ID, value: 'user_id' },
      { label:  Config.TEXT_USER_NAME, value: 'user_name' },
    ],
    applicantSelected: [],
    showOption: false,
    isVaildOnly: false,
    typeSearch: 0,
    configData: Config,
    loadPage: 1,
  }),
  methods: {
    currentItems(val: any) {
      this.$emit('get-current-items', val);
    },
    countPage() {
      this.innerPage =  this.innerPage + 1;
    },
    converDateShort(t: any) {
      return auth.converDateShort(t);
    },
    CheckColumn(data: any) {
      this.$emit('change-column');
      sessionStorage.setItem('displayOptionEntranceHis', JSON.stringify(data));
    },
  },
});
