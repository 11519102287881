
import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import EmailSettingsForm from '@/components/organisms/EmailSettingsForm/EmailSettingsForm.vue';
import * as Config from '../../../config';
export default Vue.extend({
  name: 'EmailSettingsTemplate',
  components: {
    EmailSettingsForm,
    Breadcrumbs,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    emailSettings: {
      default: {},
    },
    domainItems: {
      default: () => [],
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: Config.TEXT_EMAIL_SETTINGS, disabled: true },
        ];
      },
    },
  },
  methods: {
    //
  },
});
