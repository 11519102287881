var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-container", [
    _c(
      "div",
      [
        _c(
          "v-row",
          {
            staticClass: "mt-3 border-bottom-bold",
            attrs: {
              "no-gutters": "",
              justify: "space-between",
              "align-content": "center",
            },
          },
          [
            _c("show-per-page", {
              staticClass: "ml-8 mb-3",
              attrs: {
                "per-page": _vm.approverTotalCount,
                page: 1,
                "items-length": _vm.approverTotalCount,
              },
            }),
            _c("v-spacer"),
            _c(
              "div",
              {
                staticClass:
                  "text-caption display-option included-option text-sm-caption text-md-caption text-lg-caption text-xl-caption mr-8",
              },
              [
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple",
                        value: false,
                        expression: "false",
                      },
                    ],
                    staticClass: "allig-btn-text-link-gray allig-btn-text-link",
                    attrs: { block: "", type: "button", text: "" },
                    on: {
                      click: () => {
                        _vm.showOption = !_vm.showOption
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "display-option-title" }, [
                      _vm._v(
                        " " + _vm._s(_vm.configData.TEXT_DISPLAY_OPTIONS) + " "
                      ),
                    ]),
                    _c(
                      "span",
                      [
                        _c("v-icon", { attrs: { dense: "" } }, [
                          _vm._v("mdi-chevron-down"),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          { staticClass: "pt-0 pb-0 mt-0" },
          [
            _c(
              "v-container",
              { staticClass: "pt-0" },
              [
                _c("display-option", {
                  attrs: {
                    isShowOption: _vm.showOption,
                    "display-option-items": _vm.displayOptionItems,
                    isVaildOnly: _vm.isVaildOnly,
                  },
                  on: {
                    "show-only-isVaild": function ($event) {
                      return _vm.$emit("show-only-isValid", $event)
                    },
                    input: function ($event) {
                      return _vm.CheckColumn($event)
                    },
                  },
                  model: {
                    value: _vm.selectedDisplayOption,
                    callback: function ($$v) {
                      _vm.selectedDisplayOption = $$v
                    },
                    expression: "selectedDisplayOption",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm.loading == false
          ? _c("v-data-table", {
              directives: [
                { name: "resizable-columns", rawName: "v-resizable-columns" },
              ],
              staticClass: "border-table scroll-table",
              attrs: {
                "must-sort": "",
                headers: _vm.headers,
                items: _vm.resData,
                "items-per-page": -1,
                "item-key": "user_id",
                "no-data-text": _vm.configData.TEXT_NO_DATA,
                "loading-text": _vm.configData.TEXT_LOADING_DATA,
                "hide-default-footer": "",
                "show-select": true,
                "sort-by": ["user_id"],
                "sort-desc": false,
                "item-class": _vm.row_classes,
              },
              on: {
                input: (item) => {
                  _vm.selected = item
                },
                "current-items": _vm.currentItems,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: `item.updated`,
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.converDateShort(item.updated)) + " "
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          : _vm._e(),
        _c("v-row", {
          staticStyle: { height: "20px" },
          attrs: { "no-gutters": "" },
        }),
        _c("v-spacer"),
        _c(
          "v-dialog",
          {
            attrs: { "max-width": "1000", persistent: "" },
            model: {
              value: _vm.isShowAddDialog,
              callback: function ($$v) {
                _vm.isShowAddDialog = $$v
              },
              expression: "isShowAddDialog",
            },
          },
          [
            _c(
              "v-card",
              { staticClass: "modal-add-edit-user" },
              [
                _c(
                  "v-card-title",
                  { staticClass: "modal-title", attrs: { color: "green" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.configData.TEXT_APPLICANT_APPROVER_NEW_REG) +
                        " "
                    ),
                  ]
                ),
                _c("v-divider"),
                _c(
                  "v-card",
                  { staticClass: "pa-5 pt-5 ma-5" },
                  [
                    _c(
                      "v-form",
                      { ref: "ApproverForm", attrs: { "lazy-validation": "" } },
                      [
                        _c(
                          "div",
                          { staticClass: "input-form" },
                          [
                            _c(
                              "v-row",
                              [
                                _vm.$vuetify.breakpoint.mdAndUp
                                  ? _c(
                                      "v-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c("v-subheader", [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.configData.TEXT_USER_ID +
                                                  `<span class='required'>` +
                                                  _vm.configData.TEXT_MUST +
                                                  `</span>`
                                              ),
                                            },
                                          }),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: _vm.$vuetify.breakpoint
                                                .mdAndUp
                                                ? 10
                                                : 12,
                                            },
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: _vm.$vuetify.breakpoint
                                                  .mdAndUp
                                                  ? ""
                                                  : _vm.configData
                                                      .TEXT_USER_ID +
                                                    _vm.configData.TEXT_MUST,
                                                color: "green",
                                                "hide-details": "auto",
                                                type: "text",
                                                outlined: "",
                                                dense: "",
                                                rules: [
                                                  _vm.rulesValidate
                                                    .requiredUserId,
                                                  _vm.rulesValidate.validateUserId(
                                                    _vm.userInfo.user_id,
                                                    _vm.isCheckUser,
                                                    _vm.error
                                                  ),
                                                ],
                                                autocomplete: "off",
                                              },
                                              on: {
                                                input: () =>
                                                  (_vm.isCheckUser = false),
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  return _vm.checkUserId()
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "append",
                                                    fn: function () {
                                                      return [
                                                        _vm._t("text-append"),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.userInfo.user_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.userInfo,
                                                    "user_id",
                                                    $$v
                                                  )
                                                },
                                                expression: "userInfo.user_id",
                                              },
                                            }),
                                            _vm.error !== "" &&
                                            _vm.isCheckUser === true
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "txt-error-user",
                                                  },
                                                  [_vm._v(_vm._s(_vm.error))]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "btn-primary",
                                                attrs: { outlined: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.checkUserId()
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.configData.TEXT_SEARCH
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("input-form", {
                          attrs: {
                            title: _vm.configData.TEXT_USER_NAME,
                            headerCols: 3,
                            disabled: true,
                            inputType: "text",
                            rules: [_vm.rulesValidate.validateName],
                          },
                          model: {
                            value: _vm.userInfo.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.userInfo, "name", $$v)
                            },
                            expression: "userInfo.name",
                          },
                        }),
                        _c("input-form", {
                          attrs: {
                            title: _vm.configData.TEXT_FURIGANA,
                            headerCols: 3,
                            inputType: "text",
                            disabled: true,
                            rules: [_vm.rulesValidate.validateFurigana],
                          },
                          model: {
                            value: _vm.userInfo.furigana,
                            callback: function ($$v) {
                              _vm.$set(_vm.userInfo, "furigana", $$v)
                            },
                            expression: "userInfo.furigana",
                          },
                        }),
                        _c("input-form", {
                          attrs: {
                            title: _vm.configData.TEXT_COL_EMAIL_ADDRESS,
                            headerCols: 3,
                            inputType: "text",
                            disabled: true,
                            rules: [_vm.rulesValidate.validateEmail],
                          },
                          model: {
                            value: _vm.userInfo.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.userInfo, "email", $$v)
                            },
                            expression: "userInfo.email",
                          },
                        }),
                        _c(
                          "v-row",
                          [
                            _vm.$vuetify.breakpoint.mdAndUp
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _vm.isEdit
                                      ? _c("v-subheader", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.configData.TEXT_DEPARTMENT
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _c("v-subheader", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.configData
                                                  .TEXT_COL_COMPANY_DEPARTMENT
                                              ) +
                                              " "
                                          ),
                                        ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-col",
                              [
                                _c("v-combobox", {
                                  staticClass: "list-department",
                                  attrs: {
                                    items: _vm.organizations,
                                    multiple: "",
                                    "deletable-chips": "",
                                    chips: "",
                                    disabled: true,
                                    autocomplete: "off",
                                    "hide-details": "auto",
                                    outlined: "",
                                    dense: "",
                                  },
                                  model: {
                                    value: _vm.userInfo.organizations,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.userInfo,
                                        "organizations",
                                        $$v
                                      )
                                    },
                                    expression: "userInfo.organizations",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _vm.$vuetify.breakpoint.mdAndUp
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _c("v-subheader", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.configData
                                              .TEXT_COL_MANAGEMENT_OFFICE
                                          ) + " "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "required" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.configData.TEXT_MUST)
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-col",
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    "return-object": "",
                                    items: _vm.dataOffice,
                                    disabled: _vm.isApprover,
                                    autocomplete: "off",
                                    "hide-details": "auto",
                                    outlined: "",
                                    dense: "",
                                    "no-data-text": "",
                                    rules: [
                                      _vm.rulesValidate.validateOfficeCode(
                                        _vm.isApprover,
                                        _vm.userInfo.office_code,
                                        _vm.isCheckUser
                                      ),
                                    ],
                                  },
                                  on: {
                                    change: () => (_vm.isCheckUser = false),
                                  },
                                  model: {
                                    value: _vm.userInfo.office_code,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.userInfo, "office_code", $$v)
                                    },
                                    expression: "userInfo.office_code",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("input-form", {
                          attrs: {
                            title: _vm.configData.TEXT_SUPPLEMENT,
                            headerCols: 3,
                            inputType: "text",
                            rules: [_vm.rulesValidate.validateRemarks],
                          },
                          model: {
                            value: _vm.userInfo.memo,
                            callback: function ($$v) {
                              _vm.$set(_vm.userInfo, "memo", $$v)
                            },
                            expression: "userInfo.memo",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "mb-2 mt-2 mr-0",
                        attrs: { justify: "end" },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-secondary",
                            attrs: { outlined: "" },
                            on: {
                              click: function ($event) {
                                return _vm.closeAddDialog()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.configData.TEXT_CANCEL) + " "
                            ),
                          ]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-primary",
                            attrs: { outlined: "", disabled: _vm.isDisabled },
                            on: {
                              click: function ($event) {
                                return _vm.clickAddApprover()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.configData.TEXT_REGISTER) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-dialog",
          {
            attrs: { persistent: "", "max-width": "600" },
            model: {
              value: _vm.deleteApproverSetting,
              callback: function ($$v) {
                _vm.deleteApproverSetting = $$v
              },
              expression: "deleteApproverSetting",
            },
          },
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  { staticClass: "modal-title", attrs: { color: "black" } },
                  [_vm._v(" " + _vm._s(_vm.configData.TEXT_CONFIRMATION) + " ")]
                ),
                _c(
                  "v-card-text",
                  { staticClass: "mt-5" },
                  [
                    _c("div", [
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.configData.TEXT_REQUEST_DELETE_APPROVER)
                        ),
                      ]),
                    ]),
                    _c("v-row", {
                      staticStyle: { height: "20px" },
                      attrs: { "no-gutters": "" },
                    }),
                    _c("v-spacer"),
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  [
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { justify: "end" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-secondary",
                            attrs: { outlined: "" },
                            on: {
                              click: function ($event) {
                                return _vm.closeDeleteDialog()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.configData.TEXT_RETURN))]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-primary",
                            attrs: { outlined: "" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteApprover()
                              },
                            },
                          },
                          [_vm._v("OK")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }