var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "suika-logs-template" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _c("v-container", [
        _c(
          "div",
          { staticClass: "container-user" },
          [
            _c(
              "div",
              { staticClass: "round-action" },
              [
                _c(
                  "v-row",
                  { staticClass: "btn-div", attrs: { justify: "start" } },
                  [
                    _c("v-col", [
                      _c(
                        "div",
                        { staticClass: "area-search-box" },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "space-between",
                              },
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("searchbox", {
                                    staticClass: "input-search",
                                    attrs: {
                                      searchTargetItems: _vm.searchTargetItems,
                                      isFourItemsPerRow: false,
                                      isCSV: true,
                                      logPage: true,
                                      showTimePicker: true,
                                    },
                                    on: {
                                      search: function ($event) {
                                        return _vm.$emit("search-log", $event)
                                      },
                                      export: function ($event) {
                                        return _vm.$emit("export")
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.loadingSearch === false
              ? _c("log-list", {
                  attrs: {
                    loading: _vm.loading,
                    resData: _vm.resData,
                    logTotalCount: _vm.logTotalCount,
                  },
                  on: {
                    "change-column": function ($event) {
                      return _vm.$emit("change-column", $event)
                    },
                    "get-current-items": function ($event) {
                      return _vm.$emit("get-current-items", $event)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }