var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "add-applicant" },
    [
      _c(
        "v-form",
        { ref: "addForm", attrs: { "lazy-validation": "" } },
        [
          _vm.isAction === 0
            ? _c(
                "v-row",
                { staticClass: "pl-2 mb-2 mt-2", attrs: { justify: "start" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-secondary",
                      attrs: { outlined: "" },
                      on: {
                        click: function ($event) {
                          return _vm.goBack()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.configData.TEXT_CANCEL) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-primary",
                      attrs: {
                        outlined: "",
                        disabled: _vm.isDisabledSubEntry || !_vm.isValidGates,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.subEntry()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.configData.TEXT_APPLICATION) + " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-primary",
                      attrs: { outlined: "", disabled: _vm.isDisabledAdd },
                      on: {
                        click: function ($event) {
                          return _vm.saveEntry()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.configData.TEXT_KEEP) + " ")]
                  ),
                ],
                1
              )
            : _c(
                "v-row",
                { staticClass: "pl-2 mb-2 mt-2", attrs: { justify: "start" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-secondary",
                      attrs: { outlined: "" },
                      on: {
                        click: function ($event) {
                          return _vm.goBack()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.configData.TEXT_CLOSE_UP) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-primary",
                      attrs: {
                        outlined: "",
                        disabled:
                          _vm.isDisabledSubEntry ||
                          !_vm.isValidGates ||
                          _vm.isError,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.reSubEntry()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.configData.TEXT_APPLICATION) + " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-primary",
                      attrs: { outlined: "", disabled: _vm.isDisabledAdd },
                      on: {
                        click: function ($event) {
                          return _vm.updateEntry()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.configData.TEXT_KEEP) + " ")]
                  ),
                ],
                1
              ),
          _c(
            "v-container",
            { staticClass: "pl-0 pr-0" },
            [
              _c(
                "v-card",
                { staticClass: "pa-6 pr-10 mb-5" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c("v-subheader", [
                            _vm._v(
                              _vm._s(_vm.configData.TEXT_ADMISSION_APPLICANTS)
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-body-2",
                          class: {
                            "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown,
                          },
                          attrs: {
                            cols: "12",
                            md: "10",
                            "align-self": "center",
                          },
                        },
                        [_vm._v(_vm._s(_vm.dataItem.requester_name))]
                      ),
                    ],
                    1
                  ),
                  _vm.isAction === 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "2" } },
                                [
                                  _c("v-subheader", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.configData
                                          .TEXT_COL_APPLICATION_NUMBER
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-body-2",
                                  class: {
                                    "pl-10 pt-0":
                                      _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                  attrs: {
                                    cols: "12",
                                    md: "10",
                                    "align-self": "center",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.dataItem.request_id))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "2" } },
                                [
                                  _c("v-subheader", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.configData.TEXT_DATE_APPLICATION
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-body-2",
                                  class: {
                                    "pl-10 pt-0":
                                      _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                  attrs: {
                                    cols: "12",
                                    md: "10",
                                    "align-self": "center",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.converDateShort(_vm.dataItem.date)
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "2" } },
                                [
                                  _c("v-subheader", [
                                    _vm._v(
                                      _vm._s(_vm.configData.TEXT_UPDATE_DATE)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-body-2",
                                  class: {
                                    "pl-10 pt-0":
                                      _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                  attrs: {
                                    cols: "12",
                                    md: "10",
                                    "align-self": "center",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.converDateShort(_vm.dataItem.updated)
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "2" } },
                                [
                                  _c("v-subheader", [
                                    _vm._v(
                                      _vm._s(_vm.configData.TEXT_COL_STATUS)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-body-2",
                                  class: {
                                    "pl-10 pt-0":
                                      _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                  attrs: {
                                    cols: "12",
                                    md: "10",
                                    "align-self": "center",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.convertStatus(_vm.dataItem.status)
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c("v-subheader", [
                            _vm._v(_vm._s(_vm.configData.TEXT_CARD)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-body-2",
                          class: {
                            "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown,
                          },
                          attrs: {
                            cols: "12",
                            md: "10",
                            "align-self": "center",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.resCardData.masked_idi1) +
                              " " +
                              _vm._s(_vm.resCardData.masked_idi2) +
                              " " +
                              _vm._s(_vm.resCardData.masked_idi3) +
                              " "
                          ),
                          _vm.loading === false &&
                          _vm.isCheckCard(_vm.resCardData)
                            ? _c("span", { staticClass: "bg-error no-bg" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.configData.TEXT_ERROR_CARD_REG) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c("v-subheader", [
                            _vm._v(_vm._s(_vm.configData.TEXT_COL_BEGIN_DATE)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "8", md: "2", sm: "4", xs: "12" } },
                        [
                          _c("DatePicker", {
                            attrs: {
                              value: _vm.dataItem.begin,
                              maxDate: _vm.startMaxDate,
                            },
                            on: { "get-date": _vm.getTimeStart },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "col-zone",
                          attrs: { cols: "7", md: "1", sm: "1", xs: "12" },
                        },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              "return-object": "",
                              items: _vm.dataSpecialStart,
                              autocomplete: "off",
                              "hide-details": "auto",
                              outlined: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.specialStart,
                              callback: function ($$v) {
                                _vm.specialStart = $$v
                              },
                              expression: "specialStart",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c("v-subheader", [
                            _vm._v(_vm._s(_vm.configData.TEXT_COL_END_DATE)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "8", md: "2", sm: "4", xs: "12" } },
                        [
                          _c("DatePicker", {
                            attrs: {
                              value: _vm.dataItem.end,
                              minDate: _vm.endMinDate,
                            },
                            on: { "get-date": _vm.getTimeEnd },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "col-zone",
                          attrs: { cols: "7", md: "1", sm: "1", xs: "12" },
                        },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              "return-object": "",
                              items: _vm.dataSpecialEnd,
                              autocomplete: "off",
                              "hide-details": "auto",
                              outlined: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.specialEnd,
                              callback: function ($$v) {
                                _vm.specialEnd = $$v
                              },
                              expression: "specialEnd",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c("v-subheader", [
                            _vm._v(
                              _vm._s(_vm.configData.TEXT_COL_MANAGEMENT_OFFICE)
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-body-2",
                          attrs: {
                            cols: "12",
                            md: "10",
                            "align-self": "center",
                          },
                        },
                        [
                          _c("v-combobox", {
                            staticClass: "list-department",
                            attrs: {
                              items: _vm.resDataOffice,
                              autocomplete: "off",
                              "hide-details": "auto",
                              outlined: "",
                              dense: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeOffice()
                              },
                            },
                            model: {
                              value: _vm.dataItem.office_code,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataItem, "office_code", $$v)
                              },
                              expression: "dataItem.office_code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c("v-subheader", [
                            _vm._v(_vm._s(_vm.configData.TEXT_AREA)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-body-2",
                          attrs: {
                            cols: "12",
                            md: "10",
                            "align-self": "center",
                          },
                        },
                        [
                          _c("v-combobox", {
                            staticClass: "list-department",
                            attrs: {
                              items: _vm.resDataAreas,
                              autocomplete: "off",
                              color: "green",
                              "hide-details": "auto",
                              outlined: "",
                              dense: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeArea()
                              },
                            },
                            model: {
                              value: _vm.dataItem.area_code,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataItem, "area_code", $$v)
                              },
                              expression: "dataItem.area_code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c("v-subheader", [
                            _vm._v(_vm._s(_vm.configData.TEXT_GATE)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-body-2",
                          attrs: {
                            cols: "12",
                            md: "10",
                            "align-self": "center",
                          },
                        },
                        [
                          _c("v-combobox", {
                            staticClass: "list-department",
                            attrs: {
                              items: _vm.resDataGates,
                              autocomplete: "off",
                              color: "green",
                              "hide-details": "auto",
                              multiple: "",
                              "deletable-chips": "",
                              chips: "",
                              outlined: "",
                              dense: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.checkChangedGates()
                              },
                            },
                            model: {
                              value: _vm.dataItem.gates,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataItem, "gates", $$v)
                              },
                              expression: "dataItem.gates",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("input-form", {
                    attrs: {
                      title: _vm.configData.TEXT_WORK_CONTENT,
                      inputType: "area",
                      areaRows: _vm.areaRows,
                      rules: [_vm.rulesValidate.description],
                    },
                    model: {
                      value: _vm.dataItem.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataItem, "description", $$v)
                      },
                      expression: "dataItem.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.confirmDialog,
            callback: function ($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "modal-title", attrs: { color: "black" } },
                [_vm._v(" " + _vm._s(_vm.configData.TEXT_CONFIRMATION) + " ")]
              ),
              _c(
                "v-card-text",
                { staticClass: "mt-5" },
                [
                  _c("div", [
                    _vm.endTime > _vm.currentTime
                      ? _c("div", [
                          _c("p", { staticClass: "error-text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.configData
                                  .TEXT_ERROR_WORK_PERIOD_EXPIRED_RETURNED_APPLICATION_TOP
                              )
                            ),
                          ]),
                          _c("p", { staticClass: "error-text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.configData
                                  .TEXT_ERROR_WORK_PERIOD_EXPIRED_RETURNED_APPLICATION_BOTTOM
                              )
                            ),
                          ]),
                        ])
                      : _c("div", [
                          _c("p", { staticClass: "pb-0 mb-0" }, [
                            _vm._v(
                              _vm._s(
                                _vm.configData.TEXT_REQUEST_SUBMIT_APPLICATION
                              )
                            ),
                          ]),
                          _c("p", { staticClass: "app-more" }, [
                            _vm._v(
                              _vm._s(
                                _vm.configData
                                  .TEXT_REQUEST_SUBMIT_APPLICATION_MORE
                              )
                            ),
                          ]),
                        ]),
                  ]),
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _c("v-textarea", {
                    staticClass: "input-time input-comment",
                    attrs: {
                      color: "green",
                      outlined: "",
                      dense: "",
                      solo: "",
                      "hide-details": "auto",
                      autocomplete: "off",
                      maxlength: 240,
                      rows: "6",
                      disabled: _vm.endTime > _vm.currentTime,
                      label: _vm.configData.TEXT_LABEL_COMMENT,
                    },
                    model: {
                      value: _vm.comment,
                      callback: function ($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment",
                    },
                  }),
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _c("v-spacer"),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.closeConfirmDialog()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.configData.TEXT_RETURN))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: {
                            outlined: "",
                            disabled: _vm.endTime > _vm.currentTime,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sendSubmissions()
                            },
                          },
                        },
                        [_vm._v("OK")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }