
import Vue, { PropType } from 'vue';
import { Auth, Hub } from 'aws-amplify';
import * as Config from '@/config';
import LoginTemplate from '@/components/templates/LoginTemplate/LoginTemplate.vue';
import { LoginInfo } from '@/types/alligate';
import auth from '@/auth';

export interface DataType {
  credentials: { ownerId: string; userId: string; password: string; mfaCode: string,
  resetCode: string, confirmPassword: string };
  loginProcess: number;
  error: string;
  mfaCode: string;
  loading: boolean;
  errorMessage: any;
}

export default Vue.extend({
  name: 'LoginPage',
  components: {
    LoginTemplate,
  },
  props: {
    step: {
      type: Number,
      default: 0,
    },
  },
  data: (): DataType => ({
    credentials: {
      ownerId: '',
      userId: '',
      password: '',
      mfaCode: '',
      confirmPassword: '',
      resetCode: '',
    },
    loginProcess: 0,
    error: '',
    mfaCode: '',
    loading: false,
    errorMessage: {
      ownerId: '',
      userId: '',
      password: '',
      mfaCode: '',
      resetCode: '',
      confirmPassword: '',
    },
  }),
  mounted() {
    this.loginProcess = this.step;
    const getCode = this.$route.query.status;
    if (getCode !== '' && getCode !== undefined) {
      this.errorMessage.password = Config.ERROR_LOGIN_DEFAULT;
    }
  },
  created() {
    // ($('.main-content') as any).append('<div id="loading"><div class="loadingMsg"></div></div>');
    auth.clearAllData();
    const dataQuery = this.$route.query;
    if (dataQuery.kanriId !== undefined) {
      this.credentials.ownerId = dataQuery.kanriId.toString();
    }
    if (dataQuery.userId !== undefined) {
      this.credentials.userId = dataQuery.userId.toString();
    }
  },
  methods: {
    goPageLogin() {
      console.log('AAA');
      this.errorMessage = {
        ownerId: '',
        userId: '',
        password: '',
        mfaCode: '',
        resetCode: '',
        confirmPassword: '',
      };
      this.credentials = {
      ownerId: '',
      userId: '',
      password: '',
      mfaCode: '',
      confirmPassword: '',
      resetCode: '',
      };
      this.loginProcess = 0;
    },
    forgotPassword(data: LoginInfo) {
      this.credentials = {
        ownerId: '',
        userId: '',
        password: '',
        mfaCode: '',
        confirmPassword: '',
        resetCode: '',
      };
      this.loginProcess = 4;
    },
    formSubmitForgot(data: LoginInfo) {
      this.validateBeforeSubmit(4, data);
    },
    formSubmit(data: LoginInfo) {
      this.validateBeforeSubmit(3, data);
    },
    signIn(data: LoginInfo) {
      if (this.validateBeforeSubmit(3, data)) {
        this.messageHandlerSignIn(data);
      }
    },
    forgot(data: LoginInfo) {
      this.credentials = {
        ownerId: '',
        userId: '',
        password: '',
        mfaCode: '',
        confirmPassword: '',
        resetCode: '',
      };
      if (this.validateBeforeSubmit(4, data)) {
        this.loading = true;
        const credentials = {
          owner_id: data.ownerId,
          user_id: data.username,
        };
        sessionStorage.setItem('ownerId', data.ownerId);
        this.$http.post(Config.RESET_CODE_API, JSON.stringify(credentials))
        .then((response: any) => {
          if (response.body.message === 'Success') {
            this.loading = false;
            // this.message = Config.FORGOT_API_SUCCESS_SEND_RESET_CODE
            // owserIdとuserIdの値をlocalstorageに保存する
            sessionStorage.setItem('ownerId', credentials.owner_id);
            sessionStorage.setItem('userId', credentials.user_id);
            alert(Config.ALERT_RESET);
            this.loginProcess = 5;
            this.credentials.password = '';
          }
        })
        .then( null, (err: any) => {
          this.loading = false;
          this.errorMessage.ownerId = Config.ERROR_API_OWNER_ID;
        });
      }
    },
    resetPass(data: any) {
      this.credentials = {
        ownerId: '',
        userId: '',
        password: '',
        mfaCode: '',
        confirmPassword: '',
        resetCode: '',
      };
      this.error = '';
      if (this.validateBeforeSubmit(5, data.credentials)) {
        this.loading = true;
        const credentials = {
          owner_id: data.credentials.ownerId,
          user_id: data.username,
          reset_code: data.credentials.resetCode,
          password: data.credentials.password,
        };
        sessionStorage.setItem('ownerId', data.credentials.ownerId);
        console.log('data', data);
        this.$http.post(Config.RESET_PASSWORD_API, JSON.stringify(credentials))
        .then((response: any) => {
          if (response.body.message === 'Success') {
            this.loading = false;
            // this.message = Config.FORGOT_API_SUCCESS_SEND_RESET_CODE
            // owserIdとuserIdの値をlocalstorageに保存する
            sessionStorage.setItem('ownerId', credentials.owner_id);
            sessionStorage.setItem('userId', credentials.user_id);
            alert(Config.ALERT_RESET_SUCC);
            auth.logout(true);
          }
        })
        .then( null, (err: any) => {
          this.loading = false;
          switch (err.status) {
            case 400:
            case 401:
            case 403:
            case 404:
            case 500:
            case 503:
            default:
              this.error = Config.ERROR_LOGIN_INFO_MISMATCH;
              break;
          }
        });
      }
    },
    messageHandlerSignIn(infoUser: LoginInfo) {
      // this.dispLoading();
      sessionStorage.setItem('signIn', 'true');
      sessionStorage.setItem('ownerId', infoUser.ownerId);
      this.loading = true;
      const data = {
        owner_id: infoUser.ownerId,
        user_id: infoUser.username,
        password: infoUser.password,
      };
      this.$http.post(Config.LOGIN_API, data)
        .then((response: any) => {
          if (response.body.message === 'Success') {
            auth.saveCurrentAuthenticatedUser(response.body.id_token, response.body.refresh_token);
            sessionStorage.setItem('user_login_id', infoUser.username);
          }
        })
        .then( null, (err: any) => {
          this.loading = false;
          switch (err.status) {
            case 403:
              if (err.body.Error === 'NEW PASSWORD REQUIRED') {
                sessionStorage.setItem('newPasswordRequired', '1');
                sessionStorage.setItem('ownerId', infoUser.ownerId);
                sessionStorage.setItem('userId', infoUser.username);
                this.$router.push({ path: Config.CHANGE_INITIAL_PASSWORD});
                // window.location.href = window.location.origin + '#' + Config.CHANGE_INITIAL_PASSWORD;
              }
              break;
            case 400:
            case 401:
            case 404:
            case 500:
            case 503:
            default:
              this.errorMessage.password = Config.ERROR_LOGIN_INFO_MISMATCH;
              break;
          }
        }); // catch
    },
    validateBeforeSubmit(loginProcess: number, data: any) {
      switch (loginProcess) {
        case 1:
          if (data.ownerId === null || data.ownerId === '') {
            this.errorMessage.ownerId = Config.ERROR_OWNER_ID;
            return false;
          }
          this.errorMessage.ownerId = '';
          return true;
        case 2:
          if (data.mfaCode === null || data.mfaCode === '') {
            this.errorMessage.mfaCode = Config.ERROR_MFACODE;
            return false;
          } else {
            this.errorMessage.mfaCode = '';
            return true;
          }
        case 3:
          let check = true;
          if (data.ownerId === null || data.ownerId === '') {
            this.errorMessage.ownerId = Config.ERROR_OWNER_ID;
            check = false;
          } else {
            const pattern = /^[a-z\d\s]{3,31}$/i;
            if (pattern.test(data.ownerId)) {
              this.errorMessage.ownerId = '';
            } else {
              this.errorMessage.ownerId = Config.ERROR_OWNER_ID_LENGTH;
              check = false;
            }
          }
          if (data.username === null || data.username === '') {
            this.errorMessage.userId = Config.ERROR_USER_ID;
            check = false;
          } else {
            const pattern = /^[a-z\d\-_\s]{4,80}$/i;
            if (pattern.test(data.username)) {
              this.errorMessage.userId = '';
            } else {
              this.errorMessage.userId = Config.ERROR_USER_ID_LENGTH;
              check = false;
            }
          }
          if (data.password === null || data.password === '') {
            this.errorMessage.password = Config.ERROR_PASSWORD;
            check = false;
          } else {
            if (data.password.match('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$')) {
              this.errorMessage.password = '';
            } else {
              this.errorMessage.password = Config.ERROR_PASSWORD_REG;
              check = false;
            }
          }
          return check;
        case 4:
          let checkF = true;
          if (data.ownerId === null || data.ownerId === '') {
            this.errorMessage.ownerId = Config.ERROR_OWNER_ID;
            checkF = false;
          } else {
            this.errorMessage.ownerId = '';
          }
          if (data.username === null || data.username === '') {
            this.errorMessage.userId = Config.ERROR_USER_ID;
            checkF = false;
          } else {
            this.errorMessage.userId = '';
          }
          return checkF;
        case 5:
          let checkR = true;
          if (data.password === null || data.password === '') {
            this.errorMessage.password = Config.ERROR_PASSWORD;
            checkR = false;
          } else if (!data.password.match('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$')) {
            this.errorMessage.password = Config.ERROR_PASSWORD_REG;
            checkR = false;
          } else {
            this.errorMessage.password = '';
          }
          return checkR;
        default:
          return true;
      }
    },
    dispLoading() {
      // $('.main-content').append('<div id="loading"><div class="loadingMsg"></div></div>')
    },
  },
});
