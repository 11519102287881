var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("change-initial-password-template", {
    attrs: {
      loading: _vm.loading,
      errorMessage: _vm.errorMessage,
      error: _vm.error,
    },
    on: {
      formSubmitChangePassword: function ($event) {
        return _vm.formSubmitChangePassword($event)
      },
      changeInitialPassword: function ($event) {
        return _vm.changeInitialPassword($event)
      },
      goPageLogin: function ($event) {
        return _vm.goPageLogin()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }