var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("approval-status-template", {
    attrs: {
      loading: _vm.loading,
      resData: _vm.resData,
      dataArea: _vm.dataArea,
      dataOffice: _vm.dataOffice,
      total: _vm.total,
      loadMore: _vm.loadMore,
      loadingSearch: _vm.loadingSearch,
      openExtensions: _vm.isExtensions,
      page: _vm.page,
      perPage: _vm.perPage,
      resExtData: _vm.resExtData,
      confirmDialogFinish: _vm.confirmDialogFinish,
    },
    on: {
      search: function ($event) {
        return _vm.search($event)
      },
      "change-column": function ($event) {
        return _vm.changeColumn($event)
      },
      export: function ($event) {
        return _vm.exportCSV($event)
      },
      "change-per-page": function ($event) {
        return _vm.changePerPage($event)
      },
      "change-page": function ($event) {
        return _vm.changePage($event)
      },
      "change-office": function ($event) {
        return _vm.changeOffice($event)
      },
      "action-approval": function ($event) {
        return _vm.actionApproval($event)
      },
      "open-extensions": function ($event) {
        return _vm.openExtensions($event)
      },
      "close-dialog": function ($event) {
        return _vm.closeDialog($event)
      },
      "get-current-items": function ($event) {
        return _vm.getCurrentItems($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }