var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("office-list-template", {
    attrs: {
      loading: _vm.loading,
      isEdit: _vm.isEdit,
      dataOfficeCode: _vm.dataOfficeCode,
      dataOfficeName: _vm.dataOfficeName,
      officeList: _vm.officeList,
      loadingSearch: _vm.loadingSearch,
      officeTotalCount: _vm.officeTotalCount,
      showModalOfficeSettings: _vm.showModalOfficeSettings,
      areaList: _vm.areaList,
      isReload: _vm.isReload,
      loadingModify: _vm.loadingModify,
      officeInfo: _vm.officeInfo,
      domainItems: _vm.domainItems,
      isResetData: _vm.isResetData,
      confirmOfficeDialog: _vm.confirmOfficeDialog,
      confirmOfficeText: _vm.confirmOfficeText,
      deleteOfficeDialog: _vm.deleteOfficeDialog,
    },
    on: {
      "change-column": function ($event) {
        return _vm.changeColumn($event)
      },
      "search-office": function ($event) {
        return _vm.searchOffice($event)
      },
      createNewOffice: _vm.createNewOffice,
      clickEditOffice: function ($event) {
        return _vm.clickEditOffice($event)
      },
      saveOfficeSettings: function ($event) {
        return _vm.saveOfficeSettings($event)
      },
      updateOfficeSettings: function ($event) {
        return _vm.updateOfficeSettings($event)
      },
      closeOfficeSettingsDialog: _vm.closeOfficeSettingsDialog,
      deleteSelectedOffices: function ($event) {
        return _vm.deleteSelectedOffices($event)
      },
      export: function ($event) {
        return _vm.exportCSV($event)
      },
      showConfirmOfficeDialog: function ($event) {
        return _vm.showConfirmOfficeDialog($event)
      },
      closeConfirmOfficeDialog: function ($event) {
        return _vm.closeConfirmOfficeDialog()
      },
      showConfirmDeleteDialog: function ($event) {
        return _vm.showConfirmDeleteDialog()
      },
      closeDeleteOfficeDialog: function ($event) {
        return _vm.closeDeleteOfficeDialog()
      },
      "current-items": function ($event) {
        return _vm.currentItems($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }