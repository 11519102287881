
import {Vue } from 'vue-property-decorator';
import ApprovalTemplate from '@/components/templates/ApprovalTemplate/ApprovalTemplate.vue';
import * as Config from '@/config';
import auth from '@/auth';
import moment from 'moment';
export interface DataType {
  // Props
  isAction: number;
  loading: boolean;
  isRegistering: boolean;
  resData: any;
  resHisData: any;
  resDataGates: any;
  resCardData: any;
  listGates: any;
  request_id: string;
  resExtData: any;
  closeDialogExtention: boolean;
}
export default Vue.extend({
  name: 'ApplicantPage',
  components: {
   ApprovalTemplate,
  },
  data: (): DataType => ({
    // Props
    loading: false,
    isRegistering: false, // default: false -> reading data
    isAction: 0,
    resData: {},
    resHisData: [],
    request_id: '',
    resCardData: {},
    resDataGates: [],
    listGates: [],
    resExtData: {},
    closeDialogExtention: false,
  }),
  mounted() {
    this.$emit('activeMenu', 'approval-status');
    const dataQuery = this.$route.query;
    if (dataQuery.request_id !== undefined) {
      this.request_id = dataQuery.request_id.toString();
      this.loading = true;
      this.getListGate().then(() => {
        this.getData(dataQuery.request_id.toString());
      });
    }
  },
  created() {
    //
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
      if (value) {
        this.$emit('textCircle', this.isRegistering ? Config.TEXT_PROGRESS_REGISTERING : Config.TEXT_PROGRESS_READING);
      } else if (this.isRegistering) {
        this.isRegistering = false;
      }
    },
  },
  methods: {
    changeColumn(value: any): any {
      this.loading = true;
      setTimeout(() => {this.loading = false; }, 300);
    },
    openExtensions(args: any) {
      this.resExtData = {};
      const apiLink = Config.ENTRY_REQUESTS_API + '/' + this.$route.query.request_id + '/extensions';
      this.$http.get(apiLink)
        .then((response: any) => {
            if (response.body.extensions.length > 0) {
              const resExtData = response.body.extensions.slice(-1);
              this.resExtData = resExtData[0];
            }
          })
        .then( null, (err: any) => {
          console.log(err);
        }); // catch
    },
    actionApproval(args: any) {
      console.log(args);
      this.closeDialogExtention = false;
      this.loading = true;
      const dataQuery = this.$route.query;
      let data = {};
      data = {comment: args.data};
      if (args.action === 'cancellations') {
        args.action = 'rescissions';
      }
      let link;
      if (args.action === 'extensions') {
        link = this.$http.patch(Config.ENTRY_REQUESTS_API + '/' + args.request_id + '/' + args.action, args.data);
      } else {
        link = this.$http.post(Config.ENTRY_REQUESTS_API + '/' + dataQuery.request_id + '/' + args.action, data);
      }
      link.then((response: any) => {
        if (this.resData.status === 'submitted') {
          if (args.action === 'approvals') {
            alert(Config.TEXT_APPROVAL_COMPLETED);
          } else if (args.action === 'returns') {
            alert(Config.TEXT_RETURN_COMPLETED);
          }
        } else if (this.resData.status === 'approved') {
          if (args.action === 'rescissions') {
            alert(Config.TEXT_CANCELLATION_COMPLETED);
          } else if (args.action === 'extensions') {
            if (args.data.status === 'approved') {
              alert(Config.TEXT_EXT_APPROVAL_COMPLETED);
            } else {
              alert(Config.TEXT_EXT_DENIAL_COMPLETED);
            }
          }
        }
        this.getData(dataQuery.request_id);
        this.loading = false;
        this.closeDialogExtention = true;
        this.$router.push({ path: Config.APPROVAL_STATUS});
      })
      .then( null, (err: any) => {
        this.loading = false;
        if (this.resData.status === 'submitted') {
          if (args.action === 'approvals') {
            if (err.status === 400) {
              alert(Config.TEXT_APP_CONTENT_FAILED);
            } else if (err.status === 409) {
              alert(Config.TEXT_APP_STATE_FAILED);
            } else {
              alert(Config.TEXT_APPROVAL_FAILED);
            }
          } else if (args.action === 'returns') {
            alert(Config.TEXT_RETURN_FAILED);
          }
        } else if (this.resData.status === 'approved') {
          if (args.action === 'rescissions') {
            alert(Config.TEXT_CANCELLATION_FAILED);
          } else if (args.action === 'extensions') {
            if (args.data.status === 'approved') {
              if (err.status === 400) {
                alert(Config.TEXT_APP_CONTENT_FAILED);
              } else if (err.status === 409) {
                alert(Config.TEXT_APP_STATE_FAILED);
              } else {
                alert(Config.TEXT_EXT_APPROVAL_FAILED);
              }
            } else {
              alert(Config.TEXT_EXT_DENIAL_FAILED);
            }
          }
        }
      }); // catch
    },
    getExtensions(ID: any) {
      return new Promise((resolve, reject) => {
        this.resExtData = {};
        const apiLink = Config.ENTRY_REQUESTS_API + '/' + ID + '/extensions';
        this.$http.get(apiLink)
          .then((response: any) => {
            if (response.body.extensions.length > 0) {
              const resExtData = response.body.extensions.slice(-1);
              this.resExtData = resExtData[0];
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log(err);
            resolve(true);
          }); // catch
      });
    },
    getData(ID: any): any {
      this.loading = true;
      const apiLink = Config.ENTRY_REQUESTS_API + '/' + ID;
      this.$http.get(apiLink)
        .then(async (response: any) => {
          this.resData = response.body.request;
          this.resData.updater = sessionStorage.getItem('userProfileId');
          this.resData.begin = (moment(response.body.request.begin).add(15, 'minutes'));
          await this.getListCard(this.resData.requester_uid);
          await this.getOfficeDetail(this.resData.office_code);
          await this.getAreasDetail(this.resData.area_code);
          this.resData.listGate = '';
          const gates = this.listGates.filter((o1: { code: any; }) =>
          this.resData.gates.some((o2: { code: any; }) => o1.code === o2.code));
          if (gates.length > 0) {
            this.resData.listGates = gates.map((item: { name: any; }) => item.name).join(', ');
          }
          if (this.resData.requester_organizations.length > 0) {
            this.resData.organizations =
            this.resData.requester_organizations.map((item: { label: any; }) => item.label).join(', ');
          }
          await this.getExtensions(ID);
          this.getListHistoryData(ID);
          this.loading = false;
        })
        .then( null, (err: any) => {
          this.loading = false;
          alert(Config.TEXT_ENTRY_REQUESTS_LIST_FAILED);
        }); // catch
    },
    getAreasDetail(ID: any): any {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.AREAS_API + '?area_code=' + ID)
          .then((response: any) => {
            if (response.body.areas.length > 0) {
              const data = response.body.areas[0];
              this.resData.area_name = data.name;
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getOfficeDetail(ID: any): any {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.OFFICES_LIST_API + '?office_code=' + ID)
          .then((response: any) => {
            if (response.body.offices.length > 0) {
              const data = response.body.offices[0];
              this.resData.office_code = data.name;
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getListCard(ID: any): any {
      return new Promise((resolve, reject) => {
        const apiLink = Config.CARD_LIST_API + '/' + ID;
        this.$http.get(apiLink)
          .then((response: any) => {
            this.resCardData = response.body.cards;
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getListHistoryData(ID: any): any {
      this.resHisData = [];
      // this.resHisData = [];
      const apiLink = Config.ENTRY_REQUESTS_API + '/' + ID + '/histories';
      this.$http.get(apiLink)
        .then((response: any) => {
          this.resHisData = response.body.histories;
          if (response.body.histories.length > 0) {
            response.body.histories.forEach(async (element: any, key: any) => {
              element.organizations =
              element.organizations.map((item: { label: any; }) => item.label).join(', ');
            });
          }
        })
        .then( null, (err: any) => {
          alert(Config.TEXT_ERROR_FOR_APPLICATION_HISTORY);
        }); // catch
    },
    getUserItemForHistory(userId: any, key: any) {
      return new Promise((resolve, reject) => {
      const apiLink = Config.USERS_LIST_API + '/' + userId;
      this.$http.get(apiLink)
      .then((response: any) => {
        if (response.body.user !== null) {
          const res = response.body.user;
          this.resHisData[key].user_name = res.name;
          if (res.organizations.length > 0) {
            this.resHisData[key].organizations =
            res.organizations.map((item: { label: any; }) => item.label).join(', ');
          }
          resolve(true);
        }
      })
      .then(null, (err: any) => {
        this.resData[key].user_name = '';
        this.resData[key].organizations = '';
        resolve(false);
        });
      });
    },
    getUserItem(userId: any) {
      return new Promise((resolve, reject) => {
      const apiLink = Config.USERS_LIST_API + '?user_id=' + userId;
      this.$http.get(apiLink)
      .then((response: any) => {
        if (response.body.users.length > 0) {
          const res = response.body.users[0];
          this.resData.user_name = res.name;
          if (res.organizations.length > 0) {
            this.resData.organizations = res.organizations.map((item: { label: any; }) => item.label).join(', ');
          }
          resolve(true);
        }
      })
      .then(null, (err: any) => {
        this.resData.user_name = '';
        this.resData.organizations = '';
        resolve(false);
        });
      });
    },
    getGates(ID: any): any {
      this.resDataGates = [];
      return new Promise((resolve, reject) => {
        this.$http.get(Config.GATES_API + '?area_code=' + ID + '&is_status=false')
          .then((response: any) => {
            this.resDataGates = response.body.gates;
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getListGate(): any {
      return new Promise((resolve, reject) => {
        this.resData.gates = [];
        this.$http.get(Config.GATES_API + '?is_status=false')
          .then((response: any) => {
            if (response.body.gates.length > 0) {
              response.body.gates.forEach(async (element: any, key: any) => {
                this.listGates.push({
                  code : element.code,
                  name: element.name,
                });
              });
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
  },
});
