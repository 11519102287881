
import Vue, { PropType } from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import * as Config from '@/config';


export interface DataType {
  input: null;
  credentials: { ownerId: string; username: string; password: string;
  mfaCode: string, resetCode: string, confirmPassword: string};
  username: string;
  password: string;
  step: number;
  nextStep: boolean;
  show1: boolean;
  formLogin: boolean;
  configData: any;
}
interface InfoLoginWithProvider {
  isValid: boolean;
  cognitoIdProviderName: string;
}
export default Vue.extend({
  name: 'Login',
  components: {
    InputForm,
    TextLink,
    SuccessButton,
  },
  props: {
    loginProcess: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      default: {
        ownerId: '',
        userId: '',
        password: '',
        mfaCode: '',
        resetCode: '',
        confirmPassword: '',
      },
    },
  },
  created() {
    const dataQuery = this.$route.query;
    if (dataQuery.kanriId !== undefined) {
      this.credentials.ownerId = dataQuery.kanriId.toString();
    }
    if (dataQuery.userId !== undefined) {
      this.credentials.username = dataQuery.userId.toString();
      this.username = dataQuery.userId.toString();
    }
  },
  watch: {
    loginProcess(val) {
      this.step = val;
    },
    //
  },
  data: (): DataType => ({
    input: null,
    credentials: {
      ownerId: '',
      username: '',
      password: '',
      mfaCode: '',
      resetCode: '',
      confirmPassword: '',
    },
    username: '',
    password: '',
    step: 0,
    nextStep: false,
    show1: false,
    formLogin: false,
    configData: Config,
  }),
  methods: {
    PageLogin() {
      this.fillDataQueryToInput();
      this.$emit('goPageLogin');
    },
    forgotPassword() {
      this.fillDataQueryToInput();
      this.$emit('forgot-password');
    },
    fillDataQueryToInput() {
      const dataQuery = this.$route.query;
      let kanriId = '';
      let userId = '';
      if (dataQuery.kanriId !== undefined) {
        kanriId = dataQuery.kanriId.toString();
      }
      if (dataQuery.userId !== undefined) {
        userId = dataQuery.userId.toString();
      }
      this.credentials = {
        ownerId: kanriId,
        username: userId,
        password: '',
        mfaCode: '',
        resetCode: '',
        confirmPassword: '',
      },
      this.username = userId;
      this.password = '';
      this.step = 0;
    },
  },
});
