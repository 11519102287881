
import Vue, { PropType } from 'vue';
export default Vue.extend({
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    minDate: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    dateS: '',
    dateStart: false,
    min: '',
    max: '',
  }),
  mounted() {
    if (this.value !== null && this.value !== undefined && this.value !== '' ) {
      this.dateS = this.value;
    }
  },
  watch: {
    dateS(newValue: string) {
      this.$emit('get-date', newValue);
    },
    value(newValue: string) {
      this.dateS = newValue;
    },
    minDate(newValue: string) {
      this.min = newValue;
    },
    maxDate(newValue: string) {
      this.max = newValue;
    },
  },
});
