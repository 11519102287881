
import Vue, { PropType } from 'vue';
import Searchbox,  {SearchTargetItem} from '@/components/molecules/Searchbox/Searchbox.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import { DataTableHeader } from 'vuetify';
import auth from '@/auth';
import * as Config from '@/config';
import moment from 'moment-timezone';
export interface TypeItem {
  title: string;
}
export interface DataType {
  showOption: boolean;
  isVaildOnly: boolean;
  confirmDialog: boolean;
  confirmDialogExtention: boolean;
  typeSearch: any;
  applicantSelected: [];
  comment: string;
  description: string;
  actions: string;
  configData: any;
  workDate: any;
  itemWorkDate: any;
  titleChange: any;
  endTime: string;
  endDate: string;
  currentTime: string;
  extPeriodTime: string;
  isResend: boolean;
}

export default Vue.extend({
  name: 'ApprovalList',
  components: {
    Searchbox,
    ShowPerPage,
    Pagination,
    SelectPerPage,
    DisplayOption,
  },
  watch: {
    resExtData() {
      this.comment = this.resExtData.comment;
      if (this.resExtData.end) {
        this.endTime = auth.converDateShort(this.resExtData.end);
        const a  = moment(this.resExtData.end);
        const b  = moment(this.endDate);
        const duration = moment.duration(a.diff(b));
        const hours = duration.asMilliseconds();
        const countTime = auth.countTime(hours);
        let text = '';
        if (countTime === '02') {
          text = '+2時間';
        } else if (countTime === '04') {
          text = '+4時間';
        } else if (countTime === '06') {
          text = '+6時間';
        }
        this.extPeriodTime = auth.converDateShort(this.endDate) + '  ' + text;
      } else {
        this.endTime = auth.converDateShort(this.resData.end);
      }
    },
    resData() {
      if (this.resExtData.end) {
        this.endTime = auth.converDateShort(this.resExtData.end);
      } else {
        this.endTime = auth.converDateShort(this.resData.end);
      }
    },
  },
  props: {
    resData: {
      type: Object,
      default: () => ({}),
    },
    resHisData: {
      default: () => [],
    },
    resExtData: {
      type: Object,
      default: () => ({}),
    },
    resCardData: {
      type: Object,
      default: () => ({}),
    },
    applicantTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    isAction: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 100,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isFinishProcessEnable: {
      type: Boolean,
      default: false,
    },
    isDisabledCancelApplication: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    //
    this.endTime = auth.converDateShort(this.resData.end);
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: Config.TEXT_UPDATE_TIME,
            align: 'start',
            value: 'timestamp',
          },
          {
            text: Config.TEXT_OPERATION_CONTENT,
            align: 'start',
            value: 'content',
          },
          {
            text: Config.TEXT_BEFORE_OPERATION,
            align: 'start',
            value: 'from',
          },
          {
            text: Config.TEXT_AFTER_OPERATION,
            align: 'start',
            value: 'to',
          },
          {
            text: Config.TEXT_USER_NAME,
            align: 'start',
            value: 'user_name',
          },
          {
            text: Config.TEXT_COL_COMPANY_DEPARTMENT,
            align: 'start',
            value: 'organizations',
          },
          {
            text: Config.TEXT_CONTENT,
            align: 'start',
            value: 'comment',
          },
        ];
        return headers;
      },
      set(): void {
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', val);
        return;
      },
    },
  },
  data: (): DataType => ({
    applicantSelected: [],
    showOption: false,
    isVaildOnly: false,
    typeSearch: 0,
    confirmDialog: false,
    confirmDialogExtention: false,
    comment: '',
    actions: '',
    configData: Config,
    itemWorkDate: ['入室終了日時+2時間', '入室終了日時+4時間', '入室終了日時+6時間'],
    description: '',
    workDate: '',
    titleChange: sessionStorage.getItem('priviledge'),
    endTime: '',
    currentTime: auth.converDateShort(new Date()),
    isResend: false,
    extPeriodTime: '',
    endDate: '',
  }),
  methods: {
    ChangeDate(val: any) {
      let text = '';
      if (val === '入室終了日時+2時間') {
        text = '+2時間';
      } else if (val === '入室終了日時+4時間') {
        text = '+4時間';
      } else if (val === '入室終了日時+6時間') {
        text = '+6時間';
      }
      this.extPeriodTime = auth.converDateShort(this.endDate) + '  ' + text;
    },
    clickEdit(val: any) {
      this.$router.push({ path: Config.APPLICANT + '/edit', query: {request_id: val}});
    },
    actionApproval() {
      this.$emit('action-approval', {
        action: this.actions === 'resend-email' ? 'submissions' : this.actions,
        data: this.comment,
        description: this.description,
        workDate: this.workDate,
        isResend: this.actions === 'resend-email' ? true : false,
      });
      this.confirmDialog = false;
      this.confirmDialogExtention = false;
      this.comment = '';
    },
    approveExtensions() {
      this.isResend = false;
      this.actions = 'extensions';
      this.$emit('action-approval', {
        action: this.actions,
        data: this.comment,
        description: this.comment,
        workDate: this.workDate,
        isResend : false,
      });
      this.confirmDialogExtention = false;
    },
    showDialog(value: any, isResend: boolean = false) {
      this.comment = '';
      this.confirmDialog = true;
      this.isResend = isResend;
      this.actions = value;
      this.confirmDialogExtention = false;
    },
    clickClose() {
      this.$router.push({ path: Config.APPLICANT});
    },
    convertContent(val: any) {
      return auth.convertContent(val);
    },
    converDateShort(val: any) {
      return auth.converDateShort(val);
    },
    convertStatus(val: any) {
      return auth.convertStatus(val);
    },
    showExtensions(val: any) {
      this.endDate = val;
      this.$emit('open-extensions');
      this.confirmDialogExtention = true;
    },
    CheckColumn(data: any) {
      sessionStorage.setItem('displayOptionApplicant', JSON.stringify(data));
    },
    changeStatus(resData: any) {
      return resData.status === 'saved' && resData.status === 'approved' ? 'statusActive' : 'statusUnActive';
    },
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
  },
});
