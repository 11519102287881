
import Vue, { PropType } from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import DatePicker from '@/components/molecules/DatePicker/DatePicker.vue';
import * as Config from '@/config';

interface TypeSelect {
  text: string;
  value: string;
}

export interface DataType {
  rulesValidate: any;
  test: string;
  configData: any;
  //
  confirmDialog: boolean;  // control Confirm dialog
  targetCardNo: string;     // 登録対象カードNo.
}

export default Vue.extend({
  name: 'CardRegistration',
  components: {
    InputForm,
    DatePicker,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    isAction: {
      type: Number,
      default: 0,
    },
    resData: {
      default: () => [],
    },
    // *****
    upToDate: {
      type: Boolean,
      default: false,
    },
    maskedIdi01: {
      type: String,
      default: '',
    },
    maskedIdi02: {
      type: String,
      default: '',
    },
    maskedIdi03: {
      type: String,
      default: '',
    },
    maxNumOfCards: {
      type: Number,
      default: 3,
    },
    messageDialogTitle: {
      type: String,
      default: '',
    },
    messageDialogText: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    showMessageDialog: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    //
  },
  data: (): DataType => ({
    test: '',
    rulesValidate: {
      //
    },
    configData: Config,
    confirmDialog: false,
    targetCardNo: '',
  }),
  computed: {
    showMessageFlag: function() {
      if (!this.loading && this.showMessageDialog) {
        return true;
      }
      return false;
    },
  },
  methods: {
    // Emit event by clicking register button
    registerCard(cardNo: string) {
      this.$emit('register-card', cardNo);
    },
    //
    // Display confirm dialog
    showConfirmDialog(cardNo: string) {
      this.confirmDialog = true;
      this.targetCardNo = cardNo;
    },
    //
    // Emit event for removing Card registration
    deregisterCard() {
      this.$emit('deregister-card', this.targetCardNo);
      this.confirmDialog = false;
      this.targetCardNo = '';
    },
    closeMessageDialog() {
      this.$emit('close-message-dialog');
    },
    //
  },
});
