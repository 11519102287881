
import Vue, { PropType } from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import DatePicker from '@/components/molecules/DatePicker/DatePicker.vue';
import * as Config from '@/config';

interface TypeSelect {
  text: string;
  value: string;
}

export interface DataType {
  rulesValidate: any;
  test: string;
  confirmDialog: boolean;
  actions: string;
  comment: string;
  configData: any;
}

export default Vue.extend({
  name: 'ApprovalStatusForm',
  components: {
    InputForm,
    DatePicker,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    isAction: {
      type: Number,
      default: 0,
    },
    resData: {
      default: () => [],
    },
  },
  watch: {
    //
  },
  data: (): DataType => ({
    test: '',
    confirmDialog: false,
    actions: '',
    comment: '',
    rulesValidate: {
      //
    },
    configData: Config,
  }),
  methods: {
    //
    showDialog(value: any) {
      this.confirmDialog = true;
      this.actions = value;
    },
  },
});
