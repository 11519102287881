var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("applicant-view-template", {
    attrs: {
      loading: _vm.loading,
      errorMessageLogs: _vm.errorMessageLogs,
      resData: _vm.resData,
      resHisData: _vm.resHisData,
      resCardData: _vm.resCardData,
      resExtData: _vm.resExtData,
      isAction: _vm.isAction,
      isDisabledCancelApplication: _vm.isDisabledCancelApplication,
    },
    on: {
      "action-approval": function ($event) {
        return _vm.actionApproval($event)
      },
      "open-extensions": function ($event) {
        return _vm.openExtensions($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }