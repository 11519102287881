var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.loading === false
    ? _c("div", [
        _vm.perPage >= 0 && _vm.page >= 0 && _vm.itemsLength > 0
          ? _c(
              "div",
              {
                staticClass:
                  "allig-show-per-page text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption",
              },
              [
                _vm._v(
                  " 全" +
                    _vm._s(_vm.itemsLength) +
                    "件中" +
                    _vm._s(Math.min(_vm.perPage * _vm.page, _vm.itemsLength)) +
                    "件を表示 "
                ),
              ]
            )
          : _vm.isTotal0 === true
          ? _c(
              "div",
              {
                staticClass:
                  "allig-show-per-page text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption",
              },
              [_vm._v(" 0件 ")]
            )
          : _c(
              "div",
              {
                staticClass:
                  "allig-show-per-page text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption",
              },
              [_vm._v(" 0件 ")]
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }