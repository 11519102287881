
import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import OfficeListTable from '@/components/organisms/OfficeListTable/OfficeListTable.vue';
import OfficeSettingsForm from '@/components/organisms/OfficeSettingsForm/OfficeSettingsForm.vue';
import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
import * as Config from '../../../config';
export interface DataType {
  searchTargetItems: SearchTargetItem[];
  configData: any;
  selectedOfficeIds: string[];
  isCheck: boolean;
  priviledge: any;
}

export default Vue.extend({
  name: 'OfficeListTemplate',
  components: {
    OfficeListTable,
    OfficeSettingsForm,
    Searchbox,
    Breadcrumbs,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingSearch: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type : Boolean,
      default: true,
    },
    showModalOfficeSettings: {
      type: Boolean,
      default: false,
    },
    dataOfficeCode: {
      default: () => [],
    },
    dataOfficeName: {
      default: () => [],
    },
    officeList: {
      default: () => [],
    },
    officeTotalCount: {
      type: Number,
      default: 0,
    },
    officeInfo: {
      default: {},
    },
    isResetData: {
      default: false,
    },
    areaList: {
      default: () => [],
    },
    domainItems: {
      default: () => [],
    },
    confirmOfficeDialog: {
      type: Boolean,
      default: false,
    },
    confirmOfficeText: {
      type: String,
      default: '',
    },
    deleteOfficeDialog: {
      type: Boolean,
      default: false,
    },
    loadingModify: {
      type: Boolean,
      default: false,
    },
    isReload: {
      type: Number,
      default: 0,
    },
  },
  data: (): DataType => ({
    searchTargetItems: [
      { label: Config.TEXT_MANAGEMENT_FACILITY_CODE, name: 'office_code_list', value: '',
        maxLen: Config.OFFICE_CODE_MAX_LEN },
      { label: Config.TEXT_MANAGEMENT_FACILITY_NAME, name: 'office_name', value: '',
        maxLen: Config.OFFICE_NAME_MAX_LEN },
    ],
    configData: Config,
    selectedOfficeIds: [],
    isCheck: sessionStorage.getItem('priviledge')  === '0' ? true : false,
    priviledge: sessionStorage.getItem('priviledge'),
  }),
  watch: {
    isReload(val: any) {
      this.selectedOfficeIds = [];
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: Config.TEXT_MANAGEMENT_FACILITY_LIST, disabled: true },
        ];
      },
    },
  },
  methods: {
    //
    resetSearch() {
      this.searchTargetItems[0].value = '';
      this.searchTargetItems[1].value = '';
    },
    setSelectedOfficeIds(officeIds: string[]) {
      this.selectedOfficeIds = officeIds;
    },
  },
});
