
import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
import EntranceHistoryList from '@/components/organisms/EntranceHistoryList/EntranceHistoryList.vue';
import * as Config from '../../../config';
interface TypeSelect {
  text: string;
  value: string;
}
export interface DataType {
  selectedApplicantIds: string[];
  tab: number;
  searchTargetItems: SearchTargetItem[];
  isDisable: boolean;
}
export default Vue.extend({
  name: 'ApplicantTemplate',
  components: {
    EntranceHistoryList,
    Searchbox,
    Breadcrumbs,
  },
  props: {
    loadingSearch: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadMore: {
      type: Boolean,
      default: false,
    },
    isAction: {
      type: Number,
      default: 0,
    },
    resData: {
      default: () => [],
    },
    dataArea: {
      default: [],
    },
    dataOffice: {
      default: () => [],
    },
    gateCode: {
      default: () => [],
    },
    actionItems: {
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 100,
    },
  },
  data: (): DataType => ({
    selectedApplicantIds: [],
    tab: 0,
    isDisable: false,
    searchTargetItems: sessionStorage.getItem('priviledge')  === '1' ? [
      { label: Config.TEXT_COL_MANAGEMENT_OFFICE, name: 'office_list', value: '' },
      { label: Config.TEXT_AREA, name: 'area_list', value: '', isDisabled: true},
      { label: Config.TEXT_GATE, name: 'gate_code', value: '' },
      { label: Config.TEXT_USER_ID, name: 'user_id', value: '', maxLen: Config.USER_ID_MAX_LEN},
      { label: Config.TEXT_USER_NAME, name: 'user_name', value: '', maxLen: Config.USER_NAME_MAX_LEN},
      { label: Config.TEXT_SEARCH_PERIOD_START_DATE_AND_TIME, name: 'since', value: ''},
      { label: Config.TEXT_SEARCH_PERIOD_END_DATE_AND_TIME, name: 'until', value: ''},
    ] :  [
      sessionStorage.getItem('priviledge')  === '0' ?
      { label: Config.TEXT_COL_MANAGEMENT_OFFICE, name: 'office_list', value: ''} :
      { label: Config.TEXT_COL_MANAGEMENT_OFFICE, name: 'office_code',
        value: sessionStorage.getItem('office_name'), isDisabled: true},
      { label: Config.TEXT_AREA, name: 'area_list', value: ''},
      { label: Config.TEXT_GATE, name: 'gate_code', value: '' },
      { label: Config.TEXT_USER_ID, name: 'user_id', value: '', maxLen: Config.USER_ID_MAX_LEN},
      { label: Config.TEXT_USER_NAME, name: 'user_name', value: '', maxLen: Config.USER_NAME_MAX_LEN},
      { label: Config.TEXT_SEARCH_PERIOD_START_DATE_AND_TIME, name: 'since', value: ''},
      { label: Config.TEXT_SEARCH_PERIOD_END_DATE_AND_TIME, name: 'until', value: ''},
    ],
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: Config.TEXT_ENTRANCE_HISTORY, disabled: true },
        ];
      },
    },
  },
  methods: {
    changeOffice(args: any): any {
      if (args === null || args.value === null || args.value === '') {
        this.isDisable = false;
        this.searchTargetItems[1].value = '';
        this.searchTargetItems[2].value = '';
      } else {
        this.isDisable = true;
      }
      this.$emit('change-office', args);
    },
    changeArea(args: any): any {
      if (args === null || args.value === null || args.value === '') {
        this.isDisable = true;
        this.searchTargetItems[2].value = '';
      } else {
        this.isDisable = false;
      }
      this.$emit('change-area', args);
    },
  },
});
