var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("log-template", {
    attrs: {
      loading: _vm.loading,
      loadingSearch: _vm.loadingSearch,
      resData: !_vm.data ? _vm.resData : _vm.data,
      logTotalCount: _vm.logTotalCount,
    },
    on: {
      "search-log": function ($event) {
        return _vm.searchLog($event)
      },
      "change-column": function ($event) {
        return _vm.changeColumn($event)
      },
      export: function ($event) {
        return _vm.exportCSV($event)
      },
      "get-current-items": function ($event) {
        return _vm.getCurrentItems($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }