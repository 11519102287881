
import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import CardRegistration from '@/components/organisms/CardRegistration/CardRegistration.vue';
import ChangePassword from '@/components/organisms/ChangePassword/ChangePassword.vue';
import UserInformation from '@/components/organisms/UserInformation/UserInformation.vue';
import Searchbox, {
  SearchTargetItem,
} from '@/components/molecules/Searchbox/Searchbox.vue';
import * as Config from '../../../config';
interface TypeSelect {
  text: string;
  value: string;
}
export interface DataType {
  selectedApplicantIds: string[];
  tab: number;
}
export default Vue.extend({
  name: 'ApplicantTemplate',
  components: {
    CardRegistration,
    Searchbox,
    Breadcrumbs,
    ChangePassword,
    UserInformation,
  },
  props: {
    // For card list&registration
    maskedIdi01: {
      type: String,
      default: '',
    },
    maskedIdi02: {
      type: String,
      default: '',
    },
    maskedIdi03: {
      type: String,
      default: '',
    },
    maxNumOfCards: {
      type: Number,
      default: 3,
    },
  //
    loading: {
      type: Boolean,
      default: false,
    },
    isAction: {
      type: Number,
      default: 0,
    },
    resData: {
      default: () => [],
    },
    actionItems: {
      default: () => [],
    },
    errorMessageLogs: {
      default: '',
    },
    userInfo: {
      default: {},
    },
    // For contol of CardRegistration
    upToDate: {
      type: Boolean,
      default: false,
    },
    cardRegistrationError: {
      type: String,
      default: '',
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    showMessageDialog: {
      type: Boolean,
      default: false,
    },
    messageDialogTitle: {
      type: String,
      default: '',
    },
    messageDialogText: {
      type: String,
      default: '',
    },
    confirmDialog: {
      type: Boolean,
      default: false,
    },
    confirmPwdDialog: {
      type: Boolean,
      default: false,
    },
    showPwdMsgDialog: {
      type: Boolean,
      default: false,
    },
  },
  data: (): DataType => ({
    selectedApplicantIds: [],
    tab: 0,
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: Config.TEXT_MY_PAGE, disabled: true },
        ];
      },
    },
  },
  methods: {
    // intermediate register-card event
    registerCard(cardNo: string) {
      this.$emit('register-card', cardNo);
    },
    // intermediate deregister-card event
    deregisterCard(cardNo: string) {
      this.$emit('deregister-card', cardNo);
    },

    //
  },
});
