var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1000", persistent: "" },
          model: {
            value: _vm.showModalAddEditUser,
            callback: function ($$v) {
              _vm.showModalAddEditUser = $$v
            },
            expression: "showModalAddEditUser",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "modal-add-edit-user" },
            [
              _vm.isEdit
                ? _c(
                    "v-card-title",
                    { staticClass: "modal-title", attrs: { color: "green" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.configData.TEXT_ENTRY_APPLICANT_EDIT) +
                          " "
                      ),
                    ]
                  )
                : _c(
                    "v-card-title",
                    { staticClass: "modal-title", attrs: { color: "green" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.configData.TEXT_APPLICANT_FOR_ADMISSION) +
                          " "
                      ),
                    ]
                  ),
              _c("v-divider"),
              _c(
                "div",
                { staticClass: "row-message" },
                [
                  _vm.successMessageLogs !== ""
                    ? _c(
                        "v-col",
                        {
                          staticClass: "mb-4 font-weight-bold mt-1 bg-success",
                        },
                        [_vm._v(" " + _vm._s(_vm.successMessageLogs) + " ")]
                      )
                    : _vm._e(),
                  _vm.errorMessageLogs !== ""
                    ? _c(
                        "v-col",
                        { staticClass: "mb-4 font-weight-bold mt-1 bg-error" },
                        [_vm._v(" " + _vm._s(_vm.errorMessageLogs) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "pa-5 pt-5 ma-5" },
                [
                  _c(
                    "v-form",
                    { ref: "UserAddForm", attrs: { "lazy-validation": "" } },
                    [
                      _vm.isEdit
                        ? _c("input-form", {
                            attrs: {
                              title:
                                _vm.configData.TEXT_USER_ID +
                                `<span class='required'>` +
                                _vm.configData.TEXT_MUST +
                                `</span>`,
                              headerCols: 3,
                              inputType: "text",
                              disabled: _vm.isEdit,
                            },
                            model: {
                              value: _vm.userInfo.userId,
                              callback: function ($$v) {
                                _vm.$set(_vm.userInfo, "userId", $$v)
                              },
                              expression: "userInfo.userId",
                            },
                          })
                        : _c("input-form", {
                            attrs: {
                              title:
                                _vm.configData.TEXT_USER_ID +
                                `<span class='required'>` +
                                _vm.configData.TEXT_MUST +
                                `</span>`,
                              headerCols: 3,
                              inputType: "text",
                              disabled: _vm.isEdit,
                              rules: [
                                _vm.rulesValidate.requiredUserId,
                                _vm.rulesValidate.validateUserId,
                              ],
                            },
                            model: {
                              value: _vm.userInfo.userId,
                              callback: function ($$v) {
                                _vm.$set(_vm.userInfo, "userId", $$v)
                              },
                              expression: "userInfo.userId",
                            },
                          }),
                      _c("input-form", {
                        attrs: {
                          title:
                            _vm.configData.TEXT_USER_NAME +
                            `<span class='required'>` +
                            _vm.configData.TEXT_MUST +
                            `</span>`,
                          headerCols: 3,
                          inputType: "text",
                          rules: [
                            _vm.rulesValidate.requiredName,
                            _vm.rulesValidate.validateName,
                          ],
                        },
                        model: {
                          value: _vm.userInfo.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.userInfo, "userName", $$v)
                          },
                          expression: "userInfo.userName",
                        },
                      }),
                      _c("input-form", {
                        attrs: {
                          title: _vm.configData.TEXT_FURIGANA,
                          headerCols: 3,
                          inputType: "text",
                          rules: [_vm.rulesValidate.validateFurigana],
                        },
                        model: {
                          value: _vm.userInfo.furigana,
                          callback: function ($$v) {
                            _vm.$set(_vm.userInfo, "furigana", $$v)
                          },
                          expression: "userInfo.furigana",
                        },
                      }),
                      _c("input-form", {
                        attrs: {
                          title: _vm.configData.TEXT_COL_EMAIL_ADDRESS,
                          headerCols: 3,
                          inputType: "text",
                          maxlength: _vm.configData.EMAIL_MAX_LEN,
                          rules: [_vm.rulesValidate.validateEmail],
                        },
                        model: {
                          value: _vm.userInfo.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.userInfo, "email", $$v)
                          },
                          expression: "userInfo.email",
                        },
                      }),
                      _c(
                        "v-row",
                        [
                          _vm.$vuetify.breakpoint.mdAndUp
                            ? _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-subheader", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.configData
                                            .TEXT_COL_COMPANY_DEPARTMENT
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            [
                              _c("v-combobox", {
                                staticClass: "list-department",
                                attrs: {
                                  items: _vm.organizations,
                                  multiple: "",
                                  "deletable-chips": "",
                                  chips: "",
                                  disabled: _vm.organizations.length === 0,
                                  autocomplete: "off",
                                  color: "green",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                  "search-input": _vm.searchInput,
                                },
                                on: {
                                  "update:searchInput": function ($event) {
                                    _vm.searchInput = $event
                                  },
                                  "update:search-input": function ($event) {
                                    _vm.searchInput = $event
                                  },
                                  change: function ($event) {
                                    return _vm.validateCompanyDepartment($event)
                                  },
                                },
                                model: {
                                  value: _vm.userInfo.organizations,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.userInfo, "organizations", $$v)
                                  },
                                  expression: "userInfo.organizations",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("input-form", {
                        attrs: {
                          title: _vm.configData.TEXT_SUPPLEMENT,
                          headerCols: 3,
                          inputType: "text",
                          rules: [_vm.rulesValidate.validateRemarks],
                        },
                        model: {
                          value: _vm.userInfo.supplement,
                          callback: function ($$v) {
                            _vm.$set(_vm.userInfo, "supplement", $$v)
                          },
                          expression: "userInfo.supplement",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  !_vm.isEdit
                    ? _c(
                        "v-row",
                        {
                          staticClass: "mb-2 mt-2 mr-0",
                          attrs: { justify: "end" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeAddEditUserDialog()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.configData.TEXT_CANCEL) + " "
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "", disabled: _vm.isDisabled },
                              on: {
                                click: function ($event) {
                                  return _vm.clickSaveUser()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.configData.TEXT_ADD_USER) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isEdit
                    ? _c(
                        "v-row",
                        {
                          staticClass: "mb-2 mt-2 mr-0",
                          attrs: { justify: "end" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeAddEditUserDialog()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.configData.TEXT_CANCEL) + " "
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "", disabled: _vm.isDisabled },
                              on: {
                                click: function ($event) {
                                  return _vm.showUserChangeConfirm()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.configData.TEXT_RENEW) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isEdit
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "600", persistent: "" },
              model: {
                value: _vm.changeUserInfoDialog,
                callback: function ($$v) {
                  _vm.changeUserInfoDialog = $$v
                },
                expression: "changeUserInfoDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "modal-title", attrs: { color: "black" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.configData.TEXT_CONFIRMATION) + " "
                      ),
                    ]
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "mt-5" },
                    [
                      _c("v-row", {
                        staticStyle: { height: "20px" },
                        attrs: { "no-gutters": "" },
                      }),
                      _c("p", [
                        _vm._v(_vm._s(_vm.configData.TEXT_REQUEST_UPDATE_USER)),
                      ]),
                      _c("v-row", {
                        staticStyle: { height: "20px" },
                        attrs: { "no-gutters": "" },
                      }),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeUserChangeConfirm()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.configData.TEXT_RETURN) + " "
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.clickUpdateUser()
                                },
                              },
                            },
                            [_vm._v(" OK ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }