var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c(
        "v-tooltip",
        {
          attrs: {
            top: "",
            "max-width": "300px",
            "content-class": "text-caption",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-icon",
                    _vm._g(
                      _vm._b(
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$emit("click", $event)
                            },
                          },
                        },
                        "v-icon",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" mdi-help-circle ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v(_vm._s(_vm.text))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }