
import Vue, { PropType } from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import * as Config from '@/config';

export interface DataType {
  credentials:
  {
    ownerId: any;
    username: any;
    password: string;
    newPassword: string;
    confirmNewPassword: string;
  };
  show1: boolean;
  show2: boolean;
  show3: boolean;
  configData: any;
}

export default Vue.extend({
  name: 'ChangeInitialPassword',
  components: {
    InputForm,
    TextLink,
    SuccessButton,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: Object,
      default: {
        ownerId: '',
        userId: '',
        password: '',
        newPassword: '',
        confirmNewPassword: '',
      },
    },
    error: {
      type: String,
      default: '',
    },
  },
  created() {
    if (sessionStorage.getItem('ownerId') !== null && sessionStorage.getItem('ownerId') !== '' &&
        sessionStorage.getItem('userId') !== null && sessionStorage.getItem('userId') !== '') {
      this.credentials.ownerId = sessionStorage.getItem('ownerId');
      this.credentials.username = sessionStorage.getItem('userId');
    } else {
      this.PageLogin();
    }
  },
  watch: {
    //
  },
  data: (): DataType => ({
    credentials: {
      ownerId: '',
      username: '',
      password: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    show1: false,
    show2: false,
    show3: false,
    configData: Config,
  }),
  methods: {
    PageLogin() {
      this.$emit('goPageLogin');
    },
  },
});
