var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-container", [
    _c(
      "div",
      { staticClass: "allig-user-table" },
      [
        _c(
          "v-row",
          {
            staticClass: "mt-3 border-bottom-bold",
            attrs: {
              "no-gutters": "",
              justify: "space-between",
              "align-content": "center",
            },
          },
          [
            _vm.officeList.length > 0 &&
            _vm.officeList.length < _vm.officeTotalCount
              ? _c("div", { staticClass: "ml-8 mb-3" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "allig-show-per-page text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption",
                    },
                    [_vm._v(" " + _vm._s(_vm.officeList.length) + "件 ")]
                  ),
                ])
              : _c(
                  "div",
                  [
                    _c("show-per-page", {
                      staticClass: "ml-8 mb-3",
                      attrs: {
                        "per-page": _vm.officeTotalCount,
                        page: 1,
                        "items-length": _vm.officeTotalCount,
                      },
                    }),
                  ],
                  1
                ),
            _c("v-spacer"),
            _c(
              "div",
              {
                staticClass:
                  "text-caption display-option included-option text-sm-caption text-md-caption text-lg-caption text-xl-caption mr-8",
              },
              [
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple",
                        value: false,
                        expression: "false",
                      },
                    ],
                    staticClass: "allig-btn-text-link-gray allig-btn-text-link",
                    attrs: { block: "", type: "button", text: "" },
                    on: {
                      click: () => {
                        _vm.showOption = !_vm.showOption
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "display-option-title" }, [
                      _vm._v(
                        " " + _vm._s(_vm.configData.TEXT_DISPLAY_OPTIONS) + " "
                      ),
                    ]),
                    _c(
                      "span",
                      [
                        _c("v-icon", { attrs: { dense: "" } }, [
                          _vm._v("mdi-chevron-down"),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          { staticClass: "pt-0 pb-0 mt-0" },
          [
            _c(
              "v-container",
              { staticClass: "pt-0" },
              [
                _c("display-option", {
                  attrs: {
                    isShowOption: _vm.showOption,
                    "display-option-items": _vm.displayOptionItems,
                    disabled: _vm.loading,
                    isVaildOnly: _vm.isVaildOnly,
                  },
                  on: {
                    "show-only-isVaild": function ($event) {
                      return _vm.$emit("show-only-isVaild", $event)
                    },
                    input: function ($event) {
                      return _vm.checkColumn($event)
                    },
                  },
                  model: {
                    value: _vm.selectedDisplayOption,
                    callback: function ($$v) {
                      _vm.selectedDisplayOption = $$v
                    },
                    expression: "selectedDisplayOption",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm.loading == false
          ? _c("v-data-table", {
              directives: [
                { name: "resizable-columns", rawName: "v-resizable-columns" },
              ],
              staticClass: "border-table scroll-table",
              attrs: {
                "must-sort": "",
                headers: _vm.headers,
                items: _vm.officeList,
                loading: _vm.loading,
                "item-key": "officeId",
                "no-data-text": _vm.configData.TEXT_NO_FACILITIES,
                "loading-text": _vm.configData.TEXT_LOADING_DATA,
                "hide-default-footer": "",
                "disable-pagination": "",
                "sort-by": ["officeId"],
                "sort-desc": false,
                "show-select": _vm.isCheck,
              },
              on: {
                input: (item) => {
                  _vm.officeSelected = item
                },
                "current-items": function ($event) {
                  return _vm.$emit("current-items", $event)
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: `item.updated`,
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.converDateShort(item.updated)) + " "
                        ),
                      ]
                    },
                  },
                  {
                    key: `item.officeId`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple",
                                value: false,
                                expression: "false",
                              },
                            ],
                            staticClass: "allig-btn-text-link",
                            attrs: { block: "", type: "button", text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit(
                                  "clickEditOffice",
                                  item.officeId
                                )
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.officeId) + " ")]
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          : _vm._e(),
        _c("v-row", {
          staticStyle: { height: "20px" },
          attrs: { "no-gutters": "" },
        }),
        _c("v-spacer"),
        _c(
          "v-dialog",
          {
            attrs: { persistent: "", "max-width": "600" },
            model: {
              value: _vm.deleteOfficeDialog,
              callback: function ($$v) {
                _vm.deleteOfficeDialog = $$v
              },
              expression: "deleteOfficeDialog",
            },
          },
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  { staticClass: "modal-title", attrs: { color: "black" } },
                  [_vm._v(" " + _vm._s(_vm.configData.TEXT_CONFIRMATION) + " ")]
                ),
                _c(
                  "v-card-text",
                  { staticClass: "mt-5" },
                  [
                    _c("div", [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.configData
                              .TEXT_REQUEST_DELETE_MANAGEMENT_FACILITY
                          )
                        ),
                      ]),
                    ]),
                    _c("v-row", {
                      staticStyle: { height: "20px" },
                      attrs: { "no-gutters": "" },
                    }),
                    _c("v-spacer"),
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  [
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { justify: "end" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-secondary",
                            attrs: { outlined: "" },
                            on: {
                              click: function ($event) {
                                return _vm.closeDeleteOfficeDialog()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.configData.TEXT_RETURN))]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-primary",
                            attrs: { outlined: "" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteSelectedOffices()
                              },
                            },
                          },
                          [_vm._v("OK")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }