
import Vue from 'vue';
export default Vue.extend({
  name: 'Pagination',
  props: {
    value: {
      type: Number,
      default: 1,
    },
    pageLength: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerValue: {
      get(): number {
        return this.value;
      },
      set(val: number): void {
        this.$emit('input', val);
        return;
      },
    },
  },
});
