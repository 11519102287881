var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("email-settings-template", {
    attrs: {
      loading: _vm.loading,
      emailSettings: _vm.emailSettings,
      domainItems: _vm.domainItems,
    },
    on: {
      "change-select": function ($event) {
        return _vm.changeDomain($event)
      },
      updateSubmittionMessage: function ($event) {
        return _vm.updateSubmittionMessage($event)
      },
      updateCancelationMessage: function ($event) {
        return _vm.updateCancelationMessage($event)
      },
      updateApprovalMessage: function ($event) {
        return _vm.updateApprovalMessage($event)
      },
      updateReturnMessage: function ($event) {
        return _vm.updateReturnMessage($event)
      },
      updateRescissionsMessage: function ($event) {
        return _vm.updateRescissionsMessage($event)
      },
      updateExntensionRequestMessage: function ($event) {
        return _vm.updateExntensionRequestMessage($event)
      },
      updateExntensionApprovalMessage: function ($event) {
        return _vm.updateExntensionApprovalMessage($event)
      },
      updateExntensionDenialMessage: function ($event) {
        return _vm.updateExntensionDenialMessage($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }