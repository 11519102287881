var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "login-layout blur-login" },
    [
      _c(
        "v-overlay",
        { attrs: { absolute: "", value: _vm.loading, color: "white" } },
        [_c("img", { attrs: { src: "/loading-circle.gif", width: "40" } })]
      ),
      _c(
        "v-container",
        {
          style: [
            _vm.step === 1 || _vm.step === 3
              ? { "max-width": "600px" }
              : { "max-width": "480px" },
          ],
        },
        [
          _c(
            "v-card",
            { staticClass: "background-login" },
            [
              _vm.step === 0
                ? _c(
                    "v-form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.$emit("formSubmit", {
                            ownerId: _vm.credentials.ownerId,
                            username: _vm.username,
                            password: _vm.password,
                          })
                        },
                      },
                      model: {
                        value: _vm.formLogin,
                        callback: function ($$v) {
                          _vm.formLogin = $$v
                        },
                        expression: "formLogin",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "background-white ml-12",
                              attrs: { cols: "12", "align-self": "center" },
                            },
                            [
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _c(
                                  "div",
                                  { staticClass: "logo-text mt-10 mb-0" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.configData.TEXT_LOGO_1ST) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mt-0",
                                  attrs: { justify: "center" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "logo-text mt-1 mb-10" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.configData.TEXT_LOGO_2ND) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.ownerId !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon": "mdi-account",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: _vm.configData.TEXT_OWNER_ID,
                                          autocomplete: "off",
                                        },
                                        model: {
                                          value: _vm.credentials.ownerId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.credentials,
                                              "ownerId",
                                              $$v
                                            )
                                          },
                                          expression: "credentials.ownerId",
                                        },
                                      }),
                                      _vm.errorMessage.ownerId !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errorMessage.ownerId)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.userId !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          autocomplete: "off",
                                          "prepend-inner-icon":
                                            "mdi-account-circle",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: _vm.configData.TEXT_USER_ID,
                                        },
                                        model: {
                                          value: _vm.username,
                                          callback: function ($$v) {
                                            _vm.username = $$v
                                          },
                                          expression: "username",
                                        },
                                      }),
                                      _vm.errorMessage.userId !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errorMessage.userId)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.password !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon": "mdi-lock",
                                          "append-icon": _vm.show1
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          type: _vm.show1 ? "text" : "password",
                                          label: _vm.configData.TEXT_PASSWORD,
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          disabled: _vm.loading,
                                          autocomplete: "off",
                                        },
                                        on: {
                                          "click:append": function ($event) {
                                            _vm.show1 = !_vm.show1
                                          },
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function ($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password",
                                        },
                                      }),
                                      _vm.errorMessage.password !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errorMessage.password
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "ripple",
                                          rawName: "v-ripple",
                                          value: false,
                                          expression: "false",
                                        },
                                      ],
                                      staticClass:
                                        "allig-text-link text-center col-12",
                                      attrs: {
                                        block: "",
                                        type: "button",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.forgotPassword()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.configData.TEXT_FORGOT_LINK
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "text-color-white text-bold input-login",
                                          attrs: {
                                            block: "",
                                            type: "submit",
                                            color: "black",
                                            elevation: "1",
                                            disabled: _vm.disabled,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit("signIn", {
                                                ownerId:
                                                  _vm.credentials.ownerId,
                                                username: _vm.username,
                                                password: _vm.password,
                                              })
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.configData.TEXT_BUTTON_LOGIN
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step === 4
                ? _c(
                    "v-form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.$emit("formSubmitForgot", {
                            ownerId: _vm.credentials.ownerId,
                            username: _vm.username,
                          })
                        },
                      },
                      model: {
                        value: _vm.formLogin,
                        callback: function ($$v) {
                          _vm.formLogin = $$v
                        },
                        expression: "formLogin",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "background-white ml-12",
                              attrs: { cols: "12", "align-self": "center" },
                            },
                            [
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _c(
                                  "div",
                                  { staticClass: "logo-text mt-10 mb-0" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.configData.TEXT_LOGO_1ST) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _c(
                                  "div",
                                  { staticClass: "logo-text mt-0 mb-10" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.configData.TEXT_LOGO_2ND) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.ownerId !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon": "mdi-account",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: _vm.configData.TEXT_OWNER_ID,
                                          autocomplete: "off",
                                        },
                                        model: {
                                          value: _vm.credentials.ownerId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.credentials,
                                              "ownerId",
                                              $$v
                                            )
                                          },
                                          expression: "credentials.ownerId",
                                        },
                                      }),
                                      _vm.errorMessage.ownerId !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errorMessage.ownerId)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.userId !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon":
                                            "mdi-account-circle",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: _vm.configData.TEXT_USER_ID,
                                          autocomplete: "off",
                                        },
                                        model: {
                                          value: _vm.username,
                                          callback: function ($$v) {
                                            _vm.username = $$v
                                          },
                                          expression: "username",
                                        },
                                      }),
                                      _vm.errorMessage.userId !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errorMessage.userId)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "text-color-white text-bold input-login",
                                          attrs: {
                                            block: "",
                                            type: "submit",
                                            color: "black",
                                            elevation: "1",
                                            disabled: _vm.disabled,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit("forgot", {
                                                ownerId:
                                                  _vm.credentials.ownerId,
                                                username: _vm.username,
                                              })
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.configData
                                                  .TEXT_SEND_RESET_CODE
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "ripple",
                                          rawName: "v-ripple",
                                          value: false,
                                          expression: "false",
                                        },
                                      ],
                                      staticClass:
                                        "allig-text-link text-center col-12",
                                      attrs: {
                                        block: "",
                                        type: "button",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.PageLogin()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.configData
                                              .TEXT_RETURN_LOGIN_LINK
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step === 5
                ? _c(
                    "v-form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.$emit("formSubmitForgot", {
                            credentials: _vm.credentials,
                          })
                        },
                      },
                      model: {
                        value: _vm.formLogin,
                        callback: function ($$v) {
                          _vm.formLogin = $$v
                        },
                        expression: "formLogin",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "background-white ml-12",
                              attrs: { cols: "12", "align-self": "center" },
                            },
                            [
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _c(
                                  "div",
                                  { staticClass: "logo-text mt-10 mb-0" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.configData.TEXT_LOGO_1ST) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _c(
                                  "div",
                                  { staticClass: "logo-text mt-0 mb-10" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.configData.TEXT_LOGO_2ND) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.resetCode !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon": "mdi-barcode",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: _vm.configData.TEXT_RESETCODE,
                                          type: "text",
                                          autocomplete: "off",
                                        },
                                        model: {
                                          value: _vm.credentials.resetCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.credentials,
                                              "resetCode",
                                              $$v
                                            )
                                          },
                                          expression: "credentials.resetCode",
                                        },
                                      }),
                                      _vm.errorMessage.resetCode !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errorMessage.resetCode
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.password !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon": "mdi-lock",
                                          type: "password",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: _vm.configData.TEXT_PASSWORD,
                                          autocomplete: "off",
                                        },
                                        model: {
                                          value: _vm.credentials.password,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.credentials,
                                              "password",
                                              $$v
                                            )
                                          },
                                          expression: "credentials.password",
                                        },
                                      }),
                                      _vm.errorMessage.password !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errorMessage.password
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.error !== ""
                                ? _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { justify: "center" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-center col-8 txt-error",
                                        },
                                        [_vm._v(" " + _vm._s(_vm.error) + " ")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "text-color-white text-bold input-login",
                                          attrs: {
                                            block: "",
                                            type: "submit",
                                            color: "black",
                                            elevation: "1",
                                            disabled: _vm.disabled,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit("resetPass", {
                                                credentials: _vm.credentials,
                                                username: _vm.username,
                                              })
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.configData
                                                  .TEXT_RESET_PASSWORD
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "ripple",
                                          rawName: "v-ripple",
                                          value: false,
                                          expression: "false",
                                        },
                                      ],
                                      staticClass:
                                        "allig-text-link text-center col-12",
                                      attrs: {
                                        block: "",
                                        type: "button",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.PageLogin()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.configData
                                              .TEXT_RETURN_LOGIN_LINK
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step === 6
                ? _c(
                    "v-form",
                    {
                      model: {
                        value: _vm.formLogin,
                        callback: function ($$v) {
                          _vm.formLogin = $$v
                        },
                        expression: "formLogin",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "background-white ml-12",
                              attrs: { cols: "12", "align-self": "center" },
                            },
                            [
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _c(
                                  "div",
                                  { staticClass: "logo-text mt-10 mb-0" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.configData.TEXT_LOGO_1ST) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _c(
                                  "div",
                                  { staticClass: "logo-text mt-0 mb-10" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.configData.TEXT_LOGO_2ND) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" },
                                },
                                [
                                  _c("p", { staticClass: "txt-success" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.configData.TEXT_NOTE_RESET_SUCCESS
                                        )
                                    ),
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "ripple",
                                          rawName: "v-ripple",
                                          value: false,
                                          expression: "false",
                                        },
                                      ],
                                      staticClass:
                                        "allig-text-link text-center col-12 mt-1",
                                      attrs: {
                                        block: "",
                                        type: "button",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.PageLogin()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.configData
                                              .TEXT_RETURN_LOGIN_LINK
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }