var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "suika-user-template" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _c("v-container", [
        _c(
          "div",
          { staticClass: "container-user" },
          [
            _c(
              "div",
              { staticClass: "round-action" },
              [
                _c(
                  "v-row",
                  { staticClass: "btn-div", attrs: { justify: "start" } },
                  [
                    _c("v-col", [
                      _c(
                        "div",
                        { staticClass: "area-search-box" },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "space-between",
                              },
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("searchbox", {
                                    staticClass: "input-search",
                                    attrs: {
                                      searchTargetItems: _vm.searchTargetItems,
                                      userPage: true,
                                      organizations: _vm.organizations,
                                      selected: _vm.selectedIds,
                                      isCSV: true,
                                      isAdd: true,
                                      isDelete: true,
                                      isBulkRegistration: true,
                                      title:
                                        _vm.configData
                                          .TEXT_SEARCH_USER_NAME_DEPARTMENT_EMAIL,
                                    },
                                    on: {
                                      search: function ($event) {
                                        return _vm.$emit("search-user", $event)
                                      },
                                      export: function ($event) {
                                        return _vm.$emit("exportCSV", $event)
                                      },
                                      bulkRegistration: function ($event) {
                                        return _vm.$emit(
                                          "bulkRegistration",
                                          $event
                                        )
                                      },
                                      add: function ($event) {
                                        return _vm.$emit("addUser", $event)
                                      },
                                      delete: function ($event) {
                                        return _vm.$emit(
                                          "showConfirmDeleteUserDialog"
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.loadingSearch === false
              ? _c("user-management-list", {
                  attrs: {
                    loading: _vm.loading,
                    errorMessageProcess: _vm.errorMessageProcess,
                    resData: _vm.resData,
                    userTotalCount: _vm.total,
                    isDeleteFinish: _vm.isDeleteFinish,
                    perPage: _vm.perPage,
                    page: _vm.page,
                    loadMore: _vm.loadMore,
                    deleteUserDialog: _vm.deleteUserDialog,
                  },
                  on: {
                    deleteUser: function ($event) {
                      return _vm.$emit("delete-user", _vm.selectedIds)
                    },
                    "change-column": function ($event) {
                      return _vm.$emit("change-column", $event)
                    },
                    "change-per-page": function ($event) {
                      return _vm.$emit("change-per-page", $event)
                    },
                    "close-finish": function ($event) {
                      return _vm.$emit("close-finish", $event)
                    },
                    selected: (userIds) => {
                      _vm.selectedIds = userIds
                    },
                    "change-page": function ($event) {
                      return _vm.$emit("change-page", $event)
                    },
                    bulkRegistration: function ($event) {
                      return _vm.$emit("bulkRegistration")
                    },
                    addUser: function ($event) {
                      return _vm.$emit("addUser")
                    },
                    clickEditUser: function ($event) {
                      return _vm.$emit("editUser", $event)
                    },
                    closeDeleteUserDialog: function ($event) {
                      return _vm.$emit("closeDeleteUserDialog")
                    },
                    "get-current-items": function ($event) {
                      return _vm.$emit("get-current-items", $event)
                    },
                  },
                })
              : _vm._e(),
            _c("user-add-form", {
              attrs: {
                showModalAddEditUser: _vm.showModalAddEditUser,
                organizations: _vm.organizations,
                userInfo: _vm.userData,
                isEdit: _vm.isEdit,
                isResetData: _vm.isResetData,
                changeUserInfoDialog: _vm.changeUserInfoDialog,
              },
              on: {
                clickSaveUser: function ($event) {
                  return _vm.$emit("clickSaveUser", $event)
                },
                clickUpdateUser: function ($event) {
                  return _vm.$emit("clickUpdateUser", $event)
                },
                closeAddEditUserDialog: function ($event) {
                  return _vm.$emit("closeAddEditUserDialog")
                },
                showUserChangeConfirm: function ($event) {
                  return _vm.$emit("showUserChangeConfirm")
                },
                closeUserChangeConfirm: function ($event) {
                  return _vm.$emit("closeUserChangeConfirm")
                },
              },
            }),
            _c("user-bulk-add-form", {
              attrs: { showModalBulkUser: _vm.showModalBulkUser },
              on: {
                clickImportCSV: function ($event) {
                  return _vm.$emit("clickImportCSV", $event)
                },
                closeBulkUserDialog: function ($event) {
                  return _vm.$emit("closeBulkUserDialog")
                },
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }