var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("login", {
        attrs: {
          loginProcess: _vm.loginProcess,
          errorMessage: _vm.errorMessage,
          loading: _vm.loading,
          error: _vm.error,
        },
        on: {
          signIn: function ($event) {
            return _vm.$emit("signIn", $event)
          },
          resetPass: function ($event) {
            return _vm.$emit("resetPass", $event)
          },
          forgot: function ($event) {
            return _vm.$emit("forgot", $event)
          },
          formSubmit: function ($event) {
            return _vm.$emit("formSubmit", $event)
          },
          formSubmitForgot: function ($event) {
            return _vm.$emit("formSubmitForgot", $event)
          },
          goPageLogin: function ($event) {
            return _vm.$emit("goPageLogin", $event)
          },
          "forgot-password": function ($event) {
            return _vm.$emit("forgot-password", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }