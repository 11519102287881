
import {Vue } from 'vue-property-decorator';
import EquipmentStatusTemplate from '@/components/templates/EquipmentStatusTemplate/EquipmentStatusTemplate.vue';
import * as Config from '@/config';
import auth from '@/auth';
export interface DataType {
  // Props
  isAction: number;
  total: number;
  loading: boolean;
  isRegistering: boolean;
  loadingCSV: boolean;
  loadingSearch: boolean;
  areaList: any;
  resData: any;
  errorMessageLogs: string;
  actionItems: any;
  keyword: string;
  dataOffice: any;
  dataArea: any;
  exportData: any;
  listDataExport: any;
}
export default Vue.extend({
  name: 'EquipmentStatusListPage',
  components: {
    EquipmentStatusTemplate,
  },
  data: (): DataType => ({
    // Props
    loading: false,
    isRegistering: false, // default: false -> reading data
    loadingCSV: false,
    loadingSearch: false,
    isAction: 0,
    total: 0,
    areaList: [],
    resData: [],
    errorMessageLogs: '',
    actionItems: [
      { title: Config.TEXT_APPROVAL_STATUS_LIST, tabname: 'tab1' },
      { title: Config.TEXT_ENTRANCE_HISTORY, tabname: 'tab2' },
      { title: Config.TEXT_EQUIPMENT_STATUS_STATE, tabname: 'tab3' },
      { title: Config.TEXT_APPROVER_SETTING, tabname: 'tab4' },
    ],
    keyword: '',
    dataOffice: [],
    dataArea: [],
    exportData: [],
    listDataExport: [],
  }),
  watch: {
    loading(value) {
      this.$emit('loading', value);
      if (value) {
        this.$emit('textCircle', this.isRegistering ? Config.TEXT_PROGRESS_REGISTERING : Config.TEXT_PROGRESS_READING);
      } else if (this.isRegistering) {
        this.isRegistering = false;
      }
    },
    loadingCSV(value) {
      this.$emit('loading', value);
    },
  },
  mounted() {
    this.$emit('activeMenu', 'equipment-status');
    sessionStorage.removeItem('displayOptionEquip');
  },
  created() {
    //
    this.loading = true;
    this.getAreaList()
    .then(() => {
      if (sessionStorage.getItem('priviledge')  === '2') {
        this.getListOfficeArea(sessionStorage.getItem('office_code'));
        this.getListDataAllArea();
      } else {
        this.getListData();
        this.getListDataOffice();
      }
    });
  },
  methods: {
    getCurrentItems(item: any) {
      this.listDataExport = item;
    },
    changeColumn(value: any): any {
      this.loading = true;
      setTimeout(() => {this.loading = false; }, 300);
    },
    exportCSV(value: any): any {
      this.exportData = [];
      this.loadingCSV = true;
      this.getExportData();
    },
    changeOffice(item: any) {
      this.dataArea = [];
      if (item && item.value) {
        this.getListOfficeArea(item.value);
      }
    },
    async getExportData(): Promise<any> {
      if (!this.isEmpty(this.listDataExport) && this.listDataExport.length > 0) {
        for (const item of this.listDataExport) {
          let templateExport: any = {};
          if (sessionStorage.hasOwnProperty('displayOptionEquip')) {
            let displayOption = sessionStorage.getItem('displayOptionEquip') as string;
            displayOption = displayOption.replace(/'/g, '"');
            displayOption = JSON.parse(displayOption);
            if (displayOption.includes('gate_code')) {
              const gateCode = Config.TEXT_GATE_CODE;
              templateExport[gateCode] = item.code;
            }
            if (displayOption.includes('gate_name')) {
              const gateName = Config.TEXT_GATE_NAME;
              templateExport[gateName] = auth.getDefaultStringVal(item.name);
            }
            if (displayOption.includes('area_name')) {
              const areaName = Config.TEXT_AREA;
              templateExport[areaName] = auth.getDefaultStringVal(item.area_name);
            }
            if (displayOption.includes('gate_voltage')) {
              const voltage = Config.TEXT_BATTERY_LEVEL;
              templateExport[voltage] = auth.convertVoltage(item.voltage, item.type, item.isMinDate);
            }
            if (displayOption.includes('scan_date')) {
              const scanDate = Config.TEXT_GET_DATE;
              templateExport[scanDate] = item.scanDate;
            }
          } else {
            templateExport = {
              [Config.TEXT_GATE_CODE]: item.code,
              [Config.TEXT_GATE_NAME]: auth.getDefaultStringVal(item.name),
              [Config.TEXT_AREA]: auth.getDefaultStringVal(item.area_name),
              [Config.TEXT_BATTERY_LEVEL]: auth.convertVoltage(item.voltage, item.type, item.isMinDate),
              [Config.TEXT_GET_DATE]: item.scanDate,
            };
          }
          await this.exportData.push(templateExport);
        }
        auth.downloadCSV(this.exportData, Config.TEXT_EQUIPMENT_STATUS_CONDITION);
        this.loadingCSV = false;
      } else {
        let templateExport: any = {};
        if (sessionStorage.hasOwnProperty('displayOptionEquip')) {
          let displayOption = sessionStorage.getItem('displayOptionEquip') as string;
          displayOption = displayOption.replace(/'/g, '"');
          displayOption = JSON.parse(displayOption);
          if (displayOption.includes('gate_code')) {
            const gateCode = Config.TEXT_GATE_CODE;
            templateExport[gateCode] = '';
          }
          if (displayOption.includes('gate_name')) {
            const gateName = Config.TEXT_GATE_NAME;
            templateExport[gateName] = '';
          }
          if (displayOption.includes('area_name')) {
            const areaName = Config.TEXT_AREA;
            templateExport[areaName] = '';
          }
          if (displayOption.includes('gate_voltage')) {
            const voltage = Config.TEXT_BATTERY_LEVEL;
            templateExport[voltage] = '';
          }
          if (displayOption.includes('scan_date')) {
            const scanDate = Config.TEXT_GET_DATE;
            templateExport[scanDate] = '';
          }
        } else {
          templateExport = {
            [Config.TEXT_GATE_CODE]: '',
            [Config.TEXT_GATE_NAME]: '',
            [Config.TEXT_AREA]: '',
            [Config.TEXT_BATTERY_LEVEL]: '',
            [Config.TEXT_GET_DATE]: '',
          };
        }
        await this.exportData.push(templateExport);
        auth.downloadCSV(this.exportData, Config.TEXT_EQUIPMENT_STATUS_CONDITION);
        this.loadingCSV = false;
      }
    },
    getListDataOffice(): any {
      const apiLink = Config.OFFICES_LIST_API;
      this.dataOffice = [];
      this.$http.get(apiLink)
      .then((response: any) => {
        if (response.body.offices.length > 0) {
          response.body.offices.forEach(async (element: any, key: any) => {
            this.dataOffice.push(
              {
                text: element.name,
                value: element.code,
              },
            );
          });
        }
      })
      .then(null, (err: any) => {
        console.log(err);
      });
    },
    getListOfficeArea(ID: any): any {
      this.dataArea = [];
      return new Promise((resolve, reject) => {
        this.$http.get(Config.OFFICE_AREAS_API + '/' + ID)
        .then((response: any) => {
          if (response.body.areas !== undefined && response.body.areas !== null &&
              response.body.areas !== '' && response.body.areas.length > 0) {
            response.body.areas.forEach(async (element: any, key: any) => {
              const val = this.areaList.find((a: any) => a.code === element.area_code);
              if (val) {
                this.dataArea.push({
                  text: val.name,
                  value: val.code,
                });
              }
            });
          }
          resolve(true);
        })
        .then( null, (err: any) => {
          resolve(false);
        }); // catch
      });
    },
    refreshGate(args: any): any {
      this.total = 0;
      this.getListData();
    },
    search(args: any) {
      this.listDataExport = [];
      sessionStorage.removeItem('displayOptionEquip');
      this.loadingSearch = true;
      this.keyword = '';
      console.log(args);
      this.total = 0;
      if (args.target[0].value === null) {
        this.getListData();
      } else {
        if (args.target[1].value === '' || args.target[1].value === null) {
          this.getListDataAllArea();
        } else {
            this.keyword += 'area_code=' + args.target[1].value.value + '&';
            this.getListData();
        }
      }
    },
    getAreaList() {
      return new Promise((resolve, reject) => {
        this.areaList = [];
        this.$http.get(Config.AREAS_API)
        .then((res: any) => {
          if (!this.isEmpty(res.body.areas) && res.body.areas.length > 0) {
            this.areaList = res.body.areas;
          }
          resolve(true);
        })
        .then(null, (err: any) => {
          console.log(err);
          resolve(false);
        });
      });
    },
    getListData(): any {
      this.resData = [];
      let apiLink = Config.GATES_API;
      if (this.keyword !== '') {
        apiLink = apiLink + '?' + this.keyword.replace(/&\s*$/, '');
      }
      this.loading = true;
      this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.gates.length > 0) {
            this.resData = response.body.gates;
            this.total = response.body.gates.length;
            this.resData.forEach(async (element: any, key: any) => {
              if (element.scanDate === '1970-01-01T00:00:00.000Z') {
                this.resData[key].scanDate = '-';
                this.resData[key].isMinDate = true;
              } else {
                this.resData[key].scanDate = auth.convertEquipmentDate(element.scanDate, element.type);
                this.resData[key].isMinDate = false;
              }
              const val = this.areaList.find((a: any) => a.code === element.area_code);
              if (val) {
                this.resData[key].area_name = val.name;
              }
              // await this.getAreaItem(element.area_code, key);
            });
          }
          this.loading = false;
          this.loadingSearch = false;
        })
        .then( null, (err: any) => {
          this.loading = false;
          this.loadingSearch = false;
          this.errorMessageLogs = Config.TEXT_ERROR_FOR_EQUIPMENT_STATUS;
        }); // catch
    },
    getListDataAllArea(): any {
      this.resData = [];
      const apiLink = Config.GATES_API;
      this.loading = true;
      this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.gates.length > 0) {
            const resData = response.body.gates;
            this.total = response.body.gates.length;
            resData.forEach(async (element: any, key: any) => {
              if (element.scanDate === '1970-01-01T00:00:00.000Z') {
                element.scanDate = '-';
                element.isMinDate = true;
              } else {
                element.scanDate = auth.convertEquipmentDate(element.scanDate, element.type);
                element.isMinDate = false;
              }
              const val = this.areaList.find((a: any) => a.code === element.area_code);
              if (val) {
                element.area_name = val.name;
              }
              this.resData.push(element);
            });
          }
          this.loading = false;
          this.loadingSearch = false;
        })
        .then( null, (err: any) => {
          this.loading = false;
          this.loadingSearch = false;
          this.errorMessageLogs = Config.TEXT_ERROR_FOR_EQUIPMENT_STATUS;
        }); // catch
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
  },
});
