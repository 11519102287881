var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "suika-email-settings-template" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _c(
        "v-container",
        [
          _c("email-settings-form", {
            attrs: {
              loading: _vm.loading,
              emailSettings: _vm.emailSettings,
              domainItems: _vm.domainItems,
            },
            on: {
              "change-select": function ($event) {
                return _vm.$emit("change-select", $event)
              },
              updateSubmittionMessage: function ($event) {
                return _vm.$emit("updateSubmittionMessage", $event)
              },
              updateCancelationMessage: function ($event) {
                return _vm.$emit("updateCancelationMessage", $event)
              },
              updateApprovalMessage: function ($event) {
                return _vm.$emit("updateApprovalMessage", $event)
              },
              updateReturnMessage: function ($event) {
                return _vm.$emit("updateReturnMessage", $event)
              },
              updateRescissionsMessage: function ($event) {
                return _vm.$emit("updateRescissionsMessage", $event)
              },
              updateExntensionRequestMessage: function ($event) {
                return _vm.$emit("updateExntensionRequestMessage", $event)
              },
              updateExntensionApprovalMessage: function ($event) {
                return _vm.$emit("updateExntensionApprovalMessage", $event)
              },
              updateExntensionDenialMessage: function ($event) {
                return _vm.$emit("updateExntensionDenialMessage", $event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }