
import Vue, { PropType } from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import * as Config from '@/config';

interface TypeSelect {
  text: string;
  value: string;
}

export interface DataType {
  rulesValidate: any;
  configData: any;
  priviledge: any;
  userName: any;
}

export interface UserInfo {
  // userId: string;
  name: string;
  furigana: string;
  email: string;
}

export default Vue.extend({
  name: 'UserInformation',
  components: {
    InputForm,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    isAction: {
      type: Number,
      default: 0,
    },
    resData: {
      default: () => [],
    },
    userInfo: {
      type: Object as PropType<UserInfo>,
      default: {
        name: '',
        furigana: '',
        email: '',
      },
    },
    confirmDialog: {
      type: Boolean,
      default: false,
    },
    userMsgDialogTitle: {
      type: String,
      default: '',
    },
    userMsgDialogText: {
      type: String,
      default: '',
    },
  },
  watch: {
    //
  },
  computed: {
    isDisabled: {
      get(): boolean {
        let isValid = true;
        isValid = !!this.userInfo.name;
        isValid = isValid && (this.userInfo.name.length <= Config.USER_NAME_MAX_LEN);
        if (this.priviledge === '0') {
          isValid = isValid && !!this.userInfo.email;
        }
        if (isValid) {
          if (this.userInfo.furigana !== undefined && this.userInfo.furigana !== null
          && this.userInfo.furigana !== '') {
            isValid = isValid && (this.userInfo.furigana.length <= Config.USER_FURIGANA_MAX_LEN);
          } else {
            isValid = true;
          }
          if (this.userInfo.email) { // �󕶎���̓X���[
            if (typeof this.userInfo.email !== 'string' ||
                this.userInfo.email.length > 80 ||
                !this.userInfo.email.match('^[a-zA-Z0-9_+-]+(\\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\\.)+[a-zA-Z]{2,}$')) {
              isValid = false;
            } else {
              isValid = isValid && (this.userInfo.email.length <= Config.EMAIL_MAX_LEN);
            }
          }
        }
        return !isValid;
      },
      set(): void {
        return;
      },
    },
  },
  data: (): DataType => ({
    userName: sessionStorage.getItem('userProfileId'),
    rulesValidate: {
      requiredUserName: (userName: string) => !!userName || Config.ERROR_USER_NAME_REQUIRED,
      validateName: (name: string) => {
        if (name !== undefined && name !== null && name !== '') {
          return name.length <= Config.USER_NAME_MAX_LEN || Config.ERROR_USER_NAME_LENGTH;
        } else {
          return true;
        }
      },
      validateUserFurigana: (furigana: string) => {
        if (furigana !== undefined && furigana !== null && furigana !== '') {
          return furigana.length <= Config.USER_FURIGANA_MAX_LEN || Config.ERROR_FURIGANA_LENGTH;
        } else {
          return true;
        }
      },
      // requiredUserEmail: (furigana: string) => !!furigana || Config.ERROR_EMAIL_REQUIRED,
      validateUserEmail: (email: string) => {
        if (email) { // �󕶎���̓X���[
          if (typeof email !== 'string' ||
              email.length > 80 ||
              !email.match('^[a-zA-Z0-9_+-]+(\\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\\.)+[a-zA-Z]{2,}$')) {
            return Config.ERROR_EMAIL_VALIDATE;
          } else {
            return email.length <= Config.EMAIL_MAX_LEN || Config.ERROR_EMAIL_LENGTH;
          }
        } else {
          return true;
        }
      },
    },
    configData: Config,
    priviledge: sessionStorage.getItem('priviledge'),
  }),
  methods: {
    closeConfirmDialog() {
      this.$emit('closeConfirmDialog');
    },
    showConfirmDialog() {
      const result = (this.$refs.userInfoForm as Vue & { validate: () => boolean }).validate();
      if (result) {
        this.$emit('showConfirmDialog');
      }
    },
    updateUserInfo() {
      const result = (this.$refs.userInfoForm as Vue & { validate: () => boolean }).validate();
      if (result) {
        this.$emit('updateUserInfo',  this.userInfo);
      }
    },
    requiredEmail(email: any, priviledge: any) {
      if (priviledge === '0') {
        if (email) {
          return true;
        } else {
          return Config.ERROR_EMAIL_REQUIRED;
        }
      } else {
        return true;
      }
    },
  },
});
