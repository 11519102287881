
import Vue, { PropType } from 'vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import DatePicker from '@/components/molecules/DatePicker/DatePicker.vue';
import DateTimePicker from '@/components/molecules/DateTimePicker/DateTimePicker.vue';
import * as Config from '@/config';
export interface SearchTargetItem {
  label: string;
  timePickerName?: string;
  value: any;
  name: string;
  isDisabled?: boolean;
  valueDate?: string;
  maxLen?: number;
  minDate?: string;
  maxDate?: string;
}

export interface DataType {
  text: string;
  showCustomSearch: boolean;
  targets: any;
  items: any;
  itemsTwo: any;
  dataStatus: any;
  approvalDataStatus: any;
  dataType: any;
  dataExtention: any;
  typeSearch: string;
  configData: any;
  showDialog: boolean;
  approvalStatus: any;
  rules: any;
  valid: boolean;
  emailValid: boolean;
}

export default Vue.extend({
  name: 'Searchbox',
  components: {
    SuccessButton,
    DatePicker,
    DateTimePicker,
  },
  props: {
    searchTargetItems: {
      type: Array as PropType<SearchTargetItem[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dataOfficeCode: {
      default: () => [],
    },
    dataOffice: {
      default: () => [],
    },
    dataArea: {
      default: () => [],
    },
    isCSV: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    gateCode: {
      default: () => [],
    },
    organizations: {
      default: () => [],
    },
    showTimePicker: {
      type: Boolean,
      default: false,
    },
    isAdd: {
      type: Boolean,
      default: false,
    },
    isRegister: {
      type: Boolean,
      default: false,
    },
    isBulkRegistration: {
      type: Boolean,
      default: false,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    isRelease: {
      type: Boolean,
      default: false,
    },
    selected: {
      default: () => [],
    },
    isCheck: {
      type: Boolean,
      default: false,
    },
    priviledge: {
      default: '',
    },
    officeAddButton: {
      type: Boolean,
      default: false,
    },
    applicantAddButton: {
      type: Boolean,
      default: false,
    },
    entranceHistoryPage: {
      type: Boolean,
      default: false,
    },
    logPage: {
      type: Boolean,
      default: false,
    },
    applicantPage: {
      type: Boolean,
      default: false,
    },
    approvalStatusPage: {
      type: Boolean,
      default: false,
    },
    userPage: {
      type: Boolean,
      default: false,
    },
    approvalSettingsPage: {
      type: Boolean,
      default: false,
    },
  },
  data(): DataType {
    return {
      text: '',
      showCustomSearch: false,
      targets: [],
      items: [],
      itemsTwo: [],
      typeSearch: '0',
      dataStatus: [
        {value: 'saved', text: Config.TEXT_SAVED},
        {value: 'submitted', text: Config.TEXT_SUBMITTED},
        {value: 'canceled', text: Config.TEXT_CANCELLED},
        {value: 'returned', text: Config.TEXT_DIFFERENCE_RETURN},
        {value: 'approved', text: Config.TEXT_APPROVED},
        {value: 'rescinded', text: Config.TEXT_RESCINDED},
      ],
      approvalDataStatus: [
        {value: 'submitted', text: Config.TEXT_SUBMITTED},
        {value: 'approved', text: Config.TEXT_APPROVED},
        {value: 'returned', text: Config.TEXT_DIFFERENCE_RETURN},
        {value: 'rescinded', text: Config.TEXT_RESCINDED},
      ],
      dataType: [
        {value: '0', text: Config.TEXT_LOGIN},
        {value: '1', text: Config.TEXT_SEARCH},
        {value: '2', text: Config.TEXT_ADDITION},
        {value: '3', text: Config.TEXT_EDIT},
        {value: '4', text: Config.TEXT_DELETE},
      ],
      dataExtention: [
        {value: 'true', text: Config.TEXT_APPLYING_FOR_EXTENSION},
        {value: 'false', text: Config.TEXT_NO_APPLICATION_FOR_EXTENSION},
      ],
      configData: Config,
      showDialog: false,
      approvalStatus: [
        {value: 'submitted', text: Config.TEXT_SUBMITTED},
        {value: 'approved', text: Config.TEXT_APPROVED},
      ],
      rules: [(v: string) => v.length <= 25 || 'Max 25 characters'],
      valid: false,
      emailValid: false,
    };
  },
  watch: {
    //
  },
  computed: {
    //
  },
  mounted() {
    this.targets = this.searchTargetItems;
  },
  methods: {
    getDate(val: any, index: number) {
      console.log(val);
      console.log(index);
      const name = this.targets[index].name;
      if (name === 'since') {
        if (this.targets.length > index + 1) {
          const nextItem = this.targets[index + 1];
          if (nextItem.name === 'until') {
            nextItem.minDate = val;
          }
        }
      }
      this.targets[index].valueDate = val;
      this.targets[index].value = val;
    },
    getDateE(val: any, index: number) {
      const name = this.targets[index].name;
      if (name === 'since') {
        if (this.targets.length > index + 1) {
          const nextItem = this.targets[index + 1];
          if (nextItem.name === 'until') {
            nextItem.minDate = val;
          }
        }
      }
      this.targets[index].valueDate = val;
      this.targets[index].value = val;
    },
    getTime(val: any, index: number) {
      // let newDateTime = val;
      // if (val.length === 10) {
      //   newDateTime = newDateTime + '00:00';
      // }
      const name = this.targets[index].name;
      if (name === 'since') {
        if (this.targets.length > index + 1) {
          const nextItem = this.targets[index + 1];
          if (nextItem.name === 'until') {
            nextItem.minDate = val;
          }
        }
      }
      // else if (name === 'until') {
      //   if (index > 0) {
      //     const prevItem = this.targets[index - 1];
      //     console.log('prevItem: ' + JSON.stringify(prevItem));
      //     if (prevItem.name === 'since') {
      //       if (prevItem.value > newDateTime) {
      //         isValid = false;
      //       }
      //     }
      //   }
      // }
      this.targets[index].value = val;
    },
    clickShowCustomSearch(value: any) {
      this.showCustomSearch = true;
    },
    included() {
      return [document.querySelector('.included')];
    },
    reset() {
      this.targets.forEach((item: any, i: any) => {
        item.value = '';
      });
    },
    search() {
      const result = (this.$refs.searchValidate as Vue & { validate: () => boolean }).validate();
      console.log(result);
      if (result) {
        this.$emit('search', { target: this.targets});
      }
    },
    validateEmail(email: string) {
      if (email !== undefined && email !== null && email !== '') {
        const pattern = email.match(/^[a-zA-Z0-9_.@-]*$/);
        if (pattern) {
          return true;
        } else {
          return Config.ERROR_EMAIL_VALIDATE;
        }
      } else {
        return true;
      }
    },
    validateUserId: (userId: string) => {
      if (userId !== undefined && userId !== null && userId !== '') {
        const pattern = /^[a-z\d\-_\s]{0,80}$/i;
        return pattern.test(userId) || Config.ERROR_USER_ID_LENGTH_FOR_SEARCH;
      } else {
        return true;
      }
    },
    validateOfficeCode: (officeCode: string) => {
      if (sessionStorage.getItem('priviledge') === '2') {
        return true;
      } else {
        if (officeCode !== undefined && officeCode !== null && officeCode !== '') {
          const pattern = /^[a-z\d\-_\s]{4,20}$/i;
          return pattern.test(officeCode) || Config.ERROR_OFFICE_LENGTH;
        } else {
          return true;
        }
      }
    },
    validateRequestId: (requestId: string) => {
      if (requestId !== undefined && requestId !== null && requestId !== '') {
        const pattern = /^[0-9a-zA-Z]{0,26}$$/i;
        return pattern.test(requestId) || Config.ERROR_APPLICATION_ID_LENGTH;
      } else {
        return true;
      }
    },
  },
});
