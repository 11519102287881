var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-form",
        { ref: "searchValidate" },
        [
          _vm.searchTargetItems.length > 6 &&
          !_vm.entranceHistoryPage &&
          !_vm.approvalStatusPage
            ? _c(
                "div",
                { staticClass: "allig-searchbox row" },
                _vm._l(_vm.targets, function (item, index) {
                  return _c(
                    "v-col",
                    { key: item.name, attrs: { cols: "12", lg: "3" } },
                    [
                      item.name === "since" ||
                      item.name === "begin" ||
                      item.name === "end" ||
                      item.name === "until"
                        ? _c("DateTimePicker", {
                            attrs: {
                              showTimePicker: _vm.showTimePicker,
                              name: item.name,
                              label: item.label,
                              timePickerName: item.timePickerName,
                              value: item.value,
                              minDate: item.minDate,
                              maxDate: item.maxDate,
                              logPage: false,
                            },
                            on: {
                              "get-date": function ($event) {
                                return _vm.getDate($event, index)
                              },
                              "get-time": function ($event) {
                                return _vm.getTime($event, index)
                              },
                            },
                          })
                        : item.name === "office_list"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.dataOffice,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("change-office", $event)
                              },
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "area_list"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.dataArea,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("change-area", $event)
                              },
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "approval_status"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.approvalStatus,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "type"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.dataType,
                              "return-object": "",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "gate_code"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              "return-object": "",
                              items: _vm.gateCode,
                              autocomplete: "off",
                              color: "green",
                              "hide-details": "auto",
                              multiple: "",
                              "deletable-chips": "",
                              chips: "",
                              outlined: "",
                              dense: "",
                              "no-data-text": "",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "is_extension"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : _c("v-text-field", {
                            staticClass: "input-search-box",
                            attrs: {
                              color: "gray",
                              "single-line": "",
                              "hide-details": "",
                              label: item.label,
                              solo: "",
                              disabled: item.isDisabled,
                              maxlength: item.maxLen,
                            },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.search.apply(null, arguments)
                              },
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          }),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm.searchTargetItems && _vm.entranceHistoryPage
            ? _c(
                "div",
                { staticClass: "allig-searchbox row" },
                _vm._l(_vm.targets, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.name,
                      class: _vm.$vuetify.breakpoint.mdAndUp
                        ? "entrance-history-page-size"
                        : "max-size",
                    },
                    [
                      item.name === "since" ||
                      item.name === "begin" ||
                      item.name === "end" ||
                      item.name === "until"
                        ? _c(
                            "v-col",
                            [
                              _c("DateTimePicker", {
                                attrs: {
                                  showTimePicker: _vm.showTimePicker,
                                  name: item.name,
                                  label: item.label,
                                  timePickerName: item.timePickerName,
                                  value: item.value,
                                  minDate: item.minDate,
                                  maxDate: item.maxDate,
                                  logPage: false,
                                },
                                on: {
                                  "get-date": function ($event) {
                                    return _vm.getDate($event, index)
                                  },
                                  "get-time": function ($event) {
                                    return _vm.getTime($event, index)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _c("v-col", [
                            _c(
                              "div",
                              [
                                item.name === "office_list"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.dataOffice,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$emit(
                                            "change-office",
                                            $event
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "area_list"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.dataArea,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$emit(
                                            "change-area",
                                            $event
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "approval_status"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.approvalStatus,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "type"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.dataType,
                                        "return-object": "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "gate_code"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.gateCode,
                                        autocomplete: "off",
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "is_extension"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "user_id"
                                  ? _c("v-text-field", {
                                      staticClass: "input-search-box",
                                      attrs: {
                                        label: item.label,
                                        solo: "",
                                        "hide-details": "auto",
                                        disabled: item.isDisabled,
                                        maxlength: item.maxLen,
                                        rules: [_vm.validateUserId],
                                      },
                                      on: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.search.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name !== "since" &&
                                    item.name !== "begin" &&
                                    item.name !== "end" &&
                                    item.name !== "until"
                                  ? _c("v-text-field", {
                                      staticClass: "input-search-box",
                                      attrs: {
                                        color: "gray",
                                        "single-line": "",
                                        "hide-details": "",
                                        label: item.label,
                                        solo: "",
                                        disabled: item.isDisabled,
                                        maxlength: item.maxLen,
                                      },
                                      on: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.search.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm.searchTargetItems && _vm.logPage
            ? _c(
                "div",
                { staticClass: "allig-searchbox row" },
                _vm._l(_vm.targets, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.name,
                      class: _vm.$vuetify.breakpoint.mdAndUp
                        ? "log-page-size"
                        : "max-size",
                    },
                    [
                      item.name === "since" ||
                      item.name === "begin" ||
                      item.name === "end" ||
                      item.name === "until"
                        ? _c(
                            "v-col",
                            [
                              _c("DateTimePicker", {
                                attrs: {
                                  showTimePicker: _vm.showTimePicker,
                                  name: item.name,
                                  label: item.label,
                                  timePickerName: item.timePickerName,
                                  value: item.value,
                                  minDate: item.minDate,
                                  maxDate: item.maxDate,
                                  logPage: true,
                                },
                                on: {
                                  "get-date": function ($event) {
                                    return _vm.getDate($event, index)
                                  },
                                  "get-time": function ($event) {
                                    return _vm.getTime($event, index)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _c("v-col", [
                            _c(
                              "div",
                              [
                                item.name === "office_list"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.dataOffice,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$emit(
                                            "change-office",
                                            $event
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "area_list"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.dataArea,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$emit(
                                            "change-area",
                                            $event
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "approval_status"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.approvalStatus,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "user_id"
                                  ? _c("v-text-field", {
                                      staticClass: "input-search-box",
                                      attrs: {
                                        label: item.label,
                                        solo: "",
                                        "hide-details": "auto",
                                        disabled: item.isDisabled,
                                        maxlength: item.maxLen,
                                        rules: [_vm.validateUserId],
                                      },
                                      on: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.search.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "type"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.dataType,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "is_extension"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "organizations"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        item: _vm.organizations,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "gate_code"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        "return-object": "",
                                        items: _vm.gateCode,
                                        autocomplete: "off",
                                        color: "green",
                                        "hide-details": "auto",
                                        multiple: "",
                                        "deletable-chips": "",
                                        chips: "",
                                        outlined: "",
                                        dense: "",
                                        "no-data-text": "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name !== "since" &&
                                    item.name !== "begin" &&
                                    item.name !== "end" &&
                                    item.name !== "until"
                                  ? _c("v-text-field", {
                                      staticClass: "input-search-box",
                                      attrs: {
                                        color: "gray",
                                        "single-line": "",
                                        "hide-details": "",
                                        label: item.label,
                                        solo: "",
                                        disabled: item.isDisabled,
                                        maxlength: item.maxLen,
                                      },
                                      on: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.search.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm.searchTargetItems && _vm.applicantPage
            ? _c(
                "div",
                { staticClass: "allig-searchbox row" },
                _vm._l(_vm.targets, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.name,
                      class: _vm.$vuetify.breakpoint.mdAndUp
                        ? "applicant-page-size"
                        : "max-size",
                    },
                    [
                      item.name === "since" ||
                      item.name === "begin" ||
                      item.name === "end" ||
                      item.name === "until"
                        ? _c(
                            "v-col",
                            [
                              _c("DateTimePicker", {
                                attrs: {
                                  showTimePicker: _vm.showTimePicker,
                                  name: item.name,
                                  label: item.label,
                                  timePickerName: item.timePickerName,
                                  value: item.value,
                                  applicantPage: true,
                                  minDate: item.minDate,
                                  maxDate: item.maxDate,
                                  logPage: false,
                                },
                                on: {
                                  "get-date-e": function ($event) {
                                    return _vm.getDateE($event, index)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _c("v-col", [
                            _c(
                              "div",
                              [
                                item.name === "office_list"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.dataOffice,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$emit(
                                            "change-office",
                                            $event
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "area_list"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.dataArea,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$emit(
                                            "change-area",
                                            $event
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "approvalStatus"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.approvalStatus,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "status"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.dataStatus,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "type"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.dataType,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "is_extension"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "organizations"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        item: _vm.organizations,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "gate_code"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        "return-object": "",
                                        items: _vm.gateCode,
                                        autocomplete: "off",
                                        color: "green",
                                        "hide-details": "auto",
                                        multiple: "",
                                        "deletable-chips": "",
                                        chips: "",
                                        outlined: "",
                                        dense: "",
                                        "no-data-text": "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "request_id"
                                  ? _c("v-text-field", {
                                      staticClass: "input-search-box",
                                      attrs: {
                                        label: item.label,
                                        solo: "",
                                        "hide-details": "auto",
                                        disabled: item.isDisabled,
                                        maxlength: item.maxLen,
                                        rules: [_vm.validateRequestId],
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name !== "since" &&
                                    item.name !== "begin" &&
                                    item.name !== "end" &&
                                    item.name !== "until"
                                  ? _c("v-text-field", {
                                      staticClass: "input-search-box",
                                      attrs: {
                                        color: "gray",
                                        "single-line": "",
                                        "hide-details": "",
                                        label: item.label,
                                        solo: "",
                                        disabled: item.isDisabled,
                                        maxlength: item.maxLen,
                                      },
                                      on: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.search.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm.searchTargetItems && _vm.approvalStatusPage
            ? _c(
                "div",
                { staticClass: "allig-searchbox row" },
                _vm._l(_vm.targets, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.name,
                      class: _vm.$vuetify.breakpoint.mdAndUp
                        ? "approval-status-page-size"
                        : "max-size",
                    },
                    [
                      item.name === "since" ||
                      item.name === "begin" ||
                      item.name === "end" ||
                      item.name === "until"
                        ? _c(
                            "v-col",
                            [
                              _c("DateTimePicker", {
                                attrs: {
                                  showTimePicker: _vm.showTimePicker,
                                  name: item.name,
                                  label: item.label,
                                  timePickerName: item.timePickerName,
                                  value: item.value,
                                  approvalStatusPage: _vm.approvalStatusPage,
                                  minDate: item.minDate,
                                  maxDate: item.maxDate,
                                  logPage: false,
                                },
                                on: {
                                  "get-date-e": function ($event) {
                                    return _vm.getDateE($event, index)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _c("v-col", [
                            _c(
                              "div",
                              [
                                item.name === "office_list"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.dataOffice,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$emit(
                                            "change-office",
                                            $event
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "area_list"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.dataArea,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$emit(
                                            "change-area",
                                            $event
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "status"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.approvalDataStatus,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "type"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.dataType,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "is_extension"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        items: _vm.dataExtention,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "organizations"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        item: _vm.organizations,
                                        "return-object": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        "no-data-text": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "gate_code"
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        label: item.label,
                                        "return-object": "",
                                        items: _vm.gateCode,
                                        autocomplete: "off",
                                        color: "green",
                                        "hide-details": "auto",
                                        multiple: "",
                                        "deletable-chips": "",
                                        chips: "",
                                        outlined: "",
                                        dense: "",
                                        "no-data-text": "",
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name === "request_id"
                                  ? _c("v-text-field", {
                                      staticClass: "input-search-box",
                                      attrs: {
                                        label: item.label,
                                        solo: "",
                                        "hide-details": "auto",
                                        disabled: item.isDisabled,
                                        maxlength: item.maxLen,
                                        rules: [_vm.validateRequestId],
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : item.name !== "since" &&
                                    item.name !== "begin" &&
                                    item.name !== "end" &&
                                    item.name !== "until"
                                  ? _c("v-text-field", {
                                      staticClass: "input-search-box",
                                      attrs: {
                                        color: "gray",
                                        "single-line": "",
                                        "hide-details": "",
                                        label: item.label,
                                        solo: "",
                                        disabled: item.isDisabled,
                                        maxlength: item.maxLen,
                                      },
                                      on: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.search.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm.searchTargetItems &&
              (_vm.userPage || _vm.approvalSettingsPage)
            ? _c(
                "div",
                { staticClass: "allig-searchbox row" },
                _vm._l(_vm.targets, function (item, index) {
                  return _c(
                    "v-col",
                    {
                      key: item.name,
                      class: _vm.$vuetify.breakpoint.mdAndUp
                        ? "a-quarter-size"
                        : "max-size",
                    },
                    [
                      item.name === "since" ||
                      item.name === "begin" ||
                      item.name === "end" ||
                      item.name === "until"
                        ? _c("DateTimePicker", {
                            attrs: {
                              showTimePicker: _vm.showTimePicker,
                              label: item.label,
                              timePickerName: item.timePickerName,
                              name: item.name,
                              value: item.value,
                              minDate: item.minDate,
                              maxDate: item.maxDate,
                              logPage: false,
                            },
                            on: {
                              "get-date": function ($event) {
                                return _vm.getDate($event, index)
                              },
                              "get-time": function ($event) {
                                return _vm.getTime($event, index)
                              },
                            },
                          })
                        : item.name === "office_list"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.dataOffice,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("change-office", $event)
                              },
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "area_list"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.dataArea,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("change-area", $event)
                              },
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "status"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.dataStatus,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "approval_status"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.approvalStatus,
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "type"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.dataType,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "is_extension"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.dataExtention,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "organizations"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.organizations,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              multiple: "",
                              "small-chips": "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "gate_code"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              "return-object": "",
                              items: _vm.gateCode,
                              autocomplete: "off",
                              color: "green",
                              "hide-details": "auto",
                              multiple: "",
                              "deletable-chips": "",
                              chips: "",
                              outlined: "",
                              dense: "",
                              "no-data-text": "",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "email"
                        ? _c("v-text-field", {
                            staticClass: "input-search-box",
                            attrs: {
                              label: item.label,
                              solo: "",
                              "hide-details": "auto",
                              disabled: item.isDisabled,
                              maxlength: item.maxLen,
                              rules: [_vm.validateEmail],
                            },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.search.apply(null, arguments)
                              },
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "user_id"
                        ? _c("v-text-field", {
                            staticClass: "input-search-box",
                            attrs: {
                              label: item.label,
                              solo: "",
                              "hide-details": "auto",
                              disabled: item.isDisabled,
                              maxlength: item.maxLen,
                              rules: [_vm.validateUserId],
                            },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.search.apply(null, arguments)
                              },
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : _c("v-text-field", {
                            staticClass: "input-search-box",
                            attrs: {
                              label: item.label,
                              solo: "",
                              "hide-details": "auto",
                              disabled: item.isDisabled,
                              maxlength: item.maxLen,
                            },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.search.apply(null, arguments)
                              },
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          }),
                    ],
                    1
                  )
                }),
                1
              )
            : _c(
                "div",
                { staticClass: "allig-searchbox row" },
                _vm._l(_vm.targets, function (item, index) {
                  return _c(
                    "v-col",
                    {
                      key: item.name,
                      class: _vm.$vuetify.breakpoint.mdAndUp
                        ? "a-quarter-size"
                        : "max-size",
                    },
                    [
                      item.name === "since" ||
                      item.name === "begin" ||
                      item.name === "end" ||
                      item.name === "until"
                        ? _c("DateTimePicker", {
                            attrs: {
                              showTimePicker: _vm.showTimePicker,
                              label: item.label,
                              timePickerName: item.timePickerName,
                              name: item.name,
                              value: item.value,
                              minDate: item.minDate,
                              maxDate: item.maxDate,
                              logPage: false,
                            },
                            on: {
                              "get-date": function ($event) {
                                return _vm.getDate($event, index)
                              },
                              "get-time": function ($event) {
                                return _vm.getTime($event, index)
                              },
                            },
                          })
                        : item.name === "office_list"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.dataOffice,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("change-office", $event)
                              },
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "office_code_list"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.dataOfficeCode,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("change-office", $event)
                              },
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "office_name"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.dataOffice,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "area_list"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.dataArea,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("change-area", $event)
                              },
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "status"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.dataStatus,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "approval_status"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.approvalStatus,
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "type"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.dataType,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "is_extension"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              items: _vm.dataExtention,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "organizations"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              item: _vm.organizations,
                              "return-object": "",
                              "hide-details": "",
                              outlined: "",
                              dense: "",
                              solo: "",
                              "no-data-text": "",
                              clearable: "",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "gate_code"
                        ? _c("v-autocomplete", {
                            attrs: {
                              label: item.label,
                              "return-object": "",
                              items: _vm.gateCode,
                              autocomplete: "off",
                              color: "green",
                              "hide-details": "auto",
                              multiple: "",
                              "deletable-chips": "",
                              chips: "",
                              outlined: "",
                              dense: "",
                              "no-data-text": "",
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "email"
                        ? _c("v-text-field", {
                            staticClass: "input-search-box",
                            attrs: {
                              label: item.label,
                              solo: "",
                              "hide-details": "auto",
                              disabled: item.isDisabled,
                              maxlength: item.maxLen,
                              rules: [_vm.validateEmail],
                            },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.search.apply(null, arguments)
                              },
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "user_id"
                        ? _c("v-text-field", {
                            staticClass: "input-search-box",
                            attrs: {
                              label: item.label,
                              solo: "",
                              "hide-details": "auto",
                              disabled: item.isDisabled,
                              maxlength: item.maxLen,
                              rules: [_vm.validateUserId],
                            },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.search.apply(null, arguments)
                              },
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : item.name === "office_code"
                        ? _c("v-text-field", {
                            staticClass: "input-search-box",
                            attrs: {
                              label: item.label,
                              solo: "",
                              "hide-details": "auto",
                              disabled: item.isDisabled,
                              maxlength: item.maxLen,
                              rules: [_vm.validateOfficeCode],
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : _c("v-text-field", {
                            staticClass: "input-search-box",
                            attrs: {
                              label: item.label,
                              solo: "",
                              "hide-details": "auto",
                              disabled: item.isDisabled,
                              maxlength: item.maxLen,
                            },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.search.apply(null, arguments)
                              },
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          }),
                    ],
                    1
                  )
                }),
                1
              ),
          _c(
            "v-row",
            { staticClass: "mt-2 button-row", attrs: { justify: "start" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "col-btn-search",
                  attrs: { cols: "3", sm: "3", md: "2", lg: "2" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-secondary btn-search-box btnSearch",
                      attrs: { disabled: _vm.disabled, outlined: "" },
                      on: {
                        click: function ($event) {
                          return _vm.search()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.configData.TEXT_SEARCH) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _vm.isAdd
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary ml-0 mr-5",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("add")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.configData.TEXT_ADD_USER))]
                      )
                    : _vm.isRegister
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary ml-0 mr-5",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("add")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.configData.TEXT_REGISTER))]
                      )
                    : _vm.applicantAddButton && _vm.priviledge === "3"
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary ml-0 mr-5",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("add")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.configData.TEXT_BUTTON_CREATE))]
                      )
                    : _vm.officeAddButton && _vm.priviledge !== "1"
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary ml-0 mr-5",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("add")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.configData.TEXT_ADD_USER))]
                      )
                    : _vm._e(),
                  _vm.isBulkRegistration
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary mr-5",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("bulkRegistration")
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.configData.TEXT_BULK_REGISTRATION) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.isRelease && !_vm.isCheck
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary mr-5",
                          attrs: {
                            outlined: "",
                            disabled: _vm.selected.length === 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("delete")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.configData.TEXT_RELEASE))]
                      )
                    : _vm._e(),
                  _vm.isDelete && !_vm.isCheck
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary mr-5",
                          attrs: {
                            outlined: "",
                            disabled: _vm.selected.length === 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("delete")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.configData.TEXT_DELETE_USER))]
                      )
                    : _vm._e(),
                  _vm.isCSV
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary mr-5",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("export")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.configData.TEXT_BUTTON_CSV) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }