
  import Vue, { PropType } from 'vue';
  import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
  import ManualListTable from '@/components/organisms/ManualListTable/ManualListTable.vue';
  import * as Config from '../../../config';
  export interface DataType {
    configData: any;
  }

  export default Vue.extend({
    name: 'ManualListTemplate',
    components: {
        ManualListTable,
        Breadcrumbs,
    },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      resData: {
        default() {
            return {};
        },
      },
    },
    data: (): DataType => ({
      configData: Config,
    }),
    computed: {
        breadcrumbs: {
            get(): BreadcrumbItem[] {
                return [
                    { text: Config.TEXT_MANUAL, disabled: true },
                ];
            },
        },
    },
    methods: {
        //
    },
    mounted() {
        //
    },
  });
