
import { Vue } from 'vue-property-decorator';
import LogTemplate from '@/components/templates/LogTemplate/LogTemplate.vue';
import * as Config from '@/config';
import auth from '@/auth';
import moment from 'moment';
export interface DataType {
  // Props
  loading: boolean;
  isRegistering: boolean;
  loadingCSV: boolean;
  keyword: string;
  resData: any;
  resDataExport: any;
  userInfo: any;
  logTotalCount: number;
  loadingSearch: boolean;
  data: any;
  exportData: any;
  arrUser: any;
  listDataExport: any;
}

export default Vue.extend({
  name: 'LogPage',
  components: {
    LogTemplate,
  },
  data: (): DataType => ({
    // Props
    loading: false,
    isRegistering: false, // default: false -> reading data
    loadingCSV: false,
    keyword: '',
    resData: [],
    resDataExport: [],
    logTotalCount: 0,
    loadingSearch: false,
    data: null,
    userInfo: {},
    exportData: [],
    arrUser: [],
    listDataExport: [],
  }),
  watch: {
    loading(value) {
      this.$emit('loading', value);
      if (value) {
        this.$emit('textCircle', this.isRegistering ? Config.TEXT_PROGRESS_REGISTERING : Config.TEXT_PROGRESS_READING);
      } else if (this.isRegistering) {
        this.isRegistering = false;
      }
    },
    loadingCSV(value) {
      this.$emit('loading', value);
    },
  },
  mounted() {
    this.$emit('activeMenu', 'log');
    const dateE = moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59';
    const dateS = moment(new Date()).format('YYYY-MM-DD') + ' 00:00';
    this.keyword = '&since=' + auth.formatISOString(dateS) + '&until=' + auth.formatISOString(dateE);
    this.getLogList();
    sessionStorage.removeItem('displayOptionLog');

  },
  created() {
    //
  },
  methods: {
    getCurrentItems(item: any) {
      this.listDataExport = item;
    },
    changeColumn(value: any): any {
      this.loading = true;
      setTimeout(() => {this.loading = false; }, 300);
    },
    exportCSV(value: any): any {
      this.exportData = [];
      this.loadingCSV = true;
      this.getExportData();
    },
    searchLog(args: any): any {
      this.listDataExport = [];
      sessionStorage.removeItem('displayOptionLog');
      this.loadingSearch = true;
      this.keyword = '';
      this.resData = [];
      this.logTotalCount = 0;
      args.target.forEach((element: any, key: any) => {
        if (element.value !== undefined && element.value !== null && element.value !== '') {
          let value = element.value;
          if (element.name === 'type') {
            value = element.value.value;
            this.keyword += element.name + '=' + value + '&';
          } else if (element.name === 'user_id' && element.value.length > Config.USER_ID_MAX_LEN) {
            this.keyword += element.name + '=' + element.value.slice(0, Config.USER_ID_MAX_LEN) + '&';
          } else if (element.name === 'user_name' && element.value.length > Config.USER_NAME_MAX_LEN) {
            this.keyword += element.name + '=' + element.value.slice(0, Config.USER_NAME_MAX_LEN) + '&';
          } else {
            if (element.name === 'since' || element.name === 'until') {
              if (element.name === 'until') {
                const arrD = (element.value).split(' '); // [days, times]
                const arrH = (arrD[1]).split(':'); // [hours, minutes]
                const valueTime = arrD[0] + ' ' + arrH[0] + ':' + arrH[1] + ':59';
                value = auth.formatISOString(valueTime);
              } else {
                value = auth.formatISOString(element.value);
              }
            }
            this.keyword += element.name + '=' + value + '&';
          }
        }
      });
      this.getLogList();
    },
    async getExportData(): Promise<any> {
      if (!this.isEmpty(this.listDataExport) && this.listDataExport.length > 0) {
        for (const item of this.listDataExport) {
          let templateExport: any = {};
          if (sessionStorage.hasOwnProperty('displayOptionLog')) {
            let displayOption = sessionStorage.getItem('displayOptionLog') as string;
            displayOption = displayOption.replace(/'/g, '"');
            displayOption = JSON.parse(displayOption);
            if (displayOption.includes('timestamp')) {
              const timestamp = Config.TEXT_DATE_AND_TIME;
              templateExport[timestamp] = auth.converDateShort(item.timestamp);
            }
            if (displayOption.includes('type')) {
              const type = Config.TEXT_COL_OPERATION_DISTINCTION;
              templateExport[type] = auth.convertUserType(item.type);
            }
            if (displayOption.includes('user_id')) {
              const useID = Config.TEXT_USER_ID;
              templateExport[useID] = auth.getDefaultStringVal(item.user_id);
            }
            if (displayOption.includes('user_name')) {
              const userName = Config.TEXT_USER_NAME;
              templateExport[userName] = auth.getDefaultStringVal(item.user_name);
            }
            if (displayOption.includes('organizations')) {
              const organizations = Config.TEXT_COL_COMPANY_DEPARTMENT;
              templateExport[organizations] = '"' + item.organizations.replaceAll(', ', ',') + '"';
            }
            if (displayOption.includes('message')) {
              const message = Config.TEXT_USER_NAME;
              templateExport[message] = auth.getDefaultStringVal(item.message);
            }
          } else {
            templateExport = {
              [Config.TEXT_DATE_AND_TIME]: auth.converDateShort(item.timestamp),
              [Config.TEXT_COL_OPERATION_DISTINCTION]: auth.convertUserType(item.type),
              [Config.TEXT_USER_ID]: auth.getDefaultStringVal(item.user_id),
              [Config.TEXT_USER_NAME]: auth.getDefaultStringVal(item.user_name),
              [Config.TEXT_COL_COMPANY_DEPARTMENT]: '"' + item.organizations.replaceAll(', ', ',') + '"',
              [Config.TEXT_OPERATION_CONTENT]: auth.getDefaultStringVal(item.message),
            };
          }
          await this.exportData.push(templateExport);
        }
        auth.downloadCSV(this.exportData, Config.TEXT_OPERATION_LOG);
        this.loadingCSV = false;
      } else {
        let templateExport: any = {};
        if (sessionStorage.hasOwnProperty('displayOptionLog')) {
          let displayOption = sessionStorage.getItem('displayOptionLog') as string;
          displayOption = displayOption.replace(/'/g, '"');
          displayOption = JSON.parse(displayOption);
          if (displayOption.includes('timestamp')) {
            const timestamp = Config.TEXT_DATE_AND_TIME;
            templateExport[timestamp] = '';
          }
          if (displayOption.includes('type')) {
            const type = Config.TEXT_COL_OPERATION_DISTINCTION;
            templateExport[type] = '';
          }
          if (displayOption.includes('user_id')) {
            const useID = Config.TEXT_USER_ID;
            templateExport[useID] = '';
          }
          if (displayOption.includes('user_name')) {
            const userName = Config.TEXT_USER_NAME;
            templateExport[userName] = '';
          }
          if (displayOption.includes('organizations')) {
            const organizations = Config.TEXT_COL_COMPANY_DEPARTMENT;
            templateExport[organizations] = '';
          }
          if (displayOption.includes('message')) {
            const message = Config.TEXT_USER_NAME;
            templateExport[message] = '';
          }
        } else {
          templateExport = {
            [Config.TEXT_DATE_AND_TIME]: '',
            [Config.TEXT_COL_OPERATION_DISTINCTION]: '',
            [Config.TEXT_USER_ID]: '',
            [Config.TEXT_USER_NAME]: '',
            [Config.TEXT_COL_COMPANY_DEPARTMENT]: '',
            [Config.TEXT_OPERATION_CONTENT]: '',
          };
        }
        await this.exportData.push(templateExport);
        auth.downloadCSV(this.exportData, Config.TEXT_OPERATION_LOG);
        this.loadingCSV = false;
      }
    },
    getLogList() {
      let apiLink = Config.LOGS_API;
      if (this.keyword !== '') {
        apiLink = apiLink + '?' + this.keyword.replace(/&\s*$/, '');
      }
      this.resData = [];
      this.loading = true;
      this.$http.get(apiLink)
      .then(async (response: any) => {
        if (response.body.total > 0) {
          if (response.body.total >= Config.LOG_EXCEEDS_ITEM) {
            alert(Config.ERROR_LOG_EXCEEDS_ITEM_1 + '\n' + Config.ERROR_LOG_EXCEEDS_ITEM_2);
            this.resData = response.body.logs.slice(0, Config.LOG_EXCEEDS_ITEM);
          } else {
            this.resData = response.body.logs;
          }
          this.logTotalCount = response.body.total;
          this.resData.forEach(async (element: any, key: any) => {
              element.organizations = element.organizations.map((item: { label: any; }) => item.label).join(', ');
          });
        }
        this.loading = false;
        this.loadingSearch = false;
      })
      .then( null, (err: any) => {
        this.loadingSearch = false;
        alert(Config.ERROR_LOG_EXCEEDS_ITEM_3);
        this.loading = false;
      }); // catch
    },
    getUserItem(userId: any, key: any) {
      return new Promise((resolve, reject) => {
        const apiLink = Config.USERS_LIST_API + '/' + encodeURIComponent(userId);
        this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.user !== null) {
            const res = response.body.user;
            this.arrUser.push({
              text: res.name,
              value: userId,
            });
            this.resData[key].user_name = res.name;
            if (res.organizations.length > 0) {
              for (const org of res.organizations) {
                this.resData[key].organizations.push(org.label);
              }
            }
            resolve(true);
          }
        })
        .then(null, (err: any) => {
          this.resData[key].user_name = '';
          resolve(false);
        });
      });
    },
    getUserItemForExport(log: any, userId: any) {
      return new Promise((resolve, reject) => {
        const apiLink = Config.USERS_LIST_API + '?user_id=' + encodeURIComponent(userId);
        this.$http.get(apiLink)
        .then((response: any) => {
          if (!this.isEmpty(response.body.users) && response.body.users.length > 0) {
            const res = response.body.users[0];
            log.user_name = res.name;
            log.organizations = [];
            if (res.organizations.length > 0) {
              for (const org of res.organizations) {
                log.organizations.push(org.label);
              }
            }
            resolve(true);
          }
        })
        .then(null, (err: any) => {
          log.user_name = '';
          log.organizations = [];
          resolve(false);
        });
      });
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
  },
});
