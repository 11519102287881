
import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
import ApprovalStatusList from '@/components/organisms/ApprovalStatusList/ApprovalStatusList.vue';
import ApprovalStatusForm from '@/components/organisms/ApprovalStatusForm/ApprovalStatusForm.vue';
import * as Config from '../../../config';
export interface DataType {
  searchTargetItems: SearchTargetItem[];
  configData: any;
}

export default Vue.extend({
  name: 'ApprovalStatusTemplate',
  components: {
    ApprovalStatusList,
    Searchbox,
    Breadcrumbs,
    ApprovalStatusForm,
  },
  props: {
    confirmDialogFinish: {
      type: Boolean,
      default: false,
    },
    loadingSearch: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadMore: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    openExtensions: {
      type: Boolean,
      default: false,
    },
    isAction: {
      type: Number,
      default: 0,
    },
    resData: {
      default: () => [],
    },
    dataOffice: {
      default: () => [],
    },
    dataArea: {
      default: () => [],
    },
    approvalStatusTotalCount: {
      type: Number,
      default: 1,
    },
    page: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 100,
    },
    resExtData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: (): DataType => ({
    searchTargetItems: sessionStorage.getItem('priviledge') === '1' ? [
      { label: Config.TEXT_APPLICATION_EXTENSION, name: 'is_extension', value: ''},
      { label: Config.TEXT_COL_STATUS, name: 'status', value: ''},
      { label: Config.TEXT_COL_APPLICATION_NUMBER, name: 'request_id', value: '', maxLen: Config.REQUEST_ID_MAX_LEN},
      { label: Config.TEXT_COL_MANAGEMENT_OFFICE, name: 'office_list', value: ''},
      { label: Config.TEXT_AREA, name: 'area_list', value: ''},
      { label: Config.TEXT_WORK_START_DATE, name: 'since', value: ''},
      { label: Config.TEXT_WORK_END_DATE, name: 'until', value: ''},
    ] : [
      { label: Config.TEXT_APPLICATION_EXTENSION, name: 'is_extension', value: ''},
      { label: Config.TEXT_COL_STATUS, name: 'status', value: ''},
      { label: Config.TEXT_COL_APPLICATION_NUMBER, name: 'request_id', value: '', maxLen: Config.REQUEST_ID_MAX_LEN},
      sessionStorage.getItem('priviledge')  === '0' ?
      { label: Config.TEXT_COL_MANAGEMENT_OFFICE, name: 'office_list', value: ''} :
      { label: Config.TEXT_COL_MANAGEMENT_OFFICE, name: 'office_code',
        value: sessionStorage.getItem('office_name'), isDisabled: true},
      { label: Config.TEXT_AREA, name: 'area_list', value: ''},
      { label: Config.TEXT_WORK_START_DATE, name: 'since', value: ''},
      { label: Config.TEXT_WORK_END_DATE, name: 'until', value: ''},
    ],
    configData: Config,
  }),
  mounted() {
    //
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: Config.TEXT_APPROVAL_STATUS_LIST, disabled: true },
        ];
      },
    },
  },
  methods: {
    changeOffice(args: any): any {
      if (args === null || args.value === null || args.value === '') {
        this.searchTargetItems[4].value = '';
      }
      this.$emit('change-office', args);
    },
  },
});
