var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("applicant-add-template", {
    attrs: {
      loading: _vm.loading,
      isDataLoaded: _vm.isDataLoaded,
      isError: _vm.isError,
      resData: _vm.resData,
      resDataAreas: _vm.resDataAreas,
      resDataOffice: _vm.resDataOffice,
      resDataGates: _vm.resDataGates,
      resCardData: _vm.resCardData,
      isAction: _vm.isAction,
      dataItem: _vm.dataItem,
      resDataAreaItem: _vm.resDataAreaItem,
      resDataGateItem: _vm.resDataGateItem,
      confirmDialog: _vm.confirmDialog,
    },
    on: {
      showDialog: function ($event) {
        return _vm.showDialog($event)
      },
      saveEntry: function ($event) {
        return _vm.saveEntry($event)
      },
      subEntry: function ($event) {
        return _vm.subEntry($event)
      },
      sendSubmissions: function ($event) {
        return _vm.sendSubmissions($event)
      },
      sendSubmissionsEdit: function ($event) {
        return _vm.sendSubmissionsEdit($event)
      },
      closeConfirmDialog: function ($event) {
        return _vm.closeConfirmDialog()
      },
      reSubEntry: function ($event) {
        return _vm.reSubEntry($event)
      },
      updateEntry: function ($event) {
        return _vm.updateEntry($event)
      },
      "change-office": function ($event) {
        return _vm.changeOffice($event)
      },
      "change-area": function ($event) {
        return _vm.changeArea($event)
      },
      "reset-message": function ($event) {
        return _vm.resetMessage($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }