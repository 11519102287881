var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("approver-settings-template", {
    attrs: {
      loading: _vm.loading,
      resData: _vm.resData,
      approverTotalCount: _vm.approverTotalCount,
      isAction: _vm.isAction,
      userInfo: _vm.userInfo,
      dataOffice: _vm.dataOffice,
      office_name: _vm.office_name,
      organizations: _vm.organizations,
      loadingSearch: _vm.loadingSearch,
      isShowAddDialog: _vm.isShowAddDialog,
      deleteApproverSetting: _vm.deleteApproverSetting,
      isResetData: _vm.isResetData,
      errorMessageProcess: _vm.errorMessageProcess,
      isDeleteFinish: _vm.isDeleteFinish,
      errorUser_id: _vm.errorUser_id,
      countDelApprover: _vm.countDelApprover,
    },
    on: {
      "search-user": function ($event) {
        return _vm.search($event)
      },
      "change-column": function ($event) {
        return _vm.changeColumn($event)
      },
      "enter-userId": function ($event) {
        return _vm.enterUserId($event)
      },
      showAddDialog: function ($event) {
        return _vm.showAddDialog()
      },
      closeAddDialog: function ($event) {
        return _vm.closeAddDialog()
      },
      "add-approver": function ($event) {
        return _vm.addApprover()
      },
      "delete-approver": function ($event) {
        return _vm.deleteApprover($event)
      },
      export: function ($event) {
        return _vm.exportCSV($event)
      },
      showDeleteApproverSettingDialog: function ($event) {
        return _vm.showDeleteApproverSettingDialog()
      },
      closeDeleteDialog: function ($event) {
        return _vm.closeDeleteDialog()
      },
      "close-finish": function ($event) {
        return _vm.closeFinish($event)
      },
      "get-current-items": function ($event) {
        return _vm.getCurrentItems($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }