
import Vue, { PropType } from 'vue';
import * as Config from '@/config';
import ChangeInitialPasswordTemplate from '@/components/templates/ChangeInitialPasswordTemplate/ChangeInitialPasswordTemplate.vue';

export interface DataType {
  loading: boolean;
  errorMessage: any;
  error: string;
}

export default Vue.extend({
  name: 'ChangeInitialPasswordPage',
  components: {
    ChangeInitialPasswordTemplate,
  },
  props: {
    //
  },
  data: (): DataType => ({
    loading: false,
    errorMessage: {
      ownerId: '',
      userId: '',
      password: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    error: '',
  }),
  mounted() {
    //
  },
  created() {
    //
  },
  methods: {
    formSubmitChangePassword(data: any) {
      this.validateBeforeSubmit(data.credentials);
    },
    changeInitialPassword(data: any) {
      if (this.validateBeforeSubmit(data.credentials)) {
        this.loading = true;
        const credentials = {
          owner_id: data.credentials.ownerId,
          user_id: data.credentials.username,
          old_password: data.credentials.password,
          new_password: data.credentials.newPassword,
        };
        console.log('data', data);
        this.$http.post(Config.CHANGE_INITIAL_PASSWORD_API, JSON.stringify(credentials))
        .then((response: any) => {
          if (response.body.message === 'Success') {
            this.loading = false;
            sessionStorage.removeItem('newPasswordRequired');
            sessionStorage.setItem('ownerId', credentials.owner_id);
            sessionStorage.setItem('userId', credentials.user_id);
            alert(Config.TEXT_TITLE_CHANGE_PASS_SUCCESS);
            this.goPageLogin();
          }
        })
        .then( null, (err: any) => {
          this.loading = false;
          this.errorMessage.confirmNewPassword = Config.ERROR_LOGIN_INFO_MISMATCH;
        });
      }
    },
    goPageLogin() {
      this.$router.push({ path: Config.LOGIN,
                          query: { kanriId: sessionStorage.getItem('ownerId'),
                                    userId: sessionStorage.getItem('userId') } });
    },
    validateBeforeSubmit(data: any) {
      let check = true;
      if (data.ownerId === null || data.ownerId === '') {
        this.errorMessage.ownerId = Config.ERROR_OWNER_ID;
        check = false;
      } else {
        const pattern = /^[a-z\d\s]{3,31}$/i;
        if (pattern.test(data.ownerId)) {
          this.errorMessage.ownerId = '';
        } else {
          this.errorMessage.ownerId = Config.ERROR_OWNER_ID_LENGTH;
          check = false;
        }
      }
      if (data.username === null || data.username === '') {
        this.errorMessage.userId = Config.ERROR_USER_ID;
        check = false;
      } else {
        const pattern = /^[a-z\d\-_\s]{4,80}$/i;
        if (pattern.test(data.ownerId)) {
          this.errorMessage.userId = '';
        } else {
          this.errorMessage.userId = Config.ERROR_USER_ID_LENGTH;
          check = false;
        }
      }
      if (data.password === null || data.password === '') {
        this.errorMessage.password = Config.ERROR_CURRENT_PASSWORD_REQUIRED;
        check = false;
      } else if (data.password.match('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$')) {
        this.errorMessage.password = '';
      } else {
        this.errorMessage.password = Config.ERROR_CURRENT_PASSWORD_LENGTH;
        check = false;
      }
      if (data.newPassword === null || data.newPassword === '') {
        this.errorMessage.newPassword = Config.ERROR_NEW_PASSWORD;
        check = false;
      } else if (data.newPassword.match('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$')) {
        this.errorMessage.newPassword = '';
      } else {
        this.errorMessage.newPassword = Config.ERROR_NEW_PASSWORD_LENGTH;
        check = false;
      }
      if (data.newPassword !== data.confirmNewPassword) {
        this.errorMessage.confirmNewPassword = Config.ERROR_CONFIRM_PASSWORD;
        check = false;
      } else {
        this.errorMessage.confirmNewPassword = '';
      }
      return check;
    },
  },
});
