var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-container", [
        _c(
          "div",
          { staticClass: "domain-code" },
          [
            _vm.isSystemAdmin
              ? _c(
                  "v-row",
                  [
                    _c("input-form", {
                      attrs: {
                        title: _vm.configData.TEXT_AFFILIATION,
                        headerCols: 3,
                        inputType: "select",
                        selectItems: _vm.domainItems,
                      },
                      on: {
                        "change-select": function ($event) {
                          return _vm.$emit("change-select", $event)
                        },
                      },
                      model: {
                        value: _vm.emailSettings.domain_code,
                        callback: function ($$v) {
                          _vm.$set(_vm.emailSettings, "domain_code", $$v)
                        },
                        expression: "emailSettings.domain_code",
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "v-row",
                  [
                    _vm.emailSettings.domain_name !== null &&
                    _vm.emailSettings.domain_name !== ""
                      ? _c("input-form", {
                          attrs: {
                            title: _vm.configData.TEXT_AFFILIATION,
                            headerCols: 3,
                            inputType: "text",
                            disabled: "",
                          },
                          model: {
                            value: _vm.emailSettings.domain_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.emailSettings, "domain_name", $$v)
                            },
                            expression: "emailSettings.domain_name",
                          },
                        })
                      : _c("input-form", {
                          attrs: {
                            title: _vm.configData.TEXT_AFFILIATION,
                            headerCols: 3,
                            inputType: "text",
                            disabled: "",
                          },
                          model: {
                            value: _vm.emailSettings.domain_code,
                            callback: function ($$v) {
                              _vm.$set(_vm.emailSettings, "domain_code", $$v)
                            },
                            expression: "emailSettings.domain_code",
                          },
                        }),
                  ],
                  1
                ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "round-tab tab-vertical" },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", xl: "2", lg: "3" } },
                  [
                    _c(
                      "v-tabs",
                      {
                        attrs: { transition: false, vertical: "" },
                        model: {
                          value: _vm.tab,
                          callback: function ($$v) {
                            _vm.tab = $$v
                          },
                          expression: "tab",
                        },
                      },
                      _vm._l(_vm.actionItems, function (item) {
                        return _c("v-tab", { key: item.tabname }, [
                          _vm._v(" " + _vm._s(item.title) + " "),
                        ])
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  [
                    _c(
                      "v-tabs-items",
                      {
                        model: {
                          value: _vm.tab,
                          callback: function ($$v) {
                            _vm.tab = $$v
                          },
                          expression: "tab",
                        },
                      },
                      _vm._l(_vm.actionItems, function (item) {
                        return _c(
                          "v-tab-item",
                          {
                            key: item.tabname,
                            attrs: {
                              transition: false,
                              "reverse-transition": false,
                            },
                          },
                          [
                            item.tabname === "submittion"
                              ? _c(
                                  "v-form",
                                  {
                                    ref: "SubmittionMessageForm",
                                    refInFor: true,
                                    attrs: { "lazy-validation": "" },
                                    model: {
                                      value: _vm.isFormValidSubmitted,
                                      callback: function ($$v) {
                                        _vm.isFormValidSubmitted = $$v
                                      },
                                      expression: "isFormValidSubmitted",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { staticClass: "mt-0 pt-0" },
                                              [
                                                _c("v-textarea", {
                                                  class: _vm.$vuetify.breakpoint
                                                    .lgAndUp
                                                    ? "tab-value"
                                                    : "",
                                                  attrs: {
                                                    inputType: "text",
                                                    rules: [
                                                      _vm.rulesValidate
                                                        .validateNotificationMessage,
                                                      _vm.rulesValidate
                                                        .validateHtmlTag,
                                                    ],
                                                    rows: _vm.$vuetify
                                                      .breakpoint.lgAndUp
                                                      ? 15
                                                      : 7,
                                                    "background-color": "white",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.emailSettings
                                                        .submittion,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.emailSettings,
                                                        "submittion",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "emailSettings.submittion",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "end" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "btn-primary mb-2",
                                                attrs: {
                                                  outlined: "",
                                                  type: "button",
                                                  disabled:
                                                    !_vm.isFormValidSubmitted,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showConfirmEmailDialog(
                                                      "updateSubmittionMessage",
                                                      _vm.emailSettings
                                                        .submittion
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.configData.TEXT_RENEW
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.tabname === "cancellation"
                              ? _c(
                                  "v-form",
                                  {
                                    ref: "CancellationMessageForm",
                                    refInFor: true,
                                    attrs: { "lazy-validation": "" },
                                    model: {
                                      value: _vm.isFormValidCancel,
                                      callback: function ($$v) {
                                        _vm.isFormValidCancel = $$v
                                      },
                                      expression: "isFormValidCancel",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { staticClass: "mt-0 pt-0" },
                                              [
                                                _c("v-textarea", {
                                                  class: _vm.$vuetify.breakpoint
                                                    .lgAndUp
                                                    ? "tab-value"
                                                    : "",
                                                  attrs: {
                                                    inputType: "text",
                                                    rules: [
                                                      _vm.rulesValidate
                                                        .validateNotificationMessage,
                                                      _vm.rulesValidate
                                                        .validateHtmlTag,
                                                    ],
                                                    rows: _vm.$vuetify
                                                      .breakpoint.lgAndUp
                                                      ? 15
                                                      : 7,
                                                    "background-color": "white",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.emailSettings
                                                        .cancellation,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.emailSettings,
                                                        "cancellation",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "emailSettings.cancellation",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "end" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "btn-primary mb-2",
                                                attrs: {
                                                  outlined: "",
                                                  type: "button",
                                                  disabled:
                                                    !_vm.isFormValidCancel,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showConfirmEmailDialog(
                                                      "updateCancelationMessage",
                                                      _vm.emailSettings
                                                        .cancellation
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.configData.TEXT_RENEW
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.tabname === "approval"
                              ? _c(
                                  "v-form",
                                  {
                                    ref: "SubmittionMessageForm",
                                    refInFor: true,
                                    attrs: { "lazy-validation": "" },
                                    model: {
                                      value: _vm.isFormValidApproval,
                                      callback: function ($$v) {
                                        _vm.isFormValidApproval = $$v
                                      },
                                      expression: "isFormValidApproval",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { staticClass: "mt-0 pt-0" },
                                              [
                                                _c("v-textarea", {
                                                  class: _vm.$vuetify.breakpoint
                                                    .lgAndUp
                                                    ? "tab-value"
                                                    : "",
                                                  attrs: {
                                                    inputType: "text",
                                                    rules: [
                                                      _vm.rulesValidate
                                                        .validateNotificationMessage,
                                                      _vm.rulesValidate
                                                        .validateHtmlTag,
                                                    ],
                                                    rows: _vm.$vuetify
                                                      .breakpoint.lgAndUp
                                                      ? 15
                                                      : 7,
                                                    "background-color": "white",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.emailSettings
                                                        .approval,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.emailSettings,
                                                        "approval",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "emailSettings.approval",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "end" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "btn-primary mb-2",
                                                attrs: {
                                                  outlined: "",
                                                  type: "button",
                                                  disabled:
                                                    !_vm.isFormValidApproval,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showConfirmEmailDialog(
                                                      "updateApprovalMessage",
                                                      _vm.emailSettings.approval
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.configData.TEXT_RENEW
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.tabname === "return"
                              ? _c(
                                  "v-form",
                                  {
                                    ref: "SubmittionMessageForm",
                                    refInFor: true,
                                    attrs: { "lazy-validation": "" },
                                    model: {
                                      value: _vm.isFormValidReturn,
                                      callback: function ($$v) {
                                        _vm.isFormValidReturn = $$v
                                      },
                                      expression: "isFormValidReturn",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { staticClass: "mt-0 pt-0" },
                                              [
                                                _c("v-textarea", {
                                                  class: _vm.$vuetify.breakpoint
                                                    .lgAndUp
                                                    ? "tab-value"
                                                    : "",
                                                  attrs: {
                                                    inputType: "text",
                                                    rules: [
                                                      _vm.rulesValidate
                                                        .validateNotificationMessage,
                                                      _vm.rulesValidate
                                                        .validateHtmlTag,
                                                    ],
                                                    rows: _vm.$vuetify
                                                      .breakpoint.lgAndUp
                                                      ? 15
                                                      : 7,
                                                    "background-color": "white",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.emailSettings.return,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.emailSettings,
                                                        "return",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "emailSettings.return",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "end" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "btn-primary mb-2",
                                                attrs: {
                                                  outlined: "",
                                                  type: "button",
                                                  disabled:
                                                    !_vm.isFormValidReturn,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showConfirmEmailDialog(
                                                      "updateReturnMessage",
                                                      _vm.emailSettings.return
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.configData.TEXT_RENEW
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.tabname === "rescission"
                              ? _c(
                                  "v-form",
                                  {
                                    ref: "SubmittionMessageForm",
                                    refInFor: true,
                                    attrs: { "lazy-validation": "" },
                                    model: {
                                      value: _vm.isFormValidRescissions,
                                      callback: function ($$v) {
                                        _vm.isFormValidRescissions = $$v
                                      },
                                      expression: "isFormValidRescissions",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { staticClass: "mt-0 pt-0" },
                                              [
                                                _c("v-textarea", {
                                                  class: _vm.$vuetify.breakpoint
                                                    .lgAndUp
                                                    ? "tab-value"
                                                    : "",
                                                  attrs: {
                                                    inputType: "text",
                                                    rules: [
                                                      _vm.rulesValidate
                                                        .validateNotificationMessage,
                                                      _vm.rulesValidate
                                                        .validateHtmlTag,
                                                    ],
                                                    rows: _vm.$vuetify
                                                      .breakpoint.lgAndUp
                                                      ? 15
                                                      : 7,
                                                    "background-color": "white",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.emailSettings
                                                        .rescission,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.emailSettings,
                                                        "rescission",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "emailSettings.rescission",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "end" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "btn-primary mb-2",
                                                attrs: {
                                                  outlined: "",
                                                  type: "button",
                                                  disabled:
                                                    !_vm.isFormValidRescissions,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showConfirmEmailDialog(
                                                      "updateRescissionsMessage",
                                                      _vm.emailSettings
                                                        .rescission
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.configData.TEXT_RENEW
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.tabname === "extension_request"
                              ? _c(
                                  "v-form",
                                  {
                                    ref: "SubmittionMessageForm",
                                    refInFor: true,
                                    attrs: { "lazy-validation": "" },
                                    model: {
                                      value: _vm.isFormValidExntensionRequest,
                                      callback: function ($$v) {
                                        _vm.isFormValidExntensionRequest = $$v
                                      },
                                      expression:
                                        "isFormValidExntensionRequest",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { staticClass: "mt-0 pt-0" },
                                              [
                                                _c("v-textarea", {
                                                  class: _vm.$vuetify.breakpoint
                                                    .lgAndUp
                                                    ? "tab-value"
                                                    : "",
                                                  attrs: {
                                                    inputType: "text",
                                                    rules: [
                                                      _vm.rulesValidate
                                                        .validateNotificationMessage,
                                                      _vm.rulesValidate
                                                        .validateHtmlTag,
                                                    ],
                                                    rows: _vm.$vuetify
                                                      .breakpoint.lgAndUp
                                                      ? 15
                                                      : 7,
                                                    "background-color": "white",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.emailSettings
                                                        .extension_request,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.emailSettings,
                                                        "extension_request",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "emailSettings.extension_request",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "end" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "btn-primary mb-2",
                                                attrs: {
                                                  outlined: "",
                                                  type: "button",
                                                  disabled:
                                                    !_vm.isFormValidExntensionRequest,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showConfirmEmailDialog(
                                                      "updateExntensionRequestMessage",
                                                      _vm.emailSettings
                                                        .extension_request
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.configData.TEXT_RENEW
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.tabname === "extension_approval"
                              ? _c(
                                  "v-form",
                                  {
                                    ref: "SubmittionMessageForm",
                                    refInFor: true,
                                    attrs: { "lazy-validation": "" },
                                    model: {
                                      value: _vm.isFormValidExntensionApproval,
                                      callback: function ($$v) {
                                        _vm.isFormValidExntensionApproval = $$v
                                      },
                                      expression:
                                        "isFormValidExntensionApproval",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { staticClass: "mt-0 pt-0" },
                                              [
                                                _c("v-textarea", {
                                                  class: _vm.$vuetify.breakpoint
                                                    .lgAndUp
                                                    ? "tab-value"
                                                    : "",
                                                  attrs: {
                                                    inputType: "text",
                                                    rules: [
                                                      _vm.rulesValidate
                                                        .validateNotificationMessage,
                                                      _vm.rulesValidate
                                                        .validateHtmlTag,
                                                    ],
                                                    rows: _vm.$vuetify
                                                      .breakpoint.lgAndUp
                                                      ? 15
                                                      : 7,
                                                    "background-color": "white",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.emailSettings
                                                        .extension_approval,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.emailSettings,
                                                        "extension_approval",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "emailSettings.extension_approval",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "end" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "btn-primary mb-2",
                                                attrs: {
                                                  outlined: "",
                                                  type: "button",
                                                  disabled:
                                                    !_vm.isFormValidExntensionApproval,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showConfirmEmailDialog(
                                                      "updateExntensionApprovalMessage",
                                                      _vm.emailSettings
                                                        .extension_approval
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.configData.TEXT_RENEW
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.tabname === "extension_denial"
                              ? _c(
                                  "v-form",
                                  {
                                    ref: "SubmittionMessageForm",
                                    refInFor: true,
                                    attrs: { "lazy-validation": "" },
                                    model: {
                                      value: _vm.isFormValidExntensionDenial,
                                      callback: function ($$v) {
                                        _vm.isFormValidExntensionDenial = $$v
                                      },
                                      expression: "isFormValidExntensionDenial",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { staticClass: "mt-0 pt-0" },
                                              [
                                                _c("v-textarea", {
                                                  class: _vm.$vuetify.breakpoint
                                                    .lgAndUp
                                                    ? "tab-value"
                                                    : "",
                                                  attrs: {
                                                    inputType: "text",
                                                    rules: [
                                                      _vm.rulesValidate
                                                        .validateNotificationMessage,
                                                      _vm.rulesValidate
                                                        .validateHtmlTag,
                                                    ],
                                                    rows: _vm.$vuetify
                                                      .breakpoint.lgAndUp
                                                      ? 15
                                                      : 7,
                                                    "background-color": "white",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.emailSettings
                                                        .extension_denial,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.emailSettings,
                                                        "extension_denial",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "emailSettings.extension_denial",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "end" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "btn-primary mb-2",
                                                attrs: {
                                                  outlined: "",
                                                  type: "button",
                                                  disabled:
                                                    !_vm.isFormValidExntensionDenial,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showConfirmEmailDialog(
                                                      "updateExntensionDenialMessage",
                                                      _vm.emailSettings
                                                        .extension_denial
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.configData.TEXT_RENEW
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                attrs: { persistent: "", "max-width": "600" },
                model: {
                  value: _vm.confirmEmailDialog,
                  callback: function ($$v) {
                    _vm.confirmEmailDialog = $$v
                  },
                  expression: "confirmEmailDialog",
                },
              },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "modal-title", attrs: { color: "black" } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.configData.TEXT_CONFIRMATION) + " "
                        ),
                      ]
                    ),
                    _c(
                      "v-card-text",
                      { staticClass: "mt-5" },
                      [
                        _c("div", [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.configData
                                  .TEXT_REQUEST_UPDATE_SYSTEM_CONFIGURATION
                              )
                            ),
                          ]),
                        ]),
                        _c("v-row", {
                          staticStyle: { height: "20px" },
                          attrs: { "no-gutters": "" },
                        }),
                        _c("v-spacer"),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      [
                        _c(
                          "v-row",
                          { staticClass: "ma-2", attrs: { justify: "end" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "btn-secondary",
                                attrs: { outlined: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.confirmEmailDialog = false
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.configData.TEXT_RETURN))]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "btn-primary",
                                attrs: { outlined: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateExntensionMessage()
                                  },
                                },
                              },
                              [_vm._v("OK")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }