var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("approval-template", {
    attrs: {
      loading: _vm.loading,
      resData: _vm.resData,
      resHisData: _vm.resHisData,
      resCardData: _vm.resCardData,
      resExtData: _vm.resExtData,
      resDataGates: _vm.resDataGates,
      request_id: _vm.request_id,
      isAction: _vm.isAction,
      closeDialogExtention: _vm.closeDialogExtention,
    },
    on: {
      "change-column": function ($event) {
        return _vm.changeColumn($event)
      },
      "action-approval": function ($event) {
        return _vm.actionApproval($event)
      },
      "open-extensions": function ($event) {
        return _vm.openExtensions($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }