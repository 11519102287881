var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("equipment-status-template", {
    attrs: {
      loading: _vm.loading,
      errorMessageLogs: _vm.errorMessageLogs,
      resData: _vm.resData,
      dataOffice: _vm.dataOffice,
      dataArea: _vm.dataArea,
      actionItems: _vm.actionItems,
      isAction: _vm.isAction,
      loadingSearch: _vm.loadingSearch,
      total: _vm.total,
    },
    on: {
      search: function ($event) {
        return _vm.search($event)
      },
      "change-column": function ($event) {
        return _vm.changeColumn($event)
      },
      "refresh-gate": function ($event) {
        return _vm.refreshGate($event)
      },
      "change-office": function ($event) {
        return _vm.changeOffice($event)
      },
      export: function ($event) {
        return _vm.exportCSV($event)
      },
      "get-current-items": function ($event) {
        return _vm.getCurrentItems($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }