const ENV = process.env.VUE_APP_ENV;
// config route API
export const API_KEY_PREFIX = 'JREM';
export const API_KEY_SUFFIX = 'ART';
export const DOMAIN = process.env.VUE_APP_API_ENDPOINT ? process.env.VUE_APP_API_ENDPOINT : '';
export const LOGIN_API = DOMAIN + ENV + '/login';
export const RESET_CODE_API = DOMAIN + ENV + '/reset-code';
export const RESET_PASSWORD_API = DOMAIN + ENV + '/password-reset';
export const REFRESH_TOKEN_API = DOMAIN + ENV + '/refresh';
export const USER_CHANGE_PASSWORD_API = DOMAIN + ENV + '/force/password/change';
export const USERS_LIST_API = DOMAIN + ENV + '/users';
export const BULKS_USERS_API = DOMAIN + ENV + '/bulks/users';
export const OWNER_LOGIN_METHOD_API = DOMAIN + ENV + '/login/method';
export const PLAN_API = DOMAIN + ENV + '/plan';
export const OFFICES_LIST_API = DOMAIN + ENV + '/offices';
export const CONFIGURATIONS_API = DOMAIN + ENV + '/configurations/messages';
export const ENTRY_REQUESTS_API = DOMAIN + ENV + '/entry-requests';
export const CARD_LIST_API = DOMAIN + ENV + '/cards';
export const GATES_API = DOMAIN + ENV + '/gates';
export const RECORDS_API = DOMAIN + ENV + '/records';
export const AREAS_API = DOMAIN + ENV + '/areas';
export const ORGANIZATIONS_API = DOMAIN + ENV + '/organizations';
export const APPROVER_API = DOMAIN + ENV + '/approvers';
export const OFFICE_AREAS_API = DOMAIN + ENV + '/office-areas';
export const LOGS_API = DOMAIN + ENV + '/logs';
export const DOMAINS_API = DOMAIN + ENV + '/domains';
export const CHANGE_PASSWORD_API = DOMAIN + ENV + '/password';
export const CHANGE_INITIAL_PASSWORD_API = DOMAIN + ENV + '/change-password';
export const PROPERTIES_API = DOMAIN + ENV + '/properties';
//
export const MAX_NUMBER_OF_CARDS_CONFIG_URL = '/maxnum-of-cards.json';
//
// config route website
export const PAGE_DEFAULT = 1000;
export const PER_PAGE = 1000;
export const LOG_EXCEEDS_ITEM = 10000;
export const MAXIMUM_ITEM_IN_CSV = 3000;
export const LOGIN = '/login';
export const DASHBOARD = '/personal';
export const APPLICANT = '/applicant';
export const APPLICANT_VIEW = '/applicant/view';
export const APPLICANT_ADD = '/applicant/add';
export const APPLICANT_EDIT = '/applicant/edit';
export const APPROVAL = '/approval';
export const APPROVAL_ADD = '/approval/add';
export const APPROVAL_STATUS = '/approval-status';
export const USER_LIST = '/user';
export const USER_EDIT = '/user/edit';
export const ADMIN = '/admin';
export const PERSONAL_SETTING = '/personal';
export const CARD = '/card';
export const ENTRANCE_HISTORY = '/entrance-history';
export const EQUIPMENT_STATUS = '/equipment-status';
export const APPROVER_SETTINGS = '/approver-settings';
export const CHANGE_PASSWORD = '/change-password';
export const USER_INFORMATION = '/user-information';
export const MANUAL = '/manual';
export const CHANGE_INITIAL_PASSWORD = '/change-initial-password';
export const OFFICE = '/office';
export const LOG = '/log';
export const ENTRY_REQUESTS = '/entry-requests';
export const DEVICE_STATUS = '/device-status';
export const EMAIL_SETTINGS = '/email-settings';

// Message
export const ERROR_400 = '必須項目が未入力、もしくは誤った形式のデータが含まれています。入力内容をご確認ください。';
export const ERROR_401 = 'エラーが発生しました。システム管理者にお問合せください。（APIキー不正）。';
export const ERROR_403 = 'エラーが発生しました。システム管理者にお問合せください。（アクセス情報不正）。';
export const ERROR_404 = '該当するデータがありません。';
export const ERROR_404_CALENDAR = 'カレンダーの設定に失敗しました。再度やり直してください。';
export const ERROR_400_UNLOCKTIME = '連続解錠時間の更新に失敗しました。再度やり直してください。';
export const ERROR_409_UNLOCKTIME = '既に同じ曜日の連続解錠時間が登録されてます。';
export const ERROR_403_DENIED = 'アクセス情報が不正です。ログインし直してからやり直してください。';
export const ERROR_409 = '入力されたユーザーIDは既に使用されています。他のユーザーIDを入力してください。';
export const ERROR_409_USER = 'ユーザーIDが重複しています。';
export const ERROR_409_INIT_CHG_PWD = '初期パスワード変更は実施済。';
export const ERROR_409_CONTROLLER = 'Attoユーザーに指定したユーザーは既に登録済みです。別のユーザーを指定して再度お試しください。';
export const ERROR_409_OFFICE = '管理施設の削除に失敗しました。';
export const ERROR_500 = 'サーバーから応答がありませんでした。(status 500(Internal Server Error)) しばらく経ってからやり直してください。';
export const ERROR_503 = 'サーバーから応答がありませんでした。(status 503 (Service Unavailable)) しばらく経ってからやり直してください。';
export const ERROR_420 = '登録上限を超えたため通行可能時間帯を登録できません。<br />※登録上限が9件までです。';
export const ERROR_DEFAULT = 'アクセス情報が不正です。ログインし直してからやり直してください。';
export const SERVER_CONNECT_ERROR = 'システム管理者にお問い合わせください。';
export const ERROR_LOGIN_INFO_MISMATCH = '入力情報に誤りがあります。';

export const ERROR_OWNER_ID = '事業者IDは必須です。';
export const ERROR_API_OWNER_ID = 'エラーが発生しました。しばらく経ってから再度お試しください。';
export const ERROR_MFACODE = '認証コードは必須です。';
export const ERROR_USER_ID = 'ユーザーIDは必須です。';
export const ERROR_PASSWORD = 'パスワードは必須です。';
export const ERROR_RESETCODE = 'リセットコードは必須です。';
export const ERROR_PASSWORD_REG = 'ログインパスワードは半角英大文字、小文字、数字、特殊文字を組み合わせた8文字以上64文字以内で設定してください。';
export const ERROR_CONFIRM_PASSWORD_REG = '現在のパスワードは半角英大文字、小文字、数字、特殊文字を組み合わせた8文字以上64文字以内で設定してください。';
export const ERROR_CONFIRM_NEW_PASSWORD_REG = '新規パスワードは半角英大文字、小文字、数字、特殊文字を組み合わせた8文字以上64文字以内で設定してください。';
export const ERROR_CONFIRM_NEW_PASSWORD_FOR_CONFIRMATION_REG = '新規パスワード（確認）は半角英大文字、小文字、数字、特殊文字を組み合わせた8文字以上64文字以内で設定してください。';
export const ERROR_CONFIRM_PASSWORD = '変更後パスワードが一致しません。';
export const ERROR_NEW_PASSWORD = '変更後パスワードは必須です。';
export const ERROR_LOGIN_DEFAULT = '入力情報に誤りがあります';


// Text Config
export const TEXT_LOGO = 'Suicaスマートロック 遠隔入室管理システム';
export const TEXT_LOGO_1ST = 'Suicaスマートロック';
export const TEXT_LOGO_2ND = '遠隔入室管理システム';
export const TEXT_OWNER_ID = '事業者ID';
export const TEXT_USER_ID = 'ユーザーID';
export const TEXT_PASSWORD = 'パスワード';
export const TEXT_CONFIRM_PASSWORD = '再記入新しいパスワード';
export const TEXT_FORGOT_LINK = 'パスワードをお忘れの方';
export const TEXT_RETURN_LOGIN_LINK = 'ログイン画面に戻る';
export const TEXT_NOTE_RESET = 'パスワードは英大文字、英小文字、数字を組み合わせた8文字以上に設定してください。';
export const TEXT_NOTE_RESET_SUCCESS = 'パスワードがリセットされました。';
export const TEXT_MFACODE = '認証コード';
export const TEXT_RESETCODE = 'リセットコード';
export const TEXT_BUTTON_LOGIN = 'ログイン';
export const TEXT_BUTTON_CREATE = '新規作成';
export const TEXT_BUTTON_NEXT = '次へ';
export const TEXT_BUTTON_SEND = '送信';
export const TEXT_APPLICANT_STATUS = '申請状況';
export const TEXT_NO_DATA = 'データがありません。';
export const TEXT_LOADING_DATA = 'データを読み込中です';
export const TEXT_DISPLAY_OPTIONS = '表示オプション';
export const TEXT_APPLICATION_STATUS_LIST = '申請状況一覧';
export const TEXT_OFFICE_POPUP = '管理施設選択画面';
export const TEXT_BUTTON_CSV = 'CSV出力';
export const TEXT_PROGRESS_READING = 'データ読込中';
export const TEXT_PROGRESS_REGISTERING = 'データ登録中';
export const TEXT_PROGRESS_DELETING = 'データ削除中';
export const TEXT_DELETED_ITEMS = '削除完了しました。';
export const TEXT_REMOVED_ITEMS = '解除完了しました。';
export const TEXT_CONFIRM_EX_TOKEN = 'ログインから60分経過したためログアウトします。再ログインしてください。';

export const TEXT_AFTER_CHANGE_PASSWORD = '変更後パスワード';
export const TEXT_RE_ENTER_AFTER_CHANGE_PASSWORD = '変更後パスワード再入力';
export const TEXT_BUTTON_CHANGE_PASSWORD = 'パスワード変更';

export const TEXT_COL_WORKING_DAY = '入室日';
export const TEXT_COL_APPLICATION_NUMBER = '申請番号';
export const TEXT_COL_MANAGEMENT_OFFICE = '管理施設';
export const TEXT_COL_STATUS = 'ステータス';
export const TEXT_COL_ADMISSION_OFFICE = '入室開始日時';
export const TEXT_COL_AREA = 'エリア';
export const TEXT_COL_BEGIN_DATE = '入室開始日時';
export const TEXT_COL_END_DATE = '入室終了日時';
export const TEXT_COL_MANAGEMENT_STATION = '管理施設';
export const TEXT_COL_WORK_STATION = '入室駅';

export const TEXT_SYSTEM_ADMINTRATORS = 'システム管理者';
export const TEXT_APPLICANT_SCREEN = '新規申請';
export const TEXT_WORKING_DAY = '入室日';
export const TEXT_EXTENSION_APPLICATION = '延長申請未承認';
export const TEXT_JOB_APPLICATION = '入室申請';
export const TEXT_APPLICATION_DETAILS = '申請詳細';
export const TEXT_ACKNOWLEDGMENT = '承認者';
export const TEXT_APPROVAL_STATUS_LIST = '承認状況';
export const TEXT_ADD_APPROVER = '申請承認者 新規登録';
export const TEXT_COL_DOOR_ID = 'ゲートコード';
export const TEXT_COL_USER_ID_AND_USER_NAME = 'ユーザーID/ユーザー名';
export const TEXT_COL_INSTALLATION_STATION = '設置駅';
export const TEXT_SEARCH_PERIOD_END_DATE_AND_TIME = '検索期間（終了日時）';
export const TEXT_SEARCH_PERIOD_START_DATE_AND_TIME = '検索期間（開始日時）';
export const TEXT_APPROVER_SETTING = '申請承認者';
export const TEXT_CARD_REGISTRATION = 'カード登録';
export const TEXT_CHANGE_PASSWORD = 'パスワード変更';
export const TEXT_MANAGEMENT_STATION_ID = '管理駅ID';
export const TEXT_MANAGEMENT_STATION_NAME = '管理駅名';
export const TEXT_GET_THE_LASTEST_STATUS = '最新状況取得';
export const TEXT_USE_MANAGER = '運用管理者';
export const TEXT_DEVICE_STATUS_LIST_CONDITION = '機器状況一覧';
export const TEXT_EMAIL_SETTINGS = '通知メール';
export const TEXT_INSTITUTION = '施設';
export const TEXT_DISTINGUISH = '区分';
export const TEXT_ENTRANCE_HISTORY = '入室履歴';
export const TEXT_EQUIPMENT_STATUS_TITLE = 'ドアID、ユーザーID/ユーザー名、設置駅、ステータス、検索期間（開始日時）、検索期間（終了日時）';
export const TEXT_EQUIPMENT_STATUS_CONDITION = '機器状態';
export const TEXT_UNLOCK_PERSONAL_CODE = '個人コードを解除する';
export const TEXT_BASIC_INFORMATION = '基本情報';
export const TEXT_COL_NAME_OF_ASSOCIATION = '組織名称';
export const TEXT_COL_OPERATION_DISTINCTION = '操作区分';
export const TEXT_OPERATION_LOG = '操作ログ';
export const TEXT_ADD_NEW_MANAGEMENT_STATION = '新規登録';
export const TEXT_MANAGEMENT_STATION_DELETION = '削除';
export const TEXT_MANAGEMENT_FACILITY_CODE = '管理施設コード';
export const TEXT_MANAGEMENT_FACILITY_NAME = '管理施設名';
export const TEXT_MANAGEMENT_FACILITY_LIST = '管理施設';
export const TEXT_AFFILIATION = '所属';
export const TEXT_GENERALLY = '一般';
export const TEXT_USER_INFORMATION = 'ユーザー情報';
export const TEXT_BULK_REGISTRATION = '一括登録';
export const TEXT_ADD_USER = '新規登録';
export const TEXT_DELETE_USER = '削除';
export const TEXT_COL_DEPARTMENT = '部門';
export const TEXT_COL_EMAIL_ADDRESS = 'メールアドレス';
export const TEXT_USER_MANAGEMENT = '入室申請者';
export const TEXT_MANAGEMENT_STATION_LIST = '管理駅一覧';
export const TEXT_LOG = 'ログ';
export const TEXT_DEVICE_STATUS_LIST_STATE = '機器状態一覧';
export const TEXT_APPROVER_LIST = '承認者一覧';
export const TEXT_MY_PAGE = 'マイページ';
export const TEXT_MESSAGE_FOR_CARD_REGISTRATION_REQUEST = 'カード登録依頼メッセージ';
export const TEXT_MESSAGE_FOR_CARD_REGISTRATION_REQUEST_UPDATE = 'カード登録依頼メッセージ 1';
export const TEXT_MESSAGE_FOR_APPLICANTS = '申請者向けメッセージ';
export const TEXT_MESSAGE_FOR_APPLICANTS_UPDATE = '申請者向けメッセージ 1';
export const TEXT_MESSAGE_FOR_APPROVER = '承認者向けメッセージ';
export const TEXT_MESSAGE_FOR_APPROVER_UPDATE = '承認者向けメッセージ 1';
export const ERROR_CARD_NUMBER_ALREADY_EXISTS = 'カード番号がすでに存在している。';
export const ERROR_CANNOT_BE_ADDED_CARD_NUMBER = 'ゲートに紐づく個人カード番号が上限を超えるため、追加できない。';
export const TEXT_MAIN_MENU = 'メインメニュー';
export const TEXT_REGISTER_AND_EDIT_USERS = 'ユーザーの登録・編集を行います。';
export const TEXT_REGISTER_AND_EDIT_CARD = 'カードの登録・編集を行います。';

// Text for Card registration
export const TEXT_TITLE_FOR_CARD_REGISTRATION_SUCCESS = 'カード登録完了';
export const TEXT_MESSAGE_FOR_CARD_REGISTRATION_SUCCESS = 'カード登録が完了しました。';
export const TEXT_TITLE_FOR_CARD_REGISTRATION_FAILED = 'カード登録失敗';
export const TEXT_TITLE_FOR_CARD_CONFIRM_DEREGISTRATION = 'カード削除';
export const ERROR_MESSAGE_FOR_CARD_DEREGISTRATION_FAILED = 'カード削除が失敗しました。';
export const TEXT_TITLE_FOR_CARD_DEREGISTRATION_FAILED = 'カード削除失敗';
export const ERROR_MESSAGE_FOR_CARD_REGISTRATION_FAILED = 'カード登録に失敗しました。';
export const TEXT_MESSAGE_FOR_CARD_DEREGISTRATION_SUCCESS = 'カード削除が完了しました。';
export const ERROR_MESSAGE_FOR_CARD_LIST_FAILED = 'カード情報の取得に失敗しました。';
export const ERROR_MESSAGE_FOR_CARD_INFO_FAILED = 'カード情報取得エラー。';
export const TEXT_TITLE_FOR_CARD_LIST_FAILED = 'カード情報取得エラー';
export const ERROR_MESSAGE_CARD_REGISTRATION_FAILED = 'カード登録に失敗しました。';
export const ERROR_MESSAGE_CARD_REGISTRATION_409 = '同一のカード情報が既に登録されています。';

export const ERROR_MESSAGE_FOR_USER_UPDATE_FAILED = 'ユーザー情報の更新に失敗しました。';
export const TEXT_TITLE_CHANGE_PASS_SUCCESS = 'パスワード変更しました。';
export const TEXT_TITLE_CHANGE_PASS_FAILED = 'パスワードの変更に失敗しました。';
//
export const TEXT_REGISTER_AND_EDIT_TRAFFIC_AUTHORIZATION = '通行権限の登録・編集を行います。';
export const TEXT_VIEW_LOG_AND_EXPORT_CSV = 'ログ情報の閲覧・CSV出力を行います。';
export const TEXT_HOME = 'ホーム';
export const TEXT_EQUIPMENT_STATUS_STATE = '機器状況';
export const TEXT_MESSAGE_USER_EDITED = 'ユーザーが編集されました。';
export const ERROR_BAD_FILE = 'csvファイルの入力内容に誤りがあります。\n正しいファイルを選択しなおしてください。';
export const ERROR_MUST_SELECT_CSV_FILE = 'CSVのファイルを選択してください。';
export const TEXT_USER_NAME = 'ユーザー名';
export const TEXT_FURIGANA = 'フリガナ';
export const TEXT_SUPPLEMENT = '備考';
export const TEXT_ADDITION = '追加';
export const TEXT_CANCEL = 'キャンセル';
export const TEXT_ADMIT = '承認';
export const TEXT_SEND_BACK = '差し戻し';
export const TEXT_ADMIT_CANCELLATION = '承認取消';
export const TEXT_DENY = '否認';
export const TEXT_CLOSE_UP = '閉じる';
export const TEXT_COMMENT_INPUT_FIELD = 'コメント入力欄';
export const TEXT_RETURN = '戻る';
export const TEXT_JOB_APPLICATION_RECOGNITION = '申請承認';
export const TEXT_WORK_CONTENT = '入室理由';
export const TEXT_APPLICATION_HISTORY = '申請履歴';
export const TEXT_DELETE_APPROVER = '承認者削除';
export const TEXT_DELETE = '削除';
export const TEXT_RELEASE = '解除';
export const TEXT_REGISTER = '登録';
export const TEXT_APPLICATION = '申請';
export const TEXT_RE_APPLICATION = '提出';
export const TEXT_DATE_AND_TIME = '日時';
export const TEXT_OPERATION_SCREEN = '操作画面';
export const TEXT_OPERATION_CONTENT = '操作内容';
export const TEXT_UPDATE_DATE = '更新日時';
export const TEXT_REMARKS = '備考';
export const TEXT_CONTENT = 'コメント';
export const TEXT_AREA = 'エリア';
export const TEXT_MULTIPLE_GATE_NAMES = '複数可ゲート名';
export const TEXT_BUTTON_CANCEL = '申請取消';
export const TEXT_BUTTON_EDIT = '編集';
export const TEXT_BUTTON_EXTEN = '延長申請';
export const TEXT_BUTTON_RESEND_EMAIL = 'メール再送';
export const TEXT_TITLE_RESEND_EMAIL = 'メール再送いたしますか？';
export const TEXT_RESEND_EMAIL_COMPLETED = 'メール再送完了しました。';
export const TEXT_RESEND_EMAIL_FAILED = 'メール再送に失敗しました。';
export const TEXT_NO_FACILITIES = '施設がありません';
export const TEXT_ADMINISTRATIVE_FACILITY_SETTINGS = '管理施設 新規登録';
export const TEXT_ADMINISTRATIVE_FACILITY_SETTINGS_EDIT = '管理施設 編集';
export const TEXT_CREATE = '新規登録';
export const TEXT_LIST_OF_MANAGED_FACILITIES = '管理対象の施設リスト';
export const TEXT_LIST_OF_FACILITIES = '施設リスト';
export const TEXT_NO_FACILITIES_TO_APPROVE = '承認対象の施設がありません';
export const TEXT_KEEP = '保存';
export const TEXT_NEW_APPROVAL_HAS_BEEN_REGISTERED = '新規の承認部署が登録された。';
export const ERROR_CARD_NUMBER_IS_ALREADY_USE = '入力されたカード番号は既に使用されています。他のカード番号を入力してください。';
export const TEXT_SEARCH = '検索';
export const TEXT_RESET = 'リセット';
export const TEXT_SAVED = '保存済';
export const TEXT_SUBMITTED = '提出済';
export const TEXT_APPROVED = '承認済';
export const TEXT_DIFFERENCE_RETURN = '差戻';
export const TEXT_RESCINDED = '承認取消';
export const TEXT_CANCELLED = '申請取消';
export const TEXT_DEPARTMENT = '部門';
export const TEXT_OFFICE_CODE = '管理施設コード';
export const TEXT_OFFICE_NAME = '管理施設名';
export const TEXT_AREA_POPUP = 'エリア選択画面';
export const TEXT_AREA_CODE = 'エリアコード';
export const TEXT_AREA_NAME = 'エリア名';
export const TEXT_GATE_POPUP = 'ゲート選択画面';
export const TEXT_GATE_CODE = 'ゲートコード';
export const TEXT_GATE_NAME = 'ゲート名';
export const TEXT_DATE_APPLICATION = '申請日時';
export const TEXT_ADMISSION_APPLICATION_APPROVAL = '申請承認';
export const TEXT_BATTERY_LEVEL = '電池残量';
export const TEXT_OGRANIZATION = '会社／部門';

export const TEXT_FACILITY_NEW_REGISTRATION = '管理施設 新規登録';
export const TEXT_NAME_OF_FACILITY = '施設名';
export const ERROR_ADMINISTRATIVE_FACILITY_ID_IS_REQUIRED = '管理施設コードは必須です。';
export const ERROR_MANAGEMENT_FACILITY_NAME_IS_REQUIRED = '管理施設名は必須です。';
export const ERROR_AFFILIATION_IS_REQUIRED = '所属は必須です。';
export const ERROR_AREA_LENGTH = 'エリアコードは半角英数字で4文字以上20文字以内で設定してください。';
export const TEXT_COL_COMPANY_DEPARTMENT_NAME = '会社／部門';
export const TEXT_CURRENT_PASSWORD = '現在のパスワード';
export const TEXT_NEW_PASSWORD = '新規パスワード';
export const TEXT_CONFIRM_NEW_PASSWORD = '新規パスワード（確認）';
export const TEXT_MESSAGE = 'メッセージ';
export const TEXT_CHANGE = '変更';
export const TEXT_USER_ID_FORM = 'ユーザーID';
export const TEXT_FULL_NAME = '氏名';
export const TEXT_CARD = 'カード';
export const TEXT_APPLICANT = '申請者';
export const TEXT_APPLYING = '申請中';
export const TEXT_MANAGEMENT_STATION = '管理駅';
export const TEXT_EXTENSION_APPLICANT_APPROVAL = '延長申請承認画面';
export const TEXT_ADMISSION_FACILITY = '入場施設';
export const TEXT_SIGN_UP = '新規登録';
export const TEXT_APPLICANT_APPROVER_NEW_REG = '申請承認者 登録';
export const TEXT_USER_TYPE = 'ユーザー種別';
export const TEXT_COMPANY_NAME = '会社名（任意）';
export const TEXT_RENEW = '更新';
export const ERROR_OWNER_ID_LENGTH = '事業者IDは半角英数字で3文字以上31文字以内で設定してください。';
export const ERROR_USER_ID_REQUIRED = 'ユーザーIDは必須です。';
export const ERROR_USER_ID_LENGTH = 'ユーザーIDは半角英数字で4文字以上80文字以内で設定してください。';
export const ERROR_USER_ID_LENGTH_FOR_SEARCH = 'ユーザーIDは半角英数字を80文字以内で入力してください。';
export const ERROR_USER_NAME_REQUIRED = 'ユーザー名は必須です。';
export const ERROR_USER_NAME_LENGTH = 'ユーザー名は40文字以内で入力してください。';
export const ERROR_FURIGANA_REQUIRED = 'フリガナは必須です。';
export const ERROR_FURIGANA_LENGTH = 'フリガナは40文字以内で入力してください。';
export const ERROR_EMAIL_REQUIRED = 'メールアドレスは必須です。';
export const ERROR_EMAIL_LENGTH = 'メールアドレスは半角英数字と特殊文字を組み合わせた80文字以内で設定してください。';
export const ERROR_EMAIL_VALIDATE = 'メールアドレスの形式が誤っています。';
export const ERROR_REMARKS_LENGTH = '備考は80文字以内で入力してください。';
export const ERROR_REMARKS_LENGTH_USER = '備考は80文字以内で入力してください。';
export const ERROR_COMPANY_DEPARTMENT_LENGTH = '会社/部門は3つまでしか選択出来ません。';
export const ERROR_CURRENT_PASSWORD_REQUIRED = '現在のパスワードは必須です。';
export const ERROR_CURRENT_PASSWORD_LENGTH = '現在のパスワードは半角英大文字、小文字、数字、特殊文字を組み合わせた8文字以上64文字以内で設定してください。';
export const ERROR_NEW_PASSWORD_REQUIRED = '新規パスワードは必須です。';
export const ERROR_NEW_PASSWORD_LENGTH = '変更後パスワードは半角英大文字、小文字、数字、特殊文字を組み合わせた8文字以上64文字以内で設定してください。';
export const ERROR_CONFIRM_NEW_PASSWORD_REQUIRED = '新規パスワード（確認）は必須です。';
export const ERROR_PASSWORD_NOT_MATCH = '新規パスワードが一致しません。';
export const TEXT_INSTALLATION_STATION_ID = '設置駅ID';
export const TEXT_INSTALLATION_STATION_NAME = '設置駅名';
export const TEXT_DOOR_NUMBER = 'ドア番号';
export const TEXT_DOOR_NAME = 'ドア名';
export const TEXT_GET_DATE = '取得日時';
export const TEXT_MESSAGE_APPLICANT_SUBMISSION_NOTIFICATION = '申請提出通知メッセージ';
export const TEXT_MESSAGE_CANCEL_SUBMISSION_NOTIFICATION = '申請取消通知メッセージ';
export const TEXT_MESSAGE_APPROVAL_NOTIFICATION = '承認通知メッセージ';
export const TEXT_MESSAGE_RETURN_NOTIFICATION = '差戻通知メッセージ';
export const TEXT_MESSAGE_APPROVAL_CANCEL_NOTIFICATION = '承認取消通知メッセージ';
export const TEXT_MESSAGE_EXTENSION_APPLICATION_NOTIFICATION = '延長申請提出通知メッセージ';
export const TEXT_MESSAGE_EXTENSION_REQUEST_NOTIFICATION = '延長申請承認通知メッセージ';
export const TEXT_MESSAGE_EXTENSION_APPLICATION_DENIAL_NOTIFICATION = '延長申請否認通知メッセージ';
export const ERROR_NOTIFICATION_EMAIL_LENGTH = 'メール文面は500文字以内で入力してください。';
export const TEXT_HEADER = 'Suicaスマートロック 遠隔入室管理システム';
export const TEXT_HEADER_MOBILE = 'Suicaスマートロック <br> 遠隔入室管理システム';
export const TEXT_LOG_OFF = 'ログオフ';
export const TEXT_MANAGEMENT_FACILITY_ID = '管理施設ID';
export const TEXT_EDIT_ACCOUNT = 'アカウントを編集';
export const TEXT_ENTRY_APPLICANT_CHANGE = '入室申請者 変更';
export const TEXT_APPLICANT_FOR_ADMISSION = '入室申請者 新規登録';
export const TEXT_BULK_REG_ENTRY_APPLICANT = '入室申請者 一括登録';
export const TEXT_SELECT_FILES = 'CSVファイルを選択';
export const TEXT_IMPORT = 'CSVインポート';
export const TEXT_USER_EDIT = 'ユーザー編集';
export const TEXT_UPDATED_MESSAGE = 'メッセージを更新しました。';
export const TEXT_ADMISSION_APPLICATION = '入室申請';
export const TEXT_NEWS = 'お知らせ';
export const ERROR_LOGIN_FAILED = 'ログインに失敗しました。ユーザ登録状況について管理者にお問い合わせください。';
export const TEXT_ADMISSION_APPLICANTS = '入室申請者';
export const TEXT_MESSAGE_USER_ADDED = 'ユーザーが追加されました。';
export const ERROR_HAS_OCCURRED = 'エラーが起こりました。システム管理者にお問い合わせください。';
export const TEXT_MESSAGE_UPLOAD_SUCCESS = 'アップロード成功';
export const ERROR_NO_AUTHENTICATION = '認証が無い（IDトークンが無効）。';
export const TEXT_MUST = '※必須';
export const TEXT_FIRST_CARD_INFO = 'カード情報１';
export const TEXT_FIRST_CARD_INFO_EXAMPLE = 'カード情報１JExxxxxxxxxx0123';
export const TEXT_SECOND_CARD_INFO = 'カード情報２';
export const TEXT_THIRD_CARD_INFO = 'カード情報３';
export const TEXT_MANUAL = 'マニュアル';
export const ERROR_OFFICE_REQUIRED = '管理施設は必須です。';
export const TEXT_BUTTON_UPDATE = '変更';
export const TEXT_DELETE_COMPLETED = '削除完了しました。';
export const ERROR_PLEASE_SELECT_CSV_FILE = 'CSVファイルを選択してください。';
export const ERROR_MAXIMUM_ITEM_CSV_FILE = 'ユーザー数は3000件以内で指定してください。';
export const ERROR_MIN_ITEM_CSV_FILE = 'ユーザー数は1件以上で指定してください。';
export const TEXT_BULK_REGISTRAION_ACCEPTED = '一括登録を受付しました。';
export const TEXT_ERROR_BULK_REGISTRAION_ACCEPTED = '一括登録に失敗しました。';
export const ERROR_INPUT_CONTENTS_CSV_FILE = 'csvファイルの入力内容に誤りがあります。正しいファイルを選択しなおしてください。';
export const TEXT_UPDATE_COMPLETED = '更新完了しました。';
export const ERROR_MANAGEMENT_FACILITY_CODE_REQUIRED = '管理施設コードは必須です。';
export const TEXT_REGISTRATION_COMPLETED = '登録完了しました。';
export const TEXT_SAVE_COMPLETED = '保存完了しました。';
export const ERROR_MESSAGE_FOR_SAVE_FAILED = '申請の保存に失敗しました。';
export const TEXT_SUBMISSION_COMPLETED = '提出完了しました。';
export const TEXT_SUBMISSION_FAILED = '申請の提出に失敗しました。';
export const TEXT_SUBMISSION_PROBLEM = '申請内容に不備があります。入力内容を修正し再度実行してください。';
export const TEXT_SUBMISSION_STATE_PROBLEM = '申請に失敗しました。申請状況画面を再読み込みして最新の情報を取得してください。';
export const ERROR_409_APPLICANT = 'エラーが発生しました。もう一度やり直しても、エラーが発生する場合は システム管理者にお問い合わせください（ステータスが不正）。';
export const TEXT_CANCELLATION_COMPLETED = '取消完了しました。';
export const TEXT_RETURN_COMPLETED = '差戻完了しました。';
export const TEXT_RETURN_FAILED = '申請の差戻に失敗しました。';
export const TEXT_CANCELLATION_FAILED = '申請の取消に失敗しました。';
export const TEXT_APPROVAL_CANCELLATION_FAILED = '承認の取消に失敗しました。';
export const TEXT_APPROVAL_RETURN_FAILED = '承認の差戻に失敗しました。';
export const TEXT_APPROVAL_COMPLETED = '承認完了しました。';
export const TEXT_APPROVAL_FAILED = '承認に失敗しました。';
export const TEXT_EXT_APPROVAL_COMPLETED = '延長承認完了しました。';
export const TEXT_EXT_DENIAL_COMPLETED = '延長否認完了しました。';
export const TEXT_EXT_COMPLETED = '延長申請完了しました。';
export const TEXT_EXT_APPROVAL_FAILED = '延長承認に失敗しました。';
export const TEXT_EXT_DENIAL_FAILED = '延長否認に失敗しました。';
export const TEXT_EXT_FAILED = '延長申請に失敗しました。';
export const TEXT_APP_CONTENT_FAILED = '申請内容に不備があります。入室申請者に不備内容をお伝えください。';
export const TEXT_APP_STATE_FAILED = '承認に失敗しました。承認状況画面を再読み込みして最新の情報を取得してください。';
export const TEXT_ENTRY_REQUESTS_LIST_FAILED = '申請の取得に失敗しました。';
export const TEXT_COL_COMPANY_DEPARTMENT = '会社／部門';
export const TEXT_GATE = 'ゲート';
export const TEXT_ENTRY_APPLICANT_EDIT = '入室申請者 編集';
export const TEXT_ADMINISTRATIVE_FACILITY_EDIT = '管理施設 編集';
export const TEXT_COL_DEPARTMENT_COMPANY = '部門／会社';
export const TEXT_LOGIN = 'ログイン';
export const TEXT_EDIT = '編集';
export const TEXT_BUTTON_OTHERS = '↓more';
export const TEXT_APPLICATION_EXTENSION = '延長申請';
export const TEXT_WORK_START_DATE = '入室開始日';
export const TEXT_WORK_END_DATE = '入室終了日';
export const TEXT_UPDATE = '更新';
export const TEXT_CONFIRMATION = '確認';
export const TEXT_WARNING = '警告';
export const TEXT_UPDATE_TIME = '更新時刻';
export const TEXT_PRE_UPDATE_STATUS = '更新前ステータス';
export const TEXT_UPDATED_STATUS = '更新後ステータス';
export const TEXT_OPERATOR = '操作者';
export const TEXT_CANCELLATION_OF_APPROVAL = '承認取消';
export const TEXT_START_TIME = '開始時間';
export const TEXT_END_TIME = '終了時間';
export const TEXT_EXTENSION_CONFIRMATION = '延長確認';
export const TEXT_EMAIL_CHECK_HTML_TAG = 'htmlタグは入力禁止です。';
export const TEXT_SEND_RESET_CODE = 'リセットコード送信';
export const TEXT_RESET_PASSWORD = 'パスワード再設定';
export const ALERT_RESET = '登録してあるメールアドレスにリセットコードを送信しました。';
export const ALERT_RESET_SUCC = 'パスワード変更しました';
export const TEXT_LOG_OUT = 'ログアウト';
export const TEXT_QUESTION_APPLY_EXTENSION = '延長申請を申請しますか？';
export const TEXT_ERROR_WORK_PERIOD_EXPIRED = '入室期間を満了したため延長できません。引き続き入室が必要な場合は新規申請を行ってください';
export const TEXT_ERROR_WORK_PERIOD_EXPIRED_APPLICATION_TOP = '入室期間を満了したため延長できません。';
export const TEXT_ERROR_WORK_PERIOD_EXPIRED_APPLICATION_BOTTOM = '引き続き入室が必要な場合は新規申請を行ってください。';
export const TEXT_ERROR_WORK_PERIOD_EXPIRED_APPLICATION = '入室期間を満了したため延長できません。引き続き入室が必要な場合は新規申請を行ってください';
export const TEXT_APPROVE_EXTENSION_REQUEST = '延長申請を承認しますか？';
export const TEXT_ERROR_WORK_PERIOD_EXPIRED_APPROVAL_TOP = '入室期間を満了したため延長できません。';
export const TEXT_ERROR_NO_UNAPPROVED_EXTENSION_APPLICATIONS = '未承認の延長申請はありません';
export const TEXT_ERROR_WORK_PERIOD_EXPIRED_APPROVAL_BOTTOM = '引き続き入室が必要な場合は新規申請をご依頼ください。';
export const TEXT_EXTENSION_APPROVAL = '延長承認';
export const TEXT_EXTENSION_DENIAL = '延長否認';
export const TEXT_REQUEST_SUBMIT_APPLICATION = '申請を提出しますか？';
export const TEXT_REQUEST_SUBMIT_APPLICATION_MORE = '※提出しない場合は申請のステータスは保存済に変わります。';
export const TEXT_REQUEST_CANCEL_APPLICATION = '申請を取消しますか？';
export const TEXT_ERROR_WORK_PERIOD_EXPIRED_RETURNED_APPLICATION_TOP = '入室期間が過去のため申請できません。';
export const TEXT_ERROR_WORK_PERIOD_EXPIRED_RETURNED_APPLICATION_BOTTOM = '入室開始時間及び入室終了時間には現在時刻以降の日時を指定してください。';
export const TEXT_REQUEST_REVOKE_APPROVAL = '承認を取消しますか？';
export const TEXT_REQUEST_DENY_EXTENSION = '延長申請を否認しますか？';
export const TEXT_REQUEST_RETURN_APPLICATION = '申請を差戻しますか？';
export const TEXT_REQUEST_APPROVE_APPLICATION = '申請を承認しますか？';
export const TEXT_ERROR_CANT_APPROVED = '入室期間を見直すようにご依頼ください。';
export const TEXT_REQUEST_SUBMIT_EXTENSION = '延長申請を提出しますか？';
export const TEXT_REQUEST_UPDATE_SYSTEM_CONFIGURATION = 'システム設定情報を更新しますか？';
export const TEXT_REQUEST_REGISTER_MANAGEMENT_FACILITY = '管理施設を提出しますか？';
export const TEXT_REGISTER_DUPLICATE_FACILITY_ID = '同一管理施設コードの登録はできません。';
export const TEXT_REQUEST_ADD_MANAGEMENT_FACILITY = '管理施設を登録しますか？';
export const TEXT_REQUEST_UPDATE_MANAGEMENT_FACILITY = '管理施設を更新しますか？';
export const TEXT_REQUEST_DELETE_MANAGEMENT_FACILITY = '管理施設を削除しますか？';
export const TEXT_REQUEST_DELETE_USER = 'ユーザーを削除してもよろしいですか？\n※削除すると、元に戻せませんので、ご注意ください。';
export const TEXT_REQUEST_DELETE_APPROVER = 'ユーザーを申請承認者から解除してもよろしいですか？';
export const TEXT_REQUEST_DELETE_CARD = '削除してもよろしいですか？';
export const TEXT_REQUEST_UPDATE_USER = 'ユーザー情報を更新しますか？';
export const TEXT_REQUEST_CHANGE_USER_INFO = 'ユーザー情報を変更しますか？';
export const TEXT_REQUEST_CHANGE_PASSWORD = 'パスワードを変更しますか？';
export const ERROR_OFFICE_NAME_LENGTH = '管理施設名には80文字以内で入力してください。';
export const ERROR_MEMO_LENGTH = '備考は80文字以内で入力してください。';
export const ERROR_WORK_CONTENT_LENGTH = '入室理由は240文字以内で入力してください。';
export const ERROR_ORGANIZATIONS_LENGTH = '会社／部門は80文字以内で入力してください。';
export const TEXT_APPLYING_FOR_EXTENSION = '延長申請有';
export const TEXT_NO_APPLICATION_FOR_EXTENSION = '延長申請無';
export const TEXT_BEFORE_OPERATION = '操作前';
export const TEXT_AFTER_OPERATION = '操作後';
export const ERROR_OFFICE_LENGTH = '管理施設コードは半角英数字で4文字以上20文字以内で設定してください。';
export const ERROR_APPLICATION_ID_LENGTH = '申請番号は半角英数字で26文字以内で設定してください。';
export const ERROR_LOG_EXCEEDS_ITEM_1 = '検索対象のデータが表示上限の1万件を超えています。';
export const ERROR_LOG_EXCEEDS_ITEM_2 = 'データの続きを確認したい場合には、検索条件を変更して検索をしてください。';
export const ERROR_LOG_EXCEEDS_ITEM_3 = '操作ログの取得に失敗しました。';

export const TEXT_MANUAL_FOR_ADMIN = 'システム管理者向けマニュアル';
export const TEXT_MANUAL_FOR_OPERATION_MGR = '運用管理者向けマニュアル';
export const TEXT_MANUAL_FOR_APPLICANTS = '申請承認者向けマニュアル';
export const TEXT_MANUAL_FOR_ROOM_ENTRY_APPLICANTS = '入室申請者向けマニュアル';
export const TEXT_CLICK_TO_DOWNLOAD = 'クリックでダウンロード';

export const USER_ID_MAX_LEN = 80;
export const USER_NAME_MAX_LEN = 40;
export const USER_FURIGANA_MAX_LEN = 40;
export const EMAIL_MAX_LEN = 80;
export const REQUEST_ID_MAX_LEN = 26;
export const OFFICE_CODE_MAX_LEN = 20;
export const OFFICE_NAME_MAX_LEN = 80;
export const RECORD_MESSAGE_MAX_LEN = 40;
export const MEMO_MAX_LEN = 80;
export const WORK_CONTENT_MAX_LEN = 240;
export const ORGANAIZATION_MAX_LEN = 80;
export const AREA_CODE_MAX_LEN = 20;
export const AREA_NAME_MAX_LEN = 40;
export const GATE_CODE_MAX_LEN = 20;
export const GATE_NAME_MAX_LEN = 40;
export const EMAIL_MESSAGE_MAX_LEN = 500;

export const CONST_GATE_TYPE_ALLIGATE_PRO = 'ALLIGATE Pro';
export const CONST_GATE_TYPE_ALLIGATE = 'ALLIGATE';
export const CONST_GATE_TYPE_ESUMLOCK = 'eSumLock';
export const CONST_GATE_TYPE_HANDLELOCK = 'HandleLock';
export const CONST_GATE_TYPE_ESUM = 'ESUM';
export const CONST_GATE_TYPE_HANDLE = 'HANDLE';
export const TEXT_SEX = '性別';
export const TEXT_CARD_I1 = 'カード情報1(IDi)';
export const TEXT_CARD_M1 = 'カード情報1(IDm)';
export const TEXT_CARD_I2 = 'カード情報2(IDi)';
export const TEXT_CARD_M2 = 'カード情報2(IDm)';
export const TEXT_CARD_I3 = 'カード情報3(IDi)';
export const TEXT_CARD_M3 = 'カード情報3(IDm)';
export const TEXT_SEARCH_TAG_1 = '検索タグ1,検索タグ2,検索タグ3';
export const TEXT_SEARCH_TAG_2 = '検索タグ2';
export const TEXT_SEARCH_TAG_3 = '検索タグ3';
export const TEXT_ERROR_USER_ID = 'ユーザーIDが存在しません。';
export const TEXT_ERROR_CARD_REG = 'カードを登録してください。';
export const TEXT_USER_DELETED = '削除済みユーザー';
export const TEXT_ERROR_APPLICANT_FOR_CARD = 'カード情報の取得に失敗しました。';

export const TEXT_ERROR_FOR_ENTRANCE_HISTORY = '入室履歴の取得に失敗しました。';
export const TEXT_ERROR_FOR_ENTRANCE_HISTORY_MORE_EMPTY = '閲覧できるデータはありませんでした。';
export const TEXT_ERROR_FOR_EQUIPMENT_STATUS = 'ゲート情報の取得に失敗しました。';
export const TEXT_ERROR_FOR_EMAIL_SETTINGS = 'メール設定の取得に失敗しました。';
export const TEXT_ERROR_FOR_AFFILIATION_LIST = '所属一覧の取得に失敗しました。';
export const TEXT_ERROR_FOR_EMAIL_SETTINGS_UPDATE = 'メール設定の更新に失敗しました。';
export const TEXT_ERROR_FOR_APPROVER_LIST = '承認者の一覧取得に失敗しました。';
export const TEXT_ERROR_FOR_APPLICANT_APPROVER_NEW = '申請承認者の登録に失敗しました。';
export const TEXT_ERROR_FOR_APPLICANT_APPROVER_DEL = '申請承認者の解除に失敗しました。';
export const TEXT_ERROR_FOR_ENTRY_APPLICANT = '入室申請者の取得に失敗しました。';
export const TEXT_ERROR_FOR_ENTRY_APPLICANT_NEW = '入室申請者の登録に失敗しました。';
export const TEXT_ERROR_FOR_ENTRY_APPLICANT_UPDATE = '入室申請者の更新に失敗しました。';
export const TEXT_ERROR_FOR_ENTRY_APPLICANT_DEL = '入室申請者の削除に失敗しました。';
export const TEXT_ERROR_FOR_ENTRY_APPLICANT_DEL_MAX = '削除対象の件数を100件以下にして、再度実行してください。';
export const TEXT_ERROR_FOR_APPLICATION_HISTORY = '申請の履歴取得に失敗しました。';
export const TEXT_ERROR_FOR_MANAGEMENT_FACILITY = '管理施設の取得に失敗しました。';
export const TEXT_ERROR_FOR_REGISTRATION_MANAGEMENT_FACILITY = '管理施設の登録に失敗しました。';
export const TEXT_ERROR_FOR_UPDATE_MANAGEMENT_FACILITY = '管理施設の更新に失敗しました。';
export const TEXT_ERROR_FOR_LIST_OF_MANAGEMENT_FACILITY = '管理施設の施設リスト取得に失敗しました。';
export const TEXT_ERROR_FOR_LIST_OF_FACILITIES = '施設リスト取得に失敗しました。';
export const TEXT_LABEL_COMMENT = '申請者コメント(申請者→承認者)';
export const TEXT_LABEL_COMMENT_APPROVER = '承認者コメント(承認者→申請者)';
export const TEXT_LABEL_NO_COMMENT = '申請者コメント無し';
export const TEXT_LABEL_UNUSED = '未使用';
export const TEXT_LABEL_SEARCH_TAG = '会社／部門';
export const TEXT_DEL = '*DEL*';
export const ERROR_GET_PROPERTIES = 'エラーが起こりました。システム管理者にお問い合わせください。';
