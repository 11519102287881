var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "allig-success-button" },
    [
      _c(
        "v-btn",
        {
          staticClass: "text-color-white text-bold",
          attrs: { color: "green", elevation: "0", disabled: _vm.disabled },
          on: {
            click: function ($event) {
              return _vm.$emit("click", $event)
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.text) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }