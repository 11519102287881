
import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import ApplicantAddForm from '@/components/organisms/ApplicantAddForm/ApplicantAddForm.vue';
import Searchbox, {
  SearchTargetItem,
} from '@/components/molecules/Searchbox/Searchbox.vue';
interface TypeSelect {
  text: string;
  value: string;
}
import * as Config from '../../../config';
export interface DataType {
  configData: any;
}
export default Vue.extend({
  name: 'ApplicantAddTemplate',
  components: {
    ApplicantAddForm,
    Searchbox,
    Breadcrumbs,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isDataLoaded: {
      type: Boolean,
      default: false,
    },
    isAction: {
      type: Number,
      default: 0,
    },
    confirmDialog: {
      type: Boolean,
      default: false,
    },
    // confirmDialogFinish: {
    //   type: Boolean,
    //   default: false,
    // },
    isError: {
      type: Boolean,
      default: false,
    },
    // confirmSuccessMessageLogs: {
    //   default: '',
    // },
    // confirmErrorMessageLogs: {
    //   default: '',
    // },
    resData: {
      default: () => [],
    },
    resDataAreas: {
      default: () => [],
    },
    resDataAreaItem: {
      default: () => [],
    },
    resCardData: {
      default: () => [],
    },
    resDataOffice: {
      default: () => [],
    },
    resDataGates: {
      default: () => [],
    },
    dataItem: {
      default: () =>  ({}),
    },
  },
  data: (): DataType => ({
    configData: Config,
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        if (this.isAction === 0) {
          return [
            { text: Config.TEXT_APPLICANT_SCREEN, disabled: true },
          ];
        } else {
          return [
            { text: Config.TEXT_APPLICATION_DETAILS, disabled: true },
          ];
        }
      },
    },
  },
  methods: {
    //
  },
});
