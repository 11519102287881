var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("layout-template", {
    attrs: {
      pathName: _vm.pathName,
      isAdmin: _vm.isAdmin,
      selectedItemNav: _vm.selectedItemNav,
    },
    on: {
      menuClick: function ($event) {
        return _vm.menuClick($event)
      },
      activeMenu: function ($event) {
        return _vm.activeMenu($event)
      },
      "click-user-setting": _vm.personal,
      "click-user-manual": _vm.manual,
      "click-logout": _vm.logout,
      "click-notice": _vm.clickNotice,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }