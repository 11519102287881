var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "login-layout blur-login" },
    [
      _c(
        "v-overlay",
        { attrs: { absolute: "", value: _vm.loading, color: "white" } },
        [
          _vm.valueCircleLoading === 0
            ? _c("img", { attrs: { src: "/loading-circle.gif", width: "40" } })
            : _vm._e(),
        ]
      ),
      _c(
        "v-container",
        { staticStyle: { "max-width": "480px" } },
        [
          _c(
            "v-card",
            { staticClass: "background-green" },
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.$emit("formSubmitChangePassword", {
                        credentials: _vm.credentials,
                      })
                    },
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "background-white ml-12",
                          attrs: { cols: "12", "align-self": "center" },
                        },
                        [
                          _c("v-row", { attrs: { justify: "center" } }, [
                            _c("div", { staticClass: "logo-text mt-10 mb-0" }, [
                              _vm._v(
                                " " + _vm._s(_vm.configData.TEXT_LOGO_1ST) + " "
                              ),
                            ]),
                          ]),
                          _c("v-row", { attrs: { justify: "center" } }, [
                            _c("div", { staticClass: "logo-text mt-0 mb-10" }, [
                              _vm._v(
                                " " + _vm._s(_vm.configData.TEXT_LOGO_2ND) + " "
                              ),
                            ]),
                          ]),
                          _c(
                            "v-row",
                            {
                              staticClass: "input-full",
                              attrs: { justify: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c("v-text-field", {
                                    class:
                                      _vm.errorMessage.ownerId !== ""
                                        ? "input-login input-error"
                                        : "input-login",
                                    attrs: {
                                      "prepend-inner-icon": "mdi-account",
                                      color: "green",
                                      "hide-details": "auto",
                                      "single-line": "",
                                      outlined: "",
                                      dense: "",
                                      label: _vm.configData.TEXT_OWNER_ID,
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.credentials.ownerId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.credentials,
                                          "ownerId",
                                          $$v
                                        )
                                      },
                                      expression: "credentials.ownerId",
                                    },
                                  }),
                                  _vm.errorMessage.ownerId !== ""
                                    ? _c("p", { staticClass: "txt-error" }, [
                                        _vm._v(
                                          _vm._s(_vm.errorMessage.ownerId)
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "input-full",
                              attrs: { justify: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c("v-text-field", {
                                    class:
                                      _vm.errorMessage.userId !== ""
                                        ? "input-login input-error"
                                        : "input-login",
                                    attrs: {
                                      "prepend-inner-icon":
                                        "mdi-account-circle",
                                      color: "green",
                                      "hide-details": "auto",
                                      "single-line": "",
                                      outlined: "",
                                      dense: "",
                                      label: _vm.configData.TEXT_USER_ID,
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.credentials.username,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.credentials,
                                          "username",
                                          $$v
                                        )
                                      },
                                      expression: "credentials.username",
                                    },
                                  }),
                                  _vm.errorMessage.userId !== ""
                                    ? _c("p", { staticClass: "txt-error" }, [
                                        _vm._v(_vm._s(_vm.errorMessage.userId)),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "input-full",
                              attrs: { justify: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c("v-text-field", {
                                    class:
                                      _vm.errorMessage.password !== ""
                                        ? "input-login input-error"
                                        : "input-login",
                                    attrs: {
                                      "prepend-inner-icon": "mdi-lock",
                                      "append-icon": _vm.show1
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      type: _vm.show1 ? "text" : "password",
                                      disabled: _vm.loading,
                                      color: "green",
                                      "hide-details": "auto",
                                      "single-line": "",
                                      outlined: "",
                                      dense: "",
                                      label:
                                        _vm.configData.TEXT_CURRENT_PASSWORD,
                                      autocomplete: "off",
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.show1 = !_vm.show1
                                      },
                                    },
                                    model: {
                                      value: _vm.credentials.password,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.credentials,
                                          "password",
                                          $$v
                                        )
                                      },
                                      expression: "credentials.password",
                                    },
                                  }),
                                  _vm.errorMessage.password !== ""
                                    ? _c("p", { staticClass: "txt-error" }, [
                                        _vm._v(
                                          _vm._s(_vm.errorMessage.password)
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "input-full",
                              attrs: { justify: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c("v-text-field", {
                                    class:
                                      _vm.errorMessage.newPassword !== ""
                                        ? "input-login input-error"
                                        : "input-login",
                                    attrs: {
                                      "prepend-inner-icon": "mdi-lock",
                                      "append-icon": _vm.show2
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      type: _vm.show2 ? "text" : "password",
                                      disabled: _vm.loading,
                                      color: "green",
                                      "hide-details": "auto",
                                      "single-line": "",
                                      outlined: "",
                                      dense: "",
                                      label:
                                        _vm.configData
                                          .TEXT_AFTER_CHANGE_PASSWORD,
                                      autocomplete: "off",
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.show2 = !_vm.show2
                                      },
                                    },
                                    model: {
                                      value: _vm.credentials.newPassword,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.credentials,
                                          "newPassword",
                                          $$v
                                        )
                                      },
                                      expression: "credentials.newPassword",
                                    },
                                  }),
                                  _vm.errorMessage.newPassword !== ""
                                    ? _c("p", { staticClass: "txt-error" }, [
                                        _vm._v(
                                          _vm._s(_vm.errorMessage.newPassword)
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "input-full",
                              attrs: { justify: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c("v-text-field", {
                                    class:
                                      _vm.errorMessage.confirmNewPassword !== ""
                                        ? "input-login input-error"
                                        : "input-login",
                                    attrs: {
                                      "prepend-inner-icon": "mdi-lock",
                                      "append-icon": _vm.show3
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      type: _vm.show3 ? "text" : "password",
                                      disabled: _vm.loading,
                                      color: "green",
                                      "hide-details": "auto",
                                      "single-line": "",
                                      outlined: "",
                                      dense: "",
                                      label:
                                        _vm.configData
                                          .TEXT_RE_ENTER_AFTER_CHANGE_PASSWORD,
                                      autocomplete: "off",
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.show3 = !_vm.show3
                                      },
                                    },
                                    model: {
                                      value: _vm.credentials.confirmNewPassword,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.credentials,
                                          "confirmNewPassword",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "credentials.confirmNewPassword",
                                    },
                                  }),
                                  _vm.errorMessage.confirmNewPassword !== ""
                                    ? _c("p", { staticClass: "txt-error" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errorMessage.confirmNewPassword
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "mb-2",
                              attrs: { justify: "center" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "allig-text-link text-center col-12",
                                  on: {
                                    click: function ($event) {
                                      return _vm.PageLogin()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.configData.TEXT_RETURN_LOGIN_LINK
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "mb-2",
                              attrs: { justify: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "text-color-white text-bold input-login",
                                      attrs: {
                                        block: "",
                                        type: "submit",
                                        color: "black",
                                        elevation: "1",
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit(
                                            "changeInitialPassword",
                                            {
                                              credentials: _vm.credentials,
                                            }
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.configData
                                              .TEXT_BUTTON_CHANGE_PASSWORD
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }