var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "suika-offices-template" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _c("v-container", [
        _c(
          "div",
          { staticClass: "container-user" },
          [
            _c(
              "div",
              { staticClass: "round-action" },
              [
                _c(
                  "v-row",
                  { staticClass: "btn-div", attrs: { justify: "start" } },
                  [
                    _c("v-col", [
                      _c(
                        "div",
                        { staticClass: "area-search-box" },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "space-between",
                              },
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("searchbox", {
                                    staticClass: "input-search",
                                    attrs: {
                                      isCSV: true,
                                      priviledge: _vm.priviledge,
                                      officeAddButton: true,
                                      isDelete: _vm.isCheck,
                                      searchTargetItems: _vm.searchTargetItems,
                                      dataOfficeCode: _vm.dataOfficeCode,
                                      dataOffice: _vm.dataOfficeName,
                                      selected: _vm.selectedOfficeIds,
                                    },
                                    on: {
                                      search: function ($event) {
                                        return _vm.$emit(
                                          "search-office",
                                          $event
                                        )
                                      },
                                      reset: function ($event) {
                                        return _vm.resetSearch()
                                      },
                                      add: function ($event) {
                                        return _vm.$emit("createNewOffice")
                                      },
                                      export: function ($event) {
                                        return _vm.$emit("export")
                                      },
                                      delete: function ($event) {
                                        return _vm.$emit(
                                          "showConfirmDeleteDialog"
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.loadingSearch === false
              ? _c("office-list-table", {
                  attrs: {
                    loading: _vm.loading,
                    officeList: _vm.officeList,
                    officeTotalCount: _vm.officeTotalCount,
                    deleteOfficeDialog: _vm.deleteOfficeDialog,
                    isCheck: _vm.isCheck,
                  },
                  on: {
                    officeSelected: (officeIds) => {
                      _vm.selectedOfficeIds = officeIds
                    },
                    "change-column": function ($event) {
                      return _vm.$emit("change-column", $event)
                    },
                    clickEditOffice: function ($event) {
                      return _vm.$emit("clickEditOffice", $event)
                    },
                    "select-offices": function ($event) {
                      return _vm.setSelectedOfficeIds($event)
                    },
                    deleteSelectedOffices: function ($event) {
                      return _vm.$emit(
                        "deleteSelectedOffices",
                        _vm.selectedOfficeIds
                      )
                    },
                    closeDeleteOfficeDialog: function ($event) {
                      return _vm.$emit("closeDeleteOfficeDialog")
                    },
                    "current-items": function ($event) {
                      return _vm.$emit("current-items", $event)
                    },
                  },
                })
              : _vm._e(),
            _c("office-settings-form", {
              attrs: {
                showModalOfficeSettings: _vm.showModalOfficeSettings,
                loading: _vm.loading,
                isCheck: _vm.isCheck,
                isEdit: _vm.isEdit,
                isResetData: _vm.isResetData,
                officeInfo: _vm.officeInfo,
                areaList: _vm.areaList,
                domainItems: _vm.domainItems,
                confirmOfficeDialog: _vm.confirmOfficeDialog,
                confirmOfficeText: _vm.confirmOfficeText,
              },
              on: {
                saveOfficeSettings: function ($event) {
                  return _vm.$emit("saveOfficeSettings", $event)
                },
                showConfirmOfficeDialog: function ($event) {
                  return _vm.$emit("showConfirmOfficeDialog", $event)
                },
                closeConfirmOfficeDialog: function ($event) {
                  return _vm.$emit("closeConfirmOfficeDialog")
                },
                updateOfficeSettings: function ($event) {
                  return _vm.$emit("updateOfficeSettings", $event)
                },
                closeOfficeSettingsDialog: function ($event) {
                  return _vm.$emit("closeOfficeSettingsDialog")
                },
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }