
import Vue from 'vue';
import AlligHeader from '@/components/organisms/Header/Header.vue';
import SideNavigation from '@/components/organisms/SideNavigation/SideNavigation.vue';

export interface DataType {
  showNav: boolean;
  isMobile: boolean;
  priviledge: any;
  loading: boolean;
  activeName: string;
  userName: string;
  valueCircleLoading: number;
  textCircleLoading: string;
}

export default Vue.extend({
  name: 'LayoutTemplate',
  components: {
    AlligHeader,
    SideNavigation,
  },
  props: {
    pathName: {
      type: String,
      default: 'setting',
    },
    selectedItemNav: {
      type: String,
      default: '-1',
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data: (): DataType => ({
    showNav: false,
    activeName: '',
    loading: false,
    userName: '',
    textCircleLoading: '',
    valueCircleLoading: 0,
    isMobile:
    (sessionStorage.getItem('priviledge') === '3' && sessionStorage.getItem('type') === '0') || (sessionStorage.getItem('priviledge') === '2' && sessionStorage.getItem('office_code') === '')
    ? false : true,
    priviledge: sessionStorage.getItem('priviledge'),
  }),
  created() {
    this.userName = sessionStorage.hasOwnProperty('user_name') ? sessionStorage.getItem('user_name') as string : '';
    const path = this.$route.path.replace('/', '');
    const arr = path.split('/');
    if (this.$vuetify.breakpoint.lgAndUp) {
      this.showNav = true;
    }
  },
  watch : {
    pathName(newVal, oldVal) {
      this.activeName = newVal;
    },
  },
  mounted() {
      // setInterval(() => {
      //   if (this.value === 100) {
      //     return (this.value = 0);
      //   }
      //   this.value += 10;p
      // }, 1000);
    },
  methods: {
    Loading(value: any) {
      this.loading = value;
      this.textCircleLoading = '';
    },
    reLoadUser(value: any) {
      this.userName = sessionStorage.hasOwnProperty('user_name') ? sessionStorage.getItem('user_name') as string : '';
    },
    loadingCircle(value: any) {
      this.valueCircleLoading = value;
    },
    textCircle(value: any) {
      this.textCircleLoading = value;
    },
  },
});
