
  import Vue, { PropType } from 'vue';
  import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
  import Pagination from '@/components/molecules/Pagination/Pagination.vue';
  import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
  import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
  import TextLink from '@/components/molecules/TextLink/TextLink.vue';
  import * as Config from '../../../config';
  import { DataTableHeader } from 'vuetify';
  import auth from '@/auth';
  export interface TypeItem {
    title: string;
  }
  export interface DataType {
    displayOptionItems: DisplayOptionItem[];
    selectedDisplayOption: string[];
    showOption: boolean;
    isVaildOnly: boolean;
    typeSearch: any;
    configData: any;
    loadPage: number;
    selected: [];
  }

  export default Vue.extend({
    name: 'UserManagementList',
    components: {
      TextLink,
      ShowPerPage,
      Pagination,
      SelectPerPage,
      DisplayOption,
    },
    data: (): DataType => ({
      selectedDisplayOption: [
        'user_id',
        'user_name',
        'organizations',
        'email',
        'memo',
        'updated',
      ],
      displayOptionItems: [
        { label:  Config.TEXT_USER_ID, value: 'user_id', isDisabled: true },
        { label: Config.TEXT_USER_NAME, value: 'user_name' },
        { label: Config.TEXT_COL_COMPANY_DEPARTMENT, value: 'organizations' },
        { label: Config.TEXT_COL_EMAIL_ADDRESS, value: 'email' },
        { label: Config.TEXT_REMARKS, value: 'memo' },
        { label: Config.TEXT_UPDATE_DATE, value: 'updated' },
      ],
      selected: [],
      showOption: false,
      isVaildOnly: false,
      typeSearch: 0,
      configData: Config,
      loadPage: 1,
    }),
    computed: {
      innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
      headers: {
        get(): DataTableHeader[] {
          const headers: DataTableHeader[] = [
            {
              text: this.configData.TEXT_USER_ID,
              align: 'start',
              sortable: true,
              value: 'user_id',
              width: 'auto',
            },
          ];
          if (this.selectedDisplayOption.includes('user_name')) {
            headers.push({
              text: this.configData.TEXT_USER_NAME,
              align: 'start',
              sortable: true,
              value: 'name',
              width: 'auto',
            });
          }
          if (this.selectedDisplayOption.includes('organizations')) {
            headers.push({
              text: this.configData.TEXT_COL_COMPANY_DEPARTMENT,
              align: 'start',
              sortable: true,
              value: 'organizations',
              width: 'auto',
            });
          }
          if (this.selectedDisplayOption.includes('email')) {
            headers.push({
              text: this.configData.TEXT_COL_EMAIL_ADDRESS,
              align: 'start',
              sortable: true,
              value: 'email',
              width: 'auto',
            });
          }
          if (this.selectedDisplayOption.includes('memo')) {
            headers.push({
              text: this.configData.TEXT_REMARKS,
              align: 'start',
              sortable: true,
              value: 'memo',
              width: 'auto',
            });
          }
          if (this.selectedDisplayOption.includes('updated')) {
            headers.push({
              text: this.configData.TEXT_UPDATE_DATE,
              align: 'start',
              sortable: true,
              value: 'updated',
              width: 'auto',
            });
          }
          return headers;
        },
      },
    },
    watch: {
      selectedDisplayOption(value) {
        sessionStorage.setItem('displayOptionUser', JSON.stringify(value));
      },
      selected(val) {
        this.$emit('selected', val.map((item: { user_id: any; }) => item.user_id));
      },
      loadPage(newPage: number) {
        if (newPage > 1) {
          this.$emit('change-page', newPage);
        }
      },
    },
    props: {
      resData: {
        type: Array as PropType<TypeItem[]>,
        default: () => [],
      },
      errorMessageProcess: {
        default: () => [],
      },
      userTotalCount: {
        type: Number,
        default: 0,
      },
      page: {
        type: Number,
        default: 1,
      },
      perPage: {
        type: Number,
        default: 100,
      },
      disablePagination: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      isDeleteFinish: {
        type: Boolean,
        default: false,
      },
      loadMore: {
        type: Boolean,
        default: false,
      },
      isFinishProcessEnable: {
        type: Boolean,
        default: false,
      },
      deleteUserDialog: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      //
    },
    methods: {
      currentItems(val: any) {
        this.$emit('get-current-items', val);
      },
      deleteUser() {
        this.$emit('deleteUser');
      },
      closeDeleteUserDialog() {
        this.$emit('closeDeleteUserDialog');
      },
      converDateShort(val: any) {
        return auth.converDateShort(val);
      },
      convertPriviledge(val: any) {
        return auth.convertPriviledge(val);
      },
      convertStatus(val: any) {
        return auth.convertStatus(val);
      },
      CheckColumn(data: any) {
        this.$emit('change-column');
        sessionStorage.setItem('displayOptionUser', JSON.stringify(data));
      },
    },
  });
