var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-container", [
    _c(
      "div",
      [
        _c(
          "v-row",
          {
            staticClass: "mt-3 border-bottom-bold",
            attrs: {
              "no-gutters": "",
              justify: "space-between",
              "align-content": "center",
            },
          },
          [
            _c("div", { staticClass: "ml-8 mb-3" }, [
              _c(
                "div",
                {
                  staticClass:
                    "allig-show-per-page text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption",
                },
                [_vm._v(" " + _vm._s(_vm.total) + "件 ")]
              ),
            ]),
            _c("v-spacer"),
            _c(
              "div",
              {
                staticClass:
                  "text-caption display-option included-option text-sm-caption text-md-caption text-lg-caption text-xl-caption mr-8",
              },
              [
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple",
                        value: false,
                        expression: "false",
                      },
                    ],
                    staticClass: "allig-btn-text-link-gray allig-btn-text-link",
                    attrs: { block: "", type: "button", text: "" },
                    on: {
                      click: () => {
                        _vm.showOption = !_vm.showOption
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "display-option-title" }, [
                      _vm._v(
                        " " + _vm._s(_vm.configData.TEXT_DISPLAY_OPTIONS) + " "
                      ),
                    ]),
                    _c(
                      "span",
                      [
                        _c("v-icon", { attrs: { dense: "" } }, [
                          _vm._v("mdi-chevron-down"),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "v-row",
          { staticClass: "pt-0 pb-0 mt-0" },
          [
            _c(
              "v-container",
              { staticClass: "pt-0" },
              [
                _c("display-option", {
                  attrs: {
                    isShowOption: _vm.showOption,
                    "display-option-items": _vm.displayOptionItems,
                    isVaildOnly: _vm.isVaildOnly,
                  },
                  on: {
                    "show-only-isVaild": function ($event) {
                      return _vm.$emit("show-only-isVaild", $event)
                    },
                    input: function ($event) {
                      return _vm.CheckColumn($event)
                    },
                  },
                  model: {
                    value: _vm.selectedDisplayOption,
                    callback: function ($$v) {
                      _vm.selectedDisplayOption = $$v
                    },
                    expression: "selectedDisplayOption",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm.loading == false
          ? _c("v-data-table", {
              directives: [
                { name: "resizable-columns", rawName: "v-resizable-columns" },
              ],
              staticClass: "border-table scroll-table applicant",
              attrs: {
                "must-sort": "",
                headers: _vm.headers,
                items: _vm.resData,
                "items-per-page": -1,
                loading: _vm.Loading,
                "item-key": "request_id",
                "no-data-text": _vm.configData.TEXT_NO_DATA,
                "loading-text": _vm.configData.TEXT_LOADING_DATA,
                "hide-default-footer": "",
                "sort-by": ["begin"],
                "sort-desc": false,
                "item-class": _vm.row_classes,
              },
              on: { "current-items": _vm.currentItems },
              scopedSlots: _vm._u(
                [
                  {
                    key: `item.request_id`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple",
                                value: false,
                                expression: "false",
                              },
                            ],
                            staticClass: "allig-btn-text-link go-detail",
                            attrs: { block: "", type: "button", text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.clickDetail(item.request_id)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.request_id) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: `item.begin`,
                    fn: function ({ item }) {
                      return [_vm._v(" " + _vm._s(item.begin) + " ")]
                    },
                  },
                  {
                    key: `item.end`,
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.converDateShort(item.end)) + " "
                        ),
                      ]
                    },
                  },
                  {
                    key: `item.updated`,
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.converDateShort(item.updated)) + " "
                        ),
                      ]
                    },
                  },
                  {
                    key: `item.status`,
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.convertStatus(item.status)) + " "
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.applicantSelected,
                callback: function ($$v) {
                  _vm.applicantSelected = $$v
                },
                expression: "applicantSelected",
              },
            })
          : _vm._e(),
        _c("v-row", {
          staticStyle: { height: "20px" },
          attrs: { "no-gutters": "" },
        }),
        _c("v-spacer"),
        _vm.loadMore
          ? _c(
              "v-row",
              { attrs: { justify: "center" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "btn-secondary margin-button-load-more",
                    attrs: { outlined: "" },
                    on: {
                      click: function ($event) {
                        return _vm.countPage()
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.configData.TEXT_BUTTON_OTHERS) + " "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }