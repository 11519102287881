
import Vue, { PropType } from 'vue';
import Searchbox,  {SearchTargetItem} from '@/components/molecules/Searchbox/Searchbox.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import * as Config from '@/config';
import { DataTableHeader } from 'vuetify';
import auth from '@/auth';
import moment from 'moment-timezone';
export interface TypeItem {
  title: string;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  showOption: boolean;
  isVaildOnly: boolean;
  is_extension_waiting: boolean;
  applicantSelected: [];
  configData: any;
  loadPage: number;
  workDate: any;
  itemWorkDate: any;
  description: string;
  comment: string;
  confirmDialogExtention: boolean;
  endTime: string;
  extPeriodTime: string;
  currentTime: string;
  confirmDialogExtentionComment: boolean;
  ID: string;
  requesterName: string;
  endDate: string;
  displayPriviledge: any;
}

export default Vue.extend({
  name: 'applicantListTable',
  components: {
    Searchbox,
    ShowPerPage,
    Pagination,
    SelectPerPage,
    DisplayOption,
    TextLink,
  },
  watch: {
    loadPage(newPage: number) {
      if (newPage > 1) {
        this.$emit('change-page', newPage);
      }
    },
    resExtData() {
      if (this.resExtData.end) {
        this.endTime = auth.converDateShort(this.resExtData.end);
        const a  = moment(this.resExtData.end);
        const b  = moment(this.endDate);
        const duration = moment.duration(a.diff(b));
        const hours = duration.asMilliseconds();
        const countTime = auth.countTime(hours);
        let text = '';
        if (countTime === '02') {
          text = '+2時間';
        } else if (countTime === '04') {
          text = '+4時間';
        } else if (countTime === '06') {
          text = '+6時間';
        }
        this.extPeriodTime = auth.converDateShort(this.endDate) + '  ' + text;
      } else {
        this.endTime = auth.converDateShort(this.endDate);
        this.extPeriodTime = auth.converDateShort(this.endDate);
      }
      this.description = this.resExtData.comment;
      this.comment = '';
    },
  },
  props: {
    resData: {
      default: () => [],
    },
    applicantTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 100,
    },
    total: {
      type: Number,
      default: 0,
    },
    loadMore: {
      type: Boolean,
      default: false,
    },
    openExtensions: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    isFinishProcessEnable: {
      type: Boolean,
      default: false,
    },
    confirmDialogFinish: {
      type: Boolean,
      default: false,
    },
    loadingSearch: {
      type: Boolean,
      default: false,
    },
    resExtData: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    //
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: Config.TEXT_APPLICATION_EXTENSION,
            align: 'center',
            sortable: true,
            value: 'is_extension',
            width: '120px',
          },
        ];
        if (this.selectedDisplayOption.includes('request_id')) {
          headers.push({
            text: Config.TEXT_COL_APPLICATION_NUMBER,
            align: 'start',
            sortable: true,
            value: 'request_id',
            width: '250px',
          });
        }
        if (this.selectedDisplayOption.includes('status')) {
          headers.push({
            text: Config.TEXT_COL_STATUS,
            align: 'start',
            sortable: true,
            value: 'status',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('begin')) {
          headers.push({
            text: Config.TEXT_COL_BEGIN_DATE,
            align: 'start',
            sortable: true,
            value: 'begin',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('end')) {
          headers.push({
            text: Config.TEXT_COL_END_DATE,
            align: 'start',
            sortable: true,
            value: 'end',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('office_name')) {
          headers.push({
            text: Config.TEXT_COL_MANAGEMENT_OFFICE,
            align: 'start',
            sortable: true,
            value: 'office_name',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('area_name')) {
          headers.push({
            text: Config.TEXT_AREA,
            align: 'start',
            sortable: true,
            value: 'area_name',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('updater')) {
          headers.push({
            text: Config.TEXT_USER_MANAGEMENT,
            align: 'start',
            sortable: true,
            value: 'requester_name',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('updated')) {
          headers.push({
            text: Config.TEXT_UPDATE_DATE,
            align: 'start',
            sortable: true,
            value: 'updated',
            width: 'auto',
          });
        }
        return headers;
      },
      set(): void {
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', val);
        return;
      },
    },
  },
  data: (): DataType => ({
    selectedDisplayOption: [
      'request_id',
      'status',
      'begin',
      'end',
      'office_name',
      'area_name',
      'updater',
      'updated',
    ],
    displayOptionItems: [
      { label: Config.TEXT_COL_APPLICATION_NUMBER, value: 'request_id', isDisabled: true },
      { label: Config.TEXT_COL_STATUS, value: 'status' },
      { label: Config.TEXT_COL_BEGIN_DATE, value: 'begin' },
      { label: Config.TEXT_COL_END_DATE, value: 'end' },
      { label: Config.TEXT_COL_MANAGEMENT_STATION, value: 'office_name' },
      { label: Config.TEXT_AREA, value: 'area_name' },
      { label: Config.TEXT_USER_MANAGEMENT, value: 'updater' },
      { label: Config.TEXT_UPDATE_DATE, value: 'updated' },
    ],
    applicantSelected: [],
    showOption: false,
    isVaildOnly: false,
    configData: Config,
    loadPage: 1,
    itemWorkDate: ['入室終了日時+2時間', '入室終了日時+4時間', '入室終了日時+6時間'],
    description: '',
    workDate: '',
    comment: '',
    confirmDialogExtention: false,
    is_extension_waiting: false,
    endTime: '',
    extPeriodTime: '',
    currentTime: auth.converDateShort(new Date()),
    confirmDialogExtentionComment: false,
    ID: '',
    requesterName: '',
    endDate: '',
    displayPriviledge: sessionStorage.getItem('priviledge'),
  }),
  methods: {
    currentItems(val: any) {
      this.$emit('get-current-items', val);
    },
    row_classes(item: { status: any}) {
      if (item.status === 'submitted') {
        return 'tomato';
      } else if (item.status === 'rescinded') {
        return 'bg2';
      }
    },
    returnExtDialog() {
      this.confirmDialogExtentionComment = false;
      this.confirmDialogExtention = true;
    },
    actionApproval(extStatus: string) {
      this.$emit('action-approval', {
          action: 'extensions',
          request_id: this.ID,
          data: {
            extension_id: this.resExtData.id,
            status: extStatus,
            comment: this.comment,
          },
        });
      this.confirmDialogExtentionComment = false;
      this.confirmDialogExtention = false;
    },
    goDetail(val: any) {
      this.$router.push({ path: Config.APPROVAL, query: {request_id: val}});
    },
    showExtDialog(val: any, requesterName: any, waiting: any, end: any) {
      this.ID = val;
      this.requesterName = requesterName;
      this.endDate = '';
      this.confirmDialogExtention = true;
      this.is_extension_waiting = waiting;
      this.endDate = end;
      this.$emit('open-extensions', val);
    },
    clickShowCommentDialog() {
      this.confirmDialogExtention = false;
      this.confirmDialogExtentionComment = true;
    },
    converDateShort(val: any) {
      return auth.converDateShort(val);
    },
    convertStatus(val: any) {
      return auth.convertStatus(val);
    },
    CheckColumn(data: any) {
      this.$emit('change-column');
      sessionStorage.setItem('displayOptionApporvalStatus', JSON.stringify(data));
    },
    countPage() {
      this.innerPage =  this.innerPage + 1;
    },
  },
});
