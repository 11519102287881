var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-overlay",
        { attrs: { absolute: "", value: _vm.loading, color: "white" } },
        [_c("img", { attrs: { src: "/loading-circle.gif", width: "40" } })]
      ),
      _c(
        "v-container",
        [
          _c(
            "v-card",
            { staticClass: "pa-6 pr-10 mb-5" },
            [
              _c(
                "v-form",
                { ref: "userInfoForm", attrs: { "lazy-validation": "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          class: { "pb-0": _vm.$vuetify.breakpoint.smAndDown },
                          attrs: { cols: "12", md: "3", xs: "3" },
                        },
                        [
                          _c("v-subheader", [
                            _vm._v(_vm._s(_vm.configData.TEXT_USER_ID_FORM)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-body-2",
                          class: {
                            "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown,
                          },
                          attrs: {
                            cols: "12",
                            md: "9",
                            xs: "9",
                            "align-self": "center",
                          },
                        },
                        [_vm._v(_vm._s(_vm.userName))]
                      ),
                    ],
                    1
                  ),
                  _c("input-form", {
                    attrs: {
                      title:
                        _vm.configData.TEXT_USER_NAME +
                        `<span class='required'>` +
                        _vm.configData.TEXT_MUST +
                        `</span>`,
                      headerCols: 3,
                      inputType: "text",
                      rules: [
                        _vm.rulesValidate.requiredUserName,
                        _vm.rulesValidate.validateName,
                      ],
                    },
                    model: {
                      value: _vm.userInfo.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.userInfo, "name", $$v)
                      },
                      expression: "userInfo.name",
                    },
                  }),
                  _c("input-form", {
                    attrs: {
                      title: _vm.configData.TEXT_FURIGANA,
                      headerCols: 3,
                      inputType: "text",
                      rules: [_vm.rulesValidate.validateUserFurigana],
                    },
                    model: {
                      value: _vm.userInfo.furigana,
                      callback: function ($$v) {
                        _vm.$set(_vm.userInfo, "furigana", $$v)
                      },
                      expression: "userInfo.furigana",
                    },
                  }),
                  _c("input-form", {
                    attrs: {
                      title:
                        _vm.priviledge === "0"
                          ? _vm.configData.TEXT_COL_EMAIL_ADDRESS +
                            `<span class='required'>` +
                            _vm.configData.TEXT_MUST +
                            `</span>`
                          : _vm.configData.TEXT_COL_EMAIL_ADDRESS,
                      headerCols: 3,
                      inputType: "text",
                      maxlength: _vm.configData.EMAIL_MAX_LEN,
                      rules: [
                        _vm.rulesValidate.validateUserEmail,
                        _vm.requiredEmail(_vm.userInfo.email, _vm.priviledge),
                      ],
                    },
                    model: {
                      value: _vm.userInfo.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.userInfo, "email", $$v)
                      },
                      expression: "userInfo.email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-2 mt-2", attrs: { justify: "end" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn-primary",
                  attrs: { outlined: "", disabled: _vm.isDisabled },
                  on: {
                    click: function ($event) {
                      return _vm.showConfirmDialog()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.configData.TEXT_UPDATE) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "600" },
              model: {
                value: _vm.confirmDialog,
                callback: function ($$v) {
                  _vm.confirmDialog = $$v
                },
                expression: "confirmDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "modal-title", attrs: { color: "black" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.configData.TEXT_CONFIRMATION) + " "
                      ),
                    ]
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "mt-5" },
                    [
                      _c("v-row", {
                        staticStyle: { height: "20px" },
                        attrs: { "no-gutters": "" },
                      }),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.configData.TEXT_REQUEST_CHANGE_USER_INFO)
                        ),
                      ]),
                      _c("v-row", {
                        staticStyle: { height: "20px" },
                        attrs: { "no-gutters": "" },
                      }),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeConfirmDialog()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.configData.TEXT_RETURN) + " "
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.updateUserInfo()
                                },
                              },
                            },
                            [_vm._v(" OK ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }