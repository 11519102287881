
import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import * as Config from '@/config';
export interface DataType {
  files: any;
  fileName: string;
  configData: any;
  rulesValidate: any;
  checkMessage: string;
}

export default Vue.extend({
  name: 'UserBulkAddForm',
  components: {
    CancelButton,
    SuccessButton,
  },
  props: {
    showModalBulkUser: {
      type: Boolean,
      default: false,
    },
  },
  data: (): DataType => ({
    files: [],
    fileName: '',
    configData: Config,
    rulesValidate: {
      requiredCSVFile: (fileName: string) => !!fileName || Config.ERROR_PLEASE_SELECT_CSV_FILE,
    },
    checkMessage: '',
  }),
  methods: {
    previewFiles(event: any) {
      this.files = event.target.files[0];
      this.fileName = event.target.files[0] ? event.target.files[0].name : '';
      this.checkMessage = '';
    },
    openFile() {
      const element: HTMLElement = document.getElementsByClassName('custom-file-input')[0] as HTMLElement;
      element.click();
    },
    closeBulkUserDialog() {
      this.files = null;
      this.fileName = '';
      const result = (this.$refs.userAddBulk as Vue & { reset: () => boolean }).reset();
      this.$emit('closeBulkUserDialog');
    },
    clickImportCSV() {
      const result = (this.$refs.userAddBulk as Vue & { validate: () => boolean }).validate();
      if (result) {
        if (this.fileName && this.files.size !== 101) {
          this.$emit('clickImportCSV', {
            files: this.files,
          });
          this.checkMessage = '';
        } else {
          this.checkMessage = Config.ERROR_INPUT_CONTENTS_CSV_FILE;
        }
      }
    },
  },
  created() {
    //
  },
  mounted() {
    //
  },
  computed: {
    //
  },
});
