
import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import Approver from '@/components/organisms/WorkApplication/Approver.vue';
import Searchbox, {
  SearchTargetItem,
} from '@/components/molecules/Searchbox/Searchbox.vue';
import * as Config from '../../../config';
interface TypeSelect {
  text: string;
  value: string;
}
export interface DataType {
  selectedApplicantIds: string[];
  tab: number;
  searchTargetItems: SearchTargetItem[];
}
export default Vue.extend({
  name: 'ApplicantTemplate',
  components: {
    Approver,
    Searchbox,
    Breadcrumbs,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    closeDialogExtention: {
      type: Boolean,
      default: false,
    },
    isAction: {
      type: Number,
      default: 0,
    },
    resData: {
      type: Object,
      default: () => ({}),
    },
    resCardData: {
      type: Object,
      default: () => ({}),
    },
    resHisData: {
      default: () => [],
    },
    resDataGates: {
      default: () => [],
    },
    actionItems: {
      default: () => [],
    },
    request_id: {
      default: '',
    },
    resExtData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: (): DataType => ({
    selectedApplicantIds: [],
    tab: 0,
    searchTargetItems: [
      { label: Config.TEXT_WORKING_DAY, name: 'since', value: '', isDisabled: true },
      { label: Config.TEXT_COL_APPLICATION_NUMBER, name: 'until', value: ''},
      { label: Config.TEXT_AREA, name: 'area_list', value: ''},
      { label: Config.TEXT_COL_STATUS, name: 'status', value: ''},
      { label: Config.TEXT_COL_WORK_STATION, name: 'office_list', value: ''},
    ],
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: Config.TEXT_APPLICATION_DETAILS, disabled: true },
        ];
      },
    },
  },
  methods: {
    //
  },
});
