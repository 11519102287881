
import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';
export interface TypeItem {
  title: string;
}
export interface DataType {
  configData: any;
  search: any;
  searchText: any;
  selected: any;
}


export default Vue.extend({
  name: 'DoorListDialog',
  components: {
  },
  watch: {
    isShow(val) {
      if (val) {
        if ( this.resData.length > 0 && this.gate_code.length > 0) {
          this.selected = this.resData.filter((o: { gate_code: any; }) => this.gate_code.includes(o.gate_code));
        }
      }
    },
  },
  props: {
    resData: {
      default: () => [],
    },
    gate_code: {
      type: Array,
      default: () => [],
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data: (): DataType => ({
    configData: Config,
    search: '',
    searchText: '',
    selected: [],
  }),
  mounted() {
    //
  },
  methods: {
    clickSearch() {
      this.search = this.searchText;
    },
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: this.configData.TEXT_GATE_CODE,
            align: 'start',
            sortable: true,
            value: 'gate_code',
          },
          {
            text: this.configData.TEXT_GATE_NAME,
            align: 'start',
            sortable: true,
            value: 'gate_name',
          },
        ];
        return headers;
      },
      set(): void {
        return;
      },
    },
  },
});
