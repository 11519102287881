
  import Vue, { PropType } from 'vue';
  import * as Config from '@/config';
  import TextLink from '@/components/molecules/TextLink/TextLink.vue';

  export interface DataType {
    priviledge: any;
    configData: any;
  }

  export default Vue.extend({
    name: 'ManualListTable',
    components: {
        TextLink,
    },
    watch: {
    },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      resData: {
        default() {
          return [];
        },
      },
    },
    mounted() {
      //
    },
    computed: {
      //
    },
    data: (): DataType => ({
      priviledge: sessionStorage.getItem('priviledge'),
      configData: Config,
    }),
    methods: {
      //
      downLoad(value: any, name: string) {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (!isSafari) {
        const a = document.createElement('a');
        a.download = name;
        a.href = value;
        a.target  = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        window.open(value, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
      }
    },
    },
  });
