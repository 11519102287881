
import {Vue } from 'vue-property-decorator';
import ApprovalStatusTemplate from '@/components/templates/ApprovalStatusTemplate/ApprovalStatusTemplate.vue';
import * as Config from '@/config';
import auth from '@/auth';
import moment from 'moment';
export interface DataType {
  // Props
  page: number;
  pageExport: number;
  perPage: number;
  total: number;
  loading: boolean;
  isRegistering: boolean;
  loadingCSV: boolean;
  loadMore: boolean;
  resData: any;
  areaList: any;
  dataArea: any;
  dataOffice: any;
  keyword: string;
  isExtension: any;
  scorp: any;
  countExport: number;
  exportData: any;
  resDataOne: any;
  resExtData: any;
  confirmDialogFinish: boolean;
  loadingSearch: boolean;
  isExtensions: boolean;
  listDataExport: any;
}
export default Vue.extend({
  name: 'ApprovalStatusPage',
  components: {
    ApprovalStatusTemplate,
  },
  data: (): DataType => ({
    // Props
    loadingCSV: false,
    loading: false,
    isRegistering: false, // default: false -> reading data
    loadMore: false,
    page: 1,
    pageExport: 1,
    resData: [],
    keyword: '',
    isExtension: false,
    isExtensions: false,
    perPage: Config.PER_PAGE,
    total: 0,
    scorp: [],
    countExport: 0,
    exportData: [],
    areaList: [],
    dataOffice: [],
    dataArea: [],
    resDataOne: [],
    resExtData: ({}),
    confirmDialogFinish: false,
    loadingSearch: false,
    listDataExport: [],
  }),
  mounted() {
    // console.log('resexttime', this.resExtData);
    this.$emit('activeMenu', 'approval-status');
    sessionStorage.removeItem('displayOptionApporvalStatus');
  },
  created() {
    const day = moment().subtract(1, 'd').format('YYYY-MM-DD');
    this.keyword = 'since=' + auth.formatISOString(day + ' 23:29') + '&';
    this.loading = true;
    this.isExtension =  null;
    this.getListDataOffice()
    .then(() => {
      this.getAreaList()
      .then(() => {
        if (sessionStorage.getItem('priviledge')  === '2') {
          this.keyword += 'office_code=' + sessionStorage.getItem('office_code');
          this.getListData();
          this.getListOfficeArea(sessionStorage.getItem('office_code'));
        } else {
          this.getListData();
        }
      });
    })
    .then( null, (err: any) => {
      this.loading = false;
      console.log(err);
    });
  },
  watch: {
    countExport(newVal, oldVal) {
      if (newVal >= this.pageExport) {
        if (this.exportData.length === 0) {
          this.exportData.push({
            [Config.TEXT_APPLICATION_EXTENSION]: '',
            [Config.TEXT_COL_APPLICATION_NUMBER]: '',
            [Config.TEXT_COL_STATUS]: '',
            [Config.TEXT_COL_BEGIN_DATE]: '',
            [Config.TEXT_COL_END_DATE]: '',
            [Config.TEXT_COL_MANAGEMENT_OFFICE]: '',
            [Config.TEXT_AREA]: '',
            [Config.TEXT_USER_MANAGEMENT]: '',
            [Config.TEXT_UPDATE_DATE]: '',
          });
        }
        auth.downloadCSV(this.exportData, Config.TEXT_APPROVAL_STATUS_LIST);
        this.loadingCSV = false;
      } else if (newVal > 0) {
        this.getExportData(newVal + 1);
      }
    },
    loading(value) {
      this.$emit('loading', value);
      if (value) {
        this.$emit('textCircle', this.isRegistering ? Config.TEXT_PROGRESS_REGISTERING : Config.TEXT_PROGRESS_READING);
      } else if (this.isRegistering) {
        this.isRegistering = false;
      }
    },
    loadingCSV(value) {
      this.$emit('loading', value);
    },
    isExtensions(value) {
      this.$emit('loading', value);
    },
  },
  methods: {
    getCurrentItems(item: any) {
      this.listDataExport = item;
    },
    closeDialog(value: any) {
      this.confirmDialogFinish = false;
    },
    changeOffice(item: any) {
      this.dataArea = [];
      if (item !== null && item.value !== undefined && item.value !== '') {
        this.getListOfficeArea(item.value);
      }
    },
    changeColumn(value: any): any {
      this.loading = true;
      setTimeout(() => {this.loading = false; }, 300);
    },
    async exportCSV(value: any): Promise<any> {
      this.exportData = [];
      this.pageExport = 0;
      this.loadingCSV = true;
      if (this.listDataExport.length > 0) {
        for (const item of this.listDataExport) {
          let templateExport: any = {};
          if (sessionStorage.hasOwnProperty('displayOptionApporvalStatus')) {
            let displayOption = sessionStorage.getItem('displayOptionApporvalStatus') as string;
            displayOption = displayOption.replace(/'/g, '"');
            displayOption = JSON.parse(displayOption);
            const isExtension = Config.TEXT_APPLICATION_EXTENSION;
            templateExport[isExtension] = item.is_extension ? 1 : 0;
            if (displayOption.includes('request_id')) {
              const requestId = Config.TEXT_COL_APPLICATION_NUMBER;
              templateExport[requestId] = item.request_id;
            }
            if (displayOption.includes('status')) {
              const status = Config.TEXT_COL_STATUS;
              templateExport[status] = auth.convertStatus(item.status);
            }
            if (displayOption.includes('begin')) {
              const begin = Config.TEXT_COL_BEGIN_DATE;
              templateExport[begin] = auth.converDateShort(item.begin);
            }
            if (displayOption.includes('end')) {
              const end = Config.TEXT_COL_END_DATE;
              templateExport[end] = auth.converDateShort(item.end);
            }
            if (displayOption.includes('office_name')) {
              const officeName = Config.TEXT_COL_MANAGEMENT_OFFICE;
              templateExport[officeName] = auth.getDefaultStringVal(item.office_name);
            }
            if (displayOption.includes('area_name')) {
              const areaName = Config.TEXT_COL_AREA;
              templateExport[areaName] = auth.getDefaultStringVal(item.area_name);
            }
            if (displayOption.includes('updater')) {
              const updater = Config.TEXT_USER_MANAGEMENT;
              templateExport[updater] = auth.getDefaultStringVal(item.requester_name);
            }
            if (displayOption.includes('updated')) {
              const updated = Config.TEXT_UPDATE_DATE;
              templateExport[updated] = auth.converDateShort(item.updated);
            }
          } else {
            templateExport = {
              [Config.TEXT_APPLICATION_EXTENSION]: item.is_extension ? 1 : 0,
              [Config.TEXT_COL_APPLICATION_NUMBER]: item.request_id,
              [Config.TEXT_COL_STATUS]: auth.convertStatus(item.status),
              [Config.TEXT_COL_BEGIN_DATE]: auth.converDateShort(item.begin),
              [Config.TEXT_COL_END_DATE]: auth.converDateShort(item.end),
              [Config.TEXT_COL_MANAGEMENT_OFFICE]: auth.getDefaultStringVal(item.office_name),
              [Config.TEXT_COL_AREA]: auth.getDefaultStringVal(item.area_name),
              [Config.TEXT_USER_MANAGEMENT]: auth.getDefaultStringVal(item.requester_name),
              [Config.TEXT_UPDATE_DATE]: auth.converDateShort(item.updated),
            };
          }
          await this.exportData.push(templateExport);
        }
        auth.downloadCSV(this.exportData, Config.TEXT_APPROVAL_STATUS_LIST);
        this.loadingCSV = false;
      } else {
        let templateExport: any = {};
        const isExtension = Config.TEXT_APPLICATION_EXTENSION;
        templateExport[isExtension] = '';
        if (sessionStorage.hasOwnProperty('displayOptionApporvalStatus')) {
          let displayOption = sessionStorage.getItem('displayOptionApporvalStatus') as string;
          displayOption = displayOption.replace(/'/g, '"');
          displayOption = JSON.parse(displayOption);
          if (displayOption.includes('request_id')) {
            const requestId = Config.TEXT_COL_APPLICATION_NUMBER;
            templateExport[requestId] = '';
          }
          if (displayOption.includes('status')) {
            const status = Config.TEXT_COL_STATUS;
            templateExport[status] = '';
          }
          if (displayOption.includes('begin')) {
            const begin = Config.TEXT_COL_BEGIN_DATE;
            templateExport[begin] = '';
          }
          if (displayOption.includes('end')) {
            const end = Config.TEXT_COL_END_DATE;
            templateExport[end] = '';
          }
          if (displayOption.includes('office_name')) {
            const officeName = Config.TEXT_COL_MANAGEMENT_OFFICE;
            templateExport[officeName] = '';
          }
          if (displayOption.includes('area_name')) {
            const areaName = Config.TEXT_COL_AREA;
            templateExport[areaName] = '';
          }
          if (displayOption.includes('updater')) {
            const updater = Config.TEXT_USER_MANAGEMENT;
            templateExport[updater] = '';
          }
          if (displayOption.includes('updated')) {
            const updated = Config.TEXT_UPDATE_DATE;
            templateExport[updated] = '';
          }
        } else {
          templateExport = {
            [Config.TEXT_APPLICATION_EXTENSION]: '',
            [Config.TEXT_COL_APPLICATION_NUMBER]: '',
            [Config.TEXT_COL_STATUS]: '',
            [Config.TEXT_COL_BEGIN_DATE]: '',
            [Config.TEXT_COL_END_DATE]: '',
            [Config.TEXT_COL_MANAGEMENT_OFFICE]: '',
            [Config.TEXT_COL_AREA]: '',
            [Config.TEXT_USER_MANAGEMENT]: '',
            [Config.TEXT_UPDATE_DATE]: '',
          };
        }
        await this.exportData.push(templateExport);
        auth.downloadCSV(this.exportData, Config.TEXT_APPROVAL_STATUS_LIST);
        this.loadingCSV = false;
      }
    },
    changePerPage(value: any): any {
      this.perPage = value;
      this.page = 1;
      this.getListData();
    },
    changePage(value: any): any {
      this.page = value;
      this.loadingSearch = true;
      sessionStorage.removeItem('displayOptionApporvalStatus');
      this.getListData();
    },
    search(args: any) {
      this.listDataExport = [];
      sessionStorage.removeItem('displayOptionApporvalStatus');
      this.loadingSearch = true;
      this.resData = [];
      this.keyword = '';
      this.isExtension = null;
      this.page = 1;
      this.total = 0;
      args.target.forEach((element: any, key: any) => {
        if (element.value !== undefined && element.value !== null && element.value !== '') {
          if (element.name === 'office_code' && sessionStorage.getItem('priviledge')  === '2') {
            this.keyword += 'office_code=' + sessionStorage.getItem('office_code') + '&';
          } else {
            if (element.name === 'area_list') {
              this.keyword += 'area_code=' + element.value.value + '&';
            } else if (element.name === 'office_list') {
              this.keyword += 'office_code=' + element.value.value + '&';
            } else if (element.name === 'is_extension') {
              if (element.value.text === Config.TEXT_APPLYING_FOR_EXTENSION) {
                this.isExtension = true;
              } else if (element.value.text === Config.TEXT_NO_APPLICATION_FOR_EXTENSION) {
                this.isExtension = false;
              }
              this.keyword += 'is_extension=' + element.value.value + '&';
            } else if (element.name === 'request_id' && element.value.length > Config.REQUEST_ID_MAX_LEN) {
              this.keyword += element.name + '=' + element.value.slice(0, Config.REQUEST_ID_MAX_LEN) + '&';
            } else {
              let value = element.value;
              if (element.name === 'status') {
                value = element.value.value;
              } else if (element.name === 'since' || element.name === 'until') {
                if (element.name === 'until') {
                  const until =  moment(element.value).add(1, 'days').format('YYYY-MM-DD');
                  value = auth.formatISOString(until + ' 00:00');
                } else if (element.name === 'since') {
                  const since = moment(element.value).subtract(1, 'd').format('YYYY-MM-DD');
                  value = auth.formatISOString(since + ' 23:29');
                }
              }
              this.keyword += element.name + '=' + value + '&';
            }
          }
        }
      });
      this.getListData();
    },
    openExtensions(val: any) {
      this.isExtensions = true;
      this.resExtData = ({});
      const apiLink = Config.ENTRY_REQUESTS_API + '/' + val + '/extensions';
      this.$http.get(apiLink)
      .then((response: any) => {
        if (response.body.extensions.length > 0) {
          const result = response.body.extensions.slice(-1);
          this.resExtData = result[0];
          this.isExtensions = false;
        }
      })
      .then( null, (err: any) => {
        console.log(err);
        this.isExtensions = false;
      }); // catch
    },
    actionApproval(args: any) {
      this.loading = true;
      const dataQuery = this.$route.query;
      let data = {};
      data = {comment: args.data};
      console.log(data);
      let link;
      if (args.action === 'extensions') {
        link = this.$http.patch(Config.ENTRY_REQUESTS_API + '/' + args.request_id + '/' + args.action, args.data);
      } else {
        link = this.$http.post(Config.ENTRY_REQUESTS_API + '/' + dataQuery.request_id + '/' + args.action, data);
      }
      link.then((response: any) => {
        if (args.action === 'extensions') {
          if (args.data.status === 'approved') {
            alert(Config.TEXT_EXT_APPROVAL_COMPLETED);
          } else {
            alert(Config.TEXT_EXT_DENIAL_COMPLETED);
          }
        } else {
          alert(Config.TEXT_EXT_COMPLETED);
        }
        this.loading = false;
        this.confirmDialogFinish = true;

        this.loadingSearch = true;
        this.resData = [];
        this.page = 1;
        this.total = 0;
        this.getListData();
      })
      .then( null, (err: any) => {
        this.loading = false;
        this.confirmDialogFinish = true;
        if (args.action === 'extensions') {
          if (args.data.status === 'approved') {
            if (err.status === 400) {
              alert(Config.TEXT_APP_CONTENT_FAILED);
            } else if (err.status === 409) {
              alert(Config.TEXT_APP_STATE_FAILED);
            } else {
              alert(Config.TEXT_EXT_APPROVAL_FAILED);
            }
          } else {
            alert(Config.TEXT_EXT_DENIAL_FAILED);
          }
        } else {
          if (err.status === 400) {
            alert(Config.TEXT_APP_CONTENT_FAILED);
          } else if (err.status === 409) {
            alert(Config.TEXT_APP_STATE_FAILED);
          } else {
            alert(Config.TEXT_APPROVAL_FAILED);
          }
        }
      }); // catch
    },
    getListData(): any {
      this.loading = true;
      this.loadMore = false;
      this.resDataOne = [];
      let apiLink = Config.ENTRY_REQUESTS_API + '?limit=' + this.perPage +
        '&page=' + this.page ;
      if (this.keyword !== '') {
        apiLink += '&' + this.keyword.replace(/&\s*$/, '');
      }
      this.$http.get(apiLink)
        .then( async (response: any) => {
          // this.resData = response.body.requests;
          this.total = response.body.total;
          this.page = Math.ceil(response.body.head / this.perPage);
          if (response.body.requests.length > 0) {
            this.resDataOne = response.body.requests;
            for (let i = 0; i < this.resDataOne.length; i++) {
              this.resDataOne[i].begin = auth.converDateShort((moment(this.resDataOne[i].begin).add(15, 'minutes')));
              let isAddRequest = false;
              if ((this.resDataOne[i].status === 'approved' || this.resDataOne[i].status === 'submitted' ||
              this.resDataOne[i].status === 'returned' || this.resDataOne[i].status === 'rescinded')) {
                if (this.resDataOne[i].is_extension) {
                  isAddRequest = await this.isExtensionWaiting(this.resDataOne[i].request_id, i);
                }
                this.resDataOne[i].is_extension_waiting = isAddRequest;
                // Get office's name
                const office = this.dataOffice.find((a: any) => a.value === this.resDataOne[i].office_code);
                if (office) {
                  this.resDataOne[i].office_name = office.text;
                }
                // Get area's name
                const area = this.areaList.find((a: any) => a.code === this.resDataOne[i].area_code);
                if (area) {
                  this.resDataOne[i].area_name = area.name;
                }
                await this.resData.push(this.resDataOne[i]);
              }
            }
          }
          this.total = this.resData.length;
          if (response.body.total > response.body.tail) {
            this.loadMore = true;
          }
          this.loading = false;
          this.loadingSearch = false;
        })
        .then( null, (err: any) => {
          this.loading = false;
          this.loadingSearch = false;
          alert(Config.TEXT_ENTRY_REQUESTS_LIST_FAILED);
        }); // catch
    },
    getExportData(page: any): any {
      let apiLink = Config.ENTRY_REQUESTS_API + '?limit=1000&page=' + page;
      if (this.keyword !== '') {
        apiLink += '&' + this.keyword.replace(/&\s*$/, '');
      }
      this.$http.get(apiLink)
        .then(async (response: any) => {
          const resData = response.body.requests;
          if (resData.length > 0) {
            this.pageExport = Math.ceil(response.body.total / 1000);
            for (let i = 0; i < resData.length; i++) {
              const item = resData[i];
              const index = i;
              if ((item.status === 'approved' || item.status === 'submitted')) {
                const office = this.dataOffice.find((a: any) => a.value === item.office_code);
                if (office) {
                  item.office_name = office.text;
                }
                // Get area's name
                const area = this.areaList.find((a: any) => a.code === item.area_code);
                if (area) {
                  item.area_name = area.name;
                }
                this.exportData.push({
                  [Config.TEXT_APPLICATION_EXTENSION]: item.is_extension ? 1 : 0,
                  [Config.TEXT_COL_APPLICATION_NUMBER]: item.request_id,
                  [Config.TEXT_COL_STATUS]: auth.convertStatus(item.status),
                  [Config.TEXT_COL_BEGIN_DATE]: auth.converDateShort(item.begin),
                  [Config.TEXT_COL_END_DATE]: auth.converDateShort(item.end),
                  [Config.TEXT_COL_MANAGEMENT_OFFICE]: auth.getDefaultStringVal(item.office_name),
                  [Config.TEXT_AREA]: auth.getDefaultStringVal(item.area_name),
                  [Config.TEXT_USER_MANAGEMENT]: auth.getDefaultStringVal(item.requester_name),
                  [Config.TEXT_UPDATE_DATE]: auth.converDateShort(item.updated),
                });
              }
            }
          }
          this.countExport++;
        })
        .then( null, (err: any) => {
          this.countExport++;
        }); // catch
    },
    isExtensionWaiting(requestId: any, key: any): any {
      return new Promise((resolve, reject) => {
        const apiLink = Config.ENTRY_REQUESTS_API + '/' + requestId + '/extensions';
        this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.extensions.length > 0) {
            const result = response.body.extensions.slice(-1);
            this.resExtData = result[0];
            if (result[0].status === 'waiting') {
              resolve(true);
            }
          }
          resolve(false);
        })
        .then( null, (err: any) => {
          console.log(err);
          resolve(true);
        }); // catch
      });
    },
    getOfficesForExport(approvalStatus: any, ID: any): any {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.OFFICES_LIST_API + '?office_code=' + ID)
        .then((response: any) => {
          if (response.body.offices.length > 0) {
            const res = response.body.offices[0];
            approvalStatus.office_name = res.name;
          }
          resolve(true);
        })
        .then( null, (err: any) => {
          resolve(false);
        }); // catch
      });
    },
    getAreaForExport(approvalStatus: any, ID: any): any {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.AREAS_API + '?area_code=' + ID)
        .then((response: any) => {
          if (response.body.areas.length > 0) {
            const res = response.body.areas[0];
            approvalStatus.area_name = res.name;
          }
          resolve(true);
        })
        .then( null, (err: any) => {
          resolve(false);
        }); // catch
      });
    },
    getAreaList() {
      return new Promise((resolve, reject) => {
        this.areaList = [];
        this.$http.get(Config.AREAS_API)
        .then((res: any) => {
          if (!this.isEmpty(res.body.areas) && res.body.areas.length > 0) {
            this.areaList = res.body.areas;
          }
          resolve(true);
        })
        .then(null, (err: any) => {
          console.log(err);
          resolve(false);
        });
      });
    },
    getListDataOffice(): any {
      return new Promise((resolve, reject) => {
        const apiLink = Config.OFFICES_LIST_API;
        this.dataOffice = [];
        this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.offices.length > 0) {
            response.body.offices.forEach(async (element: any, key: any) => {
              this.dataOffice.push({
                text: element.name,
                value: element.code,
              });
            });
          }
          resolve(true);
        })
        .then(null, (err: any) => {
          console.log(err);
          resolve(false);
        });
      });
    },
    getListOfficeArea(ID: any): any {
      this.$http.get(Config.OFFICE_AREAS_API + '/' + ID)
      .then((response: any) => {
        if (response.body.areas !== undefined && response.body.areas !== null && response.body.areas.length > 0) {
          response.body.areas.forEach(async (element: any, key: any) => {
            const val = this.areaList.find((a: any) => a.code === element.area_code);
            if (val) {
              this.dataArea.push({
                text: val.name,
                value: val.code,
              });
            }
          });
        }
      })
      .then( null, (err: any) => {
        console.log(err);
      }); // catch
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
  },
});
