var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.isShow,
            callback: function ($$v) {
              _vm.isShow = $$v
            },
            expression: "isShow",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "modal-title", attrs: { color: "black" } },
                [_vm._v(" " + _vm._s(_vm.configData.TEXT_GATE_POPUP) + " ")]
              ),
              _c(
                "v-card-text",
                { staticClass: "mt-5" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label:
                                _vm.configData.TEXT_GATE_CODE +
                                "," +
                                _vm.configData.TEXT_GATE_NAME,
                              "hide-details": "auto",
                              outlined: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.searchText,
                              callback: function ($$v) {
                                _vm.searchText = $$v
                              },
                              expression: "searchText",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.clickSearch()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.configData.TEXT_SEARCH) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _c("v-data-table", {
                    directives: [
                      {
                        name: "resizable-columns",
                        rawName: "v-resizable-columns",
                      },
                    ],
                    staticClass: "border-table scroll-table",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.resData,
                      "no-data-text": _vm.configData.TEXT_NO_DATA,
                      "loading-text": _vm.configData.TEXT_LOADING_DATA,
                      "no-results-text": _vm.configData.TEXT_NO_DATA,
                      "hide-default-footer": "",
                      "items-per-page": -1,
                      "show-select": "",
                      "item-key": "gate_code",
                      search: _vm.search,
                    },
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  }),
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _c("v-spacer"),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("closeDialog", "doorDialog")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.configData.TEXT_CANCEL))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("setGate", _vm.selected)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.configData.TEXT_REGISTER))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }