var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _c("v-container", [
        _c(
          "div",
          { staticClass: "container-user" },
          [
            _c(
              "div",
              { staticClass: "round-action" },
              [
                _c(
                  "v-row",
                  { staticClass: "btn-div", attrs: { justify: "start" } },
                  [
                    _c("v-col", [
                      _c(
                        "div",
                        { staticClass: "applicant-search area-search-box" },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "space-between",
                              },
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("searchbox", {
                                    staticClass: "input-search",
                                    attrs: {
                                      searchTargetItems: _vm.searchTargetItems,
                                      isCSV: true,
                                      applicantAddButton: true,
                                      priviledge: _vm.priviledge,
                                      dataOffice: _vm.dataOffice,
                                      dataArea: _vm.dataArea,
                                      applicantPage: true,
                                    },
                                    on: {
                                      "change-office": function ($event) {
                                        return _vm.$emit(
                                          "change-office",
                                          $event
                                        )
                                      },
                                      search: function ($event) {
                                        return _vm.$emit("search", $event)
                                      },
                                      export: function ($event) {
                                        return _vm.$emit("export", $event)
                                      },
                                      add: function ($event) {
                                        return _vm.register()
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.errorMessageLogs !== ""
              ? _c(
                  "v-col",
                  { staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-error" },
                  [_vm._v(" " + _vm._s(_vm.errorMessageLogs) + " ")]
                )
              : _vm._e(),
            _vm.loadingSearch === false
              ? _c("applicant-list-table", {
                  attrs: {
                    resData: _vm.resData,
                    perPage: _vm.perPage,
                    loading: _vm.loading,
                    loadMore: _vm.loadMore,
                    page: _vm.page,
                    total: _vm.total,
                  },
                  on: {
                    search: function ($event) {
                      return _vm.$emit("search", $event)
                    },
                    "change-column": function ($event) {
                      return _vm.$emit("change-column", $event)
                    },
                    "change-per-page": function ($event) {
                      return _vm.$emit("change-per-page", $event)
                    },
                    "change-page": function ($event) {
                      return _vm.$emit("change-page", $event)
                    },
                    "get-current-items": function ($event) {
                      return _vm.$emit("get-current-items", $event)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }