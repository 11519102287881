
  import Vue, { PropType } from 'vue';
  import InputForm from '@/components/molecules/InputForm/InputForm.vue';
  import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
  import * as Config from '@/config';

  export interface DataType {
    rulesValidate: any;
    actionItems: any;
    tab: any;
    configData: any;
    confirmEmailDialog: boolean;
    exntensionEmailType: string;
    updateMessage: string;
    isFormValidSubmitted: boolean;
    isFormValidCancel: boolean;
    isFormValidApproval: boolean;
    isFormValidReturn: boolean;
    isFormValidRescissions: boolean;
    isFormValidExntensionRequest: boolean;
    isFormValidExntensionApproval: boolean;
    isFormValidExntensionDenial: boolean;
    isSystemAdmin: boolean;
  }

  export interface EmailSettings {
    domain_code: string;
    submittion: string;
    cancellation: string;
    approval: string;
    return: string;
    rescission: string;
    extension_request: string;
    extension_approval: string;
    extension_denial: string;
  }

  interface SelectItem { text: string; value: any; }

  export default Vue.extend({
    name: 'EmailSettingForm',
    components: {
      InputForm,
      SuccessButton,
    },
    props: {
      loading: {
        type: Boolean,
        default: true,
      },
      domainItems: {
        type: Array as PropType<SelectItem[]>,
        default: () => [],
      },
      emailSettings: {
        type: Object,
        default: {},
      },
    },
    data: (): DataType => ({
      tab: 0,
      actionItems: [
        {
          tabname: 'submittion' ,
          title: Config.TEXT_MESSAGE_APPLICANT_SUBMISSION_NOTIFICATION,
        },
        {
          tabname: 'cancellation' ,
          title: Config.TEXT_MESSAGE_CANCEL_SUBMISSION_NOTIFICATION,
        },
        {
          tabname: 'approval' ,
          title: Config.TEXT_MESSAGE_APPROVAL_NOTIFICATION,
        },
        {
          tabname: 'return' ,
          title: Config.TEXT_MESSAGE_RETURN_NOTIFICATION,
        },
        {
          tabname: 'rescission' ,
          title: Config.TEXT_MESSAGE_APPROVAL_CANCEL_NOTIFICATION,
        },
        {
          tabname: 'extension_request' ,
          title: Config.TEXT_MESSAGE_EXTENSION_APPLICATION_NOTIFICATION,
        },
        {
          tabname: 'extension_approval' ,
          title: Config.TEXT_MESSAGE_EXTENSION_REQUEST_NOTIFICATION,
        },
        {
          tabname: 'extension_denial' ,
          title: Config.TEXT_MESSAGE_EXTENSION_APPLICATION_DENIAL_NOTIFICATION,
        },
      ],
      rulesValidate: {
        validateNotificationMessage: (message: string) => {
          if (message !== undefined && message !== null && message !== '') {
            return message.length <= Config.EMAIL_MESSAGE_MAX_LEN || Config.ERROR_NOTIFICATION_EMAIL_LENGTH;
          } else {
            return true;
          }
        },
        validateHtmlTag: (message: string) => {
          const regex = /<\/?[a-z][\s\S]*>/gi;
          if (regex.test(message)) {
            return Config.TEXT_EMAIL_CHECK_HTML_TAG;
          } else {
            return true;
          }
        },
      },
      configData: Config,
      confirmEmailDialog: false,
      exntensionEmailType: '',
      updateMessage: '',
      isFormValidSubmitted: false,
      isFormValidCancel: false,
      isFormValidApproval: false,
      isFormValidReturn: false,
      isFormValidRescissions: false,
      isFormValidExntensionRequest: false,
      isFormValidExntensionApproval: false,
      isFormValidExntensionDenial: false,
      isSystemAdmin: sessionStorage.getItem('priviledge')  === '0',
    }),
    watch: {
      //
    },
    computed: {
      //
    },
    methods: {
      updateExntensionMessage() {
        this.$emit(this.updateMessage, this.exntensionEmailType);
        this.confirmEmailDialog = false;
      },
      showConfirmEmailDialog(updateMessage: string, exntensionEmailType: string) {
        this.updateMessage = updateMessage;
        this.exntensionEmailType = exntensionEmailType;
        this.confirmEmailDialog = true;
      },
    },
  });
