var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _c(
        "v-container",
        [
          _c("applicant", {
            attrs: {
              loading: _vm.loading,
              resData: _vm.resData,
              resHisData: _vm.resHisData,
              resExtData: _vm.resExtData,
              resCardData: _vm.resCardData,
              errorMessageLogs: _vm.errorMessageLogs,
              successMessageLogs: _vm.successMessageLogs,
              isAction: _vm.isAction,
              isDisabledCancelApplication: _vm.isDisabledCancelApplication,
            },
            on: {
              "action-approval": function ($event) {
                return _vm.$emit("action-approval", $event)
              },
              "open-extensions": function ($event) {
                return _vm.$emit("open-extensions", $event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }