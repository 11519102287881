
import moment from 'moment';
import Vue, { PropType } from 'vue';
const min: string[] = [];
for (let i = 0; i <= 59 ; i++) {
  let value = '';
  if (i < 10) {
    value = '0' + i;
  } else {
    value = i.toString();
  }
  min.push(value);
}
export default Vue.extend({
  props: {
    dateStartValue: {
      default: '',
    },
    name: {
      default: '',
    },
    dateEndValue: {
      default: '',
    },
    timeSValue: {
      default: '',
    },
    errorAddListGate: {
      default: '',
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    logPage: {
      type: Boolean,
      default: false,
    },
    isUserPage: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    timePickerName: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    showTimePicker: {
      type: Boolean,
      default: false,
    },
    applicantPage: {
      type: Boolean,
      default: false,
    },
    approvalStatusPage: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    dateS: '',
    dateEmptyE: '',
    hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    minutes: ['00', '15', '30', '45'],
    timeS: '',
    dateStart: false,
    isTime: false,
    hourStart: '00',
    minuteStart: '00',
  }),
  mounted() {
    this.minutes = this.isUserPage ? ['00', '15', '30', '45'] : min;
    this.timeS = '';
    this.dateS =  this.name === 'begin' || this.name === 'since' ? moment(new Date()).add(-1, 'week').format('YYYY-MM-DD')
    : moment(new Date()).format('YYYY-MM-DD');
    console.log('name', this.name);
    console.log('dateS', this.dateS);
    let date = new Date();
    // if ((this.applicantPage || this.approvalStatusPage) && sessionStorage.getItem('userAuthTime')) {
    //   let loginDay: any = sessionStorage.getItem('userAuthTime');
    //   loginDay = moment(new Date(parseInt(loginDay) * 1000)).add(1, 'day').format('YYYY-MM-DD');
    //   date = new Date(loginDay);
    // }
    if (this.applicantPage || this.approvalStatusPage) {
      date = new Date();
    }
    this.dateEmptyE = this.name === 'begin' || this.name === 'since'
    ? moment(date).format('YYYY-MM-DD') : '',
    this.timeS =  this.name === 'begin' || this.name === 'since' ? '00:00' : '23:59';
    if (this.logPage) {
      this.dateEmptyE = moment(new Date()).format('YYYY-MM-DD');
      this.dateS = moment(new Date()).format('YYYY-MM-DD');
    }
  },
  watch: {
    dateStartValue(newValue: string) {
      this.dateS = newValue;
    },
    timeSValue(newValue: string) {
      this.timeS = newValue;
    },
    dateS(newValue: string) {
      this.minuteStart = this.name === 'begin' || this.name === 'since' ? '00' : '59';
      this.hourStart = this.name === 'begin' || this.name === 'since' ? '00' : '23';
      this.timeS = this.name === 'begin' || this.name === 'since' ? '00:00' : '23:59';
      this.$emit('get-date', newValue + ' ' + this.timeS);
    },
    dateEmptyE(newValue: string) {
      this.$emit('get-date-e', newValue);
    },
    timeS(newValue: string) {
      this.$emit('get-time', this.dateS + ' ' + newValue);
    },
    minuteStart(newValue: string) {
      this.timeS = this.formatTime(this.hourStart, newValue);
    },
    hourStart(newValue: string) {
      this.timeS = this.formatTime(newValue, this.minuteStart);
    },
    minDate(newValue: string) {
      let newDate = newValue;
      let newTime = '00:00';
      if (newValue.length > 10) {
        newDate = newValue.substring(0, 10);
        newTime = newValue.substring(11);
      }
      // display date + time
      if (this.dateS < newDate) {
        this.dateS = '';
      }
      if (this.timeS < newTime) {
        this.timeS = '23:59';
      }
      // display date only
      if (this.dateEmptyE < newDate) {
        this.dateEmptyE = '';
      }
    },
  },
  methods: {
    selectDate(name: any) {
      this.dateStart = false;
      const element: HTMLElement = document.getElementById('time-' + name) as HTMLElement;
      console.log(element);
      setTimeout(() => element.focus(), 100);


    },
    formatTime(hours: string, minute: number | string) {
      return `${('00' + hours).slice(-2)}:${('00' + minute).slice(-2)}`;
    },
  },
});
