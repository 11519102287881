var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app-bar",
    {
      staticClass: "allig-header",
      attrs: {
        color: "white",
        dense: _vm.$vuetify.breakpoint.lgAndDown,
        elevation: "1",
        "clipped-left": "",
        app: "",
      },
    },
    [
      _vm.$vuetify.breakpoint.mobile && _vm.isMobile === true
        ? _c("v-app-bar-nav-icon", {
            nativeOn: {
              click: function ($event) {
                return _vm.$emit("click-nav")
              },
            },
          })
        : _vm._e(),
      _vm.$vuetify.breakpoint.xsOnly
        ? _c("div", { staticClass: "logo-text" }, [
            _c("span", {
              domProps: {
                innerHTML: _vm._s(_vm.configData.TEXT_HEADER_MOBILE),
              },
            }),
          ])
        : _c("div", { staticClass: "logo-text" }, [
            _vm._v(" " + _vm._s(_vm.configData.TEXT_HEADER) + " "),
          ]),
      _c("v-spacer"),
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "div",
                    _vm._g(
                      _vm._b({ staticClass: "sub-menu" }, "div", attrs, false),
                      on
                    ),
                    [
                      !_vm.$vuetify.breakpoint.mobile
                        ? _c("span", [_vm._v(_vm._s(_vm.userName) + " ")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c("v-icon", [_vm._v("mdi-chevron-down")])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b({ attrs: { icon: "" } }, "v-btn", attrs, false),
                      on
                    ),
                    [
                      _c(
                        "v-avatar",
                        [_c("v-icon", [_vm._v("mdi-account-circle")])],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                {
                  attrs: { link: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("click-user-setting")
                    },
                  },
                },
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.configData.TEXT_MY_PAGE)),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  attrs: { link: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("click-user-manual")
                    },
                  },
                },
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.configData.TEXT_MANUAL)),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  attrs: { link: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("click-logout")
                    },
                  },
                },
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.configData.TEXT_LOG_OUT)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }