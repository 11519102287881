
import Vue, { PropType } from 'vue';
import Login from '@/components/organisms/Login/Login.vue';
export default Vue.extend({
  name: 'LoginTemplate',
  components: {
    Login,
  },
  props: {
    loginProcess: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      default: {
        ownerId: '',
        userId: '',
        password: '',
        mfaCode: '',
        resetCode: '',
        confirmPassword: '',
      },
    },
  },
});
