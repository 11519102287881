var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-container", [
        _c(
          "div",
          { staticClass: "container-user" },
          [
            _c(
              "v-row",
              {
                staticClass: "mt-3 border-bottom-bold",
                attrs: {
                  "no-gutters": "",
                  justify: "space-between",
                  "align-content": "center",
                },
              },
              [
                _c("show-per-page", {
                  staticClass: "ml-8 mb-3",
                  attrs: {
                    "per-page": _vm.perPage,
                    page: _vm.page,
                    "items-length": _vm.userTotalCount,
                  },
                }),
                _c("v-spacer"),
                _c(
                  "div",
                  {
                    staticClass:
                      "text-caption display-option included-option text-sm-caption text-md-caption text-lg-caption text-xl-caption mr-8",
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple",
                            value: false,
                            expression: "false",
                          },
                        ],
                        staticClass:
                          "allig-btn-text-link-gray allig-btn-text-link",
                        attrs: { block: "", type: "button", text: "" },
                        on: {
                          click: () => {
                            _vm.showOption = !_vm.showOption
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "display-option-title" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.configData.TEXT_DISPLAY_OPTIONS) +
                              " "
                          ),
                        ]),
                        _c(
                          "span",
                          [
                            _c("v-icon", { attrs: { dense: "" } }, [
                              _vm._v("mdi-chevron-down"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "pt-0 pb-0 mt-0" },
              [
                _c(
                  "v-container",
                  { staticClass: "pt-0" },
                  [
                    _c("display-option", {
                      attrs: {
                        isShowOption: _vm.showOption,
                        "display-option-items": _vm.displayOptionItems,
                        isVaildOnly: _vm.isVaildOnly,
                      },
                      on: {
                        "show-only-isVaild": function ($event) {
                          return _vm.$emit("show-only-isVaild", $event)
                        },
                        input: function ($event) {
                          return _vm.CheckColumn($event)
                        },
                      },
                      model: {
                        value: _vm.selectedDisplayOption,
                        callback: function ($$v) {
                          _vm.selectedDisplayOption = $$v
                        },
                        expression: "selectedDisplayOption",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.loading == false
              ? _c("v-data-table", {
                  directives: [
                    {
                      name: "resizable-columns",
                      rawName: "v-resizable-columns",
                    },
                  ],
                  staticClass: "border-table scroll-table",
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.resData,
                    "items-per-page": -1,
                    "item-key": "user_id",
                    "no-data-text": _vm.configData.TEXT_NO_DATA,
                    "loading-text": _vm.configData.TEXT_LOADING_DATA,
                    "hide-default-footer": "",
                    "show-select": "",
                    "sort-by": ["user_id"],
                    "sort-desc": false,
                  },
                  on: {
                    input: (item) => {
                      _vm.selected = item
                    },
                    "current-items": _vm.currentItems,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: `item.user_id`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-btn",
                              {
                                directives: [
                                  {
                                    name: "ripple",
                                    rawName: "v-ripple",
                                    value: false,
                                    expression: "false",
                                  },
                                ],
                                staticClass: "allig-btn-text-link",
                                attrs: { block: "", type: "button", text: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit(
                                      "clickEditUser",
                                      item.user_id
                                    )
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item.user_id) + " ")]
                            ),
                          ]
                        },
                      },
                      {
                        key: `item.updated`,
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.converDateShort(item.updated)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              : _vm._e(),
            _c("v-row", {
              staticStyle: { height: "20px" },
              attrs: { "no-gutters": "" },
            }),
            _vm.loadMore
              ? _c(
                  "v-row",
                  { attrs: { justify: "center" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-secondary margin-button-load-more",
                        attrs: { outlined: "" },
                        on: {
                          click: () => {
                            _vm.innerPage++
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.configData.TEXT_BUTTON_OTHERS) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-dialog",
              {
                attrs: { persistent: "", "max-width": "600" },
                model: {
                  value: _vm.deleteUserDialog,
                  callback: function ($$v) {
                    _vm.deleteUserDialog = $$v
                  },
                  expression: "deleteUserDialog",
                },
              },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "modal-title", attrs: { color: "black" } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.configData.TEXT_CONFIRMATION) + " "
                        ),
                      ]
                    ),
                    _c(
                      "v-card-text",
                      { staticClass: "mt-5" },
                      [
                        _c("div", [
                          _c("p", { staticClass: "text-error" }, [
                            _vm._v(
                              _vm._s(_vm.configData.TEXT_REQUEST_DELETE_USER)
                            ),
                          ]),
                        ]),
                        _c("v-row", {
                          staticStyle: { height: "20px" },
                          attrs: { "no-gutters": "" },
                        }),
                        _c("v-spacer"),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      [
                        _c(
                          "v-row",
                          { staticClass: "ma-2", attrs: { justify: "end" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "btn-secondary",
                                attrs: { outlined: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.closeDeleteUserDialog()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.configData.TEXT_RETURN))]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "btn-primary",
                                attrs: { outlined: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteUser()
                                  },
                                },
                              },
                              [_vm._v("OK")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }