
import moment from 'moment';
import {Vue } from 'vue-property-decorator';
import ApplicantListTemplate from '@/components/templates/ApplicantTemplate/ApplicantListTemplate.vue';
import * as Config from '@/config';
import auth from '@/auth';
export interface DataType {
  // Props
  page: number;
  pageExport: number;
  perPage: number;
  total: number;
  loading: boolean;
  loadingSearch: boolean;
  isRegistering: boolean;
  loadingCSV: boolean;
  loadMore: boolean;
  resData: any;
  errorMessageLogs: string;
  keyword: string;
  scorp: any;
  countExport: number;
  exportData: any;
  dataOffice: any;
  dataArea: any;
  listArea: any;
  listDataExport: any;
}
export default Vue.extend({
  name: 'ApplicantListPage',
  components: {
    ApplicantListTemplate,
  },
  data: (): DataType => ({
    // Props
    loading: false,
    isRegistering: false, // default: false -> reading data
    loadingCSV: false,
    loadMore: false,
    loadingSearch: false,
    page: 1,
    pageExport: 1,
    resData: [],
    errorMessageLogs: '',
    keyword: '',
    perPage: Config.PER_PAGE,
    total: 0,
    scorp: [],
    countExport: 0,
    exportData: [],
    dataOffice: [],
    dataArea: [],
    listArea: [],
    listDataExport: [],
  }),
  watch: {
    countExport(newVal, oldVal) {
      if (newVal >= this.pageExport) {
        if (this.exportData.length === 0) {
          this.exportData.push({
            [Config.TEXT_COL_APPLICATION_NUMBER]: '',
            [Config.TEXT_COL_STATUS]: '',
            [Config.TEXT_COL_BEGIN_DATE]: '',
            [Config.TEXT_COL_END_DATE]: '',
            [Config.TEXT_COL_MANAGEMENT_OFFICE]: '',
            [Config.TEXT_COL_AREA]: '',
            [Config.TEXT_USER_MANAGEMENT]: '',
            [Config.TEXT_UPDATE_DATE]: '',
          });
        }
        auth.downloadCSV(this.exportData, Config.TEXT_APPLICANT_STATUS);
        this.loadingCSV = false;
      } else if (newVal > 0) {
        this.getExportData(newVal + 1);
      }
    },
    loading(value) {
      this.$emit('loading', value);
      if (value) {
        this.$emit('textCircle', this.isRegistering ? Config.TEXT_PROGRESS_REGISTERING : Config.TEXT_PROGRESS_READING);
      } else if (this.isRegistering) {
        this.isRegistering = false;
      }
    },
    loadingCSV(value) {
      this.$emit('loading', value);
    },
  },
  mounted() {
    this.$emit('activeMenu', 'applicant');
    sessionStorage.removeItem('displayOptionApplicant');
  },
  created() {
    const day = moment().subtract(1, 'd').format('YYYY-MM-DD');
    this.keyword = 'since=' + auth.formatISOString(day + ' 23:29') + '&';
    this.getListDataOffice().then(() => this.getAllArea().then(() => this.getListData()));
  },
  methods: {
    getCurrentItems(item: any) {
      this.listDataExport = item;
    },
    changeOffice(item: any) {
      this.dataArea = [];
      if (item !== null && item.value !== undefined && item.value !== '') {
        this.getListDataArea(item.value);
      }
    },
    changeColumn(value: any): any {
      this.loading = true;
      setTimeout(() => {this.loading = false; }, 300);
    },
    async exportCSV(value: any): Promise<any> {
      this.exportData = [];
      this.pageExport = 0;
      this.loadingCSV = true;
      if (this.listDataExport.length > 0) {
        for (const item of this.listDataExport) {
          let templateExport: any = {};
          if (sessionStorage.hasOwnProperty('displayOptionApplicant')) {
            let displayOption = sessionStorage.getItem('displayOptionApplicant') as string;
            displayOption = displayOption.replace(/'/g, '"');
            displayOption = JSON.parse(displayOption);
            if (displayOption.includes('request_id')) {
              const requestId = Config.TEXT_COL_APPLICATION_NUMBER;
              templateExport[requestId] = item.request_id;
            }
            if (displayOption.includes('status')) {
              const status = Config.TEXT_COL_STATUS;
              templateExport[status] = auth.convertStatus(item.status);
            }
            if (displayOption.includes('begin')) {
              const begin = Config.TEXT_COL_BEGIN_DATE;
              templateExport[begin] = auth.converDateShort(item.begin);
            }
            if (displayOption.includes('end')) {
              const end = Config.TEXT_COL_END_DATE;
              templateExport[end] = auth.converDateShort(item.end);
            }
            if (displayOption.includes('office_name')) {
              const officeName = Config.TEXT_COL_MANAGEMENT_OFFICE;
              templateExport[officeName] = auth.getDefaultStringVal(item.office_name);
            }
            if (displayOption.includes('area_name')) {
              const areaName = Config.TEXT_COL_AREA;
              templateExport[areaName] = auth.getDefaultStringVal(item.area_name);
            }
            if (displayOption.includes('updater')) {
              const updater = Config.TEXT_USER_MANAGEMENT;
              templateExport[updater] = auth.getDefaultStringVal(item.requester_name);
            }
            if (displayOption.includes('updated')) {
              const updated = Config.TEXT_UPDATE_DATE;
              templateExport[updated] = auth.converDateShort(item.updated);
            }
          } else {
            templateExport = {
              [Config.TEXT_COL_APPLICATION_NUMBER]: item.request_id,
              [Config.TEXT_COL_STATUS]: auth.convertStatus(item.status),
              [Config.TEXT_COL_BEGIN_DATE]: auth.converDateShort(item.begin),
              [Config.TEXT_COL_END_DATE]: auth.converDateShort(item.end),
              [Config.TEXT_COL_MANAGEMENT_OFFICE]: auth.getDefaultStringVal(item.office_name),
              [Config.TEXT_COL_AREA]: auth.getDefaultStringVal(item.area_name),
              [Config.TEXT_USER_MANAGEMENT]: auth.getDefaultStringVal(item.requester_name),
              [Config.TEXT_UPDATE_DATE]: auth.converDateShort(item.updated),
            };
          }
          await this.exportData.push(templateExport);
        }
        auth.downloadCSV(this.exportData, Config.TEXT_APPLICANT_STATUS);
        this.loadingCSV = false;
      } else {
        let templateExport: any = {};
        if (sessionStorage.hasOwnProperty('displayOptionApplicant')) {
          let displayOption = sessionStorage.getItem('displayOptionApplicant') as string;
          displayOption = displayOption.replace(/'/g, '"');
          displayOption = JSON.parse(displayOption);
          if (displayOption.includes('request_id')) {
            const requestId = Config.TEXT_COL_APPLICATION_NUMBER;
            templateExport[requestId] = '';
          }
          if (displayOption.includes('status')) {
            const status = Config.TEXT_COL_STATUS;
            templateExport[status] = '';
          }
          if (displayOption.includes('begin')) {
            const begin = Config.TEXT_COL_BEGIN_DATE;
            templateExport[begin] = '';
          }
          if (displayOption.includes('end')) {
            const end = Config.TEXT_COL_END_DATE;
            templateExport[end] = '';
          }
          if (displayOption.includes('office_name')) {
            const officeName = Config.TEXT_COL_MANAGEMENT_OFFICE;
            templateExport[officeName] = '';
          }
          if (displayOption.includes('area_name')) {
            const areaName = Config.TEXT_COL_AREA;
            templateExport[areaName] = '';
          }
          if (displayOption.includes('updater')) {
            const updater = Config.TEXT_USER_MANAGEMENT;
            templateExport[updater] = '';
          }
          if (displayOption.includes('updated')) {
            const updated = Config.TEXT_UPDATE_DATE;
            templateExport[updated] = '';
          }
        } else {
          templateExport = {
            [Config.TEXT_COL_APPLICATION_NUMBER]: '',
            [Config.TEXT_COL_STATUS]: '',
            [Config.TEXT_COL_BEGIN_DATE]: '',
            [Config.TEXT_COL_END_DATE]: '',
            [Config.TEXT_COL_MANAGEMENT_OFFICE]: '',
            [Config.TEXT_COL_AREA]: '',
            [Config.TEXT_USER_MANAGEMENT]: '',
            [Config.TEXT_UPDATE_DATE]: '',
          };
        }
        await this.exportData.push(templateExport);
        auth.downloadCSV(this.exportData, Config.TEXT_APPLICANT_STATUS);
        this.loadingCSV = false;
      }
    },
    changePerPage(value: any): any {
      //
    },
    changePage(value: any): any {
      this.page = value;
      this.loadingSearch = true;
      sessionStorage.removeItem('displayOptionApplicant');
      this.getListData();
    },
    search(args: any) {
      this.listDataExport = [];
      this.loadingSearch = true;
      this.keyword = '';
      this.page = 1;
      this.resData = [];
      this.total = 0;
      sessionStorage.removeItem('displayOptionApplicant');
      args.target.forEach((element: any, key: any) => {
        if (element.value !== undefined && element.value !== null && element.value !== '') {
          let value = element.value;
          if (element.name === 'status') {
            value = element.value.value;
            this.keyword += element.name + '=' + value + '&';
          } else if (element.name === 'since' || element.name === 'until') {
            if (element.name === 'until') {
              const until =  moment(element.value).add(1, 'days').format('YYYY-MM-DD');
              value = auth.formatISOString(until + ' 00:00');
            } else if (element.name === 'since') {
              const since = moment(element.value).subtract(1, 'd').format('YYYY-MM-DD');
              value = auth.formatISOString(since + ' 23:29');
            }
            this.keyword += element.name + '=' + value + '&';
          } else if (element.name === 'office_list') {
            this.keyword += 'office_code=' + element.value.value + '&';
          } else if (element.name === 'area_list') {
            this.keyword += 'area_code=' + element.value.value + '&';
          } else if (element.name === 'request_id' && element.value.length > Config.REQUEST_ID_MAX_LEN) {
            this.keyword += element.name + '=' + element.value.slice(0, Config.REQUEST_ID_MAX_LEN) + '&';
          } else {
            this.keyword += element.name + '=' + value + '&';
          }
        }
      });
      this.getListData();
    },
    getExportData(page: any): any {
      let displayOption = sessionStorage.getItem('displayOptionApplicant') as string;
      displayOption = displayOption.replace(/'/g, '"');
      displayOption = JSON.parse(displayOption);
      let apiLink = Config.ENTRY_REQUESTS_API + '?limit=1000&page=' + page;
      if (this.keyword !== '') {
        apiLink += '&' + this.keyword.replace(/&\s*$/, '');
      }
      this.$http.get(apiLink)
        .then(async (response: any) => {
          const resData = response.body.requests;
          if (resData.length > 0) {
            this.pageExport = Math.ceil(response.body.total / 1000);
            for (const item of resData) {
              const office = this.dataOffice.find((a: any) => a.value === item.office_code);
              if (office) {
                item.office_name = office.text;
              }
              const area = this.listArea.find((a: any) => a.value === item.area_code);
              if (area) {
                item.area_name = area.text;
              }
              item.begin = (moment(item.begin).add(15, 'minutes'));
              // await this.getOfficeForExport(item, item.office_code);
              // await this.getAreaForExport(item, item.area_code);
              const templateExport: any = {};
              if (displayOption.includes('request_id')) {
                const requestId = Config.TEXT_COL_APPLICATION_NUMBER;
                templateExport[requestId] = item.request_id;
              }
              if (displayOption.includes('status')) {
                const status = Config.TEXT_COL_STATUS;
                templateExport[status] = auth.convertStatus(item.status);
              }
              if (displayOption.includes('begin')) {
                const begin = Config.TEXT_COL_BEGIN_DATE;
                templateExport[begin] = auth.converDateShort(item.begin);
              }
              if (displayOption.includes('end')) {
                const end = Config.TEXT_COL_END_DATE;
                templateExport[end] = auth.converDateShort(item.end);
              }
              if (displayOption.includes('office_name')) {
                const officeName = Config.TEXT_COL_MANAGEMENT_OFFICE;
                templateExport[officeName] = auth.getDefaultStringVal(item.office_name);
              }
              if (displayOption.includes('area_name')) {
                const areaName = Config.TEXT_COL_AREA;
                templateExport[areaName] = auth.getDefaultStringVal(item.area_name);
              }
              if (displayOption.includes('updater')) {
                const updater = Config.TEXT_USER_MANAGEMENT;
                templateExport[updater] = auth.getDefaultStringVal(item.requester_name);
              }
              if (displayOption.includes('updated')) {
                const updated = Config.TEXT_UPDATE_DATE;
                templateExport[updated] = auth.converDateShort(item.updated);
              }
              this.exportData.push(templateExport);
              // this.exportData.push({
              //   [Config.TEXT_COL_APPLICATION_NUMBER]: item.request_id,
              //   [Config.TEXT_COL_STATUS]: auth.convertStatus(item.status),
              //   [Config.TEXT_COL_BEGIN_DATE]: auth.converDateShort(item.begin),
              //   [Config.TEXT_COL_END_DATE]: auth.converDateShort(item.end),
              //   [Config.TEXT_COL_MANAGEMENT_OFFICE]: auth.getDefaultStringVal(item.office_name),
              //   [Config.TEXT_COL_AREA]: auth.getDefaultStringVal(item.area_name),
              //   [Config.TEXT_USER_MANAGEMENT]: auth.getDefaultStringVal(item.updater),
              //   [Config.TEXT_UPDATE_DATE]: auth.converDateShort(item.updated),
              // });
            }
          }
          this.countExport++;
        })
        .then( null, (err: any) => {
          this.countExport++;
        }); // catch
    },
    getListData(): any {
      this.loading = true;
      this.loadMore = false;
      let apiLink = Config.ENTRY_REQUESTS_API + '?limit=' + this.perPage +
        '&page=' + this.page ;
      if (this.keyword !== '') {
        apiLink += '&' + this.keyword.replace(/&\s*$/, '');
      }
      this.$http.get(apiLink)
        .then(async (response: any) => {
          if (!this.isEmpty(response.body.requests) && response.body.requests.length > 0) {
            this.total = response.body.total;
            this.page = Math.ceil(response.body.head / this.perPage);
            response.body.requests.forEach(async (element: any, key: any) => {
              const office = this.dataOffice.find((a: any) => a.value === element.office_code);
              if (office) {
                response.body.requests[key].office_name = office.text;
              }
              const area = this.listArea.find((a: any) => a.value === element.area_code);
              if (area) {
                response.body.requests[key].area_name = area.text;
              }
              response.body.requests[key].begin = auth.converDateShort(moment(response.body.requests[key].begin).add(15, 'minutes'));
            });
            this.resData.push(...response.body.requests);
          }
          if (response.body.total > response.body.tail) {
            this.loadMore = true;
          }
          this.loading = false;
          this.loadingSearch = false;
        })
        .then( null, (err: any) => {
          this.loading = false;
          this.loadingSearch = false;
          this.errorMessageLogs = Config.TEXT_ENTRY_REQUESTS_LIST_FAILED;
        }); // catch
    },
    getOffices(ID: any, key: any): any {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.OFFICES_LIST_API + '?office_code=' + ID)
          .then((response: any) => {
            if (response.body.offices.length > 0) {
              const res = response.body.offices[0];
              this.resData[key].office_name = res.name;
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getArea(ID: any, key: any): any {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.AREAS_API + '?area_code=' + ID)
          .then((response: any) => {
            if (response.body.areas.length > 0) {
              const res = response.body.areas[0];
              this.resData[key].area_name = res.name;
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getOfficeForExport(applicant: any, ID: any): any {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.OFFICES_LIST_API + '?office_code=' + ID)
          .then((response: any) => {
            if (response.body.offices.length > 0) {
              const res = response.body.offices[0];
              applicant.office_name = res.name;
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getAreaForExport(applicant: any, ID: any): any {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.AREAS_API + '?area_code=' + ID)
          .then((response: any) => {
            if (response.body.areas.length > 0) {
              const res = response.body.areas[0];
              applicant.area_name = res.name;
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getListDataOffice(): any {
      return new Promise((resolve, reject) => {
        const apiLink = Config.OFFICES_LIST_API;
        this.dataOffice = [];
        this.loading = true;
        this.$http.get(apiLink)
          .then((response: any) => {
            if (response.body.offices.length > 0) {
              response.body.offices.forEach(async (element: any, key: any) => {
                this.dataOffice.push(
                  {
                    text: element.name,
                    value: element.code,
                  },
                );
              });
            }
            resolve(true);
          })
          .then(null, (err: any) => {
            console.log(err);
            resolve(true);
          });
      });
    },
    getListDataArea(ID: any): any {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.OFFICE_AREAS_API + '/' + ID)
          .then((response: any) => {
            if (response.body.areas !== undefined && response.body.areas !== null &&
                response.body.areas !== '' && response.body.areas.length > 0) {
              response.body.areas.forEach(async (element: any, key: any) => {
                await this.getSearchArea(element.area_code, key);
              });
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getSearchArea(ID: any, key: any): any {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.AREAS_API + '?area_code=' + ID)
          .then((response: any) => {
            if (response.body.areas.length > 0) {
              this.dataArea.push({
                text: response.body.areas[0].name,
                value: response.body.areas[0].code,
              });
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    getAllArea(): any {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.AREAS_API)
          .then((response: any) => {
            if (response.body.areas.length > 0) {
              response.body.areas.forEach(async (element: any, key: any) => {
                this.listArea.push({
                  text: element.name,
                  value: element.code,
                });
              });
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
          }); // catch
      });
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
  },
});
