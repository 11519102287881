
import Vue, { PropType } from 'vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';
import auth from '@/auth';
export interface Office {
  officeId: string;
  officeName: string;
  supplement: string;
  updated: string;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  showOption: boolean;
  isVaildOnly: boolean;
  officeSelected: any;
  configData: any;
}

export default Vue.extend({
  name: 'OfficeListTable',
  components: {
    TextLink,
    ShowPerPage,
    DisplayOption,
  },
  watch: {
    selectedDisplayOption(value) {
      sessionStorage.setItem('displayOptionOffice', JSON.stringify(value));
    },
    officeSelected(val: Office[]) {
      this.$emit('select-offices', val.map((item) => item.officeId));
    },
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    officeList: {
      type: Array as PropType<Office[]>,
      default: () => [],
    },
    officeTotalCount: {
      type: Number,
      default: 0,
    },
    deleteOfficeDialog: {
      type: Boolean,
      default: false,
    },
    isCheck: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    //
    if (sessionStorage.getItem('displayOptionOffice') !== null) {
      this.selectedDisplayOption = JSON.parse(sessionStorage.getItem('displayOptionOffice') as string);
    }
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: Config.TEXT_MANAGEMENT_FACILITY_CODE,
            align: 'start',
            sortable: true,
            value: 'officeId',
            width: 'auto',
          },
        ];
        if (this.selectedDisplayOption.includes('officeName')) {
          headers.push({
            text: Config.TEXT_MANAGEMENT_FACILITY_NAME,
            align: 'start',
            sortable: true,
            value: 'officeName',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('supplement')) {
          headers.push({
            text: Config.TEXT_REMARKS,
            align: 'start',
            sortable: true,
            value: 'supplement',
            width: 'auto',
          });
        }
        if (this.selectedDisplayOption.includes('updated')) {
          headers.push({
            text: Config.TEXT_UPDATE_DATE,
            align: 'start',
            sortable: true,
            value: 'updated',
            width: 'auto',
          });
        }
        return headers;
      },
      set(): void {
        return;
      },
    },
  },
  data: (): DataType => ({
    selectedDisplayOption: [
      'officeId',
      'officeName',
      'supplement',
      'updated',
    ],
    displayOptionItems: [
      { label: Config.TEXT_MANAGEMENT_FACILITY_CODE, value: 'officeId', isDisabled: true },
      { label: Config.TEXT_MANAGEMENT_FACILITY_NAME, value: 'officeName' },
      { label: Config.TEXT_REMARKS, value: 'supplement' },
      { label: Config.TEXT_UPDATE_DATE, value: 'updated' },
    ],
    officeSelected: [],
    showOption: false,
    isVaildOnly: false,
    configData: Config,
  }),
  methods: {
    showConfirmOfficeDialog() {
      const result = (this.$refs.OfficeSettingsForm as Vue & { validate: () => boolean }).validate();
      if (result) {
          this.$emit('showConfirmOfficeDialog');
        }
    },
    closeDeleteOfficeDialog() {
        this.$emit('closeDeleteOfficeDialog');
    },
    deleteSelectedOffices() {
        this.$emit('deleteSelectedOffices');
    },
    converDateShort(t: any) {
      return auth.converDateShort(t);
    },
    clickShowOfficeSettings() {
      this.$emit('clickShowOfficeSettings', {
        officeId: 'officetest1',
        officeName: 'office test 1',
        supplement: 'note for office test 1',
      });
    },
    checkColumn(data: any) {
      this.$emit('change-column');
      sessionStorage.setItem('displayOptionOffice', JSON.stringify(data));
    },
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
  },
});
