var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-overlay",
        { attrs: { absolute: "", value: _vm.loading, color: "white" } },
        [_c("img", { attrs: { src: "/loading-circle.gif", width: "40" } })]
      ),
      _c(
        "v-container",
        { staticClass: "card-registration" },
        [
          _c(
            "v-form",
            { ref: "addForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-6 pr-10 mb-5" },
                [
                  !_vm.upToDate && _vm.showMessageFlag
                    ? _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.configData
                                    .ERROR_MESSAGE_FOR_CARD_INFO_FAILED
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.upToDate
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              class: _vm.maskedIdi01 === "" ? "" : "pad-0",
                              attrs: { cols: "8", lg: "2", md: "2" },
                            },
                            [
                              _c("v-subheader", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.configData.TEXT_FIRST_CARD_INFO
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              class:
                                _vm.maskedIdi01 === ""
                                  ? "col-right-card"
                                  : "col-right-card pad-0",
                              attrs: {
                                cols: _vm.maskedIdi01 === "" ? "4" : "12",
                                lg: "5",
                                xl: "3",
                                md: "5",
                              },
                            },
                            [
                              _c("span", { staticClass: "v-sub" }, [
                                _vm._v(_vm._s(_vm.maskedIdi01)),
                              ]),
                              _vm.maskedIdi01 == ""
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-primary",
                                      attrs: { outlined: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.registerCard("1")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.configData.TEXT_REGISTER) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.maskedIdi01 != ""
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-secondary",
                                      attrs: { outlined: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showConfirmDialog("1")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.configData.TEXT_DELETE) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.maxNumOfCards > 1 && _vm.upToDate
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              class: _vm.maskedIdi02 === "" ? "" : "pad-0",
                              attrs: { cols: "8", lg: "2", md: "2" },
                            },
                            [
                              _c("v-subheader", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.configData.TEXT_SECOND_CARD_INFO
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              class:
                                _vm.maskedIdi02 === ""
                                  ? "col-right-card"
                                  : "col-right-card pad-0",
                              attrs: {
                                cols: _vm.maskedIdi02 === "" ? 4 : 12,
                                lg: "5",
                                xl: "3",
                                md: "5",
                              },
                            },
                            [
                              _c("span", { staticClass: "v-sub" }, [
                                _vm._v(_vm._s(_vm.maskedIdi02)),
                              ]),
                              _vm.maskedIdi02 == ""
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-primary",
                                      attrs: { outlined: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.registerCard("2")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.configData.TEXT_REGISTER) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.maskedIdi02 != ""
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-secondary",
                                      attrs: { outlined: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showConfirmDialog("2")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.configData.TEXT_DELETE) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.maxNumOfCards > 2 && _vm.upToDate
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              class: _vm.maskedIdi03 === "" ? "" : "pad-0",
                              attrs: { cols: "8", lg: "2", md: "2" },
                            },
                            [
                              _c("v-subheader", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.configData.TEXT_THIRD_CARD_INFO
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              class:
                                _vm.maskedIdi03 === ""
                                  ? "col-right-card"
                                  : "col-right-card pad-0",
                              attrs: {
                                cols: _vm.maskedIdi03 === "" ? 4 : 12,
                                lg: "5",
                                xl: "3",
                                md: "5",
                              },
                            },
                            [
                              _c("span", { staticClass: "v-sub" }, [
                                _vm._v(_vm._s(_vm.maskedIdi03)),
                              ]),
                              _vm.maskedIdi03 == ""
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-primary",
                                      attrs: { outlined: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.registerCard("3")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.configData.TEXT_REGISTER) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.maskedIdi03 != ""
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-secondary",
                                      attrs: { outlined: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showConfirmDialog("3")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.configData.TEXT_DELETE) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.confirmDialog,
            callback: function ($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "modal-title", attrs: { color: "black" } },
                [_vm._v(" " + _vm._s(_vm.configData.TEXT_CONFIRMATION) + " ")]
              ),
              _c(
                "v-card-text",
                { staticClass: "mt-5" },
                [
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _c("p", { staticClass: "error-text" }, [
                    _vm._v(_vm._s(_vm.configData.TEXT_REQUEST_DELETE_CARD)),
                  ]),
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _c("v-spacer"),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              _vm.confirmDialog = false
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.configData.TEXT_RETURN) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.deregisterCard()
                            },
                          },
                        },
                        [_vm._v(" OK ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.showMessageFlag,
            callback: function ($$v) {
              _vm.showMessageFlag = $$v
            },
            expression: "showMessageFlag",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "modal-title", attrs: { color: "black" } },
                [_vm._v(" " + _vm._s(_vm.messageDialogTitle) + " ")]
              ),
              _c(
                "v-card-text",
                { staticClass: "mt-5" },
                [
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _vm._v(" " + _vm._s(_vm.messageDialogText) + " "),
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _c("v-spacer"),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: { click: _vm.closeMessageDialog },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.configData.TEXT_CLOSE_UP) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }