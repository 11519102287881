var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("login-template", {
    attrs: {
      loginProcess: _vm.loginProcess,
      errorMessage: _vm.errorMessage,
      loading: _vm.loading,
      error: _vm.error,
    },
    on: {
      signIn: function ($event) {
        return _vm.signIn($event)
      },
      resetPass: function ($event) {
        return _vm.resetPass($event)
      },
      forgot: function ($event) {
        return _vm.forgot($event)
      },
      formSubmit: function ($event) {
        return _vm.formSubmit($event)
      },
      formSubmitForgot: function ($event) {
        return _vm.formSubmitForgot($event)
      },
      goPageLogin: function ($event) {
        return _vm.goPageLogin()
      },
      "forgot-password": function ($event) {
        return _vm.forgotPassword($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }