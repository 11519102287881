
import {Vue } from 'vue-property-decorator';
import CardTemplate from '@/components/templates/CardTemplate/CardTemplate.vue';
import * as Config from '@/config';
declare global {
  interface Window {
    registrationEmid: any;
    statusEmid: any;
  }
}
export interface DataType {
  // Props
  // For Card list/registration
  upToDate: boolean;
  maskedIdi01: string;
  maskedIdi02: string;
  maskedIdi03: string;
  maxNumOfCards: number;
  //
  page: number;
  isAction: number;
  loading: boolean;
  resData: any;
  errorMessageLogs: string;
  actionItems: any;
  userInfo: any;
  message: string;
  successMessageLogs: string;
  // For control
  showMessageDialog: boolean;
  messageDialogTitle: string;
  messageDialogText: string;
  confirmDialog: boolean;
  confirmPwdDialog: boolean;
  serviceId: string;
}
export default Vue.extend({
  name: 'CardListPage',
  components: {
   CardTemplate,
  },
  data: (): DataType => ({
    // Props
  // For Card list/registration
    upToDate: false,
    maskedIdi01: '',
    maskedIdi02: '',
    maskedIdi03: '',
    maxNumOfCards: 3,
    //
    loading: false,
    isAction: 0,
    page: 1,
    resData: [],
    errorMessageLogs: '',
    // for control
    showMessageDialog: false,
    messageDialogTitle: '',
    messageDialogText: '',
    //
    actionItems: [
      { title: Config.TEXT_CARD_REGISTRATION, tabname: 'tab1' },
      { title: Config.TEXT_CHANGE_PASSWORD, tabname: 'tab2' },
      { title: Config.TEXT_USER_INFORMATION, tabname: 'tab3' },
    ],
    userInfo: {},
    message: '',
    successMessageLogs: '',
    confirmDialog: false,
    confirmPwdDialog: false,
    serviceId: '',
  }),
  async mounted() {
    this.loading = true;
    this.$emit('activeMenu', 'personal');
    this.actionItems = [
      { title: Config.TEXT_CARD_REGISTRATION, tabname: 'tab1' },
      { title: Config.TEXT_CHANGE_PASSWORD, tabname: 'tab2' },
      { title: Config.TEXT_USER_INFORMATION, tabname: 'tab3' },
    ];
    // For Card registration
    const cardNo = sessionStorage.getItem('RegistrationCardNo');
    if (cardNo) {
      // console.log('Register card to SS No.' + cardNo);
      await this.registerCard(cardNo);
      sessionStorage.removeItem('RegistrationCardNo');
    }
    await this.getMaskedIdiList();
    this.loading = false;
  },
  created() {
    this.loading = true;
    // For set number of cards in the card list
    this.setMaxNumOfCards();
    this.getUserInfo();
    this.loading = false;
    //
    // this.userInfo = {
    //   // user_id: 'employee0001',
    //   // domain_code: 'SHZK0001',
    //   name: 'アート 太郎',
    //   priviledge: 3,
    //   // type: 0,
    //   organizations: [
    //     {label: '営業一課1'},
    //     {label: '営業一課2'},
    //   ],
    //   email: 'taro@example.com',
    //   furigana: 'タロウ',
    //   memo: 'これは補足です',
    // };
  },
  methods: {
    showConfirmDialog() {
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    showConfirmPwdDialog() {
      this.confirmPwdDialog = true;
    },
    closeConfirmPwdDialog() {
      this.confirmPwdDialog = false;
    },
    // Set number of cards in the card list
    setMaxNumOfCards() {
      const configUrl = location.origin + Config.MAX_NUMBER_OF_CARDS_CONFIG_URL;
      const ownerId = sessionStorage.getItem('ownerId') as string;
      this.$http.get(configUrl)
        .then((response: any) => {
          const maxNumber = response.data[ownerId];
          // console.log(maxNumber);
          if ( maxNumber) {
              if (maxNumber === '1') {
                this.maxNumOfCards = 1;
              }
          }
        }).
        then( null, (err: any) => {
          this.loading = false;
          switch (err.status) {
                case 400:
                  this.errorMessageLogs = Config.ERROR_400; break;
                case 403:
                  if (err.message === 'Unauthorized') {
                    this.errorMessageLogs = Config.ERROR_403;
                  } else {
                    this.errorMessageLogs = Config.ERROR_403_DENIED;
                  }
                  break;
                case 404:
                  this.errorMessageLogs = Config.ERROR_404;
                  break;
                case 500:
                  this.errorMessageLogs = Config.ERROR_500;
                  break;
                case 503:
                  this.errorMessageLogs = Config.ERROR_503;
                  break;
                default:
                  this.errorMessageLogs = Config.ERROR_DEFAULT;
                  break;
            }
          console.log('Error: ' + this.errorMessageLogs);
        });
    },
    // Start card regisration process
    async startCardRegistration(cardNo: string) {
      this.serviceId = '';
      const result = await this.getProperties();
      if (result) {
        // const user_id = localStorage.userProfileId;    // SHOULD BE sessionStorage instead of localStorage
        sessionStorage.setItem('RegistrationCardNo', cardNo);
        sessionStorage.setItem('RouteToPage', Config.PERSONAL_SETTING);
        // const userId = 'employee0001';
        const userId = sessionStorage.getItem('userProfileId');
        const idPortID =   userId + '_' + cardNo;
        // *** ID-PORT へリダイレクト
        const IDPOTRT_SERVICE_ID = this.serviceId;
        const  statusE = window.registrationEmid(idPortID, IDPOTRT_SERVICE_ID, 'I');
        console.log('Card Registration No.' + cardNo);
      } else {
        alert(Config.ERROR_GET_PROPERTIES);
      }
    },
    // Masked IDi list via API
    async getMaskedIdiList() {
      // const user_id="employee0001";
      const userId = sessionStorage.getItem('userProfileId');
      const apiLink = Config.CARD_LIST_API + '/' + userId ;
      await this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.message === 'Success') {
            this.maskedIdi01 = response.body.cards.masked_idi1;
            this.maskedIdi02 = response.body.cards.masked_idi2;
            this.maskedIdi03 = response.body.cards.masked_idi3;
            this.upToDate = true;
          } else {
            this.errorMessageLogs = response.body.message;
            this.messageDialogTitle = Config.TEXT_TITLE_FOR_CARD_LIST_FAILED;
            this.messageDialogText = Config.ERROR_MESSAGE_FOR_CARD_LIST_FAILED;
            this.showMessageDialog = true;
          }
        }).
        then( null, (err: any) => {
          console.log('Error: ' + this.errorMessageLogs);
          this.messageDialogTitle = Config.TEXT_TITLE_FOR_CARD_LIST_FAILED;
          this.messageDialogText = Config.ERROR_MESSAGE_FOR_CARD_LIST_FAILED;
          this.showMessageDialog = true;
        });
    },
    // Remove card registraion
    async deregisterCard(cardNo: string) {
        //       console.log('Card Deregistration No.'+cardNo);
      const userId = sessionStorage.getItem('userProfileId');
      const apiLink = Config.CARD_LIST_API + '/' + userId + '/' + cardNo;
      this.loading = true;
      let result = false;
      await this.$http.delete(apiLink)
        .then((response: any) => {
          this.loading = false;
          if (response.body.message === 'Success') {
            result = true;
            this.messageDialogTitle = Config.TEXT_TITLE_FOR_CARD_CONFIRM_DEREGISTRATION;
            this.messageDialogText = Config.TEXT_MESSAGE_FOR_CARD_DEREGISTRATION_SUCCESS;
            this.showMessageDialog = true;
            switch (cardNo) {
              case '1':
                this.maskedIdi01 = '';
                break;
              case '2':
                this.maskedIdi02 = '';
                break;
              case '3':
                this.maskedIdi03 = '';
                break;
              default:
                console.log('Error: Undefined cardNo');
                break;
            }
          } else {
            this.errorMessageLogs =  response.body.message;
            this.messageDialogTitle = Config.TEXT_TITLE_FOR_CARD_DEREGISTRATION_FAILED;
            this.messageDialogText = Config.ERROR_MESSAGE_FOR_CARD_DEREGISTRATION_FAILED;
            this.showMessageDialog = true;
          }
        })
        .then( null, (err: any) => {
          this.loading = false;
          console.log('Error: ' + this.errorMessageLogs);
          this.messageDialogTitle = Config.TEXT_TITLE_FOR_CARD_DEREGISTRATION_FAILED;
          this.messageDialogText = Config.ERROR_MESSAGE_FOR_CARD_DEREGISTRATION_FAILED;
          this.showMessageDialog = true;
          return result;
      }); // catch
    },
    // Kick up card registration
    async registerCard(cardNo: string) {
      console.log('Card Registration: No.' + cardNo);
      const userId = sessionStorage.getItem('userProfileId');
      // const userId="employee0001";
      const apiLink = Config.CARD_LIST_API + '/' + userId + '/' + cardNo;
     // Call API
      const idPortId = userId + '_' + cardNo;
      const requestData = {idport_id: idPortId };
      let returnCd = window.statusEmid();
      if (Array.isArray(returnCd)) {
        returnCd = returnCd[0];
      }
      await this.$http.put(apiLink, JSON.stringify(requestData))
        .then((response: any) => {
          if (response.body.message === 'Success') {
            this.messageDialogTitle = Config.TEXT_TITLE_FOR_CARD_REGISTRATION_SUCCESS;
            this.messageDialogText = Config.TEXT_MESSAGE_FOR_CARD_REGISTRATION_SUCCESS;
            this.showMessageDialog = true;
          } else {
            alert(Config.ERROR_MESSAGE_CARD_REGISTRATION_FAILED);
          }
        })
        .then( null, (err: any) => {
          if (err.status === 400) {
            console.log(err.status);
          } else if (err.status === 409) {
            alert(Config.ERROR_MESSAGE_CARD_REGISTRATION_409);
          } else {
            alert(Config.ERROR_MESSAGE_CARD_REGISTRATION_FAILED);
          }
        });
    },
    closeMessageDialog() {
      this.showMessageDialog = false;
    },
    async getUserInfo() {
      const apiLink = Config.USERS_LIST_API + '/' +
                      encodeURIComponent(sessionStorage.getItem('userProfileId') as string);
      await this.$http.get(apiLink)
      .then((response: any) => {
        if (response.body.user !== null) {
          this.userInfo = {
            userId: response.body.user.user_id,
            name: response.body.user.name,
            priviledge: response.body.user.priviledge,
            furigana: response.body.user.furigana,
            email: response.body.user.email,
            type: response.body.user.type,
            organizations: [],
            supplement: response.body.user.memo,
          };
          if (!this.isEmpty(response.body.user.organizations) &&
            response.body.user.organizations.length > 0) {
            for (const org of response.body.user.organizations) {
              this.userInfo.organizations.push(org.label);
            }
          }
        }
      })
      .then(null, (error: any) => {
        console.log('error', error);
        this.errorMessageLogs = '';
        switch (error.status) {
          case 400:
            this.errorMessageLogs = Config.ERROR_400;
            break;
          case 401:
            this.errorMessageLogs = Config.ERROR_401;
            break;
          case 403:
            this.errorMessageLogs = Config.ERROR_403;
            break;
          case 500:
            this.errorMessageLogs = Config.ERROR_500;
            break;
          case 503:
            this.errorMessageLogs = Config.ERROR_503;
            break;
          default:
            this.errorMessageLogs = Config.ERROR_DEFAULT;
            break;
        }
      });
    },
    updateUserInfo(userInfo: any) {
      this.confirmDialog = false;
      const apiLink = Config.USERS_LIST_API + '/' +
                      encodeURIComponent(sessionStorage.getItem('userProfileId') as string);
      this.loading = true;
      this.userInfo = {
        name: userInfo.name,
        furigana: userInfo.furigana,
        email: userInfo.email,
      },
      this.$http.patch(apiLink, JSON.stringify(this.userInfo))
      .then((response: any) => {
        this.loading = false;
        alert(Config.TEXT_UPDATE_COMPLETED);
        sessionStorage.setItem('user_name', userInfo.name);
        this.$emit('reLoadUser');
      })
      .then(null, (error: any) => {
        console.log('error', error);
        this.loading = false;
        alert(Config.ERROR_MESSAGE_FOR_USER_UPDATE_FAILED);
      });
    },
    changePassword(password: any) {
      const currentPassword = password.currentPassword;
      const newPassword = password.newPassword;
      this.confirmPwdDialog = false;
      this.updateUserPassword(sessionStorage.getItem('userProfileId'), currentPassword, newPassword);
    },
    updateUserPassword(userId: any, currentPassword: string, newPassword: string) {
      const apiLink = Config.CHANGE_PASSWORD_API + '/' + userId;
      this.loading = true;
      this.$http.put(apiLink, {old_password: currentPassword, new_password: newPassword})
      .then((response: any) => {
        this.loading = false;
        if (response.status === 200) {
          this.userInfo.password = newPassword;
          alert(Config.TEXT_TITLE_CHANGE_PASS_SUCCESS);
        }
      })
      .then(null, (error) => {
        this.loading = false;
        alert(Config.TEXT_TITLE_CHANGE_PASS_FAILED);
      });
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
    },
    getProperties() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        const apiLink = Config.PROPERTIES_API + '?keys=idport';
        this.$http.get(apiLink)
          .then((response: any) => {
            //
            this.serviceId = response.body.properties.idport.service_id;
            this.loading = false;
            resolve(true);
          }).
          then( null, (err: any) => {
            this.loading = false;
            resolve(false);
          });
      });
    },
  },
});
