
import Vue, { PropType } from 'vue';
import ChangeInitialPassword from '@/components/organisms/ChangeInitialPassword/ChangeInitialPassword.vue';
export default Vue.extend({
  name: 'ChangeInitialPasswordTemplate',
  components: {
    ChangeInitialPassword,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      default: {
        ownerId: '',
        userId: '',
        password: '',
        mfaCode: '',
        resetCode: '',
        confirmPassword: '',
      },
    },
    error: {
      type: String,
      default: '',
    },
  },
});
