
import {Vue } from 'vue-property-decorator';
import EntranceHistoryTemplate from '@/components/templates/EntranceHistoryTemplate/EntranceHistoryTemplate.vue';
import * as Config from '@/config';
import auth from '@/auth';
import moment from 'moment';
const timeOut = 15000;
export interface DataType {
  // Props
  page: number;
  pageExport: number;
  perPage: number;
  total: number;
  isAction: number;
  countExport: number;
  exportData: any;
  loading: boolean;
  isRegistering: boolean;
  loadingCSV: boolean;
  loadMore: boolean;
  loadingSearch: boolean;
  resData: any;
  areaList: any;
  office_name: string;
  keyword: string;
  dataOffice: any;
  dataArea: any;
  gateCode: any;
  listDataExport: any;
  checkTime: boolean;
}
export default Vue.extend({
  name: 'EntranceHistoryListPage',
  components: {
    EntranceHistoryTemplate,
  },
  data: (): DataType => ({
    // Props
    loadingSearch: false,
    loadingCSV: false,
    checkTime: false,
    loading: false,
    isRegistering: false, // default: false -> reading data
    loadMore: false,
    isAction: 0,
    countExport: 0,
    page: 1,
    pageExport: 1,
    resData: [],
    areaList: [],
    exportData: [],
    keyword: '',
    office_name: '',
    perPage: Config.PER_PAGE,
    total: 0,
    dataOffice: [],
    dataArea: [],
    gateCode: [],
    listDataExport: [],
  }),
  mounted() {
    this.$emit('activeMenu', 'entrance-history');
    this.getListData();
    if (sessionStorage.getItem('priviledge') === '0') {
      this.getListDataOffice();
      this.getAreaList();
    } else if (sessionStorage.getItem('priviledge') === '1') {
      const domainCode = sessionStorage.getItem('domainCode');
      this.getListDataOffice(domainCode);
      this.getAreaList();
    } else if (sessionStorage.getItem('priviledge') === '2') {
      this.getAreaList()
      .then(() => {
        this.getListOfficeArea(sessionStorage.getItem('office_code'));
      });
    }
    sessionStorage.removeItem('displayOptionEntranceHis');
  },
  created() {
    const dateE = moment(new Date()).format('YYYY-MM-DD') + ' 23:59:59';
    const dateS = moment(new Date()).add(-1, 'week').format('YYYY-MM-DD') + ' 00:00';
    this.keyword = '&since=' + auth.formatTimestamp(dateS) + '&until=' + auth.formatTimestamp(dateE);
    //
  },
  watch: {
    countExport(newVal, oldVal) {
      if (newVal >= this.pageExport) {
        if (this.exportData.length === 0) {
          this.exportData.push({
            [Config.TEXT_DATE_AND_TIME]: '',
                [Config.TEXT_MESSAGE]: '',
                [Config.TEXT_AREA]: '',
                [Config.TEXT_GATE]: '',
                [Config.TEXT_USER_ID]: '',
                [Config.TEXT_USER_NAME]: '',
          });
        }
        auth.downloadCSV(this.exportData, Config.TEXT_ENTRANCE_HISTORY);
        this.loadingCSV = false;
      } else if (newVal > 0) {
        this.getExportData(newVal + 1);
      }
    },
    loading(value) {
      this.$emit('loading', value);
      if (value) {
        this.$emit('textCircle', this.isRegistering ? Config.TEXT_PROGRESS_REGISTERING : Config.TEXT_PROGRESS_READING);
      } else if (this.isRegistering) {
        this.isRegistering = false;
      }
    },
    loadingCSV(value) {
      this.$emit('loading', value);
    },
  },
  methods: {
    getCurrentItems(item: any) {
      this.listDataExport = item;
    },
    changeColumn(value: any): any {
      this.loading = true;
      setTimeout(() => {this.loading = false; }, 300);
    },
    async changeOffice(item: any) {
      this.dataArea = [];
      this.gateCode = [];
      if (item && item.value) {
        this.getListOfficeArea(item.value);
      }
    },
    changeArea(item: any) {
      this.gateCode = [];
      if (item && item.value) {
        this.getGates(item.value);
      }
    },
    getListDataOffice(domainCode: any = null): any {
      return new Promise((resolve, reject) => {
        let apiLink = Config.OFFICES_LIST_API;
        if (domainCode !== undefined && domainCode !== null && domainCode !== '') {
          apiLink += '?domain_code=' + domainCode;
        }
        this.dataOffice = [];
        this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.offices.length > 0) {
            response.body.offices.forEach(async (element: any, key: any) => {
              this.dataOffice.push(
                {
                  text: element.name,
                  value: element.code,
                },
              );
            });
          }
          resolve(true);
        })
        .then(null, (err: any) => {
          console.log(err);
          resolve(false);
        });
      });
    },
    changePerPage(value: any): any {
      this.perPage = value;
      this.page = 1;
      sessionStorage.setItem('perPageApprove', value);
      this.getListData();
    },
    async exportCSV(value: any): Promise<any> {
      this.exportData = [];
      this.pageExport = 0;
      this.loadingCSV = true;
      if (this.listDataExport.length > 0) {
        for (const item of this.listDataExport) {
          let templateExport: any = {};
          if (sessionStorage.hasOwnProperty('displayOptionEntranceHis')) {
            let displayOption = sessionStorage.getItem('displayOptionEntranceHis') as string;
            displayOption = displayOption.replace(/'/g, '"');
            displayOption = JSON.parse(displayOption);
            if (displayOption.includes('timestamp')) {
              const timestamp = Config.TEXT_DATE_AND_TIME;
              templateExport[timestamp] = auth.converDateShort(item.timestamp);
            }
            if (displayOption.includes('message')) {
              const message = Config.TEXT_MESSAGE;
              templateExport[message] = auth.getDefaultStringVal(item.message);
            }
            if (displayOption.includes('area_name')) {
              const area = Config.TEXT_AREA;
              templateExport[area] = auth.getDefaultStringVal(item.area_name);
            }
            if (displayOption.includes('gate_name')) {
              const gate = Config.TEXT_GATE;
              templateExport[gate] = auth.getDefaultStringVal(item.gate_name);
            }
            if (displayOption.includes('user_id')) {
              const useID = Config.TEXT_USER_ID;
              templateExport[useID] = auth.getDefaultStringVal(item.user_id);
            }
            if (displayOption.includes('user_name')) {
              const userName = Config.TEXT_USER_NAME;
              templateExport[userName] = auth.getDefaultStringVal(item.user_name);
            }
          } else {
            templateExport = {
              [Config.TEXT_DATE_AND_TIME]: auth.converDateShort(item.timestamp),
              [Config.TEXT_MESSAGE]: auth.getDefaultStringVal(item.message),
              [Config.TEXT_AREA]: auth.getDefaultStringVal(item.area_name),
              [Config.TEXT_GATE]: auth.getDefaultStringVal(item.gate_name),
              [Config.TEXT_USER_ID]: auth.getDefaultStringVal(item.user_id),
              [Config.TEXT_USER_NAME]: auth.getDefaultStringVal(item.user_name),
            };
          }
          await this.exportData.push(templateExport);
        }
        auth.downloadCSV(this.exportData, Config.TEXT_ENTRANCE_HISTORY);
        this.loadingCSV = false;
      } else {
        let templateExport: any = {};
        if (sessionStorage.hasOwnProperty('displayOptionEntranceHis')) {
          let displayOption = sessionStorage.getItem('displayOptionEntranceHis') as string;
          displayOption = displayOption.replace(/'/g, '"');
          displayOption = JSON.parse(displayOption);
          if (displayOption.includes('timestamp')) {
            const timestamp = Config.TEXT_DATE_AND_TIME;
            templateExport[timestamp] = '';
          }
          if (displayOption.includes('message')) {
            const message = Config.TEXT_MESSAGE;
            templateExport[message] = '';
          }
          if (displayOption.includes('area_name')) {
            const area = Config.TEXT_AREA;
            templateExport[area] = '';
          }
          if (displayOption.includes('gate_name')) {
            const gate = Config.TEXT_GATE;
            templateExport[gate] = '';
          }
          if (displayOption.includes('user_id')) {
            const useID = Config.TEXT_USER_ID;
            templateExport[useID] = '';
          }
          if (displayOption.includes('user_name')) {
            const userName = Config.TEXT_USER_NAME;
            templateExport[userName] = '';
          }
        } else {
          templateExport = {
            [Config.TEXT_DATE_AND_TIME]: '',
            [Config.TEXT_MESSAGE]: '',
            [Config.TEXT_AREA]: '',
            [Config.TEXT_GATE]: '',
            [Config.TEXT_USER_ID]: '',
            [Config.TEXT_USER_NAME]: '',
          };
        }
        await this.exportData.push(templateExport);
        auth.downloadCSV(this.exportData, Config.TEXT_ENTRANCE_HISTORY);
        this.loadingCSV = false;
      }
    },
    changePage(value: any): any {
      console.log('value', value);
      this.page = value;
      this.loadingSearch = true;
      this.checkTime = true;
      this.getListData();
    },
    search(args: any) {
      this.listDataExport = [];
      sessionStorage.removeItem('displayOptionEntranceHis');
      this.loadingSearch = true;
      console.log(args);
      this.page = 1;
      this.resData = [];
      this.keyword = '';
      args.target.forEach((element: any, key: any) => {
        if (element.value !== undefined && element.value !== null && element.value !== '' &&
            element.name !== 'office_list' && element.name !== 'office_code') {
          if (element.name === 'area_list') {
            this.keyword += 'area_code=' + element.value.value + '&';
          } else if (element.name === 'gate_code') {
            this.keyword += 'gate_code=' + element.value.value + '&';
          } else if (element.name === 'user_id' && element.value.length > Config.USER_ID_MAX_LEN) {
            this.keyword += element.name + '=' + element.value.slice(0, Config.USER_ID_MAX_LEN) + '&';
          } else if (element.name === 'user_name' && element.value.length > Config.USER_NAME_MAX_LEN) {
            this.keyword += element.name + '=' + element.value.slice(0, Config.USER_NAME_MAX_LEN) + '&';
          } else if (element.name === 'message' && element.value.length > Config.RECORD_MESSAGE_MAX_LEN) {
            this.keyword += element.name + '=' + element.value.slice(0, Config.RECORD_MESSAGE_MAX_LEN) + '&';
          } else {
            let value = element.value;
            if (element.name === 'since' || element.name === 'until') {
              if (element.name === 'until') {
                const arrD = (element.value).split(' '); // [days, times]
                const arrH = (arrD[1]).split(':'); // [hours, minutes]
                const valueTime = arrD[0] + ' ' + arrH[0] + ':' + arrH[1] + ':59';
                value = auth.formatTimestamp(valueTime);
              } else {
                value = auth.formatTimestamp(element.value);
              }
            }
            this.keyword += element.name + '=' + value + '&';
          }
        }
      });
      this.getListData();
    },
    getListData(): any {
      this.loading = true;
      this.loadMore = false;
      let finished = false;
      const page  = this.page;
      let apiLink = Config.RECORDS_API + '?limit=' + this.perPage +
        '&page=' + this.page + '&sort=desc:timestamp' ;
      if (this.keyword !== '') {
        apiLink = apiLink + '&' + this.keyword.replace(/&\s*$/, '');
      }
      this.total = 0;
      this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.records.length > 0) {
            this.total = response.body.total;
            this.page = Math.ceil(response.body.head / this.perPage);
            if (response.body.records.length > 0) {
              const checkEmpty = response.body.records.filter((a: any) => a.timestamp  === '');
              if (response.body.records.length === checkEmpty.length) {
                if (response.body.total <= response.body.tail) {
                  this.loading = false;
                  if (this.resData.length === 0) {
                    alert(Config.TEXT_NO_DATA);
                  }
                } else {
                  this.page++;
                  this.getListData();
                }
              } else {
                response.body.records.forEach(async (element: any, key: any) => {
                  if (Object.keys(element).length > 1 && element.timestamp  !== '') {
                    this.resData.push(element);
                  }
                });
                this.loading = false;
              }
            }
          } else {
            if (this.page > 1) {
              if (this.checkTime) {
                this.checkTime = false;
                setTimeout(() => {
                  if (this.total === 0 && finished === true) {
                    alert(Config.TEXT_ERROR_FOR_ENTRANCE_HISTORY_MORE_EMPTY);
                    this.loading = false;
                  }
                }, timeOut);
              }
              if (this.loading) {
                this.getListData();
              }
            } else {
              this.loading = false;
              alert(Config.TEXT_NO_DATA);
            }
          }
          if (response.body.total > response.body.tail) {
            this.loadMore = true;
          }
          // this.loading = false;
          this.loadingSearch = false;
          finished = true;
        })
        .then( null, (err: any) => {
          if (this.page > 1) {
            if (this.checkTime) {
              this.checkTime = false;
              setTimeout(() => {
                if (this.total === 0 && finished === false) {
                  alert(Config.TEXT_ERROR_FOR_ENTRANCE_HISTORY_MORE_EMPTY);
                  this.loading = false;
                }
              }, timeOut);
            }
            if (this.loading) {
              this.getListData();
            }
          } else {
            this.loadingSearch = false;
            alert(Config.TEXT_ERROR_FOR_ENTRANCE_HISTORY);
            this.loading = false;
          }
          finished = true;
        }); // catch
    },
    getExportData(page: any): any {
      let apiLink = Config.RECORDS_API + '?limit=1000&page=' + page + '&sort=asc:timestamp';
      if (this.keyword !== '') {
        apiLink += '&' + this.keyword.replace(/&\s*$/, '');
      }
      this.$http.get(apiLink)
        .then((response: any) => {
          if (!this.isEmpty(response.body.records) && response.body.records.length > 0) {
            this.pageExport = Math.ceil(response.body.total / 1000);
            for (const item of response.body.records) {
              this.exportData.push({
                [Config.TEXT_DATE_AND_TIME]: auth.converDateShort(item.timestamp),
                [Config.TEXT_MESSAGE]: auth.getDefaultStringVal(item.message),
                [Config.TEXT_AREA]: auth.getDefaultStringVal(item.area_name),
                [Config.TEXT_GATE]: auth.getDefaultStringVal(item.gate_name),
                [Config.TEXT_USER_ID]: auth.getDefaultStringVal(item.user_id),
                [Config.TEXT_USER_NAME]: auth.getDefaultStringVal(item.user_name),
              });
            }
            this.countExport++;
          }
        })
        .then( null, (err: any) => {
          this.countExport++;
        }); // catch
    },
    getAreaList() {
      return new Promise((resolve, reject) => {
        this.areaList = [];
        this.$http.get(Config.AREAS_API)
        .then((res: any) => {
          if (!this.isEmpty(res.body.areas) && res.body.areas.length > 0) {
            this.areaList = res.body.areas;
          }
          resolve(true);
        })
        .then(null, (err: any) => {
          resolve(false);
        });
      });
    },
    getListOfficeArea(ID: any): any {
      this.$http.get(Config.OFFICE_AREAS_API + '/' + ID)
      .then((response: any) => {
        if (response.body.areas !== undefined && response.body.areas !== null && response.body.areas.length > 0) {
          response.body.areas.forEach(async (element: any, key: any) => {
            const areaCheck = this.dataArea.find((data: any) => data.value === element.area_code);
            if (areaCheck === undefined || areaCheck === null) {
              const val = this.areaList.find((a: any) => a.code === element.area_code);
              if (val) {
                this.dataArea.push({
                  text: val.name,
                  value: val.code,
                });
              }
            }
          });
        }
      })
      .then( null, (err: any) => {
        console.log(err);
      }); // catch
    },
    getGates(ID: any): any {
      this.$http.get(Config.GATES_API + '?area_code=' + ID + '&is_status=false')
      .then((response: any) => {
        if (response.body.gates.length > 0) {
          response.body.gates.forEach(async (element: any, key: any) => {
            this.gateCode.push({
              text: element.name,
              value: element.code,
            });
          });
        }
      })
      .then( null, (err: any) => {
        console.log(err);
      }); // catch
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
  },
});
