var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "allig-display-option pb-1" },
    [
      _vm.isShowOption
        ? _c(
            "v-card",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: {
                    handler: _vm.onClickOutsideOption,
                    include: _vm.included,
                  },
                  expression:
                    "{ handler: onClickOutsideOption, include: included}",
                },
              ],
              staticClass: "display-option-contents mr-0 ml-5 mt-1",
              attrs: { color: "white" },
            },
            [
              _c("v-card-text", { staticClass: "pb-0" }, [
                _c("div", { staticClass: "font-weight-black" }, [
                  _vm._v(" 表示項目: "),
                ]),
                _c(
                  "div",
                  { staticClass: "d-flex flex-wrap" },
                  _vm._l(_vm.displayOptionItems, function (item) {
                    return _c("v-checkbox", {
                      key: item.value,
                      staticClass: "mt-1 mr-10 allig-custom-search-checkbox",
                      attrs: {
                        label: item.label,
                        value: item.value,
                        color: "grey",
                        dense: "",
                        "hide-details": "",
                        disabled: item.isDisabled,
                      },
                      model: {
                        value: _vm.innerValue,
                        callback: function ($$v) {
                          _vm.innerValue = $$v
                        },
                        expression: "innerValue",
                      },
                    })
                  }),
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }