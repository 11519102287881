var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-overlay", { attrs: { value: _vm.loading, color: "white" } }, [
        _vm.valueCircleLoading === 0
          ? _c("img", { attrs: { src: "/loading-circle.gif", width: "40" } })
          : _vm._e(),
        _vm.textCircleLoading !== ""
          ? _c("div", { attrs: { id: "title-loading" } }, [
              _vm._v(_vm._s(_vm.textCircleLoading)),
            ])
          : _vm._e(),
      ]),
      _c(
        "v-card",
        { staticClass: "mx-auto overflow-hidden" },
        [
          _c("allig-header", {
            attrs: { userName: _vm.userName },
            on: {
              "click-nav": function ($event) {
                _vm.showNav = !_vm.showNav
              },
              "click-guid": function ($event) {
                return _vm.$emit("click-guide")
              },
              "click-notice": function ($event) {
                return _vm.$emit("click-notice")
              },
              "click-user-setting": function ($event) {
                return _vm.$emit("click-user-setting")
              },
              "click-user-manual": function ($event) {
                return _vm.$emit("click-user-manual")
              },
              "click-logout": function ($event) {
                return _vm.$emit("click-logout")
              },
            },
          }),
          _vm.isMobile
            ? _c("side-navigation", {
                attrs: {
                  isAdmin: _vm.isAdmin,
                  selectedItemNav: _vm.selectedItemNav,
                },
                on: {
                  menuClick: function ($event) {
                    return _vm.$emit("menuClick", $event)
                  },
                },
                model: {
                  value: _vm.showNav,
                  callback: function ($$v) {
                    _vm.showNav = $$v
                  },
                  expression: "showNav",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-main",
        {
          staticClass: "allig-main",
          class: _vm.activeName + " is-mobile-" + _vm.isMobile,
        },
        [
          _c(
            "v-container",
            { staticClass: "allig-container", attrs: { fluid: "" } },
            [
              _c("router-view", {
                attrs: { isAdmin: _vm.isAdmin },
                on: {
                  loading: function ($event) {
                    return _vm.Loading($event)
                  },
                  reLoadUser: function ($event) {
                    return _vm.reLoadUser()
                  },
                  loadingCircle: function ($event) {
                    return _vm.loadingCircle($event)
                  },
                  textCircle: function ($event) {
                    return _vm.textCircle($event)
                  },
                  activeMenu: function ($event) {
                    return _vm.$emit("activeMenu", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }