var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-form",
            { ref: "addForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "end" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "btn-add-card" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("clickBackPassable")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.configData.TEXT_RETURN) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text-subtitle-2 border-left-blue font-weight-bold",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.configData.TEXT_JOB_APPLICATION_RECOGNITION
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "pa-6 pr-10 mb-5" },
                [
                  _c("input-form", {
                    attrs: {
                      title: _vm.configData.TEXT_APPLICANT,
                      inputType: "text",
                    },
                    model: {
                      value: _vm.test,
                      callback: function ($$v) {
                        _vm.test = $$v
                      },
                      expression: "test",
                    },
                  }),
                  _c("input-form", {
                    attrs: {
                      title: _vm.configData.TEXT_COL_APPLICATION_NUMBER,
                      inputType: "text",
                    },
                    model: {
                      value: _vm.test,
                      callback: function ($$v) {
                        _vm.test = $$v
                      },
                      expression: "test",
                    },
                  }),
                  _c("input-form", {
                    attrs: {
                      title: _vm.configData.TEXT_DATE_APPLICATION,
                      inputType: "text",
                    },
                    model: {
                      value: _vm.test,
                      callback: function ($$v) {
                        _vm.test = $$v
                      },
                      expression: "test",
                    },
                  }),
                  _c("input-form", {
                    attrs: {
                      title: _vm.configData.TEXT_UPDATE_DATE,
                      inputType: "text",
                    },
                    model: {
                      value: _vm.test,
                      callback: function ($$v) {
                        _vm.test = $$v
                      },
                      expression: "test",
                    },
                  }),
                  _c("input-form", {
                    attrs: {
                      title: _vm.configData.TEXT_APPLYING,
                      inputType: "text",
                    },
                    model: {
                      value: _vm.test,
                      callback: function ($$v) {
                        _vm.test = $$v
                      },
                      expression: "test",
                    },
                  }),
                  _c("input-form", {
                    attrs: {
                      title: _vm.configData.TEXT_CARD,
                      inputType: "text",
                    },
                    model: {
                      value: _vm.test,
                      callback: function ($$v) {
                        _vm.test = $$v
                      },
                      expression: "test",
                    },
                  }),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("div", { staticClass: "v-subheader theme--light" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.configData.TEXT_COL_BEGIN_DATE)),
                          ]),
                        ]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [_c("DatePicker")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("div", { staticClass: "v-subheader theme--light" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.configData.TEXT_COL_END_DATE)),
                          ]),
                        ]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [_c("DatePicker")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("input-form", {
                    attrs: {
                      title: _vm.configData.TEXT_ADMISSION_FACILITY,
                      inputType: "text",
                    },
                    model: {
                      value: _vm.test,
                      callback: function ($$v) {
                        _vm.test = $$v
                      },
                      expression: "test",
                    },
                  }),
                  _c("input-form", {
                    attrs: {
                      title: _vm.configData.TEXT_COL_MANAGEMENT_OFFICE,
                      inputType: "text",
                    },
                    model: {
                      value: _vm.test,
                      callback: function ($$v) {
                        _vm.test = $$v
                      },
                      expression: "test",
                    },
                  }),
                  _c("input-form", {
                    attrs: {
                      title: _vm.configData.TEXT_DOORS_NAME,
                      inputType: "text",
                    },
                    model: {
                      value: _vm.test,
                      callback: function ($$v) {
                        _vm.test = $$v
                      },
                      expression: "test",
                    },
                  }),
                  _c("input-form", {
                    attrs: {
                      title: _vm.configData.TEXT_WORK_CONTENT,
                      inputType: "text",
                    },
                    model: {
                      value: _vm.test,
                      callback: function ($$v) {
                        _vm.test = $$v
                      },
                      expression: "test",
                    },
                  }),
                  _c("input-form", {
                    attrs: {
                      title: _vm.configData.TEXT_APPLICATION_HISTORY,
                      inputType: "text",
                    },
                    model: {
                      value: _vm.test,
                      callback: function ($$v) {
                        _vm.test = $$v
                      },
                      expression: "test",
                    },
                  }),
                ],
                1
              ),
              _vm.isAction === 1
                ? _c(
                    "v-row",
                    { staticClass: "mb-2 mt-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.configData.TEXT_CLOSE_UP) + " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.showDialog("approvals")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.configData.TEXT_ADMIT) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.configData.TEXT_DIFFERENCE_RETURN) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAction === 2
                ? _c(
                    "v-row",
                    { staticClass: "mb-2 mt-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.configData.TEXT_CLOSE_UP) + " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        { staticClass: "btn-primary", attrs: { outlined: "" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.configData.TEXT_ADMIT_CANCELLATION) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.confirmDialog,
            callback: function ($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "modal-title", attrs: { color: "black" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.configData.TEXT_EXTENSION_APPLICANT_APPROVAL) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-card-text",
                { staticClass: "mt-5" },
                [
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _c("v-textarea", {
                    staticClass: "input-time",
                    attrs: {
                      color: "green",
                      outlined: "",
                      dense: "",
                      "hide-details": "auto",
                      autocomplete: "off",
                      maxlength: 40,
                      rows: "6",
                    },
                    model: {
                      value: _vm.comment,
                      callback: function ($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment",
                    },
                  }),
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _c("v-spacer"),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("action-approval", {
                                action: _vm.actions,
                                data: _vm.comment,
                              })
                            },
                          },
                        },
                        [_vm._v("OK")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              _vm.confirmDialog = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.configData.TEXT_RETURN))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }