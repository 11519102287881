
import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import ApplicantListTable from '@/components/organisms/ApplicantListTable/ApplicantListTable.vue';
import Searchbox, {
  SearchTargetItem,
} from '@/components/molecules/Searchbox/Searchbox.vue';
import * as Config from '@/config';
interface TypeSelect {
  text: string;
  value: string;
}
export interface DataType {
  searchTargetItems: SearchTargetItem[];
  selectedApplicantIds: string[];
  configData: any;
  priviledge: any;
}
export default Vue.extend({
  name: 'ApplicantTemplate',
  components: {
    ApplicantListTable,
    Searchbox,
    Breadcrumbs,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingSearch: {
      type: Boolean,
      default: false,
    },
    loadMore: {
      type: Boolean,
      default: false,
    },
    resData: {
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 100,
    },
    errorMessageLogs: {
      default: '',
    },
    dataArea: {
      default: [],
    },
    dataOffice: {
      default: () => [],
    },
  },
  data: (): DataType => ({
    searchTargetItems: [
      { label: Config.TEXT_COL_STATUS, name: 'status', value: ''},
      { label: Config.TEXT_COL_APPLICATION_NUMBER, name: 'request_id', value: '', maxLen: Config.REQUEST_ID_MAX_LEN},
      { label: Config.TEXT_COL_MANAGEMENT_OFFICE, name: 'office_list', value: ''},
      { label: Config.TEXT_COL_AREA, name: 'area_list', value: ''},
      { label: Config.TEXT_WORK_START_DATE, name: 'since', value: ''},
      { label: Config.TEXT_WORK_END_DATE, name: 'until', value: ''},
    ],
    selectedApplicantIds: [],
    configData : Config,
    priviledge: sessionStorage.getItem('priviledge'),
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: Config.TEXT_APPLICANT_STATUS, disabled: true },
        ];
      },
    },
  },
  methods: {
    //
    register() {
      this.$router.push({ path: Config.APPLICANT_ADD});
    },
  },
});
