var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _c(
        "v-container",
        [
          _c("applicant-add-form", {
            attrs: {
              loading: _vm.loading,
              isDataLoaded: _vm.isDataLoaded,
              isError: _vm.isError,
              resData: _vm.resData,
              dataItem: _vm.dataItem,
              resDataAreas: _vm.resDataAreas,
              resCardData: _vm.resCardData,
              resDataOffice: _vm.resDataOffice,
              resDataGates: _vm.resDataGates,
              isAction: _vm.isAction,
              resDataAreaItem: _vm.resDataAreaItem,
              confirmDialog: _vm.confirmDialog,
            },
            on: {
              showDialog: function ($event) {
                return _vm.$emit("showDialog", $event)
              },
              "change-office": function ($event) {
                return _vm.$emit("change-office", $event)
              },
              "change-area": function ($event) {
                return _vm.$emit("change-area", $event)
              },
              saveEntry: function ($event) {
                return _vm.$emit("saveEntry", $event)
              },
              updateEntry: function ($event) {
                return _vm.$emit("updateEntry", $event)
              },
              "reset-message": function ($event) {
                return _vm.$emit("reset-message", $event)
              },
              subEntry: function ($event) {
                return _vm.$emit("subEntry", $event)
              },
              reSubEntry: function ($event) {
                return _vm.$emit("reSubEntry", $event)
              },
              sendSubmissions: function ($event) {
                return _vm.$emit("sendSubmissions", $event)
              },
              sendSubmissionsEdit: function ($event) {
                return _vm.$emit("sendSubmissionsEdit", $event)
              },
              closeConfirmDialog: function ($event) {
                return _vm.$emit("closeConfirmDialog")
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }